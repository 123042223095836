import { ApolloMapQuery, ApolloMapQueryBookingStatus, LookupResultType } from "@freightsimple/generated-apollo-openapi-client";

import { message } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLookupApi } from "../Apis/Apis";

export function useLookup() {
  const createLookupApi = useLookupApi();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function onSearch(query: string) {
    //Setting search term in session storage so that it can be used in all shipments screen
    sessionStorage.setItem("searchTerm", query);
    setLoading(true);
    try {
      const apolloMapQuery: ApolloMapQuery = {
        freeTextQuery: "",
        bookingStatus: ApolloMapQueryBookingStatus.Any,
      };
      const lookupApi = await createLookupApi();
      const result = await lookupApi.lookup({
        query,
        apolloMapQuery: apolloMapQuery,
      });

      if (result.resultType === LookupResultType.Shipment && result?.resultId) {
        navigate(`/view-shipment?shipmentId=${result.resultId}`);
      } else if (
        result.resultType === LookupResultType.Company &&
        result?.resultId
      ) {
        navigate(`/view-company?companyId=${result.resultId}`);
      } else if (
        result.resultType === LookupResultType.Shipment &&
        result?.resultList
      ) {
        sessionStorage.setItem(
          "searchResults",
          JSON.stringify(result.resultList),
        );
        // Add the initial query state to prevent default dates in AllShipmentsScreen from being applied
        const searchParams = new URLSearchParams();
        searchParams.set("searchResults", Date.now().toString());
        searchParams.set("q", JSON.stringify({
          freeTextQuery: "",
          bookingStatus: ApolloMapQueryBookingStatus.Any,
          startDate: undefined,
          endDate: undefined
        }));
        navigate(`/all-shipments?${searchParams.toString()}`);
      }
    } catch {
      message.error(`Nothing found`);
    }
    setLoading(false);
  }

  return { onSearch, loading };
}
