import { Form, Modal } from "antd";
import { UseDisplayReturn } from "../../../../Hooks/useDisplay";
import { QuoteTestDashboardProps } from "../QuoteTestDashboard";
import { RefreshFunction } from "../../../../Types/RefreshFunctionType";
import {
  QuoteTestScenarioFromShipment,
  QuoteTestScenarioFromShipmentBody,
} from "../Forms/QuoteTestScenarioFromShipmentForm";
import { useQuoteTestScenarioApi } from "../Hooks/useCreateQuoteScenario";

export function QuoteTestScenarioFromShipmentModal(
  props: QuoteTestDashboardProps & {
    groupId: string;
    refresh: RefreshFunction;
    display: UseDisplayReturn;
  },
) {
  const { groupId, refresh, display } = props;
  const { createScenarioFromShipmentId, isLoading } = useQuoteTestScenarioApi();
  const [form] = Form.useForm<QuoteTestScenarioFromShipmentBody>();

  async function handleFinish(value: QuoteTestScenarioFromShipmentBody) {
    await createScenarioFromShipmentId(groupId, value.shipmentId);
    await refresh();
  }

  return (
    <Modal
      open={display.status}
      onCancel={display.hide}
      title="Create Scenario"
      confirmLoading={isLoading}
      okText="Create"
      onOk={form.submit}
    >
      <QuoteTestScenarioFromShipment onFinish={handleFinish} form={form} />
    </Modal>
  );
}
