import { useState } from "react";
import { useQuotesApi } from "../Apis/Apis";
import { ExtrasMenuProps } from "./ViewShipmentScreenComponents/ManageQuotesTab";
import { Form, Input, Menu, message, Modal, Typography } from "antd";
import Stack from "../Components/Stack";
import Spacer from "../Spacer";

const { Text } = Typography;

export function EditQuoteNotesButton(props: ExtrasMenuProps) {
    const createQuotesApi = useQuotesApi();
    const [customerVisibleNote, setCustomerVisibleNote] = useState<string | undefined>(props.quote.customerVisibleNote);
    const [internalNote, setInternalNote] = useState<string | undefined>(props.quote.internalNote);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [saving, setSaving] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    async function handleOk() {
        const quotesApi = await createQuotesApi();
        const quoteId = props.quote.quoteId!;

        try {
            setSaving(true);

            await quotesApi.updateQuoteNotes({
                quoteId,
                customerVisibleNote,
                internalNote,
            });
            await props.onRefresh();
            message.success("Notes updated");
            setIsModalVisible(false);
        } catch (e) {
            message.error(`Oops something went wrong : ${e}`);
        }
        setSaving(false);
    }

    const handleCancel = () => {
        setCustomerVisibleNote(props.quote.customerVisibleNote);
        setInternalNote(props.quote.internalNote);
        setIsModalVisible(false);
    };

    return (
        <>
            <Modal
                title="Edit Quote Notes"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={800}
                confirmLoading={saving}
                destroyOnClose
            >
                <Stack align="left">
                    <Text>
                        This allows you to edit both the Customer Visible Note in order to show it on the Dashboard and the Internal Note only visible in Apollo.
                    </Text>
                    <Spacer height={32} />
                    <Form
                        style={{
                            width: "100%",
                        }}
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 17, offset: 1 }}
                        layout="horizontal"
                        colon={false}
                    >
                        <Form.Item label="Customer Visible Note">
                            <Input
                                placeholder="Customer Visible Note"
                                value={customerVisibleNote}
                                onChange={function (e) {
                                    setCustomerVisibleNote(e.target.value);
                                }}
                                onKeyDown={(e) => e.stopPropagation()}
                            />
                        </Form.Item>
                        <Form.Item label="Internal Note">
                            <Input
                                placeholder="Internal Note"
                                value={internalNote}
                                onChange={function (e) {
                                    setInternalNote(e.target.value);
                                }}
                                onKeyDown={(e) => e.stopPropagation()}
                            />
                        </Form.Item>
                    </Form>
                </Stack>
            </Modal>
            <Menu.Item onClick={showModal} {...props}>
                Edit notes
            </Menu.Item>
        </>
    );
}