import { Empty } from "antd";
import { useParamState } from "../../../Hooks/useParamState";
import { QuoteTestDashboard } from "./QuoteTestDashboard";

export function QuoteTestScreen() {
  const [carrierIdentifier, setCarrierIdentifier] = useParamState<
    string | null
  >({ key: "carrierIdentifier", defaultValue: null });

  const [serviceIdentifier, setServiceIdentifier] = useParamState<
    string | null
  >({ key: "serviceIdentifier", defaultValue: null });

  if (!carrierIdentifier || !serviceIdentifier) return <Empty />;

  return (
    <QuoteTestDashboard
      carrierIdentifier={carrierIdentifier}
      setCarrierIdentifier={setCarrierIdentifier}
      serviceIdentifier={serviceIdentifier}
      setServiceIdentifier={setServiceIdentifier}
    />
  );
}
