import {
  LineItem,
  PostQuoteTestScenarioBody,
} from "@freightsimple/generated-apollo-openapi-client";
import { Button, Form, FormProps, Input, Select } from "antd";
import { LocationEditor } from "../../../ViewShipmentScreenComponents/LocationEditor";
import { LineItemsTable } from "../../../ViewShipmentScreenComponents/LineItemsTable";

export function QuoteTestScenarioForm(
  props: FormProps<PostQuoteTestScenarioBody> & { isEdit?: boolean },
) {
  type FormType = PostQuoteTestScenarioBody;

  const { form, isEdit = false } = props;

  Form.useWatch("pickupLocation", form);
  Form.useWatch("deliveryLocation", form);
  Form.useWatch("lineItems", form);

  return (
    <Form {...props} name="basic" autoComplete="off">
      <Form.Item<FormType>
        label="Title"
        name="title"
        rules={[
          { required: true, message: "Please input a title for this group" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item<FormType>
        label="Description"
        name="description"
        rules={[
          {
            required: true,
            message: "Please input a description",
          },
        ]}
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item<FormType> label="Linear IDs" name={["metadata", "linearIds"]}>
        <Select
          mode="tags"
          placeholder="Enter Shipment IDs"
          open={false}
          suffixIcon={false}
        />
      </Form.Item>
      <Form.Item<FormType>
        label="Shipment IDs"
        name={["metadata", "shipmentIds"]}
      >
        <Select
          mode="tags"
          placeholder="Enter Shipment IDs"
          open={false}
          suffixIcon={false}
        />
      </Form.Item>

      {!isEdit && (
        <>
          <Form.Item<FormType> label={null} name="pickupLocation">
            <LocationEditor
              location={form?.getFieldValue("pickupLocation")}
              contact={{}}
              reference=""
              booth=""
              deadline=""
              setLocation={(location) => {
                form?.setFieldValue("pickupLocation", location);
              }}
              setContact={() => {}}
              setReference={() => {}}
              setBooth={() => {}}
              setDeadline={() => {}}
              showAppointment={false}
              adjective="Pickup"
              pickupDate={undefined}
            />
          </Form.Item>
          <Form.Item<FormType> label={null} name="deliveryLocation">
            <LocationEditor
              location={form?.getFieldValue("deliveryLocation")}
              contact={{}}
              reference=""
              booth=""
              deadline=""
              setLocation={(location) => {
                form?.setFieldValue("deliveryLocation", location);
              }}
              setContact={() => {}}
              setReference={() => {}}
              setBooth={() => {}}
              setDeadline={() => {}}
              showAppointment={false}
              adjective="Pickup"
              pickupDate={undefined}
            />
          </Form.Item>
          <Form.Item<FormType> label={null} name="lineItems">
            <LineItemsTable
              lineItems={form?.getFieldValue("lineItems") ?? []}
              editable
              updateLineItems={(items) =>
                form?.setFieldValue("lineItems", items)
              }
            />
          </Form.Item>
          <Button
            onClick={() => {
              const items = (form?.getFieldValue("lineItems") ??
                []) as LineItem[];
              form?.setFieldValue("lineItems", items.concat(items[0]));
            }}
          >
            Add Line Item
          </Button>
        </>
      )}
    </Form>
  );
}
