import { Note } from "@freightsimple/generated-apollo-openapi-client";
import { createContext, ReactNode, useState } from "react";

export interface InternalNotesContextProps {
  openModal: boolean;
  handleOpenModal: () => void;
  newNote: Note;
  setNote: (noteId: Note) => void;
}

const InternalNotesContext = createContext<
  InternalNotesContextProps | undefined
>(undefined);

const InternalNotesProvider = ({ children }: { children: ReactNode }) => {
  const [openModal, setOpenModal] = useState(false);
  const [note, setNote] = useState<Note>({});

  const handleOpenModal = () => setOpenModal(!openModal);

  const newNote = note;

  return (
    <InternalNotesContext.Provider
      value={{ openModal, handleOpenModal, setNote, newNote }}
    >
      {children}
    </InternalNotesContext.Provider>
  );
};

export { InternalNotesContext, InternalNotesProvider };
