import {
  Company,
  LogSalesCallInput,
  SalesContact,
} from "@freightsimple/generated-apollo-openapi-client";
import { Button, Modal, Popconfirm, message, notification } from "antd";
import { Link } from "react-router-dom";
import { useCompaniesApi } from "../../../Apis/Apis";
import { ButtonRow } from "../../../Components/ButtonRow";
import HorizontalStack from "../../../Components/HorizontalStack";
import Stack from "../../../Components/Stack";
import { UseDisplayReturn } from "../../../Hooks/useDisplay";
import { LogSalesCallForm } from "./LogSalesCallForm";
import { useCallContext } from "../../../Contexts/CallContext/useCallContext";
import { CompanyName } from "../../../Components/CompanyName";

interface OutboundLogSalesCallModalProps {
  display: UseDisplayReturn;
  company: Company;
  salesContacts: SalesContact[];
  onRefresh?: () => Promise<void>;
}

export function OutboundLogSalesCallModal({
  display,
  company,
  salesContacts,
}: OutboundLogSalesCallModalProps) {
  const { endCall, callData, handleLogCallDataChange, setSaving } =
    useCallContext();

  const createCompaniesApi = useCompaniesApi();

  async function handleSubmit(input: LogSalesCallInput) {
    if (!callData) {
      message.error("Something went wrong!");
      return;
    }
    setSaving(true);
    try {
      const companiesApi = await createCompaniesApi();
      await companiesApi.logSalesCall({
        logSalesCallInput: {
          ...input,
          companyNoteId: callData.companyNoteId,
        },
      });

      notification.success({
        message: "Sales Call Notes Saved!",
        description: (
          <>
            Sales call notes successfully saved for
            <div>
              <a
                href={`/view-company?companyId=${company.companyId}`}
                target="_blank"
                rel="noreferrer"
              >
                <CompanyName
                  companyName={company.companyName}
                  qualificationStatus={company.qualificationStatus}
                  temperature={company.temperature}
                  shipmentFrequency={company.shipmentFrequency}
                />
              </a>
            </div>
          </>
        ),
      });
      endCall();
    } catch (e) {
      console.error(e);
      notification.error({
        message: "Eek. Something went wrong",
        description: (
          <>
            Unable to save sales call notes for
            <div>
              <a
                href={`/view-company?companyId=${company.companyId}`}
                target="_blank"
                rel="noreferrer"
              >
                <CompanyName
                  companyName={company.companyName}
                  qualificationStatus={company.qualificationStatus}
                  temperature={company.temperature}
                  shipmentFrequency={company.shipmentFrequency}
                />
              </a>
            </div>
          </>
        ),
      });
    }
    setSaving(false);
    display.hide();
  }

  function handleCancel() {
    display.hide();
    endCall();
  }

  if (!callData) {
    return null;
  }

  return (
    <Modal
      title={
        <Stack align="left">
          <span>Log Sales Call</span>
          <Link
            to={`/view-company?companyId=${
              callData?.companyId ?? company.companyId
            }`}
            onClick={display.hide}
          >
            <CompanyName
              companyName={company.companyName}
              qualificationStatus={company.qualificationStatus}
              temperature={company.temperature}
              shipmentFrequency={company.shipmentFrequency}
            />
          </Link>
        </Stack>
      }
      open={display.status}
      onCancel={display.hide}
      footer={
        <HorizontalStack align="right">
          <ButtonRow>
            <Popconfirm
              title={
                <>
                  <span style={{ fontWeight: "500" }}>
                    Are you sure you want to discard these sales call notes?
                  </span>
                  <br />
                  This will close this dialog permanently and hide the sales
                  call notes button,
                  <br /> you will not be able to enter notes for the current
                  sales call.
                </>
              }
              okText="Yes"
              cancelText="No"
              onConfirm={handleCancel}
            >
              <Button danger type="text">
                Discard
              </Button>
            </Popconfirm>
            <LogSalesCallForm.SubmitButton loading={callData.saving} />
          </ButtonRow>
        </HorizontalStack>
      }
      width={800}
      destroyOnClose
    >
      <LogSalesCallForm
        company={company}
        salesContacts={salesContacts}
        form={callData}
        handleFormChange={handleLogCallDataChange}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
}
