import { QuoteTestExpectationType } from "@freightsimple/generated-apollo-openapi-client";

export function describeQuoteTestExpectationType(
  type: QuoteTestExpectationType | undefined,
): string {
  switch (type) {
    case QuoteTestExpectationType.QuotePriceByCarrer:
      return "Quoted Price By Carrier";
    case QuoteTestExpectationType.ResponseRegex:
      return "Response Regex";
    case QuoteTestExpectationType.RequestRegex:
      return "Request Regex";
    default:
      return "Unset Type";
  }
}
