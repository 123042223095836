import { Form, Modal } from "antd";
import { UseDisplayReturn } from "../../../../Hooks/useDisplay";
import { QuoteTestDashboardProps } from "../QuoteTestDashboard";
import {
  PostQuoteTestScenarioBody,
} from "@freightsimple/generated-apollo-openapi-client";
import { QuoteTestScenarioForm } from "../Forms/QuoteTestScenarioForm";
import { useBaseScenario } from "../Hooks/useFetchBasicScenario";
import { RefreshFunction } from "../../../../Types/RefreshFunctionType";
import { useCreateQuoteTestPermutations } from "../Hooks/useCreateQuoteTestPermutations";
import { Loading } from "../../../../Components/Loading";

export function QuoteTestPermutationFormModal(
  props: QuoteTestDashboardProps & {
    refresh: RefreshFunction;
    display: UseDisplayReturn;
  },
) {
  const { refresh, carrierIdentifier, serviceIdentifier, display } = props;
  const { data } = useBaseScenario(null);
  const { create, isLoading } = useCreateQuoteTestPermutations();
  const [form] = Form.useForm<PostQuoteTestScenarioBody>();

  async function handleFinish(value: PostQuoteTestScenarioBody) {
    await create({ ...value, carrierIdentifier, serviceIdentifier });
    await refresh();
    display.hide();
  }

  if (!data) return <Loading />;

  return (
    <>
      <Modal
        open={display.status}
        onCancel={display.hide}
        title="Create Permutation Group"
        confirmLoading={isLoading}
        okText="Create"
        onOk={form.submit}
        width={1600}
      >
        <QuoteTestScenarioForm
          initialValues={{
            ...data,
            title: "Base Permutations",
            description: "Group for permutation testing purposes",
          }}
          onFinish={handleFinish}
          form={form}
        />
      </Modal>
    </>
  );
}
