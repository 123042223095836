import { Avatar } from "antd";
import { CSSProperties, ReactNode } from "react";
import { stringToColor } from "../Helpers/stringToColor";
import Colors from "./Colors";

interface CommentProps {
  author?: string;
  date?: ReactNode;
  actions?: ReactNode;
  children: ReactNode;
  style?: CSSProperties;
}

export function Comment(props: CommentProps) {
  const { author, date, children, actions, style } = props;

  const name = author
    ?.split(".")
    .reduce((prev, n) => prev + n.substring(0, 1), "")
    .toUpperCase();

  const color = stringToColor(author ?? "unknown");

  return (
    <div style={{ display: "flex", columnGap: 8, ...style }}>
      <Avatar size={48} style={{ background: color }}>
        {name}
      </Avatar>
      <div>
        <div>
          <span style={{ fontSize: 12 }}>{author}</span>
          <span
            style={{ fontSize: 12, marginLeft: 8, color: Colors.LightText }}
          >
            {date}
          </span>
        </div>
        <div style={{ paddingBlock: 4, whiteSpace: "pre-wrap" }}>
          {children}
        </div>
        {actions}
      </div>
    </div>
  );
}
