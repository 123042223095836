import { useState } from "react";
import { useApiKeysApi } from "../Apis/Apis";
import { ApiKeyWithCompanyAndUsageSummaries } from "@freightsimple/generated-apollo-openapi-client";
import { Page } from "../Components/Page";
import { StatsRow } from "../Components/StatsRow";
import { message, Statistic, Input, Select, Button, DatePicker } from "antd";
import PageTitle from "../Components/PageTitle";
import { useOnce } from "../Hooks/useOnce";
import { Loading } from "../Components/Loading";
import { ApiKeysTable, ApiKeyWithCompanyName } from "./ApiKeysTable";
import { Link } from "react-router-dom";
const { RangePicker } = DatePicker;
import dayjs from "dayjs";
import HorizontalStack from "../Components/HorizontalStack";
import { InfoBubble } from "../Components/InfoBubble";
import Spacer from "../Spacer";

const { Option } = Select;

export function ViewApiKeysScreen() {
  const createApiKeysApi = useApiKeysApi();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<ApiKeyWithCompanyAndUsageSummaries[]>(
    [],
  );
  const [apiKeys, setApiKeys] = useState<ApiKeyWithCompanyName<true>[]>([]);
  const [isActiveFilter, setIsActiveFilter] = useState<string | undefined>(
    undefined,
  );
  const [combinedFilter, setCombinedFilter] = useState("");
  const [createdAtFilter, setCreatedAtFilter] = useState<
    [string, string] | null
  >(null);
  const [expiresAtFilter, setExpiresAtFilter] = useState<
    [string, string] | null
  >(null);
  const [lastUsedAtFilter, setLastUsedAtFilter] = useState<
    [string, string] | null
  >(null);
  const [revokedAtFilter, setRevokedAtFilter] = useState<
    [string, string] | null
  >(null);

  async function refresh() {
    setLoading(true);
    const apiKeysApi = await createApiKeysApi();

    try {
      const response = await apiKeysApi.listAllApiKeys({
        textSearch: combinedFilter,
        isActive: isActiveFilter ? isActiveFilter === "true" : null,
        createdAt: createdAtFilter,
        expiresAt: expiresAtFilter,
        lastUsed: lastUsedAtFilter,
        revokedAt: revokedAtFilter,
      });
      setResult(response);
      setLoading(false);
      const apiKeysWithCompanyName = response.map((record) => ({
        ...record.apiKey,
        companyName: record.company.companyName || "",
      }));
      setApiKeys(apiKeysWithCompanyName);
    } catch (e) {
      // @ts-expect-error - type is unknown
      if (e.status === 404) {
        message.error("Could not find API Keys");
      } else {
        message.error(`Could not load`);
      }
      setLoading(false);
    }
  }

  useOnce(refresh);

  if (loading) {
    return <Loading message="Loading API Keys" />;
  } else {
    return (
      <>
        <Page
          title="All API Keys"
          tags={[]}
          stats={
            <StatsRow>
              <Statistic
                title="Total API Keys"
                prefix=""
                value={result.length}
                style={{
                  margin: "0 32px",
                }}
              />
            </StatsRow>
          }
          extra={[]}
        >
          <PageTitle>{`API Keys`}</PageTitle>
          <div
            style={{
              marginBottom: "20px",
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            <Input
              placeholder="Filter by Company Name, API Key Name, or Description"
              value={combinedFilter}
              allowClear
              onChange={(e) => {
                setCombinedFilter(e.target.value);
              }}
              style={{ width: "400px" }}
            />
            <Select
              placeholder="Filter by Status"
              value={isActiveFilter}
              allowClear
              onChange={(value) => {
                if (value == "") {
                  setIsActiveFilter(undefined);
                }
                setIsActiveFilter(value);
              }}
              style={{ width: "200px" }}
            >
              <Option value="true">Active</Option>
              <Option value="false">Inactive</Option>
            </Select>
            <RangePicker
              placeholder={["Created At Start", "Created At End"]}
              allowClear
              value={[
                createdAtFilter ? dayjs(createdAtFilter[0]) : null,
                createdAtFilter ? dayjs(createdAtFilter[1]) : null,
              ]}
              onChange={function (e) {
                if (e === null || e[0] === null || e[1] === null) {
                  setCreatedAtFilter(null);
                } else {
                  const startDate = e[0].format("YYYY-MM-DD");
                  const endDate = e[1].format("YYYY-MM-DD");
                  setCreatedAtFilter([startDate, endDate]);
                }
              }}
            />
            <RangePicker
              placeholder={["Expires At Start", "Expires At End"]}
              allowClear
              value={[
                expiresAtFilter ? dayjs(expiresAtFilter[0]) : null,
                expiresAtFilter ? dayjs(expiresAtFilter[1]) : null,
              ]}
              onChange={function (e) {
                if (e === null || e[0] === null || e[1] === null) {
                  setExpiresAtFilter(null);
                } else {
                  const startDate = e[0].format("YYYY-MM-DD");
                  const endDate = e[1].format("YYYY-MM-DD");
                  setExpiresAtFilter([startDate, endDate]);
                }
              }}
            />
            <RangePicker
              placeholder={["Last Used At Start", "Last Used At End"]}
              allowClear
              value={[
                lastUsedAtFilter ? dayjs(lastUsedAtFilter[0]) : null,
                lastUsedAtFilter ? dayjs(lastUsedAtFilter[1]) : null,
              ]}
              onChange={function (e) {
                if (e === null || e[0] === null || e[1] === null) {
                  setLastUsedAtFilter(null);
                } else {
                  const startDate = e[0].format("YYYY-MM-DD");
                  const endDate = e[1].format("YYYY-MM-DD");
                  setLastUsedAtFilter([startDate, endDate]);
                }
              }}
            />
            <RangePicker
              placeholder={["Revoked At Start", "Revoked At End"]}
              allowClear
              value={[
                revokedAtFilter ? dayjs(revokedAtFilter[0]) : null,
                revokedAtFilter ? dayjs(revokedAtFilter[1]) : null,
              ]}
              onChange={function (e) {
                if (e === null || e[0] === null || e[1] === null) {
                  setRevokedAtFilter(null);
                } else {
                  const startDate = e[0].format("YYYY-MM-DD");
                  const endDate = e[1].format("YYYY-MM-DD");
                  setRevokedAtFilter([startDate, endDate]);
                }
              }}
            />
            <HorizontalStack>
              <Button type="primary" onClick={refresh}>
                Filter
              </Button>
              <Spacer width={10} />
              <InfoBubble overlay="Every time you change or clear the filters make sure to press the 'Filter' button!" />
            </HorizontalStack>
          </div>
          <ApiKeysTable<true>
            data={apiKeys}
            onRefresh={refresh}
            additionalColumns={[
              {
                index: 0,
                column: {
                  title: "Company",
                  dataIndex: "companyName",
                  key: "companyName",
                  render: (companyName: string, record) => {
                    return (
                      <Link
                        target="_blank"
                        to={`/view-company?companyId=${record.companyId}`}
                      >
                        {companyName}
                      </Link>
                    );
                  },
                  width: "150px",
                },
              },
            ]}
          />
        </Page>
      </>
    );
  }
}
