import { Checkbox, Modal, ModalFuncProps, CheckboxProps } from "antd";
import { useEffect, useState, ReactNode } from "react";
import Spacer from "../Spacer";

interface SecureConfirmModalProps extends ModalFuncProps {
  children?: ReactNode;
  loading?: boolean;
}

export function SecureConfirmModal({
  children,
  open,
  loading,
  ...modalProps
}: SecureConfirmModalProps) {
  const [count, setCount] = useState(7);
  const [allowConfirm, setAllowConfirm] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (open) {
      setCount(7);
      timer = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prevCount - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [open]);

  useEffect(() => {
    setAllowConfirm(count === 0 && isChecked);
  }, [count, isChecked]);

  const handleCheckbox: CheckboxProps["onChange"] = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <Modal
      open={open}
      okText={`Confirm${count !== 0 ? ` (${count})` : ""}`}
      okButtonProps={{ disabled: !allowConfirm, loading: loading }}
      {...modalProps}
    >
      {children}
      <Spacer height={20} />
      <Checkbox onChange={handleCheckbox}>
        I have carefully reviewed all the details and agree to proceed.
      </Checkbox>
    </Modal>
  );
}
