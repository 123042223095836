import {
  ListSalesContactsRow,
  QualificationStatus,
} from "@freightsimple/generated-apollo-openapi-client";
import { Form } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCompaniesApi } from "../Apis/Apis";
import { DataTable, DataTableColumn } from "../Components/DataTable";
import HorizontalStack from "../Components/HorizontalStack";
import { Loading } from "../Components/Loading";
import PageTitle from "../Components/PageTitle";
import { Panel } from "../Components/Panel";
import Stack from "../Components/Stack";
import { ViewCompanyButton } from "../Components/ViewCompanyButton";
import { emojiForShipmentVolume } from "../Helpers/emojiForShipmentVolume";
import { stringSorter } from "../Helpers/stringSorter";
import { useOnce } from "../Hooks/useOnce";
import useQuery from "../Hooks/useQuery";
import Spacer from "../Spacer";
import { Search } from "../Types/AntHelperTypes";
import { emojiForTemperature } from "../Helpers/emojiForTemperature";
import { isBlank, isNotBlank } from "@freightsimple/shared";
import { Page } from "../Components/Page";

interface SalesContactsTableProps {
  salesContacts: Array<ListSalesContactsRow>;
}

function companyNameDescription(o: ListSalesContactsRow) {
  const uq =
    o.qualificationStatus === QualificationStatus.Unqualified ? "🎈" : "";
  const sv = o.shipmentFrequency
    ? emojiForShipmentVolume(o.shipmentFrequency)
    : undefined;
  const temp = o.temperature ? emojiForTemperature(o.temperature) : undefined;
  return [uq, temp, o.companyName, sv].filter((o) => o).join(" ");
}

function SalesContactsTable(props: SalesContactsTableProps) {
  const columns: DataTableColumn<ListSalesContactsRow>[] = [
    {
      title: "Name",
      render: (o) => (
        <Stack align="left">
          <div>
            <div style={{ width: "200px" }}>{o.name}</div>
          </div>
        </Stack>
      ),
      sorter: function (a, b) {
        return stringSorter(a.name, b.name);
      },
    },
    {
      title: "Company Name",
      render: (o) => (
        <Stack align="left">
          <div>
            <div style={{ width: "200px" }}>{companyNameDescription(o)}</div>
          </div>
        </Stack>
      ),
      sorter: function (a, b) {
        return b.companyName.localeCompare(a.companyName);
      },
    },
    {
      title: "Email",
      render: (o) => <div>{o.email}</div>,
    },
    {
      title: "Phone",
      render: (o) => <div>{o.phone}</div>,
    },
    {
      title: "Mobile",
      render: (o) => <div>{o.mobilePhone}</div>,
    },
    {
      title: "Description",
      render: (o) => <div>{o.description}</div>,
    },
    {
      title: "Actions",
      render: function (o) {
        return (
          <HorizontalStack>
            <ViewCompanyButton companyId={o.companyId} />
          </HorizontalStack>
        );
      },
    },
  ];

  return (
    <DataTable pagination={{}} columns={columns} data={props.salesContacts} />
  );
}

export function ListSalesContactsScreen() {
  const urlQuery = useQuery();
  const navigate = useNavigate();
  const createCompaniesApi = useCompaniesApi();
  const [salesContacts, setSalesContacts] = useState<ListSalesContactsRow[]>();
  const [searchValue, setSearchValue] = useState(
    (urlQuery.searchValue as string) ?? "",
  );

  async function refresh() {
    const companiesApi = await createCompaniesApi();

    const response = await companiesApi.listSalesContacts();
    setSalesContacts(response);
  }

  useOnce(refresh);

  useEffect(
    function () {
      let url = `/list-sales-contacts?`;
      if (searchValue !== "") {
        url += `&searchValue=${searchValue}`;
      }

      navigate(url, {
        replace: true,
      });
    },

    [searchValue],
  );

  if (salesContacts === undefined) {
    return <Loading />;
  }

  function normalizePhoneNumber(phone: string): string {
    return phone.replace(/\D/g, ""); // Remove all non-numeric characters
  }

  function doFilterSalesContact(sc: ListSalesContactsRow): boolean {
    if (isBlank(searchValue)) {
      return true;
    }

    const lowerSearchValue = searchValue.toLowerCase();
    const numericSearchValue = normalizePhoneNumber(searchValue);

    // Normalize potential phone number fields
    const normalizedPhone = normalizePhoneNumber(sc.phone ?? "");
    const normalizedMobile = normalizePhoneNumber(sc.mobilePhone ?? "");

    const nameMatch = sc.name?.toLowerCase().includes(lowerSearchValue);
    const emailMatch = sc.email?.toLowerCase().includes(lowerSearchValue);
    const companyNameMatch = sc.companyName
      ?.toLowerCase()
      .includes(lowerSearchValue);
    const descriptionMatch = sc.description
      ?.toLowerCase()
      .includes(lowerSearchValue);
    const phoneMatch =
      isNotBlank(numericSearchValue) &&
      normalizedPhone.includes(numericSearchValue);
    const mobileMatch =
      isNotBlank(numericSearchValue) &&
      normalizedMobile.includes(numericSearchValue);

    return (
      nameMatch ||
      emailMatch ||
      companyNameMatch ||
      descriptionMatch ||
      phoneMatch ||
      mobileMatch
    );
  }

  const filteredSalesContacts = salesContacts.filter(doFilterSalesContact);

  return (
    <>
      <Page
        title={`List Sales Contacts (${filteredSalesContacts.length}/${salesContacts.length})`}
        tags={[]}
        stats={
          <Stack align="left">
            <HorizontalStack>
              <Form.Item label="Filter">
                <Search
                  value={searchValue}
                  placeholder="Filter by name, email, phone, etc"
                  allowClear
                  onChange={function (e) {
                    setSearchValue(e.target.value);
                  }}
                  style={{ width: "400px" }}
                />
              </Form.Item>
            </HorizontalStack>
          </Stack>
        }
        extra={[]}
      >
        <PageTitle>List Sales Contacts</PageTitle>
        <Panel>
          <Spacer height={16} />
          <SalesContactsTable salesContacts={filteredSalesContacts} />
        </Panel>
      </Page>
    </>
  );
}
