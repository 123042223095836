import {
  Company,
  QualificationStatus,
} from "@freightsimple/generated-apollo-openapi-client";
import { emojiForShipmentVolume } from "../Helpers/emojiForShipmentVolume";
import { emojiForTemperature } from "../Helpers/emojiForTemperature";
import { Tooltip } from "antd";

type CompanyNameProps = Pick<
  Company,
  "qualificationStatus" | "temperature" | "companyName" | "shipmentFrequency"
>;

export function CompanyName(props: CompanyNameProps) {
  const { qualificationStatus, temperature, companyName, shipmentFrequency } =
    props;

  return (
    <div>
      {companyName}{" "}
      <Tooltip
        key="qualificationStatus"
        placement="topRight"
        title={qualificationStatus}
      >
        {qualificationStatus === QualificationStatus.Unqualified ? "🎈" : ""}
      </Tooltip>
      <Tooltip key="temperature" placement="topRight" title={temperature}>
        {emojiForTemperature(temperature)}
      </Tooltip>
      <Tooltip
        key="shipmentFrequency"
        placement="topRight"
        title={shipmentFrequency}
      >
        {emojiForShipmentVolume(shipmentFrequency)}
      </Tooltip>
    </div>
  );
}
