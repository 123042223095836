import {
  PostQuoteTestExpectationBody,
  QuoteTestExpectationType,
} from "@freightsimple/generated-apollo-openapi-client";
import { Form, FormProps, Input, Select } from "antd";
import { CurrencySelector } from "../../../CurrencySelector";

export function QuoteTestExpectationForm(
  props: FormProps<PostQuoteTestExpectationBody>,
) {
  type FormType = PostQuoteTestExpectationBody;

  const { form } = props;

  const type = Form.useWatch("type", form);

  const currency = Form.useWatch(["metadata", "currency"], form);

  return (
    <Form
      {...props}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      autoComplete="off"
    >
      <Form.Item<FormType>
        label="Title"
        name="type"
        rules={[
          { required: true, message: "Please input a title for this group" },
        ]}
      >
        <Select
          options={Object.entries(QuoteTestExpectationType).map(
            ([first, second]) => ({
              value: second,
              label: first,
            }),
          )}
        />
      </Form.Item>

      <Form.Item<FormType>
        label="Description"
        name="description"
        rules={[
          {
            required: true,
            message: "Please input a description",
          },
        ]}
      >
        <Input.TextArea />
      </Form.Item>

      {type === QuoteTestExpectationType.QuotePriceByCarrer && (
        <>
          <Form.Item<FormType> label="Currency" name={["metadata", "currency"]} required>
            <CurrencySelector
              currency={currency}
              setCurrency={(value) => {
                form?.setFieldValue(["metadata", "currency"], value);
              }}
            />
          </Form.Item>
          <Form.Item<FormType> label="Amount" name={["metadata", "amount"]} required>
            <Input />
          </Form.Item>
        </>
      )}

      {(type === QuoteTestExpectationType.RequestRegex ||
        type === QuoteTestExpectationType.ResponseRegex) && (
        <Form.Item<FormType> label="Regex" name={["metadata", "regex"]} required>
          <Input />
        </Form.Item>
      )}
    </Form>
  );
}
