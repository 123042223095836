import { useState } from "react";
import { useQuoteTestApi } from "../../../../Apis/Apis";

export function useExportQuoteTestGroup() {
  const createApi = useQuoteTestApi();

  const [isLoading, setIsLoading] = useState(false);

  const exportData = async (groupId: string) => {
    setIsLoading(() => true);
    try {
      const api = await createApi();
      return await api.exportData({ groupId });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(() => false);
  };

  return { exportData, isLoading };
}
