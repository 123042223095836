import { QuoteTestExecutionComment } from "@freightsimple/generated-apollo-openapi-client";
import dayjs from "dayjs";
import { Comment } from "../../../../Components/Comment";
import { Button, Divider, Popconfirm, Tooltip } from "antd";
import { RefreshFunction } from "../../../../Types/RefreshFunctionType";
import {
  DeleteOutlined,
  HistoryOutlined,
  PushpinFilled,
  PushpinOutlined,
} from "@ant-design/icons";
import HorizontalStack from "../../../../Components/HorizontalStack";
import { useQuoteTestExecutionCommentApi } from "../Hooks/useQuoteTestExecutionCommentApi.tsx";
import Stack from "../../../../Components/Stack.tsx";
import { Fragment } from "react/jsx-runtime";
import useQuery from "../../../../Hooks/useQuery.tsx";

interface QuoteTestExecutionCommentListProps {
  comments: Array<QuoteTestExecutionComment>;
  refresh: RefreshFunction;
  setExecutionId: (_: string | null) => void;
  showGoToExecution?: boolean;
}

export function QuoteTestExecutionCommentList(
  props: QuoteTestExecutionCommentListProps,
) {
  const { comments, refresh, setExecutionId, showGoToExecution = false } = props;

  return (
    <Stack align="left" style={{ gap: 12 }}>
      {comments.map((comment, i) => (
        <Fragment key={comment.executionCommentId!}>
          {i > 0 && <Divider dashed style={{ margin: 4 }} />}
          <QuoteTestExecutionCommentComponent
            showGoToExecution={showGoToExecution}
            comment={comment}
            refresh={refresh}
            setExecutionId={setExecutionId}
          />
        </Fragment>
      ))}
    </Stack>
  );
}

export function QuoteTestExecutionCommentComponent(props: {
  comment: QuoteTestExecutionComment;
  refresh: RefreshFunction;
  setExecutionId: (_: string | null) => void;
  showGoToExecution?: boolean;
}) {
  const { comment, refresh, setExecutionId, showGoToExecution } = props;

  const query = useQuery()

  const executionId = JSON.parse(query.executionId  as string | undefined ?? "");

  const date = dayjs(comment.createdAt);

  const { update, deleteComment, isLoading } =
    useQuoteTestExecutionCommentApi();

  async function handlePinComment(comment: QuoteTestExecutionComment) {
    await update({ ...comment, isPinned: !comment.isPinned });
    await refresh();
  }

  async function handleDelete() {
    await deleteComment(comment.executionCommentId!);
    await refresh();
  }

  return (
    <Comment
      key={comment.executionCommentId}
      author={comment.createdBy}
      date={
        <Tooltip title={date.format("MMM Do YYYY hh:mm")}>
          {date.fromNow()}
        </Tooltip>
      }
      actions={
        <HorizontalStack style={{ paddingBlock: 4, gap: 12 }}>
          <Button
            loading={isLoading}
            size="small"
            type="text"
            icon={comment.isPinned ? <PushpinFilled /> : <PushpinOutlined />}
            onClick={() => handlePinComment(comment)}
            style={{ fontSize: 12 }}
          >
            Pin
          </Button>
          {showGoToExecution && executionId !== comment.executionId && (
            <Button
              type="text"
              icon={<HistoryOutlined />}
              onClick={() => setExecutionId(comment.executionId!)}
              style={{ fontSize: 12 }}
            >
              Go to Execution
            </Button>
          )}
          <Popconfirm
            title="Delete comment"
            description="Are you sure to delete this comment?"
            onConfirm={handleDelete}
            okType="danger"
            okText="Delete"
            cancelText="Cancel"
          >
            <Button
              danger
              loading={isLoading}
              size="small"
              type="text"
              icon={<DeleteOutlined />}
              style={{ fontSize: 12 }}
            >
              Delete
            </Button>
          </Popconfirm>
        </HorizontalStack>
      }
    >
      {comment.text}
    </Comment>
  );
}
