import { Form, Modal } from "antd";
import { UseDisplayReturn } from "../../../../Hooks/useDisplay";
import { QuoteTestDashboardProps } from "../QuoteTestDashboard";
import {
  PostQuoteTestExpectationBody,
  QuoteTestExpectationType,
} from "@freightsimple/generated-apollo-openapi-client";
import { useQuoteTestExpectationApi } from "../Hooks/useQuoteTestExpectationApi.tsx";
import { QuoteTestExpectationForm } from "../Forms/QuoteTestGroupExpectationForm";
import { RefreshFunction } from "../../../../Types/RefreshFunctionType";

export function QuoteTestScenarioExpectationModal(
  props: QuoteTestDashboardProps & {
    scenarioId: string;
    refresh: RefreshFunction;
    display: UseDisplayReturn;
  },
) {
  const { scenarioId, refresh, display } = props;
  const { createScenarioExpectation, isLoading } = useQuoteTestExpectationApi();
  const [form] = Form.useForm<PostQuoteTestExpectationBody>();

  async function handleFinish(values: PostQuoteTestExpectationBody) {
    await createScenarioExpectation(scenarioId, values);
    await refresh();
    display.hide();
  }
  return (
    <>
      <Modal
        open={display.status}
        onCancel={display.hide}
        title="Create Scenario Expectation"
        confirmLoading={isLoading}
        okText="Create"
        onOk={form.submit}
        width={700}
      >
        <QuoteTestExpectationForm
          initialValues={{
            type: QuoteTestExpectationType.QuotePriceByCarrer,
            description: "",
          }}
          onFinish={handleFinish}
          form={form}
        />
      </Modal>
    </>
  );
}
