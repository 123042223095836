import { formatPhoneNumber } from "../Helpers/formatPhoneNumber";
import { SavedLocation } from "@freightsimple/generated-apollo-openapi-client";

export function unformatPhoneNumber(phoneNumber: string) {
  return phoneNumber.replace(/\D/g, "");
}

export function sanitizePhoneExtension(phoneNumberExtension: string) {
  return phoneNumberExtension.replace(/\D/g, "").slice(0, 6);
}

export function handlePhoneInputChange(
  e: React.ChangeEvent<HTMLInputElement>,
  currentPhone: string,
  setPhone: (value: string) => void,
) {
  let newRawDigits = unformatPhoneNumber(e.target.value);

  // Handle backspace edge case
  if (newRawDigits === currentPhone && newRawDigits.length < 10) {
    newRawDigits = newRawDigits.slice(0, -1);
  }

  // Format phone number length
  if (newRawDigits[0] === "1") {
    newRawDigits = newRawDigits.slice(0, 11);
  } else {
    newRawDigits = newRawDigits.slice(0, 10);
  }

  const formatted = formatPhoneNumber(newRawDigits);

  // Handle cursor position after formatting
  if (
    formatted.endsWith(")") &&
    e?.currentTarget.selectionStart === e.target.value.length &&
    e?.currentTarget.selectionEnd === e.target.value.length
  ) {
    const currentTarget = e.currentTarget;
    setTimeout(() => {
      currentTarget.setSelectionRange(
        formatted.length - 1,
        formatted.length - 1,
      );
    }, 10);
  }

  setPhone(newRawDigits);
}

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export function validateUserForm(
  userName: string | undefined,
  userEmail: string | undefined,
  userPhone: string | undefined,
): { isValid: boolean; error?: string } {
  if (!userName?.trim()) {
    return { isValid: false, error: "Please enter a name" };
  }
  if (!userEmail?.trim()) {
    return { isValid: false, error: "Please enter an email address" };
  }
  if (!EMAIL_REGEX.test(userEmail)) {
    return { isValid: false, error: "Please enter a valid email address" };
  }
  if (!userPhone?.trim()) {
    return { isValid: false, error: "Please enter a phone number" };
  }
  return { isValid: true };
}

export function formatBranchOptions(branches: SavedLocation[]) {
  return [
    { value: "no_branch_assigned", label: "No branch assigned" },
    {
      value: "──────────────────────────",
      label: "──────────────────────────",
      disabled: true,
    },
    ...branches
      .filter((branch) => branch.isBranch && branch.location)
      .map((branch) => ({
        value: branch.savedLocationId,
        label: branch.location?.businessName ?? "Unnamed Branch",
      })),
  ];
}
