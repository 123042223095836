import {
  CountryCode,
  Location,
  QuoteTestScenarioDetailsDto,
} from "@freightsimple/generated-apollo-openapi-client";
import { StackedInfo } from "../../../../Components/StackedInfo";
import HorizontalStack from "../../../../Components/HorizontalStack";
import { KeyValues } from "../../../../Components/KeyValues";
import { describeAddress } from "../../../../Helpers/describeAddress";
import { nameLocationType } from "../../../../Helpers/locationTypes";
import { describeAccessorials } from "../../../ViewShipmentScreenComponents/describeAccessorials";
import { LineItemsSummary } from "../../../ViewShipmentScreenComponents/ManagePickupTab";
import Spacer from "../../../../Spacer";
import { LineItemsTable } from "../../../ViewShipmentScreenComponents/LineItemsTable";
import Stack from "../../../../Components/Stack";
import { QuoteTestViewShipmentLink } from "./QuoteTestViewShipmentLink";
import { ArrowRightOutlined, TruckOutlined } from "@ant-design/icons";
import { ReactNode } from "react";

interface QuoteTestScenarioInfoProps {
  scenario: QuoteTestScenarioDetailsDto;
}
export function QuoteTestScenarioInfo(props: QuoteTestScenarioInfoProps) {
  const { scenario } = props;

  function describeAddress2(location: Location | undefined): ReactNode {
    return (
      <>
        {describeAddress(location)} {" "}
        {location?.address?.countryCode === CountryCode.Us
          ? "🇺🇸"
          : "🇨🇦"}
      </>
    );
  }

  return (
    <Stack align="left" style={{ gap: 24, width: "100%" }}>
      <HorizontalStack style={{ gap: 40, width: "100%" }} verticalAlign="top">

        <Stack align="left" style={{ gap: 24, flexGrow: 1, maxWidth: "60%" }}>
          <HorizontalStack style={{ width: "100%", gap: 16 }}>
            <StackedInfo label="Pickup" fullWidth>
              <KeyValues
                data={{
                  ["Address"]: describeAddress2(scenario.pickupLocation),
                  ["Location Type"]: nameLocationType(
                    scenario.pickupLocation?.locationType ?? "",
                    scenario.pickupLocation?.distributionWarehouseBrand,
                  ),
                  ["Accessorials"]: describeAccessorials(
                    scenario.pickupLocation?.accessorials ?? [],
                  ),
                }}
              />
            </StackedInfo>
            <Stack>
              <TruckOutlined style={{ fontSize: 24 }} />
              <ArrowRightOutlined style={{ fontSize: 24 }} />
            </Stack>
            <StackedInfo label="Delivery" fullWidth>
              <KeyValues
                data={{
                  ["Address"]: describeAddress2(scenario.deliveryLocation),
                  ["Location Type"]: nameLocationType(
                    scenario.deliveryLocation?.locationType ?? "",
                    scenario.deliveryLocation?.distributionWarehouseBrand,
                  ),
                  ["Accessorials"]: describeAccessorials(
                    scenario.deliveryLocation?.accessorials ?? [],
                  ),
                }}
              />
            </StackedInfo>
          </HorizontalStack>
          <StackedInfo label="Line Items Summary">
            <LineItemsSummary
              totalWeight={scenario.totalWeight}
              totalVolume={scenario.totalVolume}
              totalHandlingUnits={scenario.totalHandlingUnits}
              density={scenario.density}
              standardDryVanLinearFeet={
                scenario.standardDryVanLinearFeet ?? undefined
              }
              loadingDiagram={scenario.loadingDiagram}
            />
            <Spacer height={2} />
          </StackedInfo>
        </Stack>

        <Stack align="left" style={{ gap: 24, maxWidth: "40%" }}>
          <StackedInfo label="Title">{scenario.title}</StackedInfo>
          <StackedInfo label="Description">{scenario.description}</StackedInfo>
          {scenario.parsedMetadata.linearIds.length > 0 && (
            <StackedInfo label="Linear IDs">
              {scenario.parsedMetadata.linearIds.join(", ")}
            </StackedInfo>
          )}
          {scenario.parsedMetadata.shipmentIds.length > 0 && (
            <StackedInfo label="Shipment IDs">
              {scenario.parsedMetadata.shipmentIds.map((id) => (
                <QuoteTestViewShipmentLink shipmentId={id} key={id} />
              ))}
            </StackedInfo>
          )}
        </Stack>

      </HorizontalStack>
      <StackedInfo label="Line Items" fullWidth>
        <div
          style={{
            wordBreak: "keep-all",
            overflow: "auto",
            height: "100%",
            width: "100%",
          }}
        >
          <LineItemsTable lineItems={scenario.lineItems ?? []} />
        </div>
      </StackedInfo>
    </Stack>
  );
}
