import {
  Form,
  Input,
  Menu,
  message,
  Modal,
  Select,
  Tag,
  Typography,
} from "antd";
import { useState } from "react";
import { useTechnicalApi } from "../Apis/Apis";
import Stack from "../Components/Stack";
import Spacer from "../Spacer";
import { CompanyTabMenuItemProps } from "./ViewCompanyScreen";
import { isBlank } from "@freightsimple/shared";
import { Environment } from "@freightsimple/generated-apollo-openapi-client";

const { Text } = Typography;

export function CopyShipmentToDifferentEnvMenuItem(
  props: CompanyTabMenuItemProps,
) {
  const companyId = props.data.company.companyId;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [shipmentId, setShipmentId] = useState<string>("");
  const [env, setEnv] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const createTechnicalApi = useTechnicalApi();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const items = [
    { value: "no_env", label: "No environment selected" },
    {
      value: "──────────────────────────",
      label: "──────────────────────────",
      disabled: true,
    },
    { value: Environment.Local, label: "Local" },
    { value: Environment.Demo, label: "Demo" },
    { value: Environment.Preprod, label: "Preprod" },
    { value: Environment.Production, label: "Production" },
  ];

  async function handleOk() {
    if (isBlank(shipmentId)) {
      message.warning("Please enter a shipmentId");
      return;
    }
    if (isBlank(env)) {
      message.warning("Please select an environment");
      return;
    }
    if (isBlank(companyId)) {
      message.warning("companyId not found");
      return;
    }
    setLoading(true);
    try {
      const technicalApi = await createTechnicalApi();
      await technicalApi.syncShipmentFromEnvironment({
        destinationCompanyId: companyId ? companyId : "",
        sourceShipmentId: shipmentId,
        sourceEnvironment: env as Environment,
      });
      await props.onRefresh();
      message.success(`Successfully copied shipment!`);
      setShipmentId("");
      setEnv("");
      setIsModalVisible(false);
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
    setLoading(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    setShipmentId("");
    setEnv("");
  };
  return (
    <>
      <Modal
        title="Copy a shipment from a different environment"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        confirmLoading={loading}
        width={800}
      >
        <Form
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
          layout="horizontal"
          colon={false}
        >
          <div style={{ width: "37rem" }}>
            <Stack align="left">
              <Text>
                This is useful if we are trying to quickly get a shipment for
                testing from a certain environment into the current environment.
              </Text>
              <Spacer height={10} />
              <Text>
                Enter the UUID of a shipment that you want to copy, the shipment
                will be copied into the current company.
              </Text>
              <Spacer height={10} />
              <Text>
                The environment that you select is where the query will search
                the shipmentId in.
              </Text>
            </Stack>
            <Spacer height={32} />
            <Form.Item label="Shipment UUID">
              <Input
                placeholder="shipmentId"
                value={shipmentId}
                onChange={function (e) {
                  setShipmentId(e.target.value);
                }}
                onKeyDown={(e) => e.stopPropagation()}
              />
            </Form.Item>
            <Spacer height={10} />
            <Form.Item label="Environment">
              <Select
                value={isBlank(env) ? "no_env" : env}
                options={items}
                onChange={function (value) {
                  if (value === "no_env") {
                    setEnv("");
                  } else {
                    setEnv(value);
                  }
                }}
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <Menu.Item onClick={showModal} {...props}>
        <Tag color="red">Advanced</Tag> Copy a shipment from a different
        environment
      </Menu.Item>
    </>
  );
}
