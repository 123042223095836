import { Form, Input, Menu, message, Modal, Select, Switch } from "antd";
import { useCompaniesApi } from "../Apis/Apis";
import { useState } from "react";
import {
  SavedLocation,
  User,
} from "@freightsimple/generated-apollo-openapi-client";
import { useOnce } from "../Hooks/useOnce";
import { InfoBubble } from "../Components/InfoBubble";
import Spacer from "../Spacer";
import HorizontalStack from "../Components/HorizontalStack";
import { formatPhoneNumber } from "../Helpers/formatPhoneNumber";
import {
  sanitizePhoneExtension,
  validateUserForm,
  formatBranchOptions,
  handlePhoneInputChange,
} from "./EditUsersModalUtils";

interface EditUsersModalProps {
  user: User;
  companyid: string;
  onRefresh: () => Promise<void>;
}

export function EditUsersModal(props: EditUsersModalProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userName, setUserName] = useState(props.user.name);
  const [userEmail, setUserEmail] = useState(props.user.email);
  const [userPhone, setUserPhone] = useState(props.user.phone);
  const [userPhoneExt, setUserPhoneExt] = useState(props.user.phoneExtension);
  const [userAccountingNotifications, setUserAccountingNotifications] =
    useState(props.user.accountingNotificationsEnabled);
  const [userAllShipmentsNotifications, setUserAllShipmentsNotifications] =
    useState(props.user.allShipmentNotificationsEnabled);
  const [deliveryNotificationsOnly, setDeliveryNotificationsOnly] = useState(props.user.deliveryNotificationsOnlyEnabled)
  const [userBranchId, setUserBranchId] = useState(props.user.branchId);
  const [branches, setBranches] = useState<SavedLocation[]>([]);
  const [loading, setLoading] = useState(false);

  const createCompanyApi = useCompaniesApi();

  useOnce(async function () {
    const companiesApi = await createCompanyApi();
    const companyId = props.companyid;
    if (companyId === undefined) {
      throw new Error("Missing companyId");
    }
    const response = await companiesApi.getCompanyBranch({
      companyId: companyId,
      userId: props.user.userId,
    });
    setBranches(response.items);
    setUserBranchId(response?.currentUserBranchId);
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const items = formatBranchOptions(branches);

  async function handleOk() {
    const validation = validateUserForm(userName, userEmail, userPhone);
    if (!validation.isValid) {
      message.error(validation.error);
      return;
    }
    if (
      props.user.name === userName &&
      props.user.email === userEmail &&
      props.user.phone === userPhone &&
      props.user.phoneExtension === userPhoneExt &&
      props.user.branchId === userBranchId &&
      props.user.accountingNotificationsEnabled ===
      userAccountingNotifications &&
      props.user.allShipmentNotificationsEnabled ===
      userAllShipmentsNotifications
    ) {
      message.info("No changes were made");
      return;
    }
    setLoading(true);

    try {
      const companiesApi = await createCompanyApi();
      if (!props.user.userId || !props.companyid) {
        throw new Error("Missing required IDs");
      }

      await companiesApi.updateDashboardUserForCompanyFromApollo({
        userId: props.user.userId,
        companyId: props.companyid,
        apolloCompaniesServiceUpdateUserApollo: {
          newUserData: {
            name: userName ?? "",
            email: userEmail ?? "",
            phoneNumber: userPhone ?? "",
            phoneNumberExtension: userPhoneExt ?? "",
            accountingNotificationsEnabled: userAccountingNotifications,
            allShipmentNotificationsEnabled: userAllShipmentsNotifications,
            deliveryNotificationsOnlyEnabled: deliveryNotificationsOnly,
            branchId: userBranchId,
          },
          originalUserData: {
            name: props.user.name,
            email: props.user.email,
            phoneNumber: props.user.phone,
            phoneNumberExtension: props.user.phoneExtension,
            accountingNotificationsEnabled:
              props.user.accountingNotificationsEnabled,
            allShipmentNotificationsEnabled:
              props.user.allShipmentNotificationsEnabled,
            deliveryNotificationsOnlyEnabled:
              props.user.deliveryNotificationsOnlyEnabled,
            branchId: props.user.branchId,
          },
        },
      });

      await props.onRefresh();
      message.success("User information updated, please refresh the page");
      setIsModalVisible(false);
    } catch (e) {
      message.error(`Oops. Something went wrong. ${e}`);
    }
    setLoading(false);
  }

  const handleCancel = () => {
    setUserName(props.user.name);
    setUserEmail(props.user.email);
    setUserPhone(props.user.phone);
    setUserPhoneExt(props.user.phoneExtension);
    setUserAccountingNotifications(props.user.accountingNotificationsEnabled);
    setUserAllShipmentsNotifications(
      props.user.allShipmentNotificationsEnabled,
    );
    setUserBranchId(props.user.branchId);
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Edit User"
        open={isModalVisible}
        onOk={handleOk}
        okText="OK"
        onCancel={handleCancel}
        confirmLoading={loading}
        destroyOnClose={true}
        width={900}
      >
        <Form
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
          layout="horizontal"
          colon={false}
        >
          <div style={{ width: "37rem" }}>
            <Form.Item label="Name">
              <Input
                placeholder="Enter full name"
                value={userName}
                onChange={function (e) {
                  setUserName(e.target.value);
                }}
                onKeyDown={(e) => e.stopPropagation()}
              />
            </Form.Item>
            <Form.Item label="Email">
              <Input
                type="email"
                placeholder="Email address"
                value={userEmail}
                onChange={function (e) {
                  setUserEmail(e.target.value);
                }}
                onKeyDown={(e) => e.stopPropagation()}
              />
            </Form.Item>
            <Form.Item label="Phone">
              <HorizontalStack>
                <Input
                  style={{ width: "70%" }}
                  type="tel"
                  pattern="^\+?1?\d{10}$"
                  placeholder="Phone number"
                  maxLength={17}
                  value={formatPhoneNumber(userPhone)}
                  onChange={function (e) {
                    handlePhoneInputChange(e, userPhone, setUserPhone);
                  }}
                  onKeyDown={(e) => e.stopPropagation()}
                />
                <Spacer width={6} />
                <Input
                  style={{ width: "30%" }}
                  type="tel"
                  placeholder="Ext."
                  value={userPhoneExt}
                  onChange={function (e) {
                    const sanitized = sanitizePhoneExtension(e.target.value);
                    setUserPhoneExt(sanitized);
                  }}
                  onKeyDown={(e) => e.stopPropagation()}
                />
              </HorizontalStack>
            </Form.Item>
            <Form.Item label="Branch">
              <Select
                value={
                  userBranchId === undefined
                    ? "no_branch_assigned"
                    : userBranchId
                }
                options={items}
                onChange={function (value) {
                  if (value === "no_branch_assigned") {
                    setUserBranchId(undefined);
                  } else {
                    setUserBranchId(value);
                  }
                }}
              />
            </Form.Item>
            <Form.Item label="Accounting Notifications">
              <HorizontalStack>
                <Switch
                  checked={userAccountingNotifications}
                  onChange={function (value) {
                    setUserAccountingNotifications(value);
                  }}
                />
                <Spacer width={6} />
                <InfoBubble overlay="When enabled the user will receive notifications about invoices and additional charges" />
              </HorizontalStack>
            </Form.Item>
            <Form.Item label="All Shipment Notifications">
              <HorizontalStack>
                <Switch
                  checked={userAllShipmentsNotifications}
                  onChange={function (value) {
                    setUserAllShipmentsNotifications(value);
                    //Turn off delivery notifications only if all shipments notifications are enabled
                    //Doesn't make sense to have delivery notifications only 
                    // if you're getting all shipment notifications
                    if (value) {
                      setDeliveryNotificationsOnly(false);
                    }
                  }}
                />
                <Spacer width={6} />
                <InfoBubble overlay="When enabled the user will receive notifications about booking and delivery of all shipments, regardless of who booked" />
              </HorizontalStack>
            </Form.Item>
            <Form.Item label="Delivery Notifications Only">
              <HorizontalStack>
                <Switch
                  checked={deliveryNotificationsOnly}
                  onChange={function (value) {
                    setDeliveryNotificationsOnly(value);
                    //Turn off all shipment notifications if only delilvery notifications are enabled
                    //Doesn't make sense to have all shipment notifications
                    // if you're getting only delivery notifications
                    if (value) {
                      setUserAllShipmentsNotifications(false);
                    }
                  }}
                />
                <Spacer width={6} />
                <InfoBubble overlay="When enabled the user will receive notifications only about the delivery of all shipments, 
              regardless of who booked" />
              </HorizontalStack>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <Menu.Item onClick={showModal}>Edit user</Menu.Item>
    </>
  );
}
