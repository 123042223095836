import {
  PostQuoteTestExecutionCommentBody,
  QuoteTestExecutionCommentType,
} from "@freightsimple/generated-apollo-openapi-client";
import { useState } from "react";
import { useQuoteTestExecutionCommentApi } from "../Hooks/useQuoteTestExecutionCommentApi.tsx";
import { UseDisplayReturn } from "../../../../Hooks/useDisplay";
import { Button, Form, Input } from "antd";
import HorizontalStack from "../../../../Components/HorizontalStack";
import { RefreshFunction } from "../../../../Types/RefreshFunctionType";

interface QuoteTestExecutionCommentFormProps {
  executionId: string;
  display: UseDisplayReturn;
  refresh: RefreshFunction;
}

export function QuoteTestExecutionCommentForm(
  props: QuoteTestExecutionCommentFormProps,
) {
  const { executionId, display, refresh } = props;

  const initComment = {
    executionId,
    isPinned: false,
    isResolved: false,
    text: "",
    type: QuoteTestExecutionCommentType.Note,
  };

  const [newComment, setNewComment] =
    useState<PostQuoteTestExecutionCommentBody>({ ...initComment });

  const { create, isLoading } = useQuoteTestExecutionCommentApi();

  function handleClose() {
    display.hide();
    setNewComment({ ...initComment });
  }

  async function handleCreate() {
    await create(newComment);
    await refresh();
    display.hide();
    setNewComment({ ...initComment });
  }

  return (
    <Form>
      {/* <Form.Item label="Type" style={{ width: 300 }}> */}
      {/*   <Select<QuoteTestExecutionCommentType> */}
      {/*     value={newComment.type} */}
      {/*     options={Object.entries(QuoteTestExecutionCommentType).map( */}
      {/*       ([first, second]) => ({ */}
      {/*         value: second, */}
      {/*         label: first, */}
      {/*       }), */}
      {/*     )} */}
      {/*     onChange={(e) => */}
      {/*       setNewComment((prev) => ({ */}
      {/*         ...prev, */}
      {/*         type: e, */}
      {/*       })) */}
      {/*     } */}
      {/*   /> */}
      {/* </Form.Item> */}
      <Form.Item label="Comment">
        <Input.TextArea
          value={newComment.text}
          onChange={(e) =>
            setNewComment((prev) => ({
              ...prev,
              text: e.target.value,
            }))
          }
        />
      </Form.Item>
      <HorizontalStack style={{ gap: 16 }}>
        <Button onClick={handleCreate} type="primary" loading={isLoading}>
          Submit
        </Button>
        <Button type="text" onClick={handleClose}>
          Close
        </Button>
      </HorizontalStack>
    </Form>
  );
}
