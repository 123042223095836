import { useState, useEffect } from "react";
import { UpCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";

const ScrollButton = styled.button`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background-color: #1677ff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
  transition:
    opacity 0.3s ease-in-out,
    transform 0.2s;

  &:hover {
    background-color: #146ce6;
    transform: scale(1.1);
  }
`;

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return isVisible ? (
    <ScrollButton onClick={scrollToTop}>
      <UpCircleOutlined />
    </ScrollButton>
  ) : null;
};

export default ScrollToTopButton;
