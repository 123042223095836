import { useState } from "react";
import { useQuoteTestApi } from "../../../../Apis/Apis";
import { PostQuoteTestExpectationBody } from "@freightsimple/generated-apollo-openapi-client";

export function useQuoteTestExpectationApi() {
  const createApi = useQuoteTestApi();

  const [isLoading, setIsLoading] = useState(false);

  const createGroupExpectation = async (
    groupId: string,
    body: PostQuoteTestExpectationBody,
  ) => {
    setIsLoading(() => true);
    try {
      const api = await createApi();
      await api.createGroupExpectation({
        groupId,
        postQuoteTestExpectationBody: body,
      });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(() => false);
  };

  const createScenarioExpectation = async (
    scenarioId: string,
    body: PostQuoteTestExpectationBody,
  ) => {
    setIsLoading(() => true);
    try {
      const api = await createApi();
      await api.creteScenarioExpectation({
        scenarioId,
        postQuoteTestExpectationBody: body,
      });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(() => false);
  };

  const deleteExpectation = async (expectationId: string) => {
    setIsLoading(() => true);
    try {
      const api = await createApi();
      await api.deleteExpectation({ expectationId });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(() => false);
  };

  return { createGroupExpectation, createScenarioExpectation, deleteExpectation, isLoading };
}
