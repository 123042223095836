import { Tag } from "antd";

interface CompanyApiAccessEnabledTagProps {
  apiAccessEnabled: boolean | undefined;
}

export function CompanyApiAccessEnabledTag(
  props: CompanyApiAccessEnabledTagProps,
) {
  if (props.apiAccessEnabled) {
    return <Tag color="green">🤖 API Access Enabled</Tag>;
  }

  return <></>;
}
