import { CarrierCreditNoteState } from "@freightsimple/generated-apollo-openapi-client";

export function describeCarrierCreditNotesState(
  carrierCreditNotesState: CarrierCreditNoteState,
) {
  switch (carrierCreditNotesState) {
    case CarrierCreditNoteState.Approved:
      return `👍 Approved`;
    case CarrierCreditNoteState.Paid:
      return `✅ Payment Confirmed`;
    case CarrierCreditNoteState.Voided:
      return `🌑 Voided`;
    default:
      return carrierCreditNotesState;
  }
}
