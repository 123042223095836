import { PostQuoteTestGroupBody } from "@freightsimple/generated-apollo-openapi-client";
import { Form, FormProps, Input, Select } from "antd";

export function QuoteTestGroupForm(props: FormProps<PostQuoteTestGroupBody>) {
  type FormType = PostQuoteTestGroupBody
  return (
    <Form
      {...props}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      autoComplete="off"
    >
      <Form.Item<FormType>
        label="Title"
        name="title"
        rules={[
          { required: true, message: "Please input a title for this group" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item<FormType>
        label="Description"
        name="description"
        rules={[
          {
            required: true,
            message: "Please input a description for this group",
          },
        ]}
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item<FormType>
        label="Linear IDs"
        name={["metadata", "linearIds"]}
      >
        <Select
          mode="tags"
          placeholder="Enter Shipment IDs"
          open={false}
          suffixIcon={false}
        />
      </Form.Item>
      <Form.Item<FormType>
        label="Shipment IDs"
        name={["metadata", "shipmentIds"]}
      >
        <Select
          mode="tags"
          placeholder="Enter Shipment IDs"
          open={false}
          suffixIcon={false}
        />
      </Form.Item>
    </Form>
  );
}
