import {
  ApolloMapQuery,
  DetailedShipmentReport,
} from "@freightsimple/generated-apollo-openapi-client";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useLookupApi } from "../Apis/Apis";

export function useAllShipmentsSearchResults(
  query: ApolloMapQuery,
  setQuery: (query: ApolloMapQuery) => void,
) {
  const location = useLocation();
  const createLookupApi = useLookupApi();

  const isSearchResults = new URLSearchParams(location.search).has(
    "searchResults",
  );

  const searchShipments = useMemo(() => {
    const storedResults = sessionStorage.getItem("searchResults");
    try {
      return storedResults ? JSON.parse(storedResults) : [];
    } catch (e) {
      console.error("Failed to parse stored results:", e);
      return [];
    }
  }, [sessionStorage.getItem("searchResults")]);

  useEffect(() => {
    if (
      searchShipments.length > 0 &&
      (query.startDate || query.endDate) &&
      isSearchResults
    ) {
      setQuery({
        ...query,
        startDate: undefined,
        endDate: undefined,
      });
    }
  }, [searchShipments, query, setQuery]);

  async function loadSearchResults(): Promise<DetailedShipmentReport[]> {
    if (!isSearchResults) {
      return [];
    }

    const searchApi = await createLookupApi();
    const searchTerm = sessionStorage.getItem("searchTerm") ?? "";
    const response = await searchApi.lookup({
      query: searchTerm,
      apolloMapQuery: query,
    });

    sessionStorage.setItem(
      "searchResults",
      JSON.stringify(response.resultList),
    );

    return response.resultList ?? [];
  }

  return { isSearchResults, searchShipments, loadSearchResults };
}
