import { TransitTimeSourceType } from "@freightsimple/generated-apollo-openapi-client";
import { Badge, Popover, Tag } from "antd";
import { assertNever } from "../Helpers/assertNever";
import Colors from "./Colors";
import Stack from "./Stack";
import { isNotBlank } from "@freightsimple/shared";
import HorizontalStack from "./HorizontalStack";
import Spacer from "../Spacer";
import { TagProps } from "antd/lib";

interface TransitTimeTagProps {
  source: TransitTimeSourceType | undefined;
  notes?: string;
}

function describeTransitTimeSource(source: TransitTimeSourceType): string {
  switch (source) {
    case TransitTimeSourceType.CarrierApi:
      return "Carrier API";
    case TransitTimeSourceType.CarrierWebsite:
      return "Carrier Website";
    case TransitTimeSourceType.Fallback:
      return "Fallback";
    case TransitTimeSourceType.Truckload:
      return "Truckload";
    case TransitTimeSourceType.FixedDays:
      return "Fixed Days";
    case TransitTimeSourceType.TransitTimeSheet:
      return "Transit Time Sheet";
    default:
      assertNever(source);
  }
}

function colorTransitTimeSource(
  source: TransitTimeSourceType,
): TagProps["color"] {
  switch (source) {
    case TransitTimeSourceType.CarrierApi:
      return "blue";
    case TransitTimeSourceType.CarrierWebsite:
      return "default";
    case TransitTimeSourceType.Fallback:
      return "orange";
    case TransitTimeSourceType.Truckload:
      return "red";
    case TransitTimeSourceType.FixedDays:
      return "cyan";
    case TransitTimeSourceType.TransitTimeSheet:
      return "green";
    default:
      assertNever(source);
  }
}

function explainTransitTimeSource(source: TransitTimeSourceType): string {
  switch (source) {
    case TransitTimeSourceType.CarrierApi:
      return "Transit time provided directly by the carrier's API.";
    case TransitTimeSourceType.CarrierWebsite:
      return "Transit time retrieved from the carrier's website.";
    case TransitTimeSourceType.Fallback:
      return "Estimated transit time used when the carrier did not provide one.";
    case TransitTimeSourceType.Truckload:
      return "Transit time based on typical truckload delivery durations.";
    case TransitTimeSourceType.FixedDays:
      return "Transit time set to a fixed number of days.";
    case TransitTimeSourceType.TransitTimeSheet:
      return "Transit time sourced from a predefined reference sheet.";
    default:
      assertNever(source);
  }
}

export function TransitTimeSourceTag(props: TransitTimeTagProps) {
  const { source, notes } = props;
  if (!source) return <span style={{ color: Colors.LightText }}>-</span>;

  const title = describeTransitTimeSource(source);
  const explanation = explainTransitTimeSource(source);
  const color = colorTransitTimeSource(source);

  const hasNotes = isNotBlank(notes);

  const allTransitTimeSources = Object.entries(TransitTimeSourceType)
    .map(([_, key]) => [
      describeTransitTimeSource(key),
      explainTransitTimeSource(key),
    ])
    .filter(([d]) => d !== title);

  return (
    <Popover
      title={
        <HorizontalStack align="left">
          {title}
          {hasNotes && (
            <>
              <Spacer width={4} />
              <HorizontalStack
                style={{
                  color: Colors.LightText,
                  fontWeight: 400,
                }}
              >
                &bull;
                <Spacer width={4} />
                {explanation}
              </HorizontalStack>
            </>
          )}
        </HorizontalStack>
      }
      content={
        <Stack align="left">
          <p style={{ whiteSpace: "pre-wrap", marginBlock: 8, padding: 0 }}>
            {hasNotes ? notes : explanation}
          </p>
          <div style={{ marginTop: 8, fontSize: 12 }}>
            Other possible transit time sources across all carriers:
            {allTransitTimeSources.map(([d, e], i) => (
              <li key={i}>
                {d} <span style={{ color: Colors.LightText }}> &bull; {e}</span>
              </li>
            ))}
          </div>
        </Stack>
      }
    >
      {hasNotes ? (
        <Badge dot color={color}>
          <Tag style={{ marginRight: 2 }} color={color}>
            {title}
          </Tag>
        </Badge>
      ) : (
        <Tag color={color}>{title}</Tag>
      )}
    </Popover>
  );
}
