import {
  QuoteTestExecutionType,
  QuoteTestGroupDto,
  QuoteTestScenarioDetailsDto,
} from "@freightsimple/generated-apollo-openapi-client";
import HorizontalStack from "../../../../Components/HorizontalStack";
import { Button, Card, Divider, Dropdown, Empty, Modal, Select } from "antd";
import { QuoteTestExecutionView } from "./QuoteTestExecutionView";
import { useRunQuoteTestScenario } from "../Hooks/useRunQuoteTestScenario";
import { QuoteTestHistorySelector } from "./QuoteTestHistorySelector";
import { QuoteTestScenarioExpectationModal } from "./QuoteTestScenarioExpectation";
import { QuoteTestDashboardProps } from "../QuoteTestDashboard";
import { QuoteTestScenarioInfo } from "./QuoteTestScenarioInfo";
import { StackedInfo } from "../../../../Components/StackedInfo";
import { useDisplay } from "../../../../Hooks/useDisplay";
import { QuoteTestScenarioFormModal } from "./QuoteTestScenarioFormModal";
import { RefreshFunction } from "../../../../Types/RefreshFunctionType";
import { useQuoteTestScenarioApi } from "../Hooks/useCreateQuoteScenario";
import {
  CheckOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { SelectProps } from "antd/lib";
import { useState } from "react";
import { CloneQuoteTestScenarioModal } from "./CloneQuoteTestScenarioModal";

interface QuoteTestScenarioDetailsProps extends QuoteTestDashboardProps {
  scenario: QuoteTestScenarioDetailsDto;
  refresh: RefreshFunction;
  groupRefresh: RefreshFunction;
  groups: Array<QuoteTestGroupDto>;
  executionId: string | null;
  setExecutionId: (_: string | null) => void;
}

export function QuoteTestScenarioDetails(props: QuoteTestScenarioDetailsProps) {
  const {
    scenario,
    refresh,
    executionId,
    setExecutionId,
    groupRefresh,
    groups,
  } = props;

  const { run, isLoading } = useRunQuoteTestScenario();
  const { deleteScenario } = useQuoteTestScenarioApi();

  const [runTags, setRunTags] = useState<Array<string>>([]);

  const displayExpectationModal = useDisplay();
  const displayScenarioModal = useDisplay();
  const displayEditScenarioModal = useDisplay();
  const displayCloneScenario = useDisplay();

  async function handleRun(executionType: QuoteTestExecutionType) {
    if (!scenario.scenarioId) return;
    await run(scenario.scenarioId, runTags, executionType);
    await refresh();
  }

  async function fullRefresh() {
    await refresh();
    await groupRefresh();
  }

  async function handleDelete() {
    if (!scenario.scenarioId) return;
    await deleteScenario(scenario.scenarioId);
    await groupRefresh();
    await refresh();
  }

  const execution = scenario.executions.find(
    (e) => e.executionId === executionId,
  );

  const uniqueUsedTags = [
    ...new Set(scenario.executions.flatMap((e) => e.tags)),
  ];

  const tagOptions: SelectProps["options"] =
    uniqueUsedTags.map((t) => ({ label: t, value: t })) ?? [];

  return (
    <>
      <QuoteTestScenarioExpectationModal
        {...props}
        scenarioId={scenario.scenarioId!}
        display={displayExpectationModal}
      />
      <QuoteTestScenarioFormModal
        {...props}
        groupId={scenario.groupId!}
        baseScenario={scenario}
        refresh={fullRefresh}
        display={displayScenarioModal}
      />
      <QuoteTestScenarioFormModal
        {...props}
        groupId={scenario.groupId!}
        baseScenario={scenario}
        refresh={fullRefresh}
        display={displayEditScenarioModal}
        isEdit
      />
      <CloneQuoteTestScenarioModal
        {...props}
        groups={groups
          .filter((g) => g.groupId !== scenario.groupId)
          .map((g) => ({ groupId: g.groupId!, title: g.title! }))}
        scenarioId={scenario.scenarioId!}
        refresh={fullRefresh}
        display={displayCloneScenario}
      />
      <Card
        title={
          <HorizontalStack style={{ gap: 8 }}>
            {scenario.title}
            <Dropdown
              trigger={["click"]}
              menu={{
                items: [
                  {
                    icon: <EditOutlined />,
                    key: "edit",
                    label: "Edit Scenario",
                    onClick: displayEditScenarioModal.show,
                  },
                  { type: "divider" },
                  {
                    icon: <PlusOutlined />,
                    key: "create-based-on-this",
                    label: "Create New Scenario Based On This",
                    onClick: displayScenarioModal.show,
                  },
                  {
                    icon: <CopyOutlined />,
                    key: "clone-scenario",
                    label: "Clone Scenario",
                    onClick: displayCloneScenario.show,
                  },
                  { type: "divider" },
                  {
                    icon: <CheckOutlined />,
                    key: "create-expectation",
                    label: "Create Scenario Expectation",
                    onClick: displayExpectationModal.show,
                  },
                  { type: "divider" },
                  {
                    key: "delete",
                    danger: true,
                    icon: <DeleteOutlined />,
                    label: "Delete Scenario",
                    onClick: () =>
                      Modal.confirm({
                        type: "warning",
                        title: "Delete Scenario",
                        content: "Are you sure to delete this scenario?",
                        onOk: handleDelete,
                        okType: "danger",
                        okText: "Delete",
                      }),
                  },
                ],
              }}
            >
              <Button icon={<MoreOutlined />} type="text" />
            </Dropdown>
          </HorizontalStack>
        }
        extra={
          <HorizontalStack style={{ gap: 8, justifyContent: "end" }}>
            <span>Tags:</span>
            <Select
              style={{ minWidth: 200 }}
              mode="tags"
              options={tagOptions}
              value={runTags}
              onChange={(e) => setRunTags(e)}
            />
            <Dropdown.Button
              style={{ width: "fit-content" }}
              onClick={() => handleRun(QuoteTestExecutionType.Test)}
              loading={isLoading}
              type="primary"
              menu={{
                items: [
                  {
                    key: "production",
                    label: "Run as Ready For Review",
                    onClick: () => handleRun(QuoteTestExecutionType.Production),
                  },
                ],
              }}
            >
              Run Test
            </Dropdown.Button>
          </HorizontalStack>
        }
      >
        <QuoteTestScenarioInfo scenario={scenario} />
        <Divider />
        <div style={{ position: "relative" }}>
          <div
            style={{ position: "absolute", height: "100%", right: 0, top: 0 }}
          >
            <div
              style={{
                position: "sticky",
                marginTop: 8,
                top: 12,
                zIndex: 10,
              }}
            >
              <StackedInfo
                label={
                  <span style={{ background: "white", padding: 4 }}>
                    History
                  </span>
                }
                style={{ alignItems: "end" }}
              >
                <QuoteTestHistorySelector
                  executionId={executionId}
                  setExecutionId={setExecutionId}
                  executions={scenario.executions}
                />
              </StackedInfo>
            </div>
          </div>

          {execution ? (
            <QuoteTestExecutionView
              execution={execution}
              refresh={refresh}
              setExecutionId={setExecutionId}
            />
          ) : (
            <Empty />
          )}
        </div>
      </Card>
    </>
  );
}
