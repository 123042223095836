import { Link } from "react-router-dom";
import { apolloBaseUrl } from "../../../../Helpers/apolloBaseUrl";
import { Environment } from "../../../../Helpers/environment";
import HorizontalStack from "../../../../Components/HorizontalStack";
import { Button } from "antd";

export function QuoteTestViewShipmentLink({
  shipmentId,
}: {
  shipmentId: string;
}) {
  return (
    <HorizontalStack>
      <span>{shipmentId}</span>
      <Button type="link">
        <Link to={`${apolloBaseUrl()}/view-shipment?shipmentId=${shipmentId}`}>
          Go Local
        </Link>
      </Button>
      <Button type="link">
        <Link
          to={`${apolloBaseUrl(Environment.Production)}/view-shipment?shipmentId=${shipmentId}`}
        >
          Go Prod
        </Link>
      </Button>
    </HorizontalStack>
  );
}
