import {
  LocationContext,
  NoteType,
  Shipment,
  ShipmentState,
} from "@freightsimple/generated-apollo-openapi-client";
import { Alert, Col, Row, Statistic, Typography } from "antd";
import { ReactNode } from "react";
import HorizontalStack from "../../Components/HorizontalStack";
import { StatsRow } from "../../Components/StatsRow";
import { ViewHtmlButton } from "../../Components/ViewHtmlButton";
import { isNotBlank } from "@freightsimple/shared";
import { isPhone } from "../../Helpers/isPhone";
import Spacer from "../../Spacer";
import { EditGeneralButton } from "./EditGeneralButton";
import { EditLineItemsButton } from "./EditLineItemsButton";
import { EditLocationButton } from "./EditLocationButton";
import { GeneralInfo } from "./GeneralInfo";
import { LineItemsTable } from "./LineItemsTable";
import { LocationInfo } from "./LocationInfo";
import { TabProps } from "./TabProps";
import { UpdatePickupInfo } from "./UpdatePickupInfo";
import dayjs from "dayjs";

const { Title } = Typography;

function EditPickupLocationButton(props: TabProps) {
  return (
    <EditLocationButton
      adjective="Pickup"
      showAppointment={false}
      context={LocationContext.Pickup}
      {...props}
    />
  );
}

function EditDeliveryLocationButton(props: TabProps) {
  return (
    <EditLocationButton
      adjective="Delivery"
      showAppointment={true}
      context={LocationContext.Delivery}
      {...props}
    />
  );
}

interface ShipmentLineItemsSummaryProps {
  shipment: Shipment;
}

export function ShipmentLineItemsSummary(props: ShipmentLineItemsSummaryProps) {
  return (
    <LineItemsSummary
      totalWeight={props.shipment.totalWeight}
      totalVolume={props.shipment.totalVolume}
      totalHandlingUnits={props.shipment.totalHandlingUnits}
      density={props.shipment.density}
      standardDryVanLinearFeet={props.shipment.standardDryVanLinearFeet}
      linearFeetOverride={props.shipment.linearFeetOverride}
      loadingDiagram={props.shipment.standardDryVanLinearFeetSvg}
    />
  );
}

interface LineItemsSummaryProps {
  totalWeight?: number;
  totalVolume?: number;
  totalHandlingUnits?: number;
  density?: number;
  standardDryVanLinearFeet?: number;
  linearFeetOverride?: number;
  loadingDiagram?: string | null;
}

export function LineItemsSummary(props: LineItemsSummaryProps) {
  const {
    totalWeight,
    totalVolume,
    totalHandlingUnits,
    density,
    standardDryVanLinearFeet,
    linearFeetOverride,
    loadingDiagram,
  } = props;

  const valueStyle = { fontSize: "14px" };

  return (
    <HorizontalStack
      style={{
        border: "1px solid #ddd",
        backgroundColor: "#fafafa",
        padding: "8px",
      }}
      align="spread"
    >
      <StatsRow>
        <Statistic
          title="Total Weight"
          value={totalWeight}
          suffix="lb"
          valueStyle={valueStyle}
        />
      </StatsRow>
      <StatsRow>
        <Statistic
          title="Total Volume"
          value={totalVolume}
          suffix="cuft"
          valueStyle={valueStyle}
        />
      </StatsRow>
      <StatsRow>
        <Statistic
          title="Total Handling Units"
          value={totalHandlingUnits}
          valueStyle={valueStyle}
        />
      </StatsRow>
      <StatsRow>
        <Statistic
          title="Density"
          value={density?.toFixed(2)}
          suffix="pcf"
          valueStyle={valueStyle}
        />
      </StatsRow>
      <StatsRow>
        <Statistic
          title="Standard Dry Van Linear Feet"
          value={standardDryVanLinearFeet ?? undefined}
          suffix="ft"
          valueStyle={valueStyle}
        />
      </StatsRow>
      {linearFeetOverride !== undefined && (
        <StatsRow>
          <Statistic
            title="Linear Feet Override"
            value={linearFeetOverride}
            suffix="ft"
            valueStyle={valueStyle}
          />
        </StatsRow>
      )}
      {isNotBlank(loadingDiagram) && (
        <>
          <Spacer width={16} />
          <ViewHtmlButton
            type="link"
            title="View Loading Diagram"
            message="This is how we would expect this freight to be loaded in a standard 53' dry van"
            data={loadingDiagram!}
          />
        </>
      )}
    </HorizontalStack>
  );
}

interface ColumnProps {
  children: ReactNode;
}

function Column(props: ColumnProps) {
  if (isPhone) {
    return (
      <div
        style={{
          padding: "0",
          margin: "0",
          marginTop: "64px",
          border: "none",
          borderBottom: "3px solid #444",
          paddingBottom: "4px",
        }}
      >
        {props.children}
      </div>
    );
  } else {
    return (
      <Col span={12}>
        <div
          style={{
            padding: "32px",
            margin: "16px",
            border: "1px solid #ddd",
          }}
        >
          {props.children}
        </div>
      </Col>
    );
  }
}

export function ManagePickupTab(props: TabProps) {
  /**
   * If there is more than one `ShipmentCanceled` note,
   * we show the most recent one.
   */
  const shipmentCancelledReason = props.shipmentData.notesTab.notes
    .filter((n) => n.noteType === NoteType.ShipmentCancelled)
    .sort((a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf())
    .at(0)?.message;

  return (
    <>
      {shipmentCancelledReason &&
        props.shipmentData.shipment.shipment.state ===
          ShipmentState.Cancelled && (
          <Alert
            style={{ marginInline: "15px" }}
            description={shipmentCancelledReason}
            message={"Shipment Cancelled"}
            type="info"
            showIcon
            closable
          />
        )}
      <Row>
        <Column>
          <HorizontalStack align="spread">
            <Title level={4}>General</Title>
            <EditGeneralButton {...props} />
          </HorizontalStack>
          <GeneralInfo data={props.shipmentData} />
        </Column>
        <Column>
          <Title level={4}>Update</Title>
          <UpdatePickupInfo {...props} />
        </Column>
      </Row>
      <Row>
        <Column>
          <HorizontalStack align="spread">
            <Title level={4}>Pickup</Title>
            <EditPickupLocationButton {...props} />
          </HorizontalStack>
          <LocationInfo
            deadline={props.shipmentData.shipment.shipment.pickupDeadline}
            location={props.shipmentData.shipment.shipment.pickupLocation!}
            contact={props.shipmentData.shipment.shipment.pickupContact!}
            context={LocationContext.Pickup}
            referenceNumber={
              props.shipmentData.shipment.shipment.pickupReferenceNumber!
            }
            {...props}
          />
        </Column>
        <Column>
          <HorizontalStack align="spread">
            <Title level={4}>Delivery</Title>
            <EditDeliveryLocationButton {...props} />
          </HorizontalStack>

          <LocationInfo
            deadline={props.shipmentData.shipment.shipment.deliveryDeadline}
            location={props.shipmentData.shipment.shipment.deliveryLocation!}
            contact={props.shipmentData.shipment.shipment.deliveryContact!}
            context={LocationContext.Delivery}
            referenceNumber={
              props.shipmentData.shipment.shipment.deliveryReferenceNumber!
            }
            {...props}
          />
        </Column>
      </Row>
      <Row>
        <div
          style={{ padding: "32px", margin: "16px", border: "1px solid #ddd" }}
        >
          <HorizontalStack width="100%" align="spread">
            <Title level={4}>Line Items</Title>
            <HorizontalStack>
              <Spacer width={16} />
              <EditLineItemsButton {...props} />
            </HorizontalStack>
          </HorizontalStack>
          <Spacer height={8} />
          <ShipmentLineItemsSummary
            shipment={props.shipmentData.shipment.shipment}
          />
          <Spacer height={8} />
          <LineItemsTable
            lineItems={props.shipmentData.shipment.shipment.lineItems!}
          />
        </div>
      </Row>
    </>
  );
}
