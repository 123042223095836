import {
  QuoteTestExpectationMetadata,
  QuoteTestExpectationType,
} from "@freightsimple/generated-apollo-openapi-client";
import { Button, Card, Popconfirm, Typography } from "antd";
import Colors from "../../../../Components/Colors";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useQuoteTestExpectationApi } from "../Hooks/useQuoteTestExpectationApi.tsx";
import { RefreshFunction } from "../../../../Types/RefreshFunctionType";
import { describeQuoteTestExpectationType } from "../../../../Helpers/describeQuoteTestExpectationType.tsx";

interface QuoteTestExpectationProps {
  success?: boolean;
  refresh?: RefreshFunction;
  expectation: {
    expectationId?: string;
    createdAt?: string;
    createdBy?: string;
    type?: QuoteTestExpectationType;
    description?: string;
    metadata?: string;
    parsedMetadata: QuoteTestExpectationMetadata;
  };
}

export function QuoteTestExpectationComponent(
  props: QuoteTestExpectationProps,
) {
  const { expectation, success, refresh } = props;

  const { deleteExpectation, isLoading } = useQuoteTestExpectationApi();
  let body = <>TODO</>;

  async function handleDelete() {
    await deleteExpectation(expectation.expectationId!);
    await refresh?.();
  }

  if (expectation.type === QuoteTestExpectationType.QuotePriceByCarrer)
    body = (
      <Typography.Title
        level={5}
        style={{ padding: 0, margin: 0, marginTop: 8 }}
      >
        ${expectation.parsedMetadata.amount}{" "}
        {expectation.parsedMetadata.currency}
      </Typography.Title>
    );
  else if (
    [
      QuoteTestExpectationType.RequestRegex,
      QuoteTestExpectationType.ResponseRegex,
    ].includes(expectation.type!)
  ) {
    body = (
      <p
        style={{
          display: "block",
          padding: 0,
          margin: 0,
          marginTop: 8,
          background: Colors.Palette.Gray[100],
        }}
      >
        {expectation.parsedMetadata.regex}
      </p>
    );
  }

  return (
    <Card
      title={
        <span style={{ marginRight: 12 }}>
          {describeQuoteTestExpectationType(expectation.type)}
        </span>
      }
      styles={{ title: { fontSize: 14 } }}
      extra={
        <>
          {success === undefined && expectation.expectationId && (
            <Popconfirm
              title="Delete expectation"
              description="Are you sure to delete this expectation?"
              onConfirm={handleDelete}
              okType="danger"
              okText="Delete"
              cancelText="Cancel"
            >
              <Button
                loading={isLoading}
                danger
                icon={<DeleteOutlined />}
                type="text"
              />
            </Popconfirm>
          )}
          {success !== undefined &&
            (success ? (
              <CheckCircleOutlined
                style={{ color: Colors.Palette.Green[500] }}
              />
            ) : (
              <ExclamationCircleOutlined
                style={{ color: Colors.Palette.Red[500] }}
              />
            ))}
        </>
      }
    >
      <span style={{ color: Colors.LightText }}>{expectation.description}</span>
      {body}
    </Card>
  );
}
