import { ReactNode } from "react";
import { CallContextProvider } from "./CallContext/CallContext";
import { RefreshContextProvider } from "./RefreshContextProvider";
import { SearchParamsStateProvider } from "./SearchParamsState/SearchParamsStateProvider";

export function AppContextProvider({ children }: { children: ReactNode }) {
  return (
    <SearchParamsStateProvider>
      <RefreshContextProvider>
        <CallContextProvider>{children}</CallContextProvider>
      </RefreshContextProvider>
    </SearchParamsStateProvider>
  );
}
