import { CompanyTabProps } from "../ViewCompanyScreen";
import { AddApiKeyButton } from "../AddApiKeyButton";
import Spacer from "../../Spacer";
import { ApiKeysTable } from "../ApiKeysTable";

export function CompanyApiTab(props: CompanyTabProps) {
  const { data, onRefresh } = props;
  return (
    <>
      <AddApiKeyButton
        onApiKeyAdded={onRefresh}
        companyId={data.company.companyId}
      />
      <Spacer height={10} />
      <ApiKeysTable<false> data={data.apiKeys} onRefresh={onRefresh} />
    </>
  );
}
