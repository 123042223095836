import {
  CustomerInvoice,
  CustomerInvoiceState,
  PaymentMethodInformation,
} from "@freightsimple/generated-apollo-openapi-client";
import { Button, message } from "antd";
import { useCustomerInvoiceApi, useShipmentsApi } from "../../Apis/Apis";
import { ReactNode, useEffect, useState } from "react";
import Stack from "../../Components/Stack";
import { SecureConfirmModal } from "../../Components/SecureConfirmModal";
import { CalendarOutlined } from "@ant-design/icons";
import Spacer from "../../Spacer";
import Colors from "../../Components/Colors";
import CreditCardLogo from "../../Components/CardLogo";
import HorizontalStack from "../../Components/HorizontalStack";
import { CardExpiry } from "../../Components/CardExpiry";

interface PayCustomerInvoiceButtonProps {
  customerInvoice: CustomerInvoice;
  onRefresh: () => Promise<void>;
}

interface PaymentMethodItemProps {
  label: string;
  value: ReactNode;
}

function PaymentMethodItem({ label, value }: PaymentMethodItemProps) {
  return (
    <HorizontalStack align="left" verticalAlign="middle">
      {label}:
      <Spacer width={10} />
      <span style={{ color: "#1f2937" }}>{value}</span>
    </HorizontalStack>
  );
}

export function PayCustomerInvoiceButton(props: PayCustomerInvoiceButtonProps) {
  const createCustomerInvoiceApi = useCustomerInvoiceApi();
  const customerInvoiceId = props.customerInvoice.customerInvoiceId!;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [paymentMethodInformation, setPaymentMethodInformation] = useState<
    PaymentMethodInformation | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);

  const createShipmentApi = useShipmentsApi();

  useEffect(() => {
    async function loadData() {
      try {
        const shipmentApi = await createShipmentApi();

        const shipmentId = props.customerInvoice.shipmentId;

        if (!shipmentId) {
          return;
        }

        const response = await shipmentApi.getShipmentPaymentMethodInfoById({
          shipmentId,
        });
        setPaymentMethodInformation(response);
      } catch (e: any) {
        message.error(`Ooops. Something went wrong ${e}`);
      }
    }

    loadData();
  }, [createShipmentApi]);

  async function confirm() {
    const customerInvoiceApi = await createCustomerInvoiceApi();
    setLoading(true);
    try {
      const response = await customerInvoiceApi.payInvoice({
        customerInvoiceId,
      });
      await props.onRefresh();
      if (response.success) {
        message.success("Payment Processed");
        setLoading(false);
      } else {
        message.error(`Failed : ${response.message}`);
        setLoading(false);
      }
    } catch (e: any) {
      message.error(`Oops. Something went wrong : ${e}`);
      setLoading(false);
    }
  }

  function filterPaymentInformation() {
    if (!paymentMethodInformation) {
      return undefined;
    }

    return paymentMethodInformation.paymentMethods.find(
      (paymentMethod) => paymentMethod.currentlySelectedForShipment === true,
    );
  }

  const currentPaymentMethod = filterPaymentInformation();

  const enabled =
    props.customerInvoice.customerInvoiceState === CustomerInvoiceState.Issued;

  const toggleModalVisibility = () => setIsModalVisible(!isModalVisible);

  const dueDate = new Date(
    props.customerInvoice.dueDate ? props.customerInvoice.dueDate : "",
  ).toLocaleDateString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  const getDaysLeftColor = (days: number) => {
    if (days <= 3) return Colors.Red;
    if (days <= 10) return Colors.Gold;
    return "#374151";
  };

  const daysLeft = Math.ceil(
    (new Date(props.customerInvoice.dueDate!).getTime() -
      new Date().getTime()) /
      (1000 * 60 * 60 * 24),
  );

  const daysLeftColor = getDaysLeftColor(daysLeft);

  if (!enabled) {
    return <></>;
  }

  return (
    <>
      <SecureConfirmModal
        open={isModalVisible}
        title="Are you sure you want to pay this invoice?"
        onOk={confirm}
        onCancel={toggleModalVisibility}
        destroyOnClose={true}
        width={600}
        closable={false}
        loading={loading}
      >
        <p>
          It will attempt to charge the current payment method associated with
          this shipment.
        </p>

        <>
          <Stack
            align="left"
            style={{
              width: "96%",
              padding: 10,
              backgroundColor: "#f9fafb",
              borderRadius: 5,
            }}
          >
            <strong>Payment Method:</strong>
            <PaymentMethodItem
              label="Name"
              value={currentPaymentMethod?.cardholderName}
            />

            <PaymentMethodItem
              label="Brand"
              value={
                <HorizontalStack verticalAlign="middle">
                  <CreditCardLogo
                    brand={currentPaymentMethod?.brand ?? ""}
                    size="small"
                  />
                  <Spacer width={5} />
                  <div>{currentPaymentMethod?.brand}</div>
                </HorizontalStack>
              }
            />

            <PaymentMethodItem
              label="Last 4 digits"
              value={"..." + currentPaymentMethod?.lastFourDigits}
            />
            <PaymentMethodItem
              label="Expiration Date"
              value={<CardExpiry expiry={currentPaymentMethod?.expiry} />}
            />
            <PaymentMethodItem
              label="Company Default"
              value={currentPaymentMethod?.companyDefault == true ? "✅" : "❌"}
            />
          </Stack>

          <Spacer height={15} />

          <Stack align="left">
            <span
              style={{
                color: daysLeftColor,
                fontSize: 16,
              }}
            >
              <CalendarOutlined /> {daysLeft} Days left
            </span>
            <span>Due on {dueDate} </span>
          </Stack>
        </>
      </SecureConfirmModal>
      <Button disabled={!enabled} onClick={toggleModalVisibility} danger>
        Process Payment
      </Button>
    </>
  );
}
