import { CompanyShipmentQuote } from "@freightsimple/generated-apollo-openapi-client";
import { Tag } from "antd";
import { emojiForCountry } from "../Helpers/emojiForCountry";
import { formatAsCurrency } from "../Helpers/numberFormatting";
import Spacer from "../Spacer";
import { ButtonRow } from "./ButtonRow";
import CarrierLogo from "./CarrierLogo";
import Colors from "./Colors";
import { DataTable, DataTableColumn } from "./DataTable";
import HorizontalStack from "./HorizontalStack";
import { ShipmentProblemTag } from "./ShipmentProblemTag";
import { ShipmentStatusTag } from "./ShipmentStatusTag";
import Stack from "./Stack";
import { ViewShipmentButton } from "./ViewShipmentButton";
import { WarningComponent } from "./WarningComponent";
import { Weight } from "./Weight";
import { CompanyName } from "./CompanyName";

interface ShipmentsTableProps {
  shipments: CompanyShipmentQuote[];
  addPickupNumberColumn?: boolean;
  addOriginalPickupDateColumn?: boolean;
  hideQuoteAmount?: boolean;
  hidePickupDateColumn?: boolean;
  addEstimatedDeliveryDateColumn?: boolean;
}

export function ShipmentsTable(props: ShipmentsTableProps) {
  const columns: DataTableColumn<CompanyShipmentQuote>[] = [];

  columns.push({
    title: "Carrier",
    render: (o) => (
      <CarrierLogo
        carrierIdentifier={o.quote.carrierIdentifier}
        brokeredCarrierIdentifier={o.quote.brokeredCarrierIdentifier}
        width={40}
        height={30}
      />
    ),
  });
  columns.push({
    title: "Company",
    render: (o) => (
      <Stack align="left">
        <div>
          <CompanyName
            companyName={o.company.companyName}
            qualificationStatus={o.company.qualificationStatus}
            temperature={o.company.temperature}
            shipmentFrequency={o.company.shipmentFrequency}
          />{" "}
        </div>
        <Stack align="left">
          {o.shipment.tags?.map((s) => (
            <Tag key={s} color="orange">
              {s}
            </Tag>
          ))}
          {o.shipment.problems?.map((s) => (
            <ShipmentProblemTag key={s} code={s} />
          ))}
          {o.shipment.freightMissing && (
            <>
              <Spacer height={4} />
              <Tag color="yellow">
                <WarningComponent /> Freight is Missing
              </Tag>
            </>
          )}
          {o.shipment.deliveryDeadline && (
            <>
              <Spacer height={4} />
              <Tag color="green">
                📆 Deadline : {o.shipment.deliveryDeadline}
              </Tag>
            </>
          )}
        </Stack>
      </Stack>
    ),
  });
  columns.push({
    title: "State",
    render: (o) => <ShipmentStatusTag status={o.shipment.state} />,
  });
  if (props.addOriginalPickupDateColumn) {
    columns.push({
      title: "Original Pickup Date",
      render: (o) => <div>{o.shipment.originalPickupDate}</div>,
    });
  }
  if (!props.hidePickupDateColumn) {
    columns.push({
      title: "Pickup Date",
      render: (o) => (
        <HorizontalStack>
          <div>{o.shipment.pickupDate}</div>
          {o.shipment.pickupDate !== o.shipment.originalPickupDate && (
            <>
              <Spacer width={8} />
              <div>
                <WarningComponent />
              </div>
            </>
          )}
        </HorizontalStack>
      ),
    });
  }
  if (props.addEstimatedDeliveryDateColumn) {
    columns.push({
      title: "Expected Delivery Date",
      render: (o) => <div>{o.shipment.expectedDeliveryDate}</div>,
    });
  }
  columns.push({
    title: "Pro Number",
    render: (o) => <div>{o.shipment.proNumber}</div>,
  });
  if (props.addPickupNumberColumn) {
    columns.push({
      title: "Pickup Number",
      render: (o) => <div>{o.shipment.carrierPickupNumber}</div>,
    });
  }
  columns.push({
    title: "Details",
    render: (o: CompanyShipmentQuote) => (
      <>
        <Stack align="left">
          <HorizontalStack verticalAlign="top">
            <div
              style={{
                fontSize: "10px",
                color: Colors.LightText,
                width: "16px",
                textAlign: "right",
                paddingTop: "4px",
              }}
            >
              {emojiForCountry(
                // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                o.shipment.pickupLocation?.address?.countryCode!,
              )}
            </div>
            <Spacer width={8} />
            <Stack align="left">
              <div>
                {o.shipment.pickupLocation?.address?.city},{" "}
                {o.shipment.pickupLocation?.address?.stateOrProvinceCode}
              </div>
            </Stack>
          </HorizontalStack>
          <HorizontalStack verticalAlign="top">
            <div
              style={{
                fontSize: "10px",
                color: Colors.LightText,
                width: "16px",
                textAlign: "right",
                paddingTop: "4px",
              }}
            >
              {emojiForCountry(
                // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                o.shipment.deliveryLocation?.address?.countryCode!,
              )}
            </div>
            <Spacer width={8} />
            <Stack align="left">
              <div>
                {o.shipment.deliveryLocation?.address?.city},{" "}
                {o.shipment.deliveryLocation?.address?.stateOrProvinceCode}
              </div>
            </Stack>
          </HorizontalStack>
          <HorizontalStack verticalAlign="top">
            <div
              style={{
                fontSize: "10px",
                color: Colors.LightText,
                width: "16px",
                textAlign: "right",
                paddingTop: "4px",
              }}
            >
              📦
            </div>
            <Spacer width={8} />
            <Stack align="left">
              <div>{o.shipment.lineItems![0].description}</div>
              <div
                style={{
                  marginTop: "-4px",
                  fontSize: "12px",
                  color: Colors.LightText,
                }}
              >
                {o.shipment.totalHandlingUnits} skids,{" "}
                <Weight>{o.shipment.totalWeight!}</Weight>
              </div>
            </Stack>
          </HorizontalStack>
        </Stack>
      </>
    ),
  });
  if (!props.hideQuoteAmount) {
    columns.push({
      title: "Quote Amount",
      render: (o) => (
        <div>{formatAsCurrency(o.quote.quotedPriceByCarrierCad, "CAD")}</div>
      ),
    });
  }
  columns.push({
    title: "Actions",
    render: (o) => (
      <ButtonRow>
        <ViewShipmentButton shipmentId={o.shipment.shipmentId!} />
      </ButtonRow>
    ),
  });

  return (
    <DataTable pagination={false} columns={columns} data={props.shipments} />
  );
}
