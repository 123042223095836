import HorizontalStack from "../../../Components/HorizontalStack";
import CarrierLogo from "../../../Components/CarrierLogo";
import { Page } from "../../../Components/Page";
import { QuoteTestGroupFormModal } from "./QuoteTestGroupFormModal";
import { useParamState } from "../../../Hooks/useParamState";
import { useFetchQuoteTestGroups } from "./Hooks/useFetchQuoteTestGroups";
import { Loading } from "../../../Components/Loading";
import { QuoteTestGroupMenu } from "./Components/QuoteTestGroupMenu";
import { QuoteTestScenarioView } from "./Components/QuoteTestScenarioView";
import { QuoteTestGroupView } from "./Components/QuoteTestGroupView";
import { QuoteTestPermutationFormModal } from "./Components/QuoteTestPermutationsFormModal";
import { Button } from "antd";
import { ImportQuoteTestGroupModal } from "./Components/ImportQuoteTestGroupModal";
import { useDisplay } from "../../../Hooks/useDisplay";
import { StackedInfo } from "../../../Components/StackedInfo";
import { Fragment } from "react/jsx-runtime";
import { CarrierAndServiceFilter } from "../../../Components/CarrierAndServiceFilter";
import CarriersData from "../../../GeneratedFixtures/CarriersData.json";
import { QuotingStrategyType } from "@freightsimple/generated-apollo-openapi-client";

export interface QuoteTestDashboardProps {
  carrierIdentifier: string;
  setCarrierIdentifier: (_: string | null) => void;
  serviceIdentifier: string;
  setServiceIdentifier: (_: string | null) => void;
}

export function QuoteTestDashboard(props: QuoteTestDashboardProps) {
  const {
    carrierIdentifier,
    serviceIdentifier,
    setCarrierIdentifier,
    setServiceIdentifier,
  } = props;

  const [groupId, setGroupId] = useParamState<string | null>({
    key: "groupId",
    defaultValue: null,
  });

  const [scenarioId, setScenarioId] = useParamState<string | null>({
    key: "scenarioId",
    defaultValue: null,
  });

  const [executionId, setExecutionId] = useParamState<string | null>({
    key: "executionId",
    defaultValue: null,
  });

  const instantCarriers = CarriersData.filter(
    (c) =>
      c.serviceProperties.quotingStrategy.strategyType ==
      QuotingStrategyType.Instant,
  ).toSorted((a, b) => a.carrierIdentifier.localeCompare(b.carrierIdentifier));

  const { groups, isLoading, refresh } = useFetchQuoteTestGroups(
    carrierIdentifier,
    serviceIdentifier,
  );

  const displayGroupModal = useDisplay();
  const displayImportModal = useDisplay();
  const displayPermutationModal = useDisplay();

  if (isLoading) {
    return <Loading />;
  }

  if (!groups) {
    return <h1>No data</h1>;
  }

  const permutationGroupAlreadyCreated = groups.some(
    (group) => group.parsedMetadata.isPermutationGroup === true,
  );

  const group = groups.find((group) => group.groupId === groupId);

  return (
    <>
      <QuoteTestGroupFormModal
        {...props}
        refresh={refresh}
        display={displayGroupModal}
      />

      <QuoteTestPermutationFormModal
        {...props}
        refresh={refresh}
        display={displayPermutationModal}
      />

      <ImportQuoteTestGroupModal
        refresh={refresh}
        display={displayImportModal}
      />
      <Page
        title={
          <HorizontalStack align="spread" style={{ gap: 24 }}>
            <CarrierLogo
              carrierIdentifier={carrierIdentifier}
              brokeredCarrierIdentifier={undefined}
              width={70}
              height={40}
            />

            <StackedInfo label="Carrier Identifier">
              {carrierIdentifier}
            </StackedInfo>
            <StackedInfo label="Service Identifier">
              {serviceIdentifier}
            </StackedInfo>

            <CarrierAndServiceFilter
              carriers={instantCarriers}
              carrierIdentifier={carrierIdentifier}
              serviceIdentifier={serviceIdentifier}
              onChange={(carrierIdentifier, serviceIdentifier) => {
                setCarrierIdentifier(carrierIdentifier);
                setServiceIdentifier(serviceIdentifier);
              }}
            />
          </HorizontalStack>
        }
        tags={[]}
        extra={[
          <Button onClick={displayImportModal.show} key={"1"}>
            Import Group
          </Button>,
          <Fragment key={"2"}>
            {!permutationGroupAlreadyCreated && (
              <Button type="default" onClick={displayPermutationModal.show}>
                Create Permutation Scenarios
              </Button>
            )}
          </Fragment>,
          <Button onClick={displayGroupModal.show} key={"3"}>
            Create Group
          </Button>,
        ]}
      >
        <div
          style={{
            marginTop: -16,
            display: "grid",
            gridTemplateColumns: "300px 1fr",
            width: "100%",
          }}
        >
          <aside
            style={{
              height: "98vh",
              overflow: "auto",
              position: "sticky",
              top: 0,
            }}
          >
            <QuoteTestGroupMenu
              {...props}
              groups={groups}
              groupId={groupId}
              setGroupId={setGroupId}
              scenarioId={scenarioId}
              setScenarioId={setScenarioId}
            />
          </aside>
          <main
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 24,
              paddingInline: 48,
              paddingBlock: 24,
              width: "calc(100vw - 300px)",
            }}
          >
            {group && (
              <QuoteTestGroupView {...props} group={group} refresh={refresh} />
            )}
            {scenarioId && group && (
              <QuoteTestScenarioView
                {...props}
                groups={groups}
                group={group}
                groupRefresh={refresh}
                scenarioId={scenarioId}
                executionId={executionId}
                setExecutionId={setExecutionId}
              />
            )}
          </main>
        </div>
      </Page>
    </>
  );
}
