import {
  ShipmentState,
  ViewShipmentDataForApollo,
} from "@freightsimple/generated-apollo-openapi-client";

import { Row } from "antd";
import HorizontalStack from "../../Components/HorizontalStack";
import Stack from "../../Components/Stack";
import styled from "styled-components";

interface ShipmentOnHoldReasonWidgetProps {
  shipmentData: ViewShipmentDataForApollo;
  onRefresh: () => Promise<void>;
}

const WidgetTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
`;

const WidgetSubtitle = styled.div`
  font-size: 16px;
`;

export function ShipmentOnHoldReasonWidget(
  props: ShipmentOnHoldReasonWidgetProps,
) {
  if (props.shipmentData.shipment.shipment.state != ShipmentState.OnHold) {
    return <></>;
  }

  return (
    <Row
      style={{
        padding: "16px",
        backgroundColor: "#DAB1DA",
      }}
    >
      <HorizontalStack align="spread" style={{ width: "100%" }}>
        <HorizontalStack verticalAlign="top">
          <Stack align="left">
            <WidgetTitle>On hold reason</WidgetTitle>
            <WidgetSubtitle>
              {props.shipmentData.shipment.shipment.onHoldReason}
            </WidgetSubtitle>
          </Stack>
        </HorizontalStack>
      </HorizontalStack>
    </Row>
  );
}
