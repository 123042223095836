import {
  Company,
  CompanyData,
  CompanyNoteType,
  Coupon,
  CouponState,
  InvoiceWarningState,
  ProvisionDashboardUserResponse,
  QualificationStatus,
  SalesContact,
  User,
} from "@freightsimple/generated-apollo-openapi-client";

import {
  DownOutlined,
  ExclamationCircleFilled,
  LinkOutlined,
  PaperClipOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import {
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  MenuItemProps,
  message,
  Modal,
  notification,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { ReactNode, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useCompaniesApi } from "../Apis/Apis";
import { ButtonRow } from "../Components/ButtonRow";
import Colors from "../Components/Colors";
import { ConfirmMenuItem } from "../Components/ConfirmButton";
import { CurrencyInput } from "../Components/CurrencyInput";
import HorizontalStack from "../Components/HorizontalStack";
import { InfoBubble } from "../Components/InfoBubble";
import { Json } from "../Components/Json";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import Stack from "../Components/Stack";
import { StatsRow } from "../Components/StatsRow";
import { UrlMenuItem } from "../Components/UrlMenuItem";
import { WithClipboardLink } from "../Components/WithClipboardLink";
import NonCorporateEmailData from "../GeneratedFixtures/NonCorporateEmailData.json";
import { isBlank } from "@freightsimple/shared";
import { safeColumns } from "../Helpers/safeColumns";
import { sum } from "../Helpers/sum";
import { useCopyToClipboard } from "../Hooks/useCopyToClipboard";
import useQuery from "../Hooks/useQuery";
import Spacer from "../Spacer";
import { AddSalesContactButton } from "./ViewCompanyScreenComponents/AddSalesContactButton";
import {
  CompanyDomainTag,
  NonProfessionalDomainTag,
} from "./ViewCompanyScreenComponents/ComainDomainTag";
import { CompanyGraphsTab } from "./ViewCompanyScreenComponents/CompanyGraphsTab";
import { CompanyInvoicesTab } from "./ViewCompanyScreenComponents/CompanyInvoicesTab";
import { CompanyNotesTab } from "./ViewCompanyScreenComponents/CompanyNotesTab";
import { CompanyOverviewTab } from "./ViewCompanyScreenComponents/CompanyOverviewTab";
import { CompanyPaymentMethodsTab } from "./ViewCompanyScreenComponents/CompanyPaymentMethodsTab";
import { CompanySalesCallsSnoozedTag } from "./ViewCompanyScreenComponents/CompanySalesCallsSnoozedTag";
import { CompanyStarredTag } from "./ViewCompanyScreenComponents/CompanyStarredTag";
import { DeleteSalesContactMenuItem } from "./ViewCompanyScreenComponents/DeleteSalesContactMenuItem";
import { EditCompanyMenuItem } from "./ViewCompanyScreenComponents/EditCompanyMenuItem";
import { EditContactButton } from "./ViewCompanyScreenComponents/EditContactButton";
import { MakePrimaryContactMenuItem } from "./ViewCompanyScreenComponents/MakePrimaryContactMenuItem";
import { SetPaymentTermsForCompanyMenuItem } from "./ViewCompanyScreenComponents/SetPaymentTermsForCompanyMenuItem";
import { SnoozeForSalesCallsButton } from "./ViewCompanyScreenComponents/SnoozeForSalesCalls";
import { StarCompanyButton } from "./ViewCompanyScreenComponents/StarCompanyMenu";

import { CopyOutlined } from "@ant-design/icons";
import { WarningComponent } from "../Components/WarningComponent";
import { assertNever } from "../Helpers/assertNever";
import { dashboardBaseUrl } from "../Helpers/dashboardBaseUrl";
import { removePrefix } from "../Helpers/removePrefix";
import { useDisplay } from "../Hooks/useDisplay";
import { ClickToCallButton } from "./ClickToCallButton";
import { SalesforceSendEmailButton } from "./SalessComponents/SalesforceSendEmailButton";
import { CompanyInvoiceProblemsTab } from "./ViewCompanyScreenComponents/CompanyInvoiceProblemsTab";
import { CompanyShipmentsTab } from "./ViewCompanyScreenComponents/CompanyShipmentsTab";
import { useSendCompanyVerifiedEmail } from "./ViewCompanyScreenComponents/Hooks/useSendCompanyVerfiedEmail";
import { InvitationsTable } from "./ViewCompanyScreenComponents/InvitationsTable";
import { LogSalesCallButton } from "./ViewCompanyScreenComponents/LogSalesCallButton";
import { ManageRecipientsButton } from "./ViewShipmentScreenComponents/ManageRecipientsButton";
import {
  SupportEmailButton,
  SupportEmailMenuItem,
} from "./ViewShipmentScreenComponents/SupportEmailButton";
import { NumberInput } from "../Components/NumberInput";
import dayjs from "dayjs";
import { useCallRefreshContext } from "../Contexts/CallContext/useCallRefreshContext";
import { AttachButton } from "../Hooks/AttachButton";
import { CombineWithDifferentCompanyMenuItem } from "./ViewCompanyScreenComponents/CombineWithDifferentCompanyMenuItem";
import { ClaudeButton } from "./ViewShipmentScreenComponents/ClaudeButton";
import { ClaudeConversationType } from "./ViewShipmentScreenComponents/ClaudeConversationType";
import { EditUsersModal } from "./EditUsersModal";
import { CompanyName } from "../Components/CompanyName";
import { CompanyApiAccessEnabledTag } from "./ViewCompanyScreenComponents/CompanyApiAccessEnabledTag";
import { CompanyApiTab } from "./ViewCompanyScreenComponents/CompanyApiAccessTab";
import { CopyShipmentToDifferentEnvMenuItem } from "./CopyShipmentFromDifferentEnvMenuItem";
import { Environment, getEnvironment } from "../Helpers/environment";

export interface SupportAgent {
  firstName: string;
  lastName: string;
}

const { Text } = Typography;
const { TabPane } = Tabs;
interface UsersTableProps {
  companyData: CompanyData;
  onRefresh: () => Promise<void>;
}

function useToggleQuotingAllowed(refreshCompany: () => Promise<void>) {
  const createCompanyApi = useCompaniesApi();

  async function toggleQuotingAllowed(company: Company) {
    try {
      const companyId = company.companyId;
      if (companyId === undefined) {
        throw new Error("Missing companyId");
      }
      const companyApi = await createCompanyApi();
      await companyApi.setQuotingDisabledForCompany({
        companyId,
        quotingDisabled: !company.quotingDisabled,
      });
      await refreshCompany();
      message.success(`Done`);
    } catch {
      message.error(`Oops. Something went wrong`);
    }
  }

  return { toggleQuotingAllowed };
}

type AccountType = "business" | "broker" | "personal";

function useChangeCompanyAccountType(refreshCompany: () => Promise<void>) {
  const createCompanyApi = useCompaniesApi();

  async function changeAccountType(company: Company, accountType: AccountType) {
    try {
      const companyId = company.companyId;
      if (companyId === undefined) {
        throw new Error("Missing companyId");
      }

      let isBroker = false;
      let isPersonal = false;

      if (accountType === "broker") {
        isBroker = true;
        isPersonal = false;
      } else if (accountType === "personal") {
        isBroker = false;
        isPersonal = true;
      }

      const companyApi = await createCompanyApi();
      await companyApi.setAccountTypeForCompany({
        companyId,
        isBroker: isBroker,
        isPersonal: isPersonal,
      });
      await refreshCompany();
      message.success(`Done`);
    } catch {
      message.error(`Oops. Something went wrong`);
    }
  }

  return { changeAccountType };
}

function useToggleHasNonProfessionalDomain(
  refreshCompany: () => Promise<void>,
) {
  const createCompanyApi = useCompaniesApi();

  async function toggleHasNonProfessionalDomain(company: Company) {
    try {
      const companyId = company.companyId;
      if (companyId === undefined) {
        throw new Error("Missing companyId");
      }
      const companyApi = await createCompanyApi();
      await companyApi.setHasNonProfessionalDomain({
        companyId,
        hasNonProfessionalDomain: !company.hasNonProfessionalDomain,
      });
      await refreshCompany();
      message.success(`Done`);
    } catch {
      message.error(`Oops. Something went wrong`);
    }
  }

  return { toggleHasNonProfessionalDomain };
}

export interface CompanyTabProps {
  data: CompanyData;
  onRefresh: () => Promise<void>;
}

export type CompanyTabMenuItemProps = CompanyTabProps & MenuItemProps;

interface DeleteUserMenuItemProps {
  user: User;
  onRefresh: () => Promise<void>;
}

function DeleteUserMenuItem(props: DeleteUserMenuItemProps) {
  const createCompaniesApi = useCompaniesApi();
  const [loading, setLoading] = useState(false);
  const userEmail = props.user.email;

  async function onClick() {
    setLoading(true);
    try {
      const companiesApi = await createCompaniesApi();
      await companiesApi.deleteUser({
        userEmail,
      });
      await props.onRefresh();
      message.success("Deleted user");
    } catch (e) {
      message.error(`Oops. Something went wrong. ${e}`);
    }
    setLoading(false);
  }

  return (
    <ConfirmMenuItem
      loading={loading}
      onConfirm={onClick}
      question={
        "Are you sure you want to delete the user? They will not be able to access the dashboard any more"
      }
      okText={"Yes, delete them"}
      tooltip={
        "This will delete the user, and they will lose the ability to log in, or access the dashboard"
      }
    >
      <Tag color="red">Advanced</Tag> Delete User
    </ConfirmMenuItem>
  );
}

interface SwitchUserToDifferentCompanyMenuItemProps {
  user: User;
  companyData: CompanyData;
  onRefresh: () => Promise<void>;
}

function SwitchUserToDifferentCompanyMenuItem(
  props: SwitchUserToDifferentCompanyMenuItemProps,
) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newCompanyId, setNewCompanyId] = useState<string | undefined>();
  const createCompaniesApi = useCompaniesApi();
  const [loading, setLoading] = useState(false);
  const userEmail = props.user.email;

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (newCompanyId === undefined) {
      message.error("Please enter company id");
      return;
    }
    setLoading(true);
    try {
      const companiesApi = await createCompaniesApi();
      await companiesApi.moveUserToDifferentCompany({
        userEmail,
        newCompanyId,
      });
      await props.onRefresh();
      message.success("Switched company");
    } catch (e) {
      message.error(`Oops. Something went wrong. ${e}`);
    }
    setLoading(false);
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Modal
        title="Move to different company"
        visible={isModalVisible}
        onOk={handleOk}
        okText="Switch"
        onCancel={handleCancel}
        confirmLoading={loading}
        width={900}
      >
        <Form
          style={{ width: "800px" }}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 8, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Stack align="left">
            <Text>
              Enter the UUID of the company you want to move this used to. A
              UUID looks like
            </Text>
            <Text style={{ color: "#888" }}>
              d62a64e3-ba8f-4118-8458-c03753936350
            </Text>
          </Stack>
          <Spacer height={32} />
          <Form.Item label="New Company UUID">
            <Input
              value={newCompanyId}
              onChange={function (e) {
                setNewCompanyId(e.target.value);
              }}
              style={{ width: "600px" }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Menu.Item onClick={showModal}>Move to different company</Menu.Item>
    </>
  );
}

interface SwitchSalesContactToDifferentCompanyMenuItemProps {
  salesContact: SalesContact;
  companyData: CompanyData;
  onRefresh: () => Promise<void>;
}

function SwitchSalesContactToDifferentCompanyMenuItem(
  props: SwitchSalesContactToDifferentCompanyMenuItemProps,
) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newCompanyId, setNewCompanyId] = useState<string | undefined>();
  const createCompaniesApi = useCompaniesApi();
  const [loading, setLoading] = useState(false);
  const salesContactId = props.salesContact.salesContactId;

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (newCompanyId === undefined) {
      message.error("Please enter company id");
      return;
    }

    if (salesContactId === undefined) {
      message.error("Sales Contact Id is missing. Should not be here");
      return;
    }

    setLoading(true);
    try {
      const companiesApi = await createCompaniesApi();
      await companiesApi.moveSalesContactToDifferentCompany({
        salesContactId,
        newCompanyId,
      });
      await props.onRefresh();
      message.success("Switched company");
    } catch (e) {
      message.error(`Oops. Something went wrong. ${e}`);
    }
    setLoading(false);
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Move sales contact to different company"
        visible={isModalVisible}
        onOk={handleOk}
        okText="Switch"
        onCancel={handleCancel}
        confirmLoading={loading}
        width={900}
      >
        <Form
          style={{ width: "800px" }}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 8, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Stack align="left">
            <Text>
              Enter the UUID of the company you want to move this used to. A
              UUID looks like
            </Text>
            <Text style={{ color: "#888" }}>
              d62a64e3-ba8f-4118-8458-c03753936350
            </Text>
          </Stack>
          <Spacer height={32} />
          <Form.Item label="New Company UUID">
            <Input
              value={newCompanyId}
              onChange={function (e) {
                setNewCompanyId(e.target.value);
              }}
              style={{ width: "600px" }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Menu.Item onClick={showModal}>Move to different company</Menu.Item>
    </>
  );
}

interface ProvisionDashboardUserProps {
  salesContact: SalesContact;
  companyData: CompanyData;
  onRefresh: () => Promise<void>;
}
function ProvisionDashboardUser(props: ProvisionDashboardUserProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDataModalVisible, setDataIsModalVisible] = useState(false);
  const createCompaniesApi = useCompaniesApi();
  const [loading, setLoading] = useState(false);
  const salesContactId = props.salesContact.salesContactId;

  const [response, setResponse] =
    useState<ProvisionDashboardUserResponse | null>(null);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (salesContactId === undefined) {
      message.error("Sales Contact Id is missing. Should not be here");
      return;
    }
    setLoading(true);
    try {
      const companiesApi = await createCompaniesApi();
      const response = await companiesApi.provisionDashboardUser({
        salesContactId,
      });
      setResponse(response);
      await props.onRefresh();
      message.success("Dashboard user provided");
    } catch (e) {
      message.error(`Oops. Something went wrong. ${e}`);
    }
    setLoading(false);
    setIsModalVisible(false);
    setDataIsModalVisible(true);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    setDataIsModalVisible(false);
  };

  const salesContactAlreadyHasDashboardUser = (): boolean => {
    const find = props.companyData.users.find(
      (user) => user.email === props.salesContact.email,
    );
    return find !== undefined && find != null;
  };
  return (
    <>
      {response && (
        <Modal
          title="Login details"
          visible={isDataModalVisible}
          onCancel={handleCancel}
          width={900}
          footer={[
            <Button key="ok" onClick={handleCancel}>
              Ok
            </Button>,
          ]}
        >
          <div style={{ marginBottom: "20px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label
                style={{
                  marginRight: "10px",
                  width: "100px",
                }}
              >
                Email
              </label>
              <Input.Group compact>
                <Input
                  readOnly={true}
                  value={response.email}
                  style={{ width: "calc(100% - 32px)", minWidth: "100px" }}
                />
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(response.email);
                  }}
                  icon={<CopyOutlined />}
                />
              </Input.Group>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <label style={{ marginRight: "10px", width: "100px" }}>
                Password
              </label>
              <Input.Group compact>
                <Input
                  readOnly={true}
                  value={response.password}
                  style={{ width: "calc(100% - 32px)", minWidth: "100px" }}
                />
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(response.password);
                  }}
                  icon={<CopyOutlined />}
                />
              </Input.Group>
            </div>
          </div>
          <p>
            To make it easier, all you have to do is click this link <br />
            <a
              href={`${dashboardBaseUrl()}/qli?email=${
                response.email
              }&password=${response.password}`}
            >
              https://dashboard.freightsimple.com/qli?email={response.email}
              &password={response.password}
            </a>
          </p>
        </Modal>
      )}

      <Modal
        title="Provision Dashboard User"
        visible={isModalVisible}
        onOk={handleOk}
        okText="Provision"
        onCancel={handleCancel}
        confirmLoading={loading}
        width={900}
      >
        <p>
          Are you are sure you want to create a dashboard user for{" "}
          <span style={{ fontWeight: "bolder" }}>
            {props.salesContact.email}
          </span>
          ?
        </p>
      </Modal>
      <Menu.Item
        onClick={showModal}
        disabled={salesContactAlreadyHasDashboardUser()}
      >
        Provision Dashboard User
      </Menu.Item>
    </>
  );
}

function DisableQuotingMenuItem(props: CompanyTabMenuItemProps) {
  const { toggleQuotingAllowed } = useToggleQuotingAllowed(props.onRefresh);

  async function onToggle() {
    if (props.data?.company !== undefined) {
      await toggleQuotingAllowed(props.data?.company);
    }
  }

  if (props.data.company.quotingDisabled) {
    return (
      <Menu.Item onClick={onToggle} {...props}>
        Enable Quoting
      </Menu.Item>
    );
  } else {
    return (
      <Menu.Item onClick={onToggle} {...props}>
        Disable Quoting
      </Menu.Item>
    );
  }
}

function EnableAccountTypeMenuItem(props: CompanyTabMenuItemProps) {
  const { changeAccountType } = useChangeCompanyAccountType(props.onRefresh);
  const { isPersonal, isBroker } = props.data.company;

  async function handleClick(accountType: AccountType) {
    if (props.data?.company !== undefined) {
      await changeAccountType(props.data?.company, accountType);
    }
  }

  return (
    <Menu.ItemGroup title="Account Type">
      {(isPersonal || isBroker) && (
        <Menu.Item onClick={() => handleClick("business")}>
          Mark Business Account
        </Menu.Item>
      )}
      {!isBroker && (
        <Menu.Item onClick={() => handleClick("broker")}>
          Mark Broker Account
        </Menu.Item>
      )}
      {!isPersonal && (
        <Menu.Item onClick={() => handleClick("personal")}>
          Mark Personal Account
        </Menu.Item>
      )}
    </Menu.ItemGroup>
  );
}

function MarkHasNonProfessionalDomainMenuItem(props: CompanyTabMenuItemProps) {
  const { toggleHasNonProfessionalDomain } = useToggleHasNonProfessionalDomain(
    props.onRefresh,
  );

  async function onToggle() {
    if (props.data?.company !== undefined) {
      await toggleHasNonProfessionalDomain(props.data?.company);
    }
  }

  if (props.data.company.hasNonProfessionalDomain) {
    return (
      <Menu.Item onClick={onToggle} {...props}>
        Mark Does Not Have Non Professional Domain
      </Menu.Item>
    );
  } else {
    return (
      <Menu.Item onClick={onToggle} {...props}>
        Mark Has Non Professional Domain
      </Menu.Item>
    );
  }
}

function ToggleNeedsVerificationMenuItem(props: CompanyTabMenuItemProps) {
  const createCompaniesApi = useCompaniesApi();
  const confirmDisplay = useDisplay();

  const {
    onFetchPreview,
    onSend: onSendEmail,
    hash,
    to,
    setTo,
  } = useSendCompanyVerifiedEmail(props.data.company.companyId!);

  async function onSend(supportAgent: SupportAgent) {
    confirmDisplay.hide();
    const promise = onSendEmail(supportAgent);
    await props.onRefresh();
    return promise;
  }

  async function handleClick() {
    try {
      const needsVerification = !props.data.company.needsVerification;
      const companiesApi = await createCompaniesApi();
      companiesApi.setNeedsVerification({
        companyId: props.data.company.companyId!,
        needsVerification,
      });
      await props.onRefresh();
      if (!needsVerification) {
        confirmDisplay.show();
      }
      message.success("Done!");
    } catch {
      message.error(`Oops. Something went wrong`);
    }
  }

  async function handleCancel() {
    confirmDisplay.hide();
    await props.onRefresh();
  }

  return (
    <>
      {props.data.company.needsVerification ? (
        <Menu.Item onClick={handleClick} {...props}>
          Mark Company Verified
        </Menu.Item>
      ) : (
        <Menu.Item onClick={handleClick} {...props}>
          Mark Company Not Verified
        </Menu.Item>
      )}
      <Modal
        onCancel={confirmDisplay.hide}
        destroyOnClose
        closable
        open={confirmDisplay.status}
        title={"Send company verified email?"}
        footer={
          <>
            <Button type="default" onClick={confirmDisplay.hide}>
              Don't send
            </Button>
            <SupportEmailButton
              onCancel={handleCancel}
              type="primary"
              label="Preview email"
              onFetchPreview={onFetchPreview}
              onSend={onSend}
              previewUpdateHash={hash}
              extraFormItems={
                to && (
                  <ManageRecipientsButton
                    toAddresses={to}
                    setToAddresses={setTo}
                  />
                )
              }
            />
          </>
        }
      >
        You can preview the email, make changes to the recipients before
        sending, or cancel the action.
      </Modal>
    </>
  );
}

function SendCompanyVerifiedEmailMenuItem(props: CompanyTabMenuItemProps) {
  const { onFetchPreview, onSend, hash, to, setTo } =
    useSendCompanyVerifiedEmail(props.data.company.companyId!);

  if (props.data.company.needsVerification) {
    return null;
  }

  return (
    <SupportEmailMenuItem
      label="Send Company Verified Email"
      onFetchPreview={onFetchPreview}
      onSend={onSend}
      previewUpdateHash={hash}
      extraFormItems={
        to && <ManageRecipientsButton toAddresses={to} setToAddresses={setTo} />
      }
      {...props}
    />
  );
}

function ToggleVerificationDeniedMenuItem(props: CompanyTabMenuItemProps) {
  const createCompaniesApi = useCompaniesApi();

  async function onClick() {
    try {
      const companiesApi = await createCompaniesApi();
      companiesApi.setVerificationDenied({
        companyId: props.data.company.companyId!,
        verificationDenied: !props.data.company.verificationDenied,
      });
      await props.onRefresh();
      message.success("Done!");
    } catch {
      message.error(`Oops. Something went wrong`);
    }
  }

  if (!props.data.company.needsVerification) {
    return <></>;
  }

  if (props.data.company.verificationDenied) {
    return (
      <Menu.Item onClick={onClick} {...props}>
        Mark Verification Not Denied
      </Menu.Item>
    );
  } else {
    return (
      <Menu.Item onClick={onClick} {...props}>
        Mark Verification Denied
      </Menu.Item>
    );
  }
}

function RenameCompanyMenuItem(props: CompanyTabMenuItemProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newCompanyName, setNewCompanyName] = useState(
    props.data.company.companyName!,
  );
  const createCompaniesApi = useCompaniesApi();
  const companyId = props.data.company.companyId!;
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setLoading(true);
    try {
      const companiesApi = await createCompaniesApi();
      await companiesApi.renameCompany({
        companyId,
        newCompanyName,
      });
      await props.onRefresh();
      message.success("Renamed");
    } catch (e) {
      message.error(`Oops. Something went wrong. ${e}`);
    }
    setLoading(false);
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Modal
        title="Rename Company"
        visible={isModalVisible}
        onOk={handleOk}
        okText="Switch"
        onCancel={handleCancel}
        confirmLoading={loading}
        width={900}
      >
        <Form
          style={{ width: "800px" }}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 8, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="New Company Name">
            <Input
              value={newCompanyName}
              onChange={function (e) {
                setNewCompanyName(e.target.value);
              }}
              style={{ width: "600px" }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Menu.Item onClick={showModal} {...props}>
        Rename Company
      </Menu.Item>
    </>
  );
}

function ChangeDiscountMarkupMenuItem(props: CompanyTabMenuItemProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(
    props.data.company.discountPercentage!,
  );
  const [fixedMarkup, setFixedMarkup] = useState(
    props.data.company.fixedMarkup!,
  );
  const createCompaniesApi = useCompaniesApi();
  const companyId = props.data.company.companyId!;
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setLoading(true);
    try {
      const companiesApi = await createCompaniesApi();
      await companiesApi.setDiscountAndFixedMarkup({
        companyId,
        discountPercentage,
        fixedMarkup,
      });
      await props.onRefresh();
      message.success("Saved");
    } catch (e) {
      message.error(`Oops. Something went wrong. ${e}`);
    }
    setLoading(false);
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Modal
        title="Discount / Fixed Markup"
        visible={isModalVisible}
        onOk={handleOk}
        okText="Save"
        onCancel={handleCancel}
        confirmLoading={loading}
        width={1200}
      >
        <Form
          style={{ width: "1200px" }}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 8, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item
            label={
              <Stack align="right">
                <div>Discount Percentage (Negative means price increase)</div>
                <div style={{ color: Colors.LightText, fontSize: "12px" }}>
                  eg. -20 will increase their rates by 20%. 5 will decrease
                  their rates by 5%
                </div>
              </Stack>
            }
          >
            <NumberInput
              value={discountPercentage}
              setValue={function (e) {
                setDiscountPercentage(e);
              }}
              style={{ width: "600px" }}
            />
          </Form.Item>
          <Form.Item label={`Fixed Markup ${props.data.company.currency}`}>
            <NumberInput
              prefix={"$"}
              value={fixedMarkup}
              setValue={function (e) {
                setFixedMarkup(e);
              }}
              style={{ width: "600px" }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Menu.Item onClick={showModal} {...props}>
        Change Discount / Fixed Markup
      </Menu.Item>
    </>
  );
}

function DeleteCompanyMenuItem(props: CompanyTabMenuItemProps) {
  const createCompaniesApi = useCompaniesApi();
  const companyId = props.data.company.companyId!;
  const navigate = useNavigate();

  async function deleteCompany() {
    try {
      const companiesApi = await createCompaniesApi();
      await companiesApi.deleteCompany({
        companyId,
      });
      message.success("Deleted!");
      navigate("/");
    } catch {
      message.error("Oops. Something went wrong");
    }
  }
  return (
    <ConfirmMenuItem
      {...props}
      onConfirm={deleteCompany}
      question={
        "Are you sure you want to delete this company? It will remove all quoted shipments and other related records. It cannot be deleted if there are dashboard users or booked shipments"
      }
      okText={"Yes, delete this company"}
      tooltip={
        "Use this to clean up our records. You cannot delete companies that still have users or have booked shipments"
      }
    >
      Delete Company
    </ConfirmMenuItem>
  );
}

function ExtrasMenu(props: CompanyTabProps) {
  const companyId = props.data.company.companyId!;
  const copyCompanyId = useCopyToClipboard(companyId);
  const stripeCustomerId = props.data.company.stripeCustomerId;
  const stripeUrl = `https://dashboard.stripe.com/customers/${stripeCustomerId}`;
  const profitLossReportUrl = `/profit-loss?companyId=${companyId}`;

  const menu = (
    <Menu>
      <Menu.ItemGroup title="General">
        <Menu.Item onClick={copyCompanyId} icon={<PaperClipOutlined />}>
          Copy Company Id to clipboard
        </Menu.Item>
        <DeleteCompanyMenuItem {...props} />
        <RenameCompanyMenuItem {...props} />
        <EditCompanyMenuItem {...props} />
        <DisableQuotingMenuItem {...props} />
        <ChangeDiscountMarkupMenuItem {...props} />
        <SetPaymentTermsForCompanyMenuItem {...props} />
        <MarkHasNonProfessionalDomainMenuItem {...props} />
        <CombineWithDifferentCompanyMenuItem {...props} />
      </Menu.ItemGroup>
      <Menu.Divider />
      <Menu.ItemGroup title="Links">
        <Menu.Item icon={<LinkOutlined />}>
          <a target="_blank" rel="noreferrer" href={stripeUrl}>
            View in Stripe
          </a>
        </Menu.Item>
        <UrlMenuItem title="Profit / Loss Report" url={profitLossReportUrl} />
      </Menu.ItemGroup>
      <Menu.Divider />
      <Menu.ItemGroup title="Company Verification">
        <ToggleNeedsVerificationMenuItem {...props} />
        <SendCompanyVerifiedEmailMenuItem {...props} />
        <ToggleVerificationDeniedMenuItem {...props} />
      </Menu.ItemGroup>
      <Menu.Divider />
      <EnableAccountTypeMenuItem {...props} />
      {getEnvironment() != Environment.Production && (
        <>
          <Menu.Divider />
          <CopyShipmentToDifferentEnvMenuItem {...props} />
        </>
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button>
        <DownOutlined />
      </Button>
    </Dropdown>
  );
}

interface AddCouponButtonProps {
  companyData: CompanyData;
  refresh: () => void;
}

export function AddCouponButton(props: AddCouponButtonProps) {
  const companyId = props.companyData.company.companyId!;
  const currency = props.companyData.company.currency!;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [amount, setAmount] = useState(0);
  const createCompaniesApi = useCompaniesApi();

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    const companiesApi = await createCompaniesApi();
    companiesApi.createCoupon({ companyId, amount });
    setIsModalVisible(false);
    notification.success({
      message: "Coupon Created",
    });
    props.refresh();
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Add Coupon"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Enter the value for the coupon</p>
        <CurrencyInput
          amount={amount}
          setAmount={setAmount}
          currency={currency}
        />
      </Modal>
      <Button onClick={showModal} type="primary" icon={<PlusOutlined />}>
        Add Coupon
      </Button>
    </>
  );
}

function UsersTable(props: UsersTableProps) {
  return (
    <Table
      columns={safeColumns<User>([
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          render: (text) => <div>{text}</div>,
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
          render: (text) => <div>{text}</div>,
        },
        {
          title: "Phone",
          dataIndex: "phone",
          key: "phone",
          render: (phone) => <div>{phone}</div>,
        },
        {
          title: "Last Login",
          dataIndex: "lastLoginAt",
          key: "lastLoginAt",
          render: (lastLoginAt) => <div>{lastLoginAt}</div>,
        },
        {
          title: "Branch Name",
          dataIndex: "branchName",
          key: "branchName",
          render: (branchName) => <div>{branchName}</div>,
        },
        {
          title: "Accounting Notifications",
          dataIndex: "accountingNotificationsEnabled",
          key: "accountingNotificationsEnabled",
          render: (accountingNotificationsEnabled) => (
            <div>{accountingNotificationsEnabled ? "✅" : "❌"}</div>
          ),
        },
        {
          title: "All Shipment Notifications",
          dataIndex: "allShipmentNotificationsEnabled",
          key: "allShipmentNotificationsEnabled",
          render: (allShipmentNotificationsEnabled) => (
            <div>{allShipmentNotificationsEnabled ? "✅" : "❌"}</div>
          ),
        },
        {
          title: "Delivery Notifications Only",
          dataIndex: "deliveryNotificationsOnlyEnabled",
          key: "deliveryNotificationsOnlyEnabled",
          render: (deliveryNotificationsOnlyEnabled) => (
            <div>{deliveryNotificationsOnlyEnabled ? "✅" : "❌"}</div>
          ),
        },
        {
          title: "Actions",
          dataIndex: "userId",
          key: "userId",
          render: function (userId: string, user: User) {
            return (
              <ButtonRow
                extrasMenu={
                  <Menu>
                    <UrlMenuItem
                      title="View in Auth0"
                      url={`https://manage.auth0.com/dashboard/us/freightsimple-production/users/${btoa(
                        encodeURI(userId),
                      )}`}
                    />
                    <EditUsersModal
                      user={user}
                      companyid={props.companyData.company.companyId!}
                      onRefresh={props.onRefresh}
                    />
                    <SwitchUserToDifferentCompanyMenuItem
                      {...props}
                      user={user}
                    />
                    <DeleteUserMenuItem {...props} user={user} />
                  </Menu>
                }
              >
                <></>
              </ButtonRow>
            );
          },
        },
      ])}
      dataSource={props.companyData.users}
    />
  );
}

function SalesContactsTable(props: UsersTableProps) {
  function errorForSalesContactName(sc: SalesContact) {
    if (
      sum(props.companyData.salesContacts, (sc2) =>
        sc2.name === sc.name ? 1 : 0,
      ) > 1
    ) {
      return (
        <span style={{ marginLeft: "8px" }}>
          <Tooltip title="Duplicate name">🚨</Tooltip>
        </span>
      );
    } else {
      return "";
    }
  }

  function errorForSalesContactEmail(sc: SalesContact) {
    if (isBlank(sc.email)) {
      return "";
    }

    function hasEmailDomainProblem(): boolean {
      const apds = props.companyData.company.associatedProfessionalDomain ?? [];
      const scEmail = sc.email;
      if (isBlank(scEmail) || scEmail === undefined) {
        return false;
      }
      const ed = scEmail.split("@")[1];

      let permittedDomains = [...apds];
      if (props.companyData.company.hasNonProfessionalDomain) {
        permittedDomains = [
          ...permittedDomains,
          ...NonCorporateEmailData.map((ed) => removePrefix(ed, "@")),
        ];
      }

      return !permittedDomains.includes(ed);
    }

    const salesContactsWithoutKnownGroupEmails =
      props.companyData.salesContacts.filter(function (sc) {
        if (
          sc.email !== undefined &&
          props.companyData.company.knownGroupEmailAddresses?.includes(sc.email)
        ) {
          return false;
        } else {
          return true;
        }
      });

    if (
      sum(salesContactsWithoutKnownGroupEmails, (sc2) =>
        sc2.email === sc.email ? 1 : 0,
      ) > 1
    ) {
      return (
        <span style={{ marginLeft: "8px" }}>
          <Tooltip title="Duplicate email">🚨</Tooltip>
        </span>
      );
    } else if (hasEmailDomainProblem()) {
      return (
        <span style={{ marginLeft: "8px" }}>
          <Tooltip title="Email does not delong to associated domain. Note - if there is a non-professional email and a professional email - you can mark the company as having a non professioanl domain">
            🚨
          </Tooltip>
        </span>
      );
    } else {
      return "";
    }
  }

  return (
    <Table
      columns={safeColumns<SalesContact>([
        {
          title: "Primary",
          dataIndex: "primary",
          key: "primary",
          render: (_, o) => (
            <div>
              {o.salesContactId ===
              props.companyData.company.defaultSalesContactId
                ? "✅"
                : ""}
            </div>
          ),
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          render: (_, o) => (
            <div>
              {o.name}
              {errorForSalesContactName(o)}
            </div>
          ),
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
          render: (_, o) => (
            <div>
              {o.email}
              {errorForSalesContactEmail(o)}
            </div>
          ),
        },
        {
          title: "Phone",
          dataIndex: "phone",
          key: "phone",
          render: (_, o) => (
            <ClickToCallButton
              salesContact={o}
              isMobile={false}
              data={props.companyData}
              onRefresh={props.onRefresh}
            />
          ),
        },
        {
          title: "Phone Extension",
          dataIndex: "phoneExtension",
          key: "phoneExtension",
          render: (_, o) => <div>{o.phoneExtension}</div>,
        },
        {
          title: "Mobile Phone",
          dataIndex: "mobilePhone",
          key: "mobilePhone",
          render: (_, o) => (
            <ClickToCallButton
              salesContact={o}
              isMobile={true}
              data={props.companyData}
              onRefresh={props.onRefresh}
            />
          ),
        },
        {
          title: "Description",
          dataIndex: "description",
          key: "description",
          render: (_, o) => <div>{o.description}</div>,
        },
        {
          title: "Actions",
          dataIndex: "actions",
          key: "actions",
          render: (_, o) => (
            <ButtonRow>
              <EditContactButton
                salesContactId={o.salesContactId!}
                data={props.companyData}
                onRefresh={props.onRefresh}
              />
              <Dropdown
                overlay={
                  <Menu>
                    <MakePrimaryContactMenuItem
                      disabled={
                        o.salesContactId ===
                        props.companyData.company.defaultSalesContactId
                      }
                      salesContactId={o.salesContactId!}
                      companyId={o.companyId!}
                      onRefresh={props.onRefresh}
                    />
                    <SwitchSalesContactToDifferentCompanyMenuItem
                      {...props}
                      salesContact={o}
                    />
                    <ProvisionDashboardUser {...props} salesContact={o} />

                    <DeleteSalesContactMenuItem
                      disabled={
                        o.salesContactId ===
                        props.companyData.company.defaultSalesContactId
                      }
                      salesContactId={o.salesContactId!}
                      onRefresh={props.onRefresh}
                    />
                  </Menu>
                }
              >
                <Button>
                  <DownOutlined />
                </Button>
              </Dropdown>
            </ButtonRow>
          ),
        },
      ])}
      dataSource={props.companyData.salesContacts}
      pagination={false}
    />
  );
}

interface CouponTableProps {
  coupons: Array<Coupon>;
  bestCoupon: Coupon | null | undefined;
  refresh: () => void;
}

export function CouponsTable(props: CouponTableProps) {
  const createCompaniesApi = useCompaniesApi();

  function bestCouponDescription() {
    const amount = props.bestCoupon?.amount;

    if (amount !== undefined) {
      return `✅ Active coupon : $${amount}`;
    } else {
      return "No active coupon";
    }
  }

  function describeCouponState(state: CouponState) {
    switch (state) {
      case CouponState.Issued:
        return `🔵 Issued`;
      case CouponState.Used:
        return `👍 Used`;
      case CouponState.Voided:
        return `❌ Voided`;
      default:
        assertNever(state);
    }
  }

  return (
    <>
      <Spacer height={32} />
      <div style={{ marginBottom: "16px" }}>{bestCouponDescription()}</div>
      <Table
        columns={safeColumns<Coupon>([
          {
            title: "State",
            dataIndex: "couponState",
            key: "couponState",
            render: (state) => <div>{describeCouponState(state)}</div>,
          },
          {
            title: "Created by",
            dataIndex: "createdBy",
            key: "createdBy",
            render: (state) => <div>{state}</div>,
          },
          {
            title: "Amount",
            key: "email",
            render: (coupon: Coupon) => (
              <div>
                <NumericFormat
                  value={coupon.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </div>
            ),
          },
          {
            title: "Actions",
            dataIndex: "couponId",
            key: "couponId",
            render: function (couponId: string, coupon: Coupon) {
              async function voidCoupon() {
                const companiesApi = await createCompaniesApi();
                try {
                  companiesApi.voidCoupon({ couponId });
                  notification.success({ message: "Coupon voided" });
                  props.refresh();
                } catch {
                  notification.error({
                    message: "Oops",
                    description: "Something went wrong",
                  });
                }
              }
              const disable = coupon.couponState !== CouponState.Issued;
              return (
                <Button
                  disabled={disable}
                  type="primary"
                  danger
                  onClick={voidCoupon}
                >
                  Void Coupon
                </Button>
              );
            },
          },
        ])}
        dataSource={props.coupons}
      />
    </>
  );
}

interface PropertiesProp {
  company: Company;
}

function Properties(props: PropertiesProp) {
  return <Json data={props.company} />;
}

interface PageTitleProps {
  companyData: CompanyData;
}

const PageTitleComponentKey = styled.div`
  font-weight: 300;
  font-size: 12px;
  color: ${Colors.NormalText};
`;

const PageTitleComponentValue = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: ${Colors.LightText};
`;

interface PageTitleComponentKeyValueProps {
  title: string;
  value: any | undefined;
}

function PageTitleComponentKeyValue(props: PageTitleComponentKeyValueProps) {
  return (
    <HorizontalStack spacing={4} style={{ marginLeft: 4 }}>
      <PageTitleComponentKey>{props.title}:</PageTitleComponentKey>
      <PageTitleComponentValue>
        {props.value || "Unknown"}
      </PageTitleComponentValue>
    </HorizontalStack>
  );
}

function describeInvoiceWarningState(state: InvoiceWarningState): string {
  switch (state) {
    case InvoiceWarningState.BookingSuspendedDueToOverCreditLimit:
      return "Over Credit Limit";
    case InvoiceWarningState.BookingSuspendedDueToOverdueInvoices:
      return "Overdue Invoices";
    case InvoiceWarningState.BookingWarningCloseToCreditLimit:
      return "Close to Credit Limit";

    case InvoiceWarningState.BookingWarningInvoicesCloseToOverdue:
      return "Close to Overdue";

    case InvoiceWarningState.BookingSuspendedNeedsAccountVerification:
      return "Verification Needed";

    case InvoiceWarningState.NoWarning:
      return "No Warning";
    default:
      assertNever(state);
  }
}

interface InvoiceWarningTagProps {
  state: InvoiceWarningState;
}

function InvoiceWarningTag(props: InvoiceWarningTagProps) {
  return (
    <Tag color="orange">🚨 {describeInvoiceWarningState(props.state)}</Tag>
  );
}

function QuotingDisabledTag(props: PageTitleProps) {
  if (props.companyData.company.quotingDisabled) {
    return (
      <>
        <Tag color="red">‼️ Quoting Disabled</Tag>
      </>
    );
  } else {
    return <></>;
  }
}

export function AccountTypeTag(props: PageTitleProps) {
  if (props.companyData.company.isPersonal) {
    return <Tag color="magenta">Personal Account</Tag>;
  }

  if (props.companyData.company.isBroker) {
    return <Tag color="purple">Broker Account</Tag>;
  }

  return <></>;
}

function UnqualifiedTag(props: PageTitleProps) {
  if (
    props.companyData.company.qualificationStatus ===
    QualificationStatus.Unqualified
  ) {
    return (
      <>
        <Tag color="purple">🎈 Lead</Tag>
      </>
    );
  } else if (
    props.companyData.company.qualificationStatus ===
    QualificationStatus.Disqualified
  ) {
    return (
      <>
        <Tag color="red">Disqualified</Tag>
      </>
    );
  } else {
    return <></>;
  }
}

function VerificationTag(props: PageTitleProps) {
  if (props.companyData.company.verificationDenied) {
    return (
      <>
        <Tag color="red">❌ Verification Denied</Tag>
      </>
    );
  } else if (props.companyData.company.needsVerification) {
    return (
      <>
        <Tag color="yellow">? Verification Needed</Tag>
      </>
    );
  } else {
    return <></>;
  }
}

function PageTitleComponent(props: PageTitleProps) {
  const { companyData } = props;

  return (
    <Stack align="left">
      <HorizontalStack spacing={16} style={{ marginLeft: "-8px" }}>
        <CompanyName
          companyName={companyData.company.companyName}
          qualificationStatus={companyData.company.qualificationStatus}
          temperature={companyData.company.temperature}
          shipmentFrequency={companyData.company.shipmentFrequency}
        />

        <QuotingDisabledTag {...props} />
        <AccountTypeTag {...props} />
        <UnqualifiedTag {...props} />
        <VerificationTag {...props} />

        {companyData.company.associatedProfessionalDomain &&
          companyData.company.associatedProfessionalDomain.map((domain) => (
            <CompanyDomainTag key={domain} domain={domain} />
          ))}
        {companyData.company.hasNonProfessionalDomain && (
          <NonProfessionalDomainTag />
        )}
      </HorizontalStack>
      <HorizontalStack spacing={16} style={{ marginLeft: "-8px" }}>
        {companyData.company.firstQuoteDate && (
          <PageTitleComponentKeyValue
            title="First Quote"
            value={dayjs(companyData.company.firstQuoteDate).format(
              "MMM Do YYYY",
            )}
          />
        )}
        <PageTitleComponentKeyValue
          title="Lead Source"
          value={companyData.company.leadSource}
        />
        <PageTitleComponentKeyValue
          title="Frequency"
          value={companyData.company.shipmentFrequency}
        />
        <PageTitleComponentKeyValue
          title="Temperature"
          value={companyData.company.temperature}
        />
      </HorizontalStack>
    </Stack>
  );
}

interface CompanyStatProps {
  title: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactNode;
  value: ReactNode | undefined;
  copyToClipboard?: boolean;
  renderEmptyAsUnknown?: boolean;
}

function CompanyStatContent(props: CompanyStatProps) {
  if (props.value === undefined || props.value === null || props.value === "") {
    if (props.renderEmptyAsUnknown) {
      return <div style={{ color: Colors.Red }}>Unknown</div>;
    } else {
      return <>-</>;
    }
  }

  if (props.copyToClipboard) {
    return (
      <WithClipboardLink>
        {props.prefix}
        {props.value.toString()}
      </WithClipboardLink>
    );
  }

  if (Number.isFinite(props.value)) {
    return (
      <>
        {props.prefix}
        {props.value.toLocaleString()}
        {props.suffix}
      </>
    );
  }

  return (
    <>
      {props.prefix}
      {props.value}
      {props.suffix}
    </>
  );
}

function CompanyStat(props: CompanyStatProps) {
  return (
    <Stack align="left">
      <div style={{ color: Colors.LightText, fontSize: "12px" }}>
        {props.title}
      </div>
      <div>
        <CompanyStatContent {...props} />
      </div>
    </Stack>
  );
}

export function ViewCompanyScreen() {
  const createCompaniesApi = useCompaniesApi();
  const [loading, setLoading] = useState(false);

  const { setRefresh } = useCallRefreshContext();

  async function refresh() {
    const companiesApi = await createCompaniesApi();

    try {
      const response = await companiesApi.viewCompanyData({
        companyId,
      });
      setCompanyData(response);
    } catch (e) {
      // @ts-expect-error - type is unknown
      if (e.status === 404) {
        message.error("Could not find company");
      } else {
        message.error(`Could not load`);
      }
    }
  }

  async function refreshWithLoading() {
    setLoading(true);
    await refresh();
    setLoading(false);
  }

  const [companyData, setCompanyData] = useState<CompanyData | undefined>();
  const query = useQuery();
  const navigate = useNavigate();

  const companyId = query.companyId as string;
  const tab = query.tab as string;

  useEffect(
    function () {
      refreshWithLoading();
      setRefresh(refresh);
    },

    [companyId],
  );

  function callback(key: string) {
    navigate(`/view-company?companyId=${companyId}&tab=${key}`, {
      replace: true,
    });
  }

  function ExpiredPaymentMethodsNotification() {
    const expiredPaymentMethods =
      companyData?.paymentMethodInfo.paymentMethods.filter((paymentMethod) => {
        if (!paymentMethod.expiry) return false;

        const [month, year] = paymentMethod.expiry.split("/").map(Number);
        const expiryDate = new Date(year, month, 0);

        return expiryDate < new Date();
      });

    return expiredPaymentMethods?.length ? (
      <ExclamationCircleFilled
        style={{ color: Colors.Red, width: 10, marginTop: -5 }}
      />
    ) : null;
  }

  if (companyData === undefined || loading) {
    return <Loading message="Loading company data" />;
  } else {
    return (
      <>
        {/* Working Here -------------------------------------------------- */}
        <Page
          title={<PageTitleComponent companyData={companyData} />}
          tags={[
            <CompanyStarredTag key="starred" companyId={companyId} />,
            <CompanySalesCallsSnoozedTag
              key="snooze"
              snoozeSalesCallsUntil={companyData.company.snoozeSalesCallsUntil}
            />,
            <CompanyApiAccessEnabledTag
              key="apiAccessEnabled"
              apiAccessEnabled={companyData.company.apiAccessEnabled}
            />,
          ]}
          stats={
            <StatsRow>
              <CompanyStat
                title="Total Sales"
                prefix="$"
                value={companyData.company.totalCadSales?.toFixed(2)}
              />
              <CompanyStat
                title="Booked"
                value={companyData.company.shipmentsBooked}
              />
              <CompanyStat
                title="Quoted"
                value={companyData.company.quotesRan}
              />
              <CompanyStat
                title="Acceptance %"
                value={companyData.company.acceptanceRate}
              />
              <CompanyStat
                title="Payment Terms Days"
                value={
                  companyData.company.paymentTermsDays === 0
                    ? "Immediate"
                    : `${companyData.company.paymentTermsDays} days`
                }
              />
              {companyData.company.creditLimit !== undefined && (
                <CompanyStat
                  title="Credit Amount"
                  prefix={`$`}
                  value={companyData.company.creditLimit}
                />
              )}

              <CompanyStat
                title="Overdue"
                prefix={`$`}
                value={companyData.company.totalOverdueIssuedInvoicesCad}
              />

              <CompanyStat
                title="Issued"
                prefix={`$`}
                value={companyData.company.totalIssuedInvoicesCad}
              />

              {(companyData.company.totalDisputedInvoicesCad ?? 0) > 0 && (
                <CompanyStat
                  title="Disputed"
                  prefix={`$`}
                  value={companyData.company.totalDisputedInvoicesCad}
                />
              )}

              <CompanyStat
                title="Pending"
                prefix={`$`}
                value={companyData.company.totalSettlementPendingInvoicesCad}
              />

              {companyData.invoiceWarningState !==
                InvoiceWarningState.NoWarning && (
                <CompanyStat
                  title="Booking Warning"
                  value={
                    <InvoiceWarningTag
                      state={companyData.invoiceWarningState}
                    />
                  }
                />
              )}

              <CompanyStat
                title={
                  <HorizontalStack>
                    <div>
                      {(companyData.company.discountPercentage ?? 0) < 0
                        ? "Extra Markup"
                        : "Discount"}
                    </div>
                    <Spacer width={4} />
                    <InfoBubble overlay="If this is an extra markup - we have put their rates up (maybe because they're hard to deal with?), if this is a discount then we are reducing their rates" />
                  </HorizontalStack>
                }
                suffix={`%`}
                value={Math.abs(companyData.company.discountPercentage ?? 0)}
              />
              <CompanyStat
                title={
                  <HorizontalStack>
                    <div>Fixed Markup</div>
                    <Spacer width={4} />
                    <InfoBubble overlay="If we always get some additional charge for a company (eg. they are in a beyond delivery zone) then we can just add a fixed markup. This will just add a simple dollar amount to each quote to make up for the charges" />
                  </HorizontalStack>
                }
                prefix={`$`}
                suffix={` ${companyData.company.currency}`}
                value={companyData.company.fixedMarkup}
              />
            </StatsRow>
          }
          extra={[
            <ClaudeButton
              key="claude"
              context={ClaudeConversationType.COMPANY}
              data={companyData}
              onRefresh={refresh}
            />,
            <SalesforceSendEmailButton
              key="salesforce"
              data={companyData}
              onRefresh={refresh}
            />,
            <LogSalesCallButton
              key="logSalesCall"
              data={companyData}
              onRefresh={refresh}
            />,
            <SnoozeForSalesCallsButton
              key="snoozeSalesCalls"
              data={companyData}
              onRefresh={refresh}
            />,
            <Tooltip
              key="attach"
              placement="topLeft"
              title="Attach your user to this company"
            >
              <AttachButton companyId={companyId} />
            </Tooltip>,

            <StarCompanyButton
              key="star"
              data={companyData}
              onRefresh={refresh}
            />,

            <ExtrasMenu key="extras" data={companyData} onRefresh={refresh} />,
          ]}
        >
          <PageTitle>{`🏭 ${companyData.company.companyName}`}</PageTitle>
          <Tabs
            defaultActiveKey={tab}
            onChange={callback}
            style={{ width: "100%" }}
          >
            <TabPane tab="🔍 Company Overview" key="overview">
              <CompanyOverviewTab data={companyData} onRefresh={refresh} />
            </TabPane>
            <TabPane
              tab={`👥 Dashboard Users (${companyData.users.length})`}
              key="users"
            >
              <Typography.Title level={3}>Users</Typography.Title>
              <UsersTable companyData={companyData} onRefresh={refresh} />
              <Spacer height={30} />
              <Typography.Title level={3}>Invitations</Typography.Title>
              <InvitationsTable companyData={companyData} onRefresh={refresh} />
            </TabPane>
            <TabPane
              tab={`👥 Sales Contacts (${companyData.salesContacts.length})`}
              key="salesContacts"
            >
              <Stack align="left">
                <HorizontalStack>
                  <AddSalesContactButton
                    companyId={companyId}
                    onContactAdded={refresh}
                  />
                  {companyData.company.knownGroupEmailAddresses?.map(
                    function (kgea) {
                      return (
                        <Tag key={kgea} style={{ margin: "8px" }} color="blue">
                          Known Group Email: {kgea}
                        </Tag>
                      );
                    },
                  )}
                </HorizontalStack>
                <Spacer height={8} />
                <SalesContactsTable
                  companyData={companyData}
                  onRefresh={refresh}
                />
              </Stack>
            </TabPane>
            <TabPane
              tab={`☎️ Sales Calls (${
                companyData.companyNotes.filter(
                  (n) => n.significant && n.type === CompanyNoteType.Call,
                ).length
              }) & Other Notes (${
                companyData.companyNotes.filter(
                  (n) => n.type !== CompanyNoteType.Call,
                ).length
              })`}
              key="notes"
            >
              <CompanyNotesTab data={companyData} onRefresh={refresh} />
            </TabPane>
            <TabPane tab={`🚚 Shipments`} key="shipments">
              <CompanyShipmentsTab data={companyData} onRefresh={refresh} />
            </TabPane>
            <TabPane tab="📈 Graphs" key="graphs">
              <CompanyGraphsTab companyData={companyData} />
            </TabPane>
            <TabPane tab="📄 Invoices" key="invoices">
              <CompanyInvoicesTab companyData={companyData} />
            </TabPane>
            <TabPane
              tab={
                <>
                  <WarningComponent /> Invoice Problems
                </>
              }
              key="invoice_problems"
            >
              <CompanyInvoiceProblemsTab
                data={companyData}
                onRefresh={refresh}
              />
            </TabPane>
            <TabPane tab="🎟 Coupons" key="coupons">
              <Stack align="left">
                <AddCouponButton companyData={companyData} refresh={refresh} />
                <Spacer height={8} />
                <Link
                  to="https://gemini.freightsimple.com/sales/coupons"
                  target="_blank"
                >
                  See how coupons work
                </Link>
              </Stack>

              <CouponsTable
                coupons={companyData.coupons}
                bestCoupon={companyData.bestCoupon}
                refresh={refresh}
              />
            </TabPane>

            <TabPane
              tab={
                <HorizontalStack>
                  💳 Payment Methods
                  <Spacer width={5} />
                  <ExpiredPaymentMethodsNotification />
                </HorizontalStack>
              }
              key="paymentMethods"
            >
              <CompanyPaymentMethodsTab
                data={companyData}
                onRefresh={refresh}
              />
            </TabPane>
            <TabPane tab="💾 Raw" key="raw">
              <Properties company={companyData.company} />
            </TabPane>
            {companyData.company.apiAccessEnabled && (
              <TabPane tab="🤖 API Access" key="apiAccess">
                <CompanyApiTab data={companyData} onRefresh={refresh} />
              </TabPane>
            )}
          </Tabs>
        </Page>
      </>
    );
  }
}
