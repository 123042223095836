import { Note } from "@freightsimple/generated-apollo-openapi-client";
import { NoteHeadline } from "../NotesBase/NoteHeadline";
import { NoteMessage } from "../NotesBase/NoteMessage";
import { useInternalNotesContext } from "../../Contexts/InternalNotes/useInternalNotesContext";
import { useEffect, useState } from "react";
import { useCurrentUserEmail } from "../../Hooks/useCurrentUserEmail";
import styled from "styled-components";

interface BaseNoteProps {
  note: Note;
}

export function BaseNote({ note }: BaseNoteProps) {
  const currentUser = useCurrentUserEmail();
  const { handleOpenModal, setNote } = useInternalNotesContext();
  const [allowEdit, setAllowEdit] = useState(false);

  useEffect(() => {
    if (note.noteType == "internal_note" && note.author == currentUser)
      setAllowEdit(true);
  }, [currentUser, note]);

  const handleNote = (note: Note) => {
    setNote(note);
  };

  const handleEditNote = (note: Note) => {
    handleNote(note);
    handleOpenModal();
  };

  const Note = styled.div<{ allowEdit: boolean }>`
    font-weight: 500;

    font-size: 2rem;
    color: black;
    cursor: ${(props) => (props.allowEdit ? "pointer" : "default")};
    transition: background-color 0.2s;
    background-color: transparent;
    padding: 8px;
    border-radius: 4px;

    ${(props) =>
      props.allowEdit &&
      `
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    `}
  `;

  return (
    <Note
      allowEdit={allowEdit}
      onClick={allowEdit ? () => handleEditNote(note) : () => {}}
    >
      <NoteHeadline>{note.headline}</NoteHeadline>
      <NoteMessage>{note.message}</NoteMessage>
    </Note>
  );
}
