import { useContext } from "react";
import { InternalNotesContext } from "./InternalNotesContext";

export const useInternalNotesContext = () => {
  const context = useContext(InternalNotesContext);
  if (!context) {
    throw new Error("useMyContext debe usarse dentro de MyProvider");
  }
  return context;
};
