import {
  SignalAlarmRules,
  SignalRuleCount,
} from "@freightsimple/generated-apollo-openapi-client";

import { message } from "antd";
import { useState } from "react";
import { ButtonRow } from "../../../../Components/ButtonRow";
import { ConfirmButton } from "../../../../Components/ConfirmButton";
import { DataTable, DataTableColumn } from "../../../../Components/DataTable";
import { Loading } from "../../../../Components/Loading";
import { Page } from "../../../../Components/Page";
import { WarningComponent } from "../../../../Components/WarningComponent";
import { useOnce } from "../../../../Hooks/useOnce";
import { useSignalAlarmRulesApi } from "../../../../Apis/Apis";
import { AddSignalAlarmRuleButtonButton } from "./AddSignalAlarmRule";

interface DeleteSignalRuleButtonProps {
  signalAlarmRule: SignalAlarmRules;
  onRefresh: () => Promise<void>;
}

function DeleteSignalRuleButton(props: DeleteSignalRuleButtonProps) {
  const createSignalAlarmRulesApi = useSignalAlarmRulesApi();
  const [loading, setLoading] = useState(false);

  async function onConfirm() {
    setLoading(true);
    try {
      const signalAlarmRulesApi = await createSignalAlarmRulesApi();

      await signalAlarmRulesApi.deleteSignalAlarmRule({
        signalAlarmRuleId: props.signalAlarmRule.signalAlarmRulesId!,
      });
      await props.onRefresh();
      message.success("Alarm rule successfully deleted");
    } catch {
      message.error("Eek. Something went wrong");
    }
    setLoading(false);
  }

  return (
    <ConfirmButton
      type="default"
      loading={loading}
      tooltip="Use this if the rule is no longer valid."
      okText="Yes, do it."
      onConfirm={onConfirm}
      question="Are you sure you want to delete this rule?"
    >
      <WarningComponent /> Delete Rule
    </ConfirmButton>
  );
}

export function SignalsAlarmRulesScreen() {
  const createSignalAlarmRulesApi = useSignalAlarmRulesApi();
  const [signalAlarmRules, setSignalAlarmRules] = useState<
    SignalAlarmRules[] | undefined
  >();
  const [signalRuleCounts, setSignalRuleCounts] = useState<
    SignalRuleCount[] | undefined
  >();

  async function refresh() {
    const signalAlarmRulesApi = await createSignalAlarmRulesApi();
    const response = await signalAlarmRulesApi.getSignalAlarmRules();
    setSignalAlarmRules(response.rules);
    setSignalRuleCounts(response.signalRuleCounts);
  }

  useOnce(refresh);

  if (signalAlarmRules === undefined) {
    return <Loading />;
  }

  // Function to check for records without rules
  function getRecordsWithoutRules() {
    if (!signalRuleCounts) return [];
    return signalRuleCounts.filter((count) => count.ruleCount === 0);
  }

  const recordsWithoutRules = getRecordsWithoutRules();

  const columns: DataTableColumn<SignalAlarmRules>[] = [
    { title: "Signal Name", render: (o) => <div>{o.signalName}</div> },
    {
      title: "Carrier Identifier",
      render: (o) => <div>{o.carrierIdentifier}</div>,
    },
    { title: "Service Type", render: (o) => <div>{o.serviceType}</div> },
    {
      title: "Business Days Threshold",
      render: (o) => <div>{o.businessDayThreshold}</div>,
    },
    {
      title: "Actions",
      render: (o) => (
        <ButtonRow>
          <AddSignalAlarmRuleButtonButton
            onRefresh={refresh}
            signalRuleCount={signalRuleCounts}
            signalAlarm={o}
          />
          <DeleteSignalRuleButton signalAlarmRule={o} onRefresh={refresh} />
        </ButtonRow>
      ),
    },
  ];

  const ruleCountColumns: DataTableColumn<SignalRuleCount>[] = [
    { title: "Signal Name", render: (o) => <div>{o.name}</div> },
    {
      title: "Actions",
      render: (o) => (
        <ButtonRow>
          <AddSignalAlarmRuleButtonButton
            onRefresh={refresh}
            signalRuleCount={signalRuleCounts}
            originalSignalName={o.name}
          />
        </ButtonRow>
      ),
    },
  ];

  return (
    <Page
      title={`Signal Alarm Rules`}
      tags={[]}
      extra={[
        <ButtonRow key="add">
          <AddSignalAlarmRuleButtonButton
            onRefresh={refresh}
            signalRuleCount={signalRuleCounts}
          />
        </ButtonRow>,
      ]}
    >
      {recordsWithoutRules.length > 0 && (
        <>
          <div style={{ marginBottom: "2rem", color: "red" }}>
            Warning: The following records do not have any rules:
          </div>
          <DataTable
            pagination={false}
            columns={ruleCountColumns}
            data={recordsWithoutRules}
          />
        </>
      )}
      <DataTable
        style={{ marginTop: "3rem" }}
        pagination={false}
        columns={columns}
        data={signalAlarmRules}
      />
    </Page>
  );
}
