import { Form, Input, message, Modal, Switch } from "antd";
import { useState } from "react";
import { ApiKey } from "@freightsimple/generated-apollo-openapi-client";
import { InfoBubble } from "../Components/InfoBubble";
import Spacer from "../Spacer";
import HorizontalStack from "../Components/HorizontalStack";
import { useApiKeysApi } from "../Apis/Apis";
interface EditAPIKeyMenuItemProps {
  apiKey: ApiKey;
  onRefresh: () => Promise<void>;
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
}

export function EditAPIKeyMenuItem(props: EditAPIKeyMenuItemProps) {
  const [name, setName] = useState(props.apiKey.name || "");
  const [description, setDescription] = useState(
    props.apiKey.description || "",
  );
  const [isActive, setIsActive] = useState(props.apiKey.isActive || false);
  const [loading, setLoading] = useState(false);
  const createApiKeysApi = useApiKeysApi();

  async function handleOk() {
    if (
      props.apiKey.name === name &&
      props.apiKey.description === description &&
      props.apiKey.isActive === isActive
    ) {
      message.info("No changes were made");
      return;
    }

    if (name === "") {
      message.warning("Please enter a name for the API key");
      return;
    }

    setLoading(true);

    try {
      const apiKeysApi = await createApiKeysApi();
      if (!props.apiKey.apiKeyId) {
        throw new Error("Missing required IDs");
      }

      await apiKeysApi.editAPIKey({
        apiKeyId: props.apiKey.apiKeyId,
        name,
        description,
        isActive,
      });

      await props.onRefresh();
      message.success("API key information updated, please refresh the page");
      props.setIsModalOpen(false);
    } catch (e) {
      message.error(`Oops. Something went wrong. ${e}`);
    }
    setLoading(false);
  }

  const handleCancel = () => {
    setName(props.apiKey.name || "");
    setDescription(props.apiKey.description ?? "");
    setIsActive(props.apiKey.isActive || false);
    props.setIsModalOpen(false);
  };

  return (
    <Modal
      title="Edit API Key"
      open={props.isModalOpen}
      onOk={handleOk}
      okText="OK"
      onCancel={handleCancel}
      confirmLoading={loading}
      destroyOnClose={true}
      width={900}
    >
      <Form
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        layout="horizontal"
        colon={false}
      >
        <div style={{ width: "37rem" }}>
          <Form.Item label="Name">
            <Input
              placeholder="API key name"
              value={name}
              onChange={function (e) {
                setName(e.target.value);
              }}
              onKeyDown={(e) => e.stopPropagation()}
            />
          </Form.Item>
          <Form.Item label="Description">
            <Input
              placeholder="API key description"
              value={description}
              onChange={function (e) {
                setDescription(e.target.value);
              }}
              onKeyDown={(e) => e.stopPropagation()}
            />
          </Form.Item>
          <Form.Item label="Is Active">
            <HorizontalStack>
              <Switch
                checked={isActive}
                onChange={function (value) {
                  setIsActive(value);
                }}
              />
              <Spacer width={6} />
              <InfoBubble overlay="When enabled the API key will be available to use" />
            </HorizontalStack>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}
