import {
  CurrencyCode,
  QuoteTestExecutionAudit,
  QuoteTestExecutionDto,
} from "@freightsimple/generated-apollo-openapi-client";
import { Alert, Button, Splitter, Tabs, TabsProps } from "antd";
import SyntaxHighlighter from "react-syntax-highlighter";
import { StackedInfo } from "../../../../Components/StackedInfo";
import { useDisplay } from "../../../../Hooks/useDisplay";
import { QuoteTestExecutionCommentForm } from "./QuoteTestExecutionCommentForm";
import Spacer from "../../../../Spacer";
import Stack from "../../../../Components/Stack";
import { RefreshFunction } from "../../../../Types/RefreshFunctionType";
import { IsolatedHTML } from "../../../ViewShipmentScreenComponents/IsolatedHtml";
import { FallbackErrorBoundary } from "../../../../Components/FallbackErrorBoundary";
import { QuoteTestExecutionCommentList } from "./QuoteTestExecutionCommentList";
import { QuoteTestExpectationComponent } from "./QuoteTestExpectation";
import HorizontalStack from "../../../../Components/HorizontalStack";
import { KeyValues } from "../../../../Components/KeyValues";
import { useEffect, useState } from "react";
import { useXmlToJson } from "../Hooks/useXmlToJson";
import { colorBrewer } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Colors from "../../../../Components/Colors";
import { DataTable } from "../../../../Components/DataTable";
import { Price } from "../../../../Components/Price";

interface QuoteTestExecutionViewProps {
  execution: QuoteTestExecutionDto;
  refresh: RefreshFunction;
  setExecutionId: (_: string | null) => void;
}
export function QuoteTestExecutionView(props: QuoteTestExecutionViewProps) {
  const { execution, refresh, setExecutionId } = props;

  const displayNewComment = useDisplay();

  const items: TabsProps["items"] =
    execution.audits?.map((audit) => ({
      key: audit.subcommand!,
      label: audit.subcommand!,
      children: (
        <QuoteTestExecutionResults
          audit={audit}
          executionId={execution.executionId!}
        />
      ),
    })) ?? [];

  if (
    execution.quote?.carrierPriceExplanationExternal ||
    execution.quote?.carrierPriceExplanationInternal
  ) {
    items.push({
      key: "carrier-explanation",
      label: "Carrier Explanations",
      children: (
        <>
          <StackedInfo label="Internal">
            <FallbackErrorBoundary
              fallback={<p>Error. Please reload the page</p>}
            >
              <IsolatedHTML
                html={execution.quote?.carrierPriceExplanationInternal ?? ""}
              />
            </FallbackErrorBoundary>
          </StackedInfo>
          <StackedInfo label="External" labelStyle={{ marginTop: 24 }}>
            <FallbackErrorBoundary
              fallback={<p>Error. Please reload the page</p>}
            >
              <IsolatedHTML
                html={execution.quote?.carrierPriceExplanationExternal ?? ""}
              />
            </FallbackErrorBoundary>
          </StackedInfo>
        </>
      ),
    });
  }

  if (execution.quote?.additionalCarrierFees) {
    items.push({
      key: "additional-fees",
      label: "Additional Fees",
      children: (
        <>
          <Alert
            type="info"
            description="These additional carrier fees were added after receiving the carrier's response."
            showIcon
          />
          <Spacer height={24} />
          <DataTable
            data={execution.quote.additionalCarrierFees}
            columns={[
              {
                key: "description",
                title: "Description",
                render: (o) => o.description,
              },
              {
                key: "amount-cad",
                title: "Amount CAD",
                render: (o) => (
                  <Price currency={CurrencyCode.Cad}>{o.feeAmountCad}</Price>
                ),
              },
              {
                key: "amount-usd",
                title: "Amount USD",
                render: (o) => (
                  <Price currency={CurrencyCode.Usd}>{o.feeAmountUsd}</Price>
                ),
              },
            ]}
          />
        </>
      ),
    });
  }

  return (
    <Stack style={{ gap: 40 }} align="left">
      <StackedInfo
        label="Execution comments"
        style={{ width: "100%", gap: 4 }}
        labelStyle={{ marginBottom: 16 }}
      >
        <QuoteTestExecutionCommentList
          comments={execution.comments}
          refresh={refresh}
          setExecutionId={setExecutionId}
        />
        <Spacer height={16} />
        {!displayNewComment.status && (
          <Button type="default" onClick={displayNewComment.show}>
            New Comment
          </Button>
        )}
        {displayNewComment.status && (
          <>
            <QuoteTestExecutionCommentForm
              display={displayNewComment}
              executionId={execution.executionId!}
              refresh={refresh}
            />
          </>
        )}
      </StackedInfo>
      {((execution.success && execution.success.length > 0) ||
        (execution.failures && execution.failures.length > 0)) && (
        <StackedInfo label="Expectations" fullWidth>
          <HorizontalStack
            style={{ gap: 24, paddingBlock: 16 }}
            verticalAlign="top"
          >
            {execution.success?.map((e, i) => (
              <QuoteTestExpectationComponent
                key={i}
                expectation={{ ...e }}
                success
              />
            ))}

            {execution.failures?.map((e, i) => (
              <QuoteTestExpectationComponent
                key={i}
                expectation={{ ...e }}
                success={false}
              />
            ))}
          </HorizontalStack>
        </StackedInfo>
      )}
      {execution.errors && execution.errors.length > 0 && (
        <StackedInfo label="Errors">
          {execution.errors.map((e, i) => (
            <Alert
              type="error"
              showIcon
              message={"Error caught"}
              key={i}
              description={e}
            />
          ))}
        </StackedInfo>
      )}
      {execution.quote && (
        <StackedInfo label="Execution quote output">
          <KeyValues
            data={{
              ["Quoted Price By Carrier Original Currency"]: `$${execution.quote?.quotedPriceByCarrierOriginalCurrency} ${execution.quote?.originalCurrency}`,
              ["Quoted Price By Carrier CAD"]: `$${execution.quote?.quotedPriceByCarrierCad} CAD`,
            }}
          />
        </StackedInfo>
      )}
      {execution.audits && execution.audits.length > 0 && (
        <StackedInfo label="Execution audits" fullWidth>
          <Tabs style={{ width: "100%" }} items={items} />
        </StackedInfo>
      )}
    </Stack>
  );
}
interface QuoteTestExecutionResultsProps {
  executionId: string;
  audit: QuoteTestExecutionAudit;
}
function QuoteTestExecutionResults(props: QuoteTestExecutionResultsProps) {
  const { audit } = props;

  const { xmlToJson } = useXmlToJson();

  const [response, setResponse] = useState(audit.response ?? "");
  const [responseType, setResponseType] = useState(
    audit.responseType ?? "text",
  );
  const [request, setRequest] = useState(audit.request ?? "");
  const [requestType, setRequestType] = useState(audit.requestType ?? "text");

  function pretty(str: string, format: string): string {
    if (format === "json") {
      try {
        return JSON.stringify(JSON.parse(str), null, 2);
      } catch (error) {
        console.error(error);
        return str;
      }
    }
    return str;
  }

  useEffect(() => {
    setResponse(audit.response ?? "");
    setResponseType(audit.responseType ?? "text");
    setRequest(audit.request ?? "");
    setRequestType(audit.requestType ?? "text");
  }, [JSON.stringify(audit)]);

  const prettyResponse = pretty(response, responseType);
  const prettyRequest = pretty(request, requestType);

  return (
    <div style={{ paddingInline: 16 }}>
      <StackedInfo label="URL" fullWidth>
        <SyntaxHighlighter
          language="url"
          wrapLines
          wrapLongLines
          style={colorBrewer}
        >
          {audit?.url ?? ""}
        </SyntaxHighlighter>
      </StackedInfo>
      <Splitter>
        <Splitter.Panel defaultSize="40%" min="3%" max="95%">
          <StackedInfo
            label={
              <HorizontalStack align="spread">
                Request
                <div>
                  {requestType !== audit.requestType && (
                    <Button
                      type="link"
                      onClick={async () => {
                        setRequest(audit.request ?? "");
                        setRequestType(audit.requestType ?? "text");
                      }}
                    >
                      Show Original
                    </Button>
                  )}
                  {requestType === "xml" && (
                    <Button
                      type="link"
                      onClick={async () => {
                        const json = await xmlToJson({ value: request! });
                        if (json) {
                          setRequest(json);
                          setRequestType("json");
                        }
                      }}
                    >
                      to JSON
                    </Button>
                  )}
                </div>
              </HorizontalStack>
            }
            fullWidth
          >
            <SyntaxHighlighter
              showLineNumbers
              lineNumberStyle={{ color: Colors.VeryLightText }}
              language={requestType}
              wrapLines
              style={colorBrewer}
            >
              {prettyRequest}
            </SyntaxHighlighter>
          </StackedInfo>
        </Splitter.Panel>
        <Splitter.Panel>
          <StackedInfo
            label={
              <HorizontalStack align="spread">
                Response
                <div>
                  {responseType !== audit.responseType && (
                    <Button
                      type="link"
                      onClick={async () => {
                        setResponse(audit.response ?? "");
                        setResponseType(audit.responseType ?? "text");
                      }}
                    >
                      Show Original
                    </Button>
                  )}
                  {responseType === "xml" && (
                    <Button
                      type="link"
                      onClick={async () => {
                        const json = await xmlToJson({ value: response! });
                        if (json) {
                          setResponse(json);
                          setResponseType("json");
                        }
                      }}
                    >
                      to JSON
                    </Button>
                  )}
                </div>
              </HorizontalStack>
            }
            fullWidth
          >
            <SyntaxHighlighter
              showLineNumbers
              lineNumberStyle={{ color: Colors.VeryLightText }}
              language={responseType}
              wrapLines
              style={colorBrewer}
            >
              {prettyResponse}
            </SyntaxHighlighter>
          </StackedInfo>
        </Splitter.Panel>
      </Splitter>
    </div>
  );
}
