export function emojiForShipmentVolume(volume: string | undefined) {
  if (volume === "Daily") {
    return "🐳";
  } else if (volume === "Weekly") {
    return "🐬";
  } else if (volume === "Occasional") {
    return "🤙";
  } else if (volume === "SingleShipment") {
    return "🙄";
  } else {
    return "";
  }
}
