import { useState } from "react";
import { useQuoteTestApi } from "../../../../Apis/Apis";
import {
  ExportQuoteGroupData,
  QuoteTestExpectation,
} from "@freightsimple/generated-apollo-openapi-client";

export function useImportQuoteTestGroup() {
  const createApi = useQuoteTestApi();

  const [isLoading, setIsLoading] = useState(false);

  const importData = async (data: ExportQuoteGroupData) => {
    setIsLoading(() => true);
    try {
      const api = await createApi();
      const executions = data.scenarioDetails.flatMap((e) => e.executions);
      type Expectations = { [key: string]: Array<QuoteTestExpectation> };
      const scenarioExpectations = data.scenarioDetails.reduce(
        (acc, scenario) => {
          acc[scenario.scenarioId!] =
            scenario.expectations as Array<QuoteTestExpectation>;
          return acc;
        },
        {} as Expectations,
      );
      await api.importData({
        importQuoteGroupData: {
          group: data.group,
          groupExpectation: {
            [data.group.groupId!]: data.group.expectations,
          },
          scenarios: data.scenarioDetails,
          scenarioExpectations,
          executions: executions,
          comments: executions.flatMap((e) => e.comments),
        },
      });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(() => false);
  };

  return { importData, isLoading };
}
