import {
  CarrierServiceInfo,
  QuotingStrategyType,
} from "@freightsimple/generated-apollo-openapi-client";
import { Button, message, Statistic } from "antd";
import { useState } from "react";
import { useCarriersApi } from "../Apis/Apis";
import CarrierLogo from "../Components/CarrierLogo";
import { DataTable, DataTableColumn } from "../Components/DataTable";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import { Percentage } from "../Components/Percentage";
import { StatsRow } from "../Components/StatsRow";
import { groupBy } from "../Helpers/groupBy";
import { useOnce } from "../Hooks/useOnce";
import HorizontalStack from "../Components/HorizontalStack";

interface CarrierServicesTableProps {
  services: CarrierServiceInfo[];
}

function CarrierServicesTable(props: CarrierServicesTableProps) {
  const columns: DataTableColumn<CarrierServiceInfo>[] = [
    {
      title: "Carrier",
      render: (o) => (
        <CarrierLogo
          carrierIdentifier={o.carrierIdentifier}
          brokeredCarrierIdentifier={undefined}
          width={40}
          height={30}
        />
      ),
    },
    {
      title: "Service",
      render: (o) => <div>{o.serviceIdentifier}</div>,
    },
    {
      title: "Customer Name",
      render: (o) => <div>{o.serviceDisplayName}</div>,
    },
    {
      title: "Quoting",
      render: (o) => <div>{o.quotingStrategy.strategyType}</div>,
    },
    {
      title: "Booking",
      render: (o) => <div>{o.bookShipmentStrategy.strategyType}</div>,
    },
    {
      title: "Service Area",
      render: (o) => <div>{o.serviceAreaDescription}</div>,
    },
    {
      title: "Markup",
      render: (o) => <Percentage colored>{o.markupPercentage}</Percentage>,
    },
    {
      title: "Actions",
      render: (o) => (
        <HorizontalStack style={{ gap: 12 }}>
          <Button href={`/carrier?carrierIdentifier=${o.carrierIdentifier}`}>
            🚚 View Carrier
          </Button>
          {o.quotingStrategy.strategyType == QuotingStrategyType.Instant && (
            <Button
              href={`/carriers/quote-test?carrierIdentifier="${o.carrierIdentifier}"&serviceIdentifier="${o.serviceIdentifier}"`}
            >
              Go to Quote Test
            </Button>
          )}
        </HorizontalStack>
      ),
    },
  ];

  return (
    <DataTable pagination={false} columns={columns} data={props.services} />
  );
}

export function CarrierServicesScreen() {
  const [services, setServices] = useState<undefined | CarrierServiceInfo[]>();
  const createCarriersApi = useCarriersApi();

  useOnce(async function () {
    try {
      const carriersApi = await createCarriersApi();
      const response = await carriersApi.getCarrierServices();
      setServices(response);
    } catch {
      message.error("Something went wrong loading data");
    }
  });

  if (services === undefined) {
    return <Loading message="Loading services" />;
  } else {
    const carrierCount = groupBy(services, (o) => o.carrierIdentifier).length;

    return (
      <>
        <Page
          title="All Services"
          tags={[]}
          stats={
            <StatsRow>
              <Statistic
                title="Total Carriers"
                prefix=""
                value={carrierCount}
                style={{
                  margin: "0 32px",
                }}
              />
              <Statistic
                title="Total Services"
                value={services.length}
                style={{
                  margin: "0 32px",
                }}
              />
            </StatsRow>
          }
          extra={[]}
        >
          <PageTitle>{`Services`}</PageTitle>
          <CarrierServicesTable services={services} />
        </Page>
      </>
    );
  }
}
