import { Button, Card, Dropdown, Modal } from "antd";
import { StackedInfo } from "../../../../Components/StackedInfo";
import { QuoteTestDashboardProps } from "../QuoteTestDashboard";
import { QuoteTestGroupDto } from "@freightsimple/generated-apollo-openapi-client";
import { RefreshFunction } from "../../../../Types/RefreshFunctionType";
import { QuoteTestGroupExpectationModal } from "./QuoteTestGroupExpectationModal";
import { QuoteTestScenarioFromShipmentModal } from "./QuoteTestScenarioFromShipmentModal";
import { QuoteTestScenarioFormModal } from "./QuoteTestScenarioFormModal";
import { useExportQuoteTestGroup } from "../Hooks/useExportQuoteTestGroup";
import HorizontalStack from "../../../../Components/HorizontalStack";
import { QuoteTestGroupFormModal } from "../QuoteTestGroupFormModal";
import { useDisplay } from "../../../../Hooks/useDisplay";
import { useQuoteTestGroupApi } from "../Hooks/useCreateQuoteTestGroup";
import { QuoteTestScenarioFromJSONModal } from "./QuoteTestScenarioFromJSONModal";
import { QuoteTestViewShipmentLink } from "./QuoteTestViewShipmentLink";
import {
  CheckOutlined,
  CodeOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
  MoreOutlined,
  NumberOutlined,
} from "@ant-design/icons";
import { CloneQuoteTestGroupModal } from "./CloneQuoteTestGroupModal";

interface QuoteTestGroupViewProps extends QuoteTestDashboardProps {
  group: QuoteTestGroupDto;
  refresh: RefreshFunction;
}
export function QuoteTestGroupView(props: QuoteTestGroupViewProps) {
  const { group, refresh } = props;

  const { exportData } = useExportQuoteTestGroup();

  const { deleteGroup } = useQuoteTestGroupApi();

  const displayExpectationModal = useDisplay();
  const displayScenarioFromShipmentJson = useDisplay();
  const displayScenarioFromShipmentModal = useDisplay();
  const displayScenarioModal = useDisplay();
  const displayGroupModal = useDisplay();
  const displayCloneGroupModal = useDisplay();

  async function exportGroup() {
    const data = await exportData(group.groupId!);

    // Create a Blob from the JSON string
    const blob = new Blob([JSON.stringify(data)], { type: "application/json" });

    // Create a link element
    const link = document.createElement("a");

    // Set the download attribute with a file name
    link.download = `exported_group_${group.groupId}.json`;

    // Create an object URL for the Blob and set it as the href for the link
    link.href = URL.createObjectURL(blob);

    // Programmatically click the link to trigger the download
    link.click();

    // Optional: Clean up the object URL after the download
    URL.revokeObjectURL(link.href);
  }

  async function handleDelete() {
    if (!group.groupId) return;
    await deleteGroup(group.groupId);
    await refresh();
  }

  // async function handleRun() {
  //   if (!group.groupId) return;
  //   await runGroup(group.groupId, []);
  //   await refresh();
  // }

  return (
    <>
      <QuoteTestScenarioFormModal
        {...props}
        groupId={group.groupId!}
        baseScenario={null}
        refresh={refresh}
        display={displayScenarioModal}
      />
      <QuoteTestGroupExpectationModal
        {...props}
        groupId={group.groupId!}
        refresh={refresh}
        display={displayExpectationModal}
      />
      <QuoteTestScenarioFromJSONModal
        {...props}
        groupId={group.groupId!}
        refresh={refresh}
        display={displayScenarioFromShipmentJson}
      />
      <QuoteTestGroupFormModal
        {...props}
        refresh={refresh}
        group={group}
        display={displayGroupModal}
      />
      <QuoteTestScenarioFromShipmentModal
        {...props}
        groupId={group.groupId!}
        refresh={refresh}
        display={displayScenarioFromShipmentModal}
      />

      <CloneQuoteTestGroupModal
        {...props}
        groupId={group.groupId!}
        refresh={refresh}
        display={displayCloneGroupModal}
      />
      <Card
        title={
          <HorizontalStack style={{ gap: 8 }}>
            {group.title}

            <Dropdown
              trigger={["click"]}
              menu={{
                items: [
                  {
                    key: "edit",
                    icon: <EditOutlined />,
                    label: "Edit Group",
                    onClick: displayGroupModal.show,
                  },
                  { type: "divider" },
                  {
                    key: "export",
                    icon: <ExportOutlined />,
                    label: "Export Group",
                    onClick: exportGroup,
                  },
                  {
                    key: "clone",
                    icon: <CopyOutlined />,
                    label: "Clone Group",
                    onClick: displayCloneGroupModal.show,
                  },
                  { type: "divider" },
                  {
                    key: "create-expectation",
                    icon: <CheckOutlined />,
                    label: "Create Expectation",
                    onClick: displayExpectationModal.show,
                  },
                  { type: "divider" },
                  // {
                  //   key: "run",
                  //   icon: <PlayCircleOutlined />,
                  //   label: "Run Group",
                  //   onClick: () =>
                  //     Modal.confirm({
                  //       width: 600,
                  //       type: "warning",
                  //       title: "Run Group",
                  //       content: (
                  //         <p>
                  //           Run Group option should only be used for{" "}
                  //           <strong>RateSheet Quote Runners</strong>
                  //         </p>
                  //       ),
                  //       onOk: handleRun,
                  //       okType: "danger",
                  //       okText: "Run",
                  //     }),
                  // },
                  // { type: "divider" },
                  {
                    key: "delete",
                    icon: <DeleteOutlined />,
                    danger: true,
                    label: "Delete Group",
                    onClick: () =>
                      Modal.confirm({
                        type: "warning",
                        title: "Delete Group",
                        content: "Are you sure to delete this group?",
                        onOk: handleDelete,
                        okType: "danger",
                        okText: "Delete",
                      }),
                  },
                ],
              }}
            >
              <Button icon={<MoreOutlined />} type="text" />
            </Dropdown>
          </HorizontalStack>
        }
        extra={
          <HorizontalStack style={{ gap: 12 }}>
            <Dropdown.Button
              type="primary"
              onClick={displayScenarioModal.show}
              menu={{
                items: [
                  {
                    icon: <NumberOutlined />,
                    key: "create-from-id",
                    label: "Create Scenario From Shipment ID",
                    onClick: displayScenarioFromShipmentModal.show,
                  },
                  {
                    icon: <CodeOutlined />,
                    key: "create-from-json",
                    label: "Create Scenario From JSON",
                    onClick: displayScenarioFromShipmentJson.show,
                  },
                ],
              }}
            >
              Create Scenario
            </Dropdown.Button>
          </HorizontalStack>
        }
      >
        <HorizontalStack style={{ gap: 24 }} verticalAlign="top">
          <StackedInfo label="Description" preWrapWhiteSpace>
            {group.description}
          </StackedInfo>
          <StackedInfo label="Linear IDs">
            {group.parsedMetadata.linearIds.join(", ")}
          </StackedInfo>
          <StackedInfo label="Shipment IDs">
            {group.parsedMetadata.shipmentIds.map((id) => (
              <QuoteTestViewShipmentLink shipmentId={id} key={id} />
            ))}
          </StackedInfo>
        </HorizontalStack>
      </Card>
    </>
  );
}
