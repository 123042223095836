import { useState } from "react";
import { useQuoteTestApi } from "../../../../Apis/Apis";
import { PostXmlToJson } from "@freightsimple/generated-apollo-openapi-client";

export function useXmlToJson() {
  const createApi = useQuoteTestApi();

  const [isLoading, setIsLoading] = useState(false);

  const xmlToJson = async (body: PostXmlToJson) => {
    setIsLoading(() => true);
    try {
      const api = await createApi();
      const response = await api.xmlToJson({
        postXmlToJson: body,
      });
      return response.json;
    } catch (error) {
      console.error(error);
    }
    setIsLoading(() => false);
  };

  return { xmlToJson, isLoading };
}
