import { useEffect } from "react";
import { useSearchParamsState } from "../Contexts/SearchParamsState/useSearchParamsState";

export type UseParamStateReturn<T> = [T, (value: T) => void, string | null];

export interface UseParamStateConfig<T> {
  key: string;
  defaultValue: T;
  removeIf?: (value: T) => boolean;
  showDefaultValueInURL?: boolean;
  deleteEmptyArrays?: boolean;
}

/**
 * A custom hook for synchronizing state with URL query parameters using `URLSearchParams`.
 * It provides a way to read from and write to the URL query string, making it useful for persisting state across page reloads and URL changes.
 * This hook can also clean empty arrays from the state before serializing and updating the URL, based on the `deleteEmptyArrays` flag.
 *
 * @param config - Configuration object for managing the state:
 *   - `key`: The query parameter key in the URL.
 *   - `defaultValue`: The default value to be used when the key is not present or when the value cannot be parsed.
 *   - `removeIf`: An optional function that determines whether the query parameter should be removed from the URL based on the state value.
 *   - `showDefaultValueInURL`: Optionally show the default value in the URL even if it's the same as the stored one.
 *   - `deleteEmptyArrays`: Flag to clean empty arrays before serializing the value for the URL (default: `true`).
 *
 * @returns A tuple containing:
 *   - `state`: The current state value, either from the URL query parameter or the `defaultValue` if the parameter is absent or invalid.
 *   - `setState`: A function to update the URL query parameter. It serializes the value before updating or deletes the parameter if necessary.
 *   - `serializedValue`: The raw string value of the URL parameter (`string | null`). If the key is absent or invalid, this will be `null`.
 *
 * This hook is especially useful for managing query parameters in filters, pagination, or search states, allowing for state persistence across navigation or page reloads.
 *
 * Example usage:
 * ```tsx
 * const [filter, setFilter] = useParamState({
 *    key: "filter",
 *    defaultValue: { search: "", categories: [], dateRange: { start: "", end: "" } },
 *    deleteEmptyArrays: true, // Automatically clean empty arrays from the state
 *  });
 * ```
 */
export function useParamState<T>({
  key,
  defaultValue,
  removeIf,
  showDefaultValueInURL = false,
  deleteEmptyArrays = true, // Default to cleaning empty arrays
}: UseParamStateConfig<T>): UseParamStateReturn<T> {
  const [searchParams, setSearchParams] = useSearchParamsState();
  const storedValue = searchParams.get(key);

  // Function to remove empty arrays
  const cleanEmptyArrays = (value: any): any => {
    if (Array.isArray(value)) {
      // If the value is an empty array, return undefined to remove it
      return value.length === 0 ? undefined : value;
    }
    if (typeof value === "object" && value !== null) {
      // If it's an object, recursively clean its values
      Object.keys(value).forEach((key) => {
        value[key] = cleanEmptyArrays(value[key]);
        if (value[key] === undefined) {
          delete value[key]; // Remove the key if its value is cleaned out
        }
      });
    }
    return value;
  };

  const serialize = (_value: T): string => {
    let value = _value;
    if (deleteEmptyArrays) {
      value = cleanEmptyArrays(value);
    }
    return JSON.stringify(value);
  };

  const deserialize = (value: string): T => JSON.parse(value);

  const setValue = (newValue: T) => {
    setSearchParams((prev) => {
      const serializedValue = serialize(newValue);
      const serializedDefaultValue = serialize(defaultValue);

      // If the value should be removed or is equal to the default value, delete the parameter
      if (
        removeIf?.(newValue) ||
        (serializedValue === serializedDefaultValue && !showDefaultValueInURL)
      ) {
        prev.delete(key);
      } else {
        prev.set(key, serializedValue);
      }

      return prev;
    });
  };

  useEffect(() => {
    if (storedValue !== null || !showDefaultValueInURL) {
      return;
    }
    setSearchParams((prev) => {
      const serializedValue = serialize(defaultValue);
      prev.set(key, serializedValue);
      return prev;
    });
  }, []);

  if (storedValue === null) {
    return [defaultValue, setValue, null];
  }

  try {
    return [deserialize(storedValue) as T, setValue, storedValue];
  } catch (error) {
    console.error(`Error parsing value for key "${key}":`, error);
    return [defaultValue, setValue, storedValue];
  }
}
