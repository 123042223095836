import { Cascader } from "antd";
import CarriersData from "../GeneratedFixtures/CarriersData.json";
import { groupBy } from "../Helpers/groupBy";

type CarrierDataType = typeof CarriersData[number]

interface CarrierAndServiceFilterProps {
  carriers?: Array<CarrierDataType>;
  carrierIdentifier: string | undefined;
  serviceIdentifier: string | undefined;
  onChange: (carrierIdentifier: string, serviceIdentifier: string) => void;
}

function convertCarriersToOptions(data: CarrierDataType[] = CarriersData) {
  return groupBy(data, (c) => c.carrierIdentifier).map(function (c) {
    return {
      value: c.key,
      label: c.key,
      children: c.value.map(function (s) {
        return {
          value: s.serviceIdentifier,
          label: s.serviceIdentifier,
        };
      }),
    };
  });
}

export function CarrierAndServiceFilter(props: CarrierAndServiceFilterProps) {
  return (
    <Cascader
      value={
        props.carrierIdentifier !== undefined &&
        props.serviceIdentifier !== undefined
          ? [props.carrierIdentifier, props.serviceIdentifier]
          : []
      }
      options={convertCarriersToOptions(props.carriers)}
      onChange={function (value: (string | number)[]) {
        props.onChange(value[0].toString(), value[1].toString());
      }}
      placeholder="Please select"
    />
  );
}
