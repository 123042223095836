import {
  ApolloMapQuery,
  ApolloMapQueryBookingStatus,
  ShipmentReport,
} from "@freightsimple/generated-apollo-openapi-client";
import { FilterOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, Tabs } from "antd";
import { useEffect, useState } from "react";
import { CarrierFilter } from "../../Components/CarrierFilter";
import Colors from "../../Components/Colors";
import { DateRangePicker } from "../../Components/DateRangePicker";
import { GeoFilterDropdown } from "../../Components/GeoFilterDropdown";
import HorizontalStack from "../../Components/HorizontalStack";
import { ShipmentStateDropdown } from "../../Components/ShipmentStateDropdown";
import { TriStateBoolean } from "../../Components/TriStateBoolean";
import Spacer from "../../Spacer";
import { CompaniesDropdown } from "../MapScreenComponents/CompaniesDropdown";
import { LocationDropdown } from "../MapScreenComponents/LocationDropdown";
import { ShipmentFrequencyDropdown } from "../ViewCompanyScreenComponents/ShipmentFrequencyDropdown";
import { QuoteFilterDropdown } from "../ViewQuotesScreen";
import { LocationTypeDropdown } from "../ViewShipmentScreenComponents/LocationTypeDropdown";
import { assertNever } from "../../Helpers/assertNever";
import { InvoiceAuditProblemTypeDropdown } from "../ViewShipmentScreenComponents/InvoiceAuditProblemTypeDropdown";
import {
  InvoiceAuditProblemFaultDropdown,
  InvoiceAuditProblemResolutionStateDropdown,
  InvoiceAuditProblemResolutionTypeDropdown,
} from "../ViewShipmentScreenComponents/IdentifyInvoiceAuditProblemsSection";
import { isBlank } from "@freightsimple/shared";

// The regular input number seems to lose the value when we click away
// Not seen that before, so build a new one for this screen

interface InputNumProps {
  value: number | undefined | null;
  onChange: (_: number | undefined) => void;
}
function InputNum(props: InputNumProps) {
  return (
    <Input
      value={props.value?.toString()}
      onChange={function (e) {
        if (isBlank(e.target.value)) {
          props.onChange(undefined);
          return;
        }
        const newValue = parseInt(e.target.value);
        props.onChange(newValue);
      }}
      style={{ width: 90 }}
    />
  );
}

interface AllShipmentsScreenFilterButtonProps {
  shipments: ShipmentReport[];
  query: ApolloMapQuery;
  setQuery: (_: ApolloMapQuery) => void;
}

const formProps = {
  layout: "horizontal" as const,
  labelCol: { span: 8, offset: 1 },
  wrapperCol: { span: 12, offset: 1 },
  colon: true,
  style: { marginTop: "24px", width: "100%" },
};

export function AllShipmentsScreenFilterButton(
  props: AllShipmentsScreenFilterButtonProps,
) {
  const { shipments } = props;
  const total = shipments.length;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [localQuery, setLocalQuery] = useState({ ...props.query });

  useEffect(() => {
    setLocalQuery({ ...props.query });
  }, [props.query]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    props.setQuery(localQuery);
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function label() {
    switch (localQuery.bookingStatus) {
      case ApolloMapQueryBookingStatus.Booked:
        return `Shipments (${total})`;
      case ApolloMapQueryBookingStatus.NotBooked:
        return `Quotes (${total})`;
      case ApolloMapQueryBookingStatus.Any:
        return `Shipments & Quotes (${total})`;
      default:
        assertNever(localQuery.bookingStatus);
    }
  }

  function handleChange<K extends keyof ApolloMapQuery>(
    key: K,
    value: ApolloMapQuery[K],
  ) {
    setLocalQuery((prev) => ({ ...prev, [key]: value }));
  }

  return (
    <>
      <Modal
        title={null}
        open={isModalVisible}
        onOk={handleOk}
        okText="Filter"
        cancelText="Cancel"
        onCancel={handleCancel}
        width={1200}
        styles={{ body: { padding: "24px 32px" } }}
        footer={
          <div
            style={{
              padding: "16px 32px",
              marginTop: 0,
              borderTop: "1px solid #f0f0f0",
            }}
          >
            <HorizontalStack align="right" spacing={8}>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button type="primary" onClick={handleOk}>
                Filter
              </Button>
            </HorizontalStack>
          </div>
        }
      >
        <Tabs defaultActiveKey="general" style={{ marginTop: -8 }}>
          <Tabs.TabPane tab="General" key="general">
            <Form {...formProps}>
              <Form.Item
                label="Free Text Search"
                help={
                  <div
                    style={{
                      fontSize: "10px",
                      color: Colors.LightText,
                      marginBottom: "24px",
                      marginTop: "4px",
                    }}
                  >
                    Search any field e.g. address, business names, contacts,
                    reference numbers, commodity information
                  </div>
                }
              >
                <Input
                  placeholder="Enter search terms..."
                  value={localQuery.freeTextQuery ?? undefined}
                  onChange={function (e) {
                    handleChange("freeTextQuery", e.target.value);
                  }}
                  allowClear
                />
              </Form.Item>

              <Form.Item label="Include Only Booked?">
                <Select
                  value={localQuery.bookingStatus}
                  style={{ width: 220 }}
                  onChange={function (_bookingStatus) {
                    handleChange("bookingStatus", _bookingStatus);
                  }}
                >
                  <Select.Option value={ApolloMapQueryBookingStatus.Booked}>
                    Booked
                  </Select.Option>
                  ;
                  <Select.Option value={ApolloMapQueryBookingStatus.NotBooked}>
                    Just Quoted
                  </Select.Option>
                  ;
                  <Select.Option value={ApolloMapQueryBookingStatus.Any}>
                    Booked &amp; Quoted
                  </Select.Option>
                  ;
                </Select>
              </Form.Item>

              <Form.Item label="Shipment State">
                <ShipmentStateDropdown
                  value={localQuery.shipmentState || undefined}
                  setValue={function (_shipmentState) {
                    handleChange("shipmentState", _shipmentState);
                  }}
                />
              </Form.Item>

              <Form.Item label="Quote Filter">
                <QuoteFilterDropdown
                  value={localQuery.quoteFilter || undefined}
                  setValue={function (_quoteFilter) {
                    handleChange("quoteFilter", _quoteFilter);
                  }}
                />
              </Form.Item>

              <Form.Item label="Quoted Between">
                <DateRangePicker
                  startDate={localQuery.startDate}
                  endDate={localQuery.endDate}
                  onClear={function () {
                    handleChange("startDate", undefined);
                    handleChange("endDate", undefined);
                  }}
                  onChange={function (startDate, endDate) {
                    handleChange("startDate", startDate);
                    handleChange("endDate", endDate);
                  }}
                />
              </Form.Item>
            </Form>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Company Options" key="company">
            <Form {...formProps}>
              <Form.Item label="Company">
                <CompaniesDropdown
                  companyId={localQuery.companyId}
                  setCompanyId={function (_companyId) {
                    handleChange("companyId", _companyId);
                  }}
                />
              </Form.Item>

              <Form.Item label="Shipment Frequency">
                <ShipmentFrequencyDropdown
                  value={localQuery.companyShipmentFrequency || undefined}
                  onChange={function (_frequency) {
                    handleChange("companyShipmentFrequency", _frequency);
                  }}
                  allowClear
                />
              </Form.Item>

              <Form.Item label="Company First Quote">
                <DateRangePicker
                  startDate={localQuery.companyFirstQuotedDateStartDate}
                  endDate={localQuery.companyFirstQuotedDateEndDate}
                  allowClear
                  onClear={function () {
                    handleChange("companyFirstQuotedDateStartDate", undefined);
                    handleChange("companyFirstQuotedDateEndDate", undefined);
                  }}
                  onChange={function (startDate, endDate) {
                    handleChange("companyFirstQuotedDateStartDate", startDate);
                    handleChange("companyFirstQuotedDateEndDate", endDate);
                  }}
                />
              </Form.Item>
            </Form>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Location" key="location">
            <Form {...formProps}>
              <Form.Item label="Geo Filter">
                <GeoFilterDropdown
                  value={localQuery.geoFilter || undefined}
                  setValue={function (_geoFilter) {
                    handleChange("geoFilter", _geoFilter);
                  }}
                />
              </Form.Item>
              <Form.Item label="Pickup Location">
                <LocationDropdown
                  locationFilterIdentifiers={
                    localQuery.pickupLocationFilterIdentifiers
                  }
                  setLocationFilterIdentifiers={function (
                    _locationFilterIdentifiers,
                  ) {
                    handleChange(
                      "pickupLocationFilterIdentifiers",
                      _locationFilterIdentifiers,
                    );
                  }}
                />
              </Form.Item>
              <Form.Item label="Pickup Location Type">
                <LocationTypeDropdown
                  allowLocationTypeClear
                  allowDistributionWarehouseBrandClear
                  locationType={localQuery.pickupLocationType || undefined}
                  distributionWarehouseBrand={
                    localQuery.pickupDistributionWarehouseBrand
                  }
                  setLocationType={function (_locationType) {
                    handleChange("pickupLocationType", _locationType);
                    if (_locationType != "DistributionWarehouse") {
                      handleChange(
                        "pickupDistributionWarehouseBrand",
                        undefined,
                      );
                    }
                  }}
                  setDistributionWarehouseBrand={function (
                    _distributionWarehouseBrand,
                  ) {
                    handleChange(
                      "pickupDistributionWarehouseBrand",
                      _distributionWarehouseBrand,
                    );
                  }}
                />
              </Form.Item>

              <Form.Item label="Pickup City">
                <Input
                  value={localQuery.pickupCity ?? undefined}
                  onChange={function (e) {
                    handleChange("pickupCity", e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item label="Delivery Location">
                <LocationDropdown
                  locationFilterIdentifiers={
                    localQuery.deliveryLocationFilterIdentifiers
                  }
                  setLocationFilterIdentifiers={function (
                    _locationFilterIdentifiers,
                  ) {
                    handleChange(
                      "deliveryLocationFilterIdentifiers",
                      _locationFilterIdentifiers,
                    );
                  }}
                />
              </Form.Item>
              <Form.Item label="Delivery Location Type">
                <LocationTypeDropdown
                  allowLocationTypeClear
                  allowDistributionWarehouseBrandClear
                  locationType={localQuery.deliveryLocationType || undefined}
                  distributionWarehouseBrand={
                    localQuery.deliveryDistributionWarehouseBrand
                  }
                  setLocationType={function (_locationType) {
                    handleChange("deliveryLocationType", _locationType);
                    if (_locationType != "DistributionWarehouse") {
                      handleChange(
                        "deliveryDistributionWarehouseBrand",
                        undefined,
                      );
                    }
                  }}
                  setDistributionWarehouseBrand={function (
                    _distributionWarehouseBrand,
                  ) {
                    handleChange(
                      "deliveryDistributionWarehouseBrand",
                      _distributionWarehouseBrand,
                    );
                  }}
                />
              </Form.Item>

              <Form.Item label="Delivery City">
                <Input
                  value={localQuery.deliveryCity ?? undefined}
                  onChange={function (e) {
                    handleChange("deliveryCity", e.target.value);
                  }}
                />
              </Form.Item>
            </Form>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Shipment Details" key="shipment">
            <Form {...formProps}>
              <Form.Item label="Shipment Weight">
                <HorizontalStack>
                  <div>Min:</div>
                  <Spacer width={8} />
                  <InputNum
                    value={localQuery.minimumShipmentWeight}
                    onChange={function (newValue) {
                      handleChange("minimumShipmentWeight", newValue);
                    }}
                  />

                  <Spacer width={8} />
                  <div>Max:</div>
                  <Spacer width={8} />
                  <InputNum
                    value={localQuery.maximumShipmentWeight}
                    onChange={function (newValue) {
                      handleChange("maximumShipmentWeight", newValue);
                    }}
                  />
                </HorizontalStack>
              </Form.Item>
              <Form.Item label="Handling Units">
                <HorizontalStack>
                  <div>Min:</div>
                  <Spacer width={8} />
                  <InputNum
                    value={localQuery.minimumNumberHandlingUnits}
                    onChange={function (newValue) {
                      handleChange("minimumNumberHandlingUnits", newValue);
                    }}
                  />

                  <Spacer width={8} />
                  <div>Max:</div>
                  <Spacer width={8} />
                  <InputNum
                    value={localQuery.maximumNumberHandlingUnits}
                    onChange={function (newValue) {
                      handleChange("maximumNumberHandlingUnits", newValue);
                    }}
                  />
                </HorizontalStack>
              </Form.Item>
              <Form.Item label="Volume">
                <HorizontalStack>
                  <div>Min:</div>
                  <Spacer width={8} />
                  <InputNum
                    value={localQuery.minimumVolume}
                    onChange={function (newValue) {
                      handleChange("minimumVolume", newValue);
                    }}
                  />

                  <Spacer width={8} />
                  <div>Max:</div>
                  <Spacer width={8} />
                  <InputNum
                    value={localQuery.maximumVolume}
                    onChange={function (newValue) {
                      handleChange("maximumVolume", newValue);
                    }}
                  />
                </HorizontalStack>
              </Form.Item>
              <Form.Item
                tooltip="This will filter based on the calculated standard dry van linear feet the shipment is predicted to take up"
                label="Linear Feet"
              >
                <HorizontalStack>
                  <div>Min:</div>
                  <Spacer width={8} />
                  <InputNum
                    value={localQuery.minimumStandardDryVanLinearFeet}
                    onChange={function (newValue) {
                      handleChange("minimumStandardDryVanLinearFeet", newValue);
                    }}
                  />

                  <Spacer width={8} />
                  <div>Max:</div>
                  <Spacer width={8} />
                  <InputNum
                    value={localQuery.maximumStandardDryVanLinearFeet}
                    onChange={function (newValue) {
                      handleChange("maximumStandardDryVanLinearFeet", newValue);
                    }}
                  />
                </HorizontalStack>
              </Form.Item>
            </Form>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Invoice Audits" key="invoiceAudits">
            <Form {...formProps}>
              <Form.Item label="Invoice Audit Ever Opened">
                <TriStateBoolean
                  value={localQuery.invoiceAuditEverOpened || undefined}
                  setValue={function (v) {
                    handleChange("invoiceAuditEverOpened", v);
                  }}
                />
              </Form.Item>

              <Form.Item label="Invoice Audit Currently Open">
                <TriStateBoolean
                  value={localQuery.invoiceAuditCurrentlyOpen || undefined}
                  setValue={function (v) {
                    handleChange("invoiceAuditCurrentlyOpen", v);
                  }}
                />
              </Form.Item>

              <Form.Item label="Invoice Problem Type">
                <InvoiceAuditProblemTypeDropdown
                  mode="multiple"
                  placeHolder="Any"
                  value={(localQuery.invoiceAuditType as any) || undefined}
                  setValue={function (v: any) {
                    handleChange("invoiceAuditType", v);
                  }}
                />
              </Form.Item>

              <Form.Item label="Invoice Problem Fault">
                <InvoiceAuditProblemFaultDropdown
                  mode="multiple"
                  placeHolder="Any"
                  value={
                    (localQuery.invoiceAuditProblemFault as any) || undefined
                  }
                  setValue={function (v: any) {
                    handleChange("invoiceAuditProblemFault", v);
                  }}
                />
              </Form.Item>

              <Form.Item label="Invoice Problem Resolution State">
                <InvoiceAuditProblemResolutionStateDropdown
                  mode="multiple"
                  placeHolder="Any"
                  value={
                    (localQuery.invoiceAuditResolutionState as any) || undefined
                  }
                  setValue={function (v: any) {
                    handleChange("invoiceAuditResolutionState", v);
                  }}
                />
              </Form.Item>

              <Form.Item label="Invoice Problem Resolution Type">
                <InvoiceAuditProblemResolutionTypeDropdown
                  mode="multiple"
                  placeHolder="Any"
                  value={
                    (localQuery.invoiceAuditResolutionType as any) || undefined
                  }
                  setValue={function (v: any) {
                    handleChange("invoiceAuditResolutionType", v);
                  }}
                />
              </Form.Item>
            </Form>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Carrier Options" key="carrier">
            <Form {...formProps}>
              <Form.Item label={<>In Carrier Coverage Area</>}>
                <CarrierFilter
                  carrierIdentifier={
                    localQuery.inCarrierCoverageAreaCarrierIdentifier
                  }
                  setCarrierIdentifier={function (_carrierIdentifier) {
                    handleChange(
                      "inCarrierCoverageAreaCarrierIdentifier",
                      _carrierIdentifier,
                    );
                  }}
                />
              </Form.Item>

              <Form.Item label={<>Booked Carrier</>}>
                <CarrierFilter
                  carrierIdentifier={localQuery.bookedCarrierIdentifier}
                  setCarrierIdentifier={function (_carrierIdentifier) {
                    handleChange("bookedCarrierIdentifier", _carrierIdentifier);
                  }}
                />
              </Form.Item>

              <Form.Item label={<>Had quotes from carrier</>}>
                <CarrierFilter
                  carrierIdentifier={localQuery.hadQuotesFromCarrierIdentifier}
                  setCarrierIdentifier={function (_carrierIdentifier) {
                    handleChange(
                      "hadQuotesFromCarrierIdentifier",
                      _carrierIdentifier,
                    );
                  }}
                />
              </Form.Item>

              <Form.Item label={<>Lowest Rate Carrier</>}>
                <CarrierFilter
                  carrierIdentifier={localQuery.lowestRateCarrierIdentifier}
                  setCarrierIdentifier={function (_carrierIdentifier) {
                    handleChange(
                      "lowestRateCarrierIdentifier",
                      _carrierIdentifier,
                    );
                  }}
                />
              </Form.Item>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Accessorials" key="accessorials">
            <Form {...formProps}>
              <Form.Item label={<>Liftgate Pickup Required</>}>
                <TriStateBoolean
                  value={localQuery.pickupLiftGateRequired || undefined}
                  setValue={function (v) {
                    handleChange("pickupLiftGateRequired", v);
                  }}
                />
              </Form.Item>
              <Form.Item label={<>Liftgate Delivery Required</>}>
                <TriStateBoolean
                  value={localQuery.deliveryLiftGateRequired || undefined}
                  setValue={function (v) {
                    handleChange("deliveryLiftGateRequired", v);
                  }}
                />
              </Form.Item>
              <Form.Item label={<>Inside Pickup Required</>}>
                <TriStateBoolean
                  value={localQuery.pickupInsideRequired || undefined}
                  setValue={function (v) {
                    handleChange("pickupInsideRequired", v);
                  }}
                />
              </Form.Item>
              <Form.Item label={<>Inside Delivery Required</>}>
                <TriStateBoolean
                  value={localQuery.deliveryInsideRequired || undefined}
                  setValue={function (v) {
                    handleChange("deliveryInsideRequired", v);
                  }}
                />
              </Form.Item>
              <Form.Item label={<>Dangerous Goods</>}>
                <TriStateBoolean
                  value={localQuery.dangerous || undefined}
                  setValue={function (v) {
                    handleChange("dangerous", v);
                  }}
                />
              </Form.Item>
              <Form.Item label={<>Protect from Freezing</>}>
                <TriStateBoolean
                  value={localQuery.protectFromFreezingRequired || undefined}
                  setValue={function (v) {
                    handleChange("protectFromFreezingRequired", v);
                  }}
                />
              </Form.Item>
              <Form.Item label={<>Delivery Appointment Required</>}>
                <TriStateBoolean
                  value={localQuery.deliveryAppointmentRequired || undefined}
                  setValue={function (v) {
                    handleChange("deliveryAppointmentRequired", v);
                  }}
                />
              </Form.Item>
            </Form>
          </Tabs.TabPane>
        </Tabs>
      </Modal>
      <Button
        icon={<FilterOutlined />}
        onClick={showModal}
        type="link"
        style={{ paddingLeft: "4px", paddingRight: "4px" }}
      >
        {label()}
      </Button>
    </>
  );
}
