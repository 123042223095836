import { ReactNode, useEffect, useState } from "react";
import {
  ApiKeyUsageSummary,
  ApiKeyWithCompanyAndUsageSummaries,
} from "@freightsimple/generated-apollo-openapi-client";
import {
  DownOutlined,
  ExclamationCircleOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, message, Table } from "antd";
import { useCopyToClipboard } from "../Hooks/useCopyToClipboard";
import useQuery from "../Hooks/useQuery";
import { useApiKeysApi } from "../Apis/Apis";
import { useCallRefreshContext } from "../Contexts/CallContext/useCallRefreshContext";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import { AdditionalColumns } from "./ApiKeysTable";
import { safeColumns } from "../Helpers/safeColumns";
import Stack from "../Components/Stack";
import Colors from "../Components/Colors";
import { StatsRow } from "../Components/StatsRow";
import { Link } from "react-router-dom";
import { MenuProps } from "antd/lib";
import { RevokeApiKeyMenuItem } from "./RevokeApiKeyMenuItem";
import { EditAPIKeyMenuItem } from "./EditApiKeyModal";

interface ApiKeysExtraMenuProps {
  data: ApiKeyWithCompanyAndUsageSummaries;
  onRefresh: () => Promise<void>;
}

interface ApiKeyUsageTableProps {
  data: ApiKeyUsageSummary[];
  onRefresh: () => Promise<void>;
  additionalColumns?: AdditionalColumns<ApiKeyUsageSummary>[];
}

interface APIKeyStatProps {
  title: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactNode;
  value: ReactNode | undefined;
  copyToClipboard?: boolean;
  renderEmptyAsUnknown?: boolean;
}

function ExtrasMenu(props: ApiKeysExtraMenuProps) {
  const apiKeyId = props.data.apiKey.apiKeyId!;
  const copyApiKeyId = useCopyToClipboard(apiKeyId);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isRevokedModalOpen, setIsRevokedModalOpen] = useState(false);
  const showModal = (setIsModalOpen: (isOpen: boolean) => void) =>
    setIsModalOpen(true);

  const items: MenuProps["items"] = [
    {
      key: "copyId",
      label: "Copy API Key ID",
      icon: <PaperClipOutlined />,
      onClick: () => copyApiKeyId(),
    },
    {
      key: "edit",
      label: (
        <span
          onClick={() => showModal(setIsEditModalOpen)}
          style={{ display: "flex", alignItems: "center", gap: 8 }}
        >
          Edit API Key
        </span>
      ),
      disabled:
        props.data.apiKey.isActive === false &&
        props.data.apiKey.revokedAt != null,
    },
    {
      key: "revoke",
      label: (
        <span
          onClick={() => showModal(setIsRevokedModalOpen)}
          style={{ display: "flex", alignItems: "center", gap: 8 }}
        >
          <ExclamationCircleOutlined /> Revoke API Key
        </span>
      ),
      disabled:
        props.data.apiKey.isActive === false &&
        props.data.apiKey.revokedAt != null,
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <Button>
          <DownOutlined />
        </Button>
      </Dropdown>
      <EditAPIKeyMenuItem
        apiKey={props.data.apiKey}
        onRefresh={props.onRefresh}
        isModalOpen={isEditModalOpen}
        setIsModalOpen={setIsEditModalOpen}
      />
      <RevokeApiKeyMenuItem
        apiKeyId={props.data.apiKey.apiKeyId!}
        onRefresh={props.onRefresh}
        isModalOpen={isRevokedModalOpen}
        setIsModalOpen={setIsRevokedModalOpen}
      />
    </>
  );
}

function APIKeyStatContent(props: APIKeyStatProps) {
  if (props.value === undefined || props.value === null || props.value === "") {
    if (props.renderEmptyAsUnknown) {
      return <div style={{ color: Colors.Red }}>Unknown</div>;
    } else {
      return <>-</>;
    }
  }
  if (Number.isFinite(props.value)) {
    return (
      <>
        {props.prefix}
        {props.value.toLocaleString()}
        {props.suffix}
      </>
    );
  }

  return (
    <>
      {props.prefix}
      {props.value}
      {props.suffix}
    </>
  );
}

function APIKeyStat(props: APIKeyStatProps) {
  return (
    <Stack align="left">
      <div style={{ color: Colors.LightText, fontSize: "12px" }}>
        {props.title}
      </div>
      <div>
        <APIKeyStatContent {...props} />
      </div>
    </Stack>
  );
}

function ApiKeyUsageTable(props: ApiKeyUsageTableProps) {
  const columns = safeColumns<ApiKeyUsageSummary>([
    {
      title: "Endpoint",
      dataIndex: "endpoint",
      key: "endpoint",
      render: (_, o) => <div>{o.endpoint}</div>,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (_, o) => <div>{o.date}</div>,
    },
    {
      title: "Request Count",
      dataIndex: "requestcount",
      key: "requestcount",
      render: (_, o) => <div>{o.requestCount}</div>,
    },
    {
      title: "Success Count",
      dataIndex: "succescount",
      key: "successcount",
      render: (_, o) => <div>{o.successCount}</div>,
    },
    {
      title: "Error Count",
      dataIndex: "errorcount",
      key: "errorcount",
      render: (_, o) => <div>{o.errorCount}</div>,
    },
  ]);

  if (props.additionalColumns) {
    props.additionalColumns.forEach((additional) => {
      columns.splice(additional.index, 0, additional.column);
    });
  }

  const dataWithKeys = props.data.map((item) => ({
    ...item,
    key: item.apiKeyId,
  }));

  return (
    <div style={{ width: "100%", overflowX: "auto" }}>
      <Table
        columns={columns}
        dataSource={dataWithKeys}
        pagination={false}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
}

export function ViewApiKeyScreen() {
  const createApiKeysApi = useApiKeysApi();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<ApiKeyWithCompanyAndUsageSummaries>({
    apiKey: {},
    company: {},
  });
  const { setRefresh } = useCallRefreshContext();
  const query = useQuery();
  const apiKeyId = query.apiKeyId as string;

  async function refresh() {
    const apiKeysApi = await createApiKeysApi();

    try {
      const response = await apiKeysApi.getApiKeyWithCompanyAndUsageData({
        apiKeyId,
      });
      if (response.length >= 1) {
        setResult(response[0]);
      }
    } catch (e) {
      // @ts-expect-error - type is unknown
      if (e.status === 404) {
        message.error("Could not find API Key");
      } else {
        message.error(`Could not load`);
      }
    }
  }

  async function refreshWithLoading() {
    setLoading(true);
    await refresh();
    setLoading(false);
  }

  useEffect(
    function () {
      refreshWithLoading();
      setRefresh(refresh);
    },
    [apiKeyId],
  );

  if (result === undefined || loading) {
    return <Loading message="Loading API Key data" />;
  } else {
    return (
      <>
        <Page
          title={result?.apiKey.name}
          tags={[]}
          stats={
            <StatsRow>
              <APIKeyStat
                title="API Key Description"
                value={result.apiKey.description}
              />
              <APIKeyStat
                title="Status"
                value={result.apiKey.isActive ? "Active" : "Inactive"}
              />
              <APIKeyStat
                title="Created At"
                value={
                  result.apiKey.createdAt
                    ? new Date(result.apiKey.createdAt).toLocaleString()
                    : undefined
                }
              />
              <APIKeyStat
                title="Expires At"
                value={
                  result.apiKey.expiresAt
                    ? new Date(result.apiKey.expiresAt).toLocaleString()
                    : undefined
                }
              />
              <APIKeyStat
                title="Last Used At"
                value={
                  result.apiKey.lastUsedAt
                    ? new Date(result.apiKey.lastUsedAt).toLocaleString()
                    : undefined
                }
              />
              <APIKeyStat
                title="Revoked At"
                value={
                  result.apiKey.revokedAt
                    ? new Date(result.apiKey.revokedAt).toLocaleString()
                    : undefined
                }
              />
              <APIKeyStat
                title="Created By"
                value={
                  result.apiKey.createdByAdminEmail
                    ? result.apiKey.createdByAdminEmail
                    : result.apiKey.createdByEmail
                }
              />
            </StatsRow>
          }
          extra={[
            <Link
              key={"viewcompany"}
              to={`/view-company?companyId=${result.apiKey.companyId}`}
              target="_blank"
            >
              <Button>🏭 View Company</Button>
            </Link>,

            <ExtrasMenu key="extras" data={result} onRefresh={refresh} />,
          ]}
        >
          <PageTitle>
            {result?.apiKey.name ? result.apiKey.name : "API Key"}
          </PageTitle>
          <ApiKeyUsageTable
            onRefresh={refresh}
            data={result.apiKeyUsageSummaries || []}
          />
        </Page>
      </>
    );
  }
}
