import { Signals } from "@freightsimple/generated-apollo-openapi-client";

import { message } from "antd";
import { useEffect, useState } from "react";
import { useSignalsApi } from "../../../Apis/Apis";
import { ButtonRow } from "../../../Components/ButtonRow";
import { ConfirmButton } from "../../../Components/ConfirmButton";
import { CreatedAt } from "../../../Components/CreatedAt";
import { DataTable, DataTableColumn } from "../../../Components/DataTable";
import HorizontalStack from "../../../Components/HorizontalStack";
import { Loading } from "../../../Components/Loading";
import { Page } from "../../../Components/Page";
import { WarningComponent } from "../../../Components/WarningComponent";
import { assertNever } from "../../../Helpers/assertNever";
import dayjs from "dayjs";
import { SignalFilter } from "./SignalFilter";
import { SignalFilterTypeDropdown } from "./SignalFilterTypeDropdown";

interface DeleteSignalButtonProps {
  signal: Signals;
  onRefresh: () => Promise<void>;
}

function DeleteSignalButton(props: DeleteSignalButtonProps) {
  const createSignalsApi = useSignalsApi();
  const [loading, setLoading] = useState(false);

  async function onConfirm() {
    setLoading(true);
    try {
      const signalsApi = await createSignalsApi();

      await signalsApi.deleteSignal({
        signalName: props.signal.name!,
      });
      await props.onRefresh();
      message.success("Done!");
    } catch {
      message.error("Eek. Something went wrong");
    }
    setLoading(false);
  }

  return (
    <ConfirmButton
      type="default"
      loading={loading}
      tooltip="Use this if the signal is no longer valid. eg we stopped working with a carrier"
      okText="Yes, do it."
      onConfirm={onConfirm}
      question="Are you sure you want to delete this signal"
    >
      <WarningComponent /> Delete Signal
    </ConfirmButton>
  );
}

export function SignalsScreen() {
  const createSignalsApi = useSignalsApi();
  const [signals, setSignals] = useState<Signals[] | undefined>();
  const [signalFilterTypeFilter, setSignalFilterTypeFilter] = useState<
    SignalFilter | undefined
  >();

  async function refresh() {
    const signalsApi = await createSignalsApi();
    const response = await signalsApi.getSignals();
    setSignals(response);
  }

  async function getExceedingThreshold() {
    const signalsApi = await createSignalsApi();
    const response = await signalsApi.getSignalsExceedingThreshold();
    const convertedSignals: Signals[] = response.map((item) => ({
      name: item.name,
      lastSuccessTimestamp: item.lastSuccessTimestamp,
      errorThresholdHours: item.errorThresholdHours,
      alarmSilencedUntilTimestamp: item.alarmSilencedUntilTimestamp,
      requiresActiveShipmentsCarrierIdentifier:
        item.requiresActiveShipmentsCarrierIdentifier,
      requiresActiveShipmentsServiceIdentifier:
        item.requiresActiveShipmentsServiceIdentifier,
      carrierIdentifier: item.carrierIdentifier,
      serviceType: item.serviceType,
    }));
    setSignals(convertedSignals);
  }

  useEffect(() => {
    if (signalFilterTypeFilter === undefined) {
      refresh();
    }
    if (signalFilterTypeFilter == SignalFilter.AlarmState) {
      getExceedingThreshold();
    }
  }, [signalFilterTypeFilter]);

  if (signals === undefined) {
    return <Loading />;
  }

  const filteredSignals = signals.filter(function (s) {
    if (signalFilterTypeFilter === undefined) {
      return true;
    }

    switch (signalFilterTypeFilter) {
      case SignalFilter.NotFiredInLastMonth:
        return (
          s.lastSuccessTimestamp === undefined ||
          dayjs(s.lastSuccessTimestamp).isBefore(dayjs().subtract(1, "month"))
        );
      case SignalFilter.AlarmState:
        return true;
      default: {
        assertNever(signalFilterTypeFilter);
      }
    }

    // Should not be here
    return false;
  });

  const columns: DataTableColumn<Signals>[] = [
    { title: "Name", render: (o) => <div>{o.name}</div> },
    {
      title: "Last Success",
      render: (o) => <CreatedAt timestamp={o.lastSuccessTimestamp!} />,
    },
    {
      title: "Actions",
      render: (o) => (
        <ButtonRow>
          <DeleteSignalButton signal={o} onRefresh={refresh} />
        </ButtonRow>
      ),
    },
  ];
  // last successf
  // error threshold
  //alarm silenced until
  //
  return (
    <Page
      title={`Signals`}
      tags={[]}
      extra={[
        <HorizontalStack key="filter">
          <SignalFilterTypeDropdown
            value={signalFilterTypeFilter}
            setValue={setSignalFilterTypeFilter}
          />
        </HorizontalStack>,
      ]}
    >
      <DataTable pagination={false} columns={columns} data={filteredSignals} />
    </Page>
  );
}
