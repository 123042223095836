import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { useParamState } from "../../Hooks/useParamState";
import {
  ApolloMapQuery,
  ApolloMapQueryBookingStatus,
  DetailedShipmentReport,
} from "@freightsimple/generated-apollo-openapi-client";
import HorizontalStack from "../../Components/HorizontalStack";
import Colors from "../../Components/Colors";
import Spacer from "../../Spacer";
import styled from "styled-components";

const StyledDateButton = styled.button<{ isActive: boolean }>`
  background: none;
  border: none;
  outline: none;
  font-weight: bold;
  cursor: pointer;
  font-size: ${(props) => (props.isActive ? "30px" : "23px")};
  color: ${(props) => (props.isActive ? Colors.NormalText : Colors.LightText)};

  &:hover {
    opacity: 0.7;
  }
`;

const CountBadge = styled.span`
  font-size: 14px;
  color: ${Colors.LightText};
  margin-left: 4px;
`;

export function QuickDateFilters({
  shipments,
}: {
  shipments: DetailedShipmentReport[];
}) {
  const [query, setQuery] = useParamState<ApolloMapQuery>({
    key: "q",
    showDefaultValueInURL: true,
    defaultValue: {
      freeTextQuery: "",
      bookingStatus: ApolloMapQueryBookingStatus.Any,
      startDate: dayjs().format("YYYY-MM-DD"),
      endDate: dayjs().format("YYYY-MM-DD"),
    },
  });

  const [isTodayFilterActive, setIsTodayFilterActive] = useState(true);
  const [isThisWeekFilterActive, setIsThisWeekFilterActive] = useState(false);

  useEffect(() => {
    const today = dayjs().format("YYYY-MM-DD");
    setQuery({
      ...query,
      startDate: today,
      endDate: today,
    });
  }, []);

  const toggleTodayFilter = () => {
    const today = dayjs().format("YYYY-MM-DD");

    if (isTodayFilterActive) {
      setQuery({
        ...query,
        startDate: dayjs().subtract(30, "days").format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
      });
    } else {
      setQuery({
        ...query,
        startDate: today,
        endDate: today,
      });

      if (isThisWeekFilterActive) {
        setIsThisWeekFilterActive(false);
      }
    }

    setIsTodayFilterActive(!isTodayFilterActive);
  };

  const toggleThisWeekFilter = () => {
    // Get start of current week (Sunday) and end (Saturday)
    const startOfWeek = dayjs().startOf("week").format("YYYY-MM-DD");
    const endOfWeek = dayjs().endOf("week").format("YYYY-MM-DD");

    if (isThisWeekFilterActive) {
      setQuery({
        ...query,
        startDate: dayjs().subtract(30, "days").format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
      });
    } else {
      setQuery({
        ...query,
        startDate: startOfWeek,
        endDate: endOfWeek,
      });

      if (isTodayFilterActive) {
        setIsTodayFilterActive(false);
      }
    }

    setIsThisWeekFilterActive(!isThisWeekFilterActive);
  };

  return (
    <HorizontalStack verticalAlign="middle">
      {!isTodayFilterActive && !isThisWeekFilterActive ? (
        <span
          style={{
            fontSize: 30,
            fontWeight: "bold",
            marginRight: 7,
            marginBottom: 4,
          }}
        >
          Last 30 days
          <CountBadge>({shipments.length})</CountBadge>
        </span>
      ) : null}
      <StyledDateButton
        isActive={isTodayFilterActive}
        onClick={toggleTodayFilter}
      >
        Today
        {isTodayFilterActive && <CountBadge>({shipments.length})</CountBadge>}
      </StyledDateButton>

      <Spacer width={5} />
      <StyledDateButton
        isActive={isThisWeekFilterActive}
        onClick={toggleThisWeekFilter}
      >
        This week
        {isThisWeekFilterActive && (
          <CountBadge>({shipments.length})</CountBadge>
        )}
      </StyledDateButton>
    </HorizontalStack>
  );
}
