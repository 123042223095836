import { useImportQuoteTestGroup } from "../Hooks/useImportQuoteTestGroup";
import { Form, Modal, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { ExportQuoteGroupData } from "@freightsimple/generated-apollo-openapi-client";
import { UseDisplayReturn } from "../../../../Hooks/useDisplay";
import { RefreshFunction } from "../../../../Types/RefreshFunctionType";

export function ImportQuoteTestGroupModal(props: {
  refresh: RefreshFunction;
  display: UseDisplayReturn;
}) {
  const { refresh, display } = props;
  type FormType = { data: Blob };
  const { importData } = useImportQuoteTestGroup();
  const [form] = Form.useForm<FormType>();

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  async function handleFinish(value: any) {
    const file = value.data[0].originFileObj;
    console.log(file);
    const reader = new FileReader();
    reader.onload = async () => {
      try {
        // Parse the JSON content
        const parsedContent = JSON.parse(
          reader.result as string,
        ) as ExportQuoteGroupData;
        console.log(parsedContent);
        await importData(parsedContent);
        await refresh();
        display.hide();
      } catch (error) {
        console.error(error);
      }
    };

    reader.readAsText(file);
  }

  return (
    <Modal
      title="Import Data"
      onOk={form.submit}
      onCancel={display.hide}
      okText="Import"
      open={display.status}
    >
      <Form form={form} onFinish={handleFinish}>
        <Form.Item label={null}>
          <Form.Item
            name="data"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            noStyle
          >
            <Upload.Dragger name="data">
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
}
