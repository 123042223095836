import {
  Address,
  Contact,
  Hours,
  Location,
  LocationContext,
  NoteType,
  Quote,
} from "@freightsimple/generated-apollo-openapi-client";
import { Button } from "antd";
import { ReactNode } from "react";
import Colors from "../../Components/Colors";
import { CreatedAt } from "../../Components/CreatedAt";
import HorizontalStack from "../../Components/HorizontalStack";
import { KeyValues } from "../../Components/KeyValues";
import Stack from "../../Components/Stack";
import { WithClipboardLink } from "../../Components/WithClipboardLink";
import { describeEquipmentType } from "../../Helpers/describeEquipmentType";
import { formatPhoneNumberWithExtension } from "../../Helpers/formatPhoneNumber";
import { isPhone } from "../../Helpers/isPhone";
import {
  describeLocationType,
  nameLocationType,
} from "../../Helpers/locationTypes";
import { getMapUrlForAddress } from "../../Helpers/mapUtils";
import { useCopyToClipboard } from "../../Hooks/useCopyToClipboard";
import Spacer from "../../Spacer";
import dayjs from "dayjs";
import { describeAccessorials } from "./describeAccessorials";
import { TabProps } from "./TabProps";
import { objectDifferences } from "../../Helpers/objectDifferences";
import {
  ChangeHistory,
  ChangeHistoryPopover,
} from "../../Components/ChangeHistoryPopover";
import { capitalizeFirstLetter } from "../../Helpers/capitalizeFirstLetter";
import { NonAsciiWarningComponent } from "../../Components/NonAsciiWarningComponent";

interface QuoteGeneralInfoProps {
  quote: Quote;
}

export function QuoteGeneralInfo(props: QuoteGeneralInfoProps) {
  const data: Record<string, any> = {};

  const quote = props.quote;

  data["Transit Days"] = (
    <>
      {quote.transitBusinessDays}-{quote.latestTransitBusinessDays}
    </>
  );

  data["Estimated Delivery"] = (
    <>
      {quote.expectedDeliveryDate} to {quote.latestEstimatedDeliveryDate}
    </>
  );

  data["Earliest Permitted Pickup Date"] = (
    <CreatedAt timestamp={quote.earliestPermittedPickupDate!} />
  );
  data["Latest Permitted Pickup Date"] = (
    <CreatedAt timestamp={quote.latestPermittedPickupDate!} />
  );

  data["Quoted At"] = <CreatedAt timestamp={quote.createdAt!} />;

  data["Equipment Type"] = describeEquipmentType(quote.equipmentType);

  data["Exclusive Use"] = quote.exclusiveUseNeeded ? "Yes" : "No";

  if (quote.addInsuranceToShipment) {
    data["Insurance"] = `${quote.insuranceAmount} ${quote.insuranceCurrency}`;
  }

  data["Internal Note"] = <>{quote.internalNote}</>;
  data["Customer Visible Note"] = <>{quote.customerVisibleNote}</>;

  return <KeyValues data={data} />;
}

interface LocationInfoProps extends TabProps {
  deadline: string | undefined;
  location: Location;
  contact: Contact;
  context: LocationContext;
  referenceNumber: string;
}

function renderLines(lines: Array<string | undefined>): Array<ReactNode> {
  return lines
    .filter((line) => line !== undefined && line !== "")
    .map(function (line, index) {
      return <div key={index}>{line}</div>;
    });
}

function DescribeAddress(props: LocationInfoProps) {
  const address = props.location.address!;
  const cityStatePostalCode = `${address.city}, ${address.stateOrProvinceCode}, ${address.postalCode}`;

  const lines = [
    address.addressLine,
    address.addressLine2,
    cityStatePostalCode,
  ];

  const onCopyPostalCode = useCopyToClipboard(address.postalCode ?? "");
  const onCopyFullAddress = useCopyToClipboard(
    lines.filter((o) => o).join(", "),
  );

  return (
    <Stack align="left">
      {lines.map(function (l, index) {
        return <div key={index}>{l}</div>;
      })}
      <Spacer height={8} />
      {!isPhone && (
        <HorizontalStack style={{ marginLeft: "-16px" }}>
          <Button
            type="link"
            href={getMapUrlForAddress(address)}
            target="_blank"
          >
            View Map
          </Button>
          <Button type="link" onClick={onCopyPostalCode}>
            Copy Postal Code
          </Button>
          <Button type="link" onClick={onCopyFullAddress}>
            Copy Full Address
          </Button>
        </HorizontalStack>
      )}
    </Stack>
  );
}

function describeCityStatePostalCode(address: Address): Array<ReactNode> {
  const cityStatePostalCode = `${address.city}, ${address.stateOrProvinceCode}, ${address.postalCode}`;

  const lines = [cityStatePostalCode];
  return [
    ...renderLines(lines),
    <Spacer key="spacer" height={4} />,
    <Button
      key="view-map"
      href={getMapUrlForAddress(address)}
      type="link"
      target="_blank"
    >
      View Map
    </Button>,
  ];
}

function DescribeContact(props: LocationInfoProps) {
  const contact = props.contact;

  function getPhone() {
    if (contact === undefined) {
      return "";
    }

    const phone = formatPhoneNumberWithExtension(
      contact.phoneNumber,
      contact.phoneNumberExtension,
    );
    return phone;
  }

  function getNameandPhone() {
    if (contact === undefined) {
      return "";
    }
    const name = contact.contactName;
    const phone = getPhone();

    const nameAndPhone = `${name}, ${phone}`;
    return nameAndPhone;
  }

  const onCopyNameAndPhone = useCopyToClipboard(getNameandPhone());
  const onCopyPhone = useCopyToClipboard(getPhone());

  if (contact === undefined) {
    return <>-</>;
  }

  const lines = [
    contact.contactName,
    formatPhoneNumberWithExtension(
      contact.phoneNumber,
      contact.phoneNumberExtension,
    ),
    contact.emailAddress,
  ];

  return (
    <Stack align="left">
      {lines.map(function (l, index) {
        return <div key={index}>{l}</div>;
      })}
      <Spacer key="spacer" height={8} />
      {!isPhone && (
        <HorizontalStack style={{ marginLeft: "-16px" }}>
          <Button type="link" onClick={onCopyNameAndPhone}>
            Copy Name and Phone
          </Button>
          <Button type="link" onClick={onCopyPhone}>
            Copy Phone
          </Button>
        </HorizontalStack>
      )}
    </Stack>
  );
}

function describeHours(hours: Hours | undefined): string {
  if (hours === undefined) {
    return "-";
  }

  return `${hours.openFrom} - ${hours.openUntil}`;
}

interface DescribeLocationTypeProps {
  locationType: string | undefined;
  distributionWarehouseBrand: string | undefined;
}

function DescribeLocationType(props: DescribeLocationTypeProps) {
  return (
    <>
      {props.locationType !== undefined && (
        <Stack align="left">
          <WithClipboardLink
            copyText={nameLocationType(
              props.locationType,
              props.distributionWarehouseBrand,
            )}
          >
            <div>
              {nameLocationType(
                props.locationType,
                props.distributionWarehouseBrand,
              )}
            </div>
          </WithClipboardLink>
          <div style={{ fontSize: "12px", color: Colors.LightText }}>
            {describeLocationType(props.locationType)}
          </div>
        </Stack>
      )}
      {props.locationType === undefined && <>Unknown</>}
    </>
  );
}

export function LocationInfo(props: LocationInfoProps) {
  try {
    const contextTitle = capitalizeFirstLetter(props.context);

    const shipmentModifiedNotes = props.shipmentData.notesTab.notes.filter(
      (n) => n.noteType === NoteType.ShipmentModified,
    );

    const history: ChangeHistory[] = shipmentModifiedNotes
      .map((note) => {
        const after = JSON.parse(note.afterMetadata ?? "{}");
        const before = JSON.parse(note.beforeMetadata ?? "{}");
        const afterData = JSON.parse(after.data ?? "{}");
        const beforeData = JSON.parse(before.data ?? "{}");

        if (
          ("context" in afterData && afterData.context != props.context) ||
          ("context" in beforeData && beforeData.context != props.context)
        ) {
          return null;
        }

        if (
          !note.headline?.toLowerCase().includes(props.context.toLowerCase())
        ) {
          return null;
        }

        const diff = objectDifferences(beforeData, afterData);
        return { differences: diff, date: note.createdAt, by: note.author };
      })
      .filter((h) => h !== null);

    const data = [
      {
        key: (
          <ChangeHistoryPopover keys={["businessName"]} history={history}>
            {contextTitle} Business Name
          </ChangeHistoryPopover>
        ),
        value: (
          <HorizontalStack>
            <WithClipboardLink>{props.location.businessName}</WithClipboardLink>
            <NonAsciiWarningComponent
              text={props.location.businessName || ""}
            />
          </HorizontalStack>
        ),
      },
      {
        key: (
          <ChangeHistoryPopover paths={["location.address"]} history={history}>
            {contextTitle} Address
          </ChangeHistoryPopover>
        ),
        value: (
          <HorizontalStack>
            <DescribeAddress {...props} />
            <NonAsciiWarningComponent
              text={JSON.stringify(props.location.address)}
            />
          </HorizontalStack>
        ),
      },
      {
        key: (
          <ChangeHistoryPopover paths={["contact"]} history={history}>
            {contextTitle} Contact
          </ChangeHistoryPopover>
        ),
        value: (
          <HorizontalStack verticalAlign="top">
            <DescribeContact {...props} />
            <NonAsciiWarningComponent text={JSON.stringify(props.contact)} />
          </HorizontalStack>
        ),
      },
      {
        key: (
          <ChangeHistoryPopover paths={["location.hours"]} history={history}>
            {contextTitle} Hours
          </ChangeHistoryPopover>
        ),
        value: describeHours(props.location.hours),
      },
      {
        key: (
          <ChangeHistoryPopover keys={["notes"]} history={history}>
            {contextTitle} Notes
          </ChangeHistoryPopover>
        ),
        value: (
          <>
            {props.location.notes}
            <NonAsciiWarningComponent text={props.location.notes || ""} />
          </>
        ),
      },
      {
        key: (
          <ChangeHistoryPopover keys={["accessorials"]} history={history}>
            {contextTitle} Accessorials
          </ChangeHistoryPopover>
        ),
        value: describeAccessorials(props.location.accessorials!),
      },
      {
        key: (
          <ChangeHistoryPopover keys={["reference"]} history={history}>
            {contextTitle} Reference Number
          </ChangeHistoryPopover>
        ),
        value: (
          <HorizontalStack>
            <WithClipboardLink>{props.referenceNumber}</WithClipboardLink>
            <NonAsciiWarningComponent text={props.referenceNumber || ""} />
          </HorizontalStack>
        ),
      },
      {
        key: (
          <ChangeHistoryPopover keys={["locationType"]} history={history}>
            {contextTitle} Location Type
          </ChangeHistoryPopover>
        ),
        value: (
          <DescribeLocationType
            locationType={props.location.locationType}
            distributionWarehouseBrand={
              props.location.distributionWarehouseBrand
            }
          />
        ),
      },
    ];

    if (props.deadline) {
      data.push({
        key: (
          <ChangeHistoryPopover keys={["deadline"]} history={history}>
            {contextTitle} deadline
          </ChangeHistoryPopover>
        ),
        value: dayjs(props.deadline).format("dddd, MMM Do YYYY"),
      });
    }
    return <KeyValues data={data} />;
  } catch (e: any) {
    return <div>Oops. Something went wrong {e.toString()}</div>;
  }
}

interface LocationInfoForQuotingProps {
  location: Location;
  context: string;
}

export function LocationInfoForQuoting(props: LocationInfoForQuotingProps) {
  try {
    return (
      <KeyValues
        data={{
          [`${props.context} Location`]: describeCityStatePostalCode(
            props.location.address!,
          ),
          [`${props.context} Accessorials`]: describeAccessorials(
            props.location.accessorials!,
          ),
          [`${props.context} Location Type`]: (
            <DescribeLocationType
              locationType={props.location.locationType}
              distributionWarehouseBrand={
                props.location.distributionWarehouseBrand
              }
            />
          ),
        }}
      />
    );
  } catch (e: any) {
    return <div>Oops. Something went wrong {e.toString()}</div>;
  }
}
