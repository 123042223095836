import {
  NoteCategory,
  NoteType,
} from "@freightsimple/generated-apollo-openapi-client";
import { Checkbox, Form, Input, message, Modal } from "antd";
import { useState } from "react";
import { ButtonRow } from "../../Components/ButtonRow";
import { NotesTable } from "../../Components/NotesTable";
import Stack from "../../Components/Stack";
import Spacer from "../../Spacer";
import { AddInternalNoteButton } from "./AddInternalNoteButton";
import { NoteAuthorDropdown } from "./NoteAuthorDropdown";
import {
  NoteCategoryDropdown,
  OptionalNoteCategoryDropdown,
} from "./NoteCategoryDropdown";
import { NoteTypeDropdown } from "./NoteTypeDropdown";
import { TabProps } from "./TabProps";
import { ViewEmailConversationsButton } from "./ViewEmailConversationsButton";
import { useShipmentsApi } from "../../Apis/Apis";
import { useInternalNotesContext } from "../../Contexts/InternalNotes/useInternalNotesContext";
import TextArea from "antd/es/input/TextArea";
import { ConfirmButton } from "../../Components/ConfirmButton";

export function NotesTab(props: TabProps) {
  const [noteTypeFilter, setNoteTypeFilter] = useState<NoteType>();
  const [noteCategoryFilter, setNoteCategoryFilter] = useState<NoteCategory>();
  const [noteAuthorFilter, setNoteAuthorFilter] = useState<string>();
  const [freeFilter, setFreeFilter] = useState<string>();
  const [showSystemErrors, setShowSystemErrors] = useState<boolean>(false);

  const title = "Update Internal Note";

  const createShipmentApi = useShipmentsApi();
  const [loading, setLoading] = useState(false);

  const { openModal, newNote, handleOpenModal, setNote } =
    useInternalNotesContext();

  const note = newNote;

  async function handleUpdateNote() {
    setLoading(true);
    const shipmentApi = await createShipmentApi();

    const noteId = note?.noteId;
    const noteMessage = note?.message;
    const category = note?.category;

    if (!noteId || !noteMessage || !category) {
      message.error("Note ID, message and category are required");
      setLoading(false);
      return;
    }

    try {
      await shipmentApi.updateInternalNote({
        noteId,
        message: noteMessage,
        category,
      });
      await props.onRefresh();
      message.success("Note Updated");

      handleOpenModal();
      setNote({});
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
    setLoading(false);
  }

  const handleEditNoteMessage = (message: string) => {
    setNote({ ...note, message: message });
  };

  const handleEditNoteCategory = (category: NoteCategory | undefined) => {
    setNote({ ...note, category: category });
  };

  // Delete Note // --------------------------------------------------

  async function handleDeleteNote() {
    setLoading(true);
    const shipmentApi = await createShipmentApi();
    const noteId = note?.noteId;

    if (!noteId) {
      message.error("Note ID is required");
      setLoading(false);
      return;
    }

    try {
      await shipmentApi.deleteInternalNote({
        noteId,
      });
      await props.onRefresh();
      message.success("Note Deleted");
      handleOpenModal();
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
    setLoading(false);
  }

  return (
    <Stack align="left">
      <ButtonRow>
        <Form.Item label="Filter">
          <Input
            value={freeFilter}
            onChange={function (e) {
              setFreeFilter(e.currentTarget.value);
            }}
          ></Input>
        </Form.Item>
        <Form.Item label="by type">
          <NoteTypeDropdown
            mode="multiple"
            value={noteTypeFilter}
            setValue={setNoteTypeFilter}
          />
        </Form.Item>
        <Form.Item label="by category">
          <OptionalNoteCategoryDropdown
            mode="multiple"
            value={noteCategoryFilter}
            setValue={setNoteCategoryFilter}
          />
        </Form.Item>
        <Form.Item label="by author">
          <NoteAuthorDropdown
            value={noteAuthorFilter}
            setValue={setNoteAuthorFilter}
            notes={props.shipmentData.notesTab.notes}
          />
        </Form.Item>
        <Form.Item label="Actions">
          <ButtonRow>
            <ViewEmailConversationsButton {...props} />
            <AddInternalNoteButton {...props} />
          </ButtonRow>
        </Form.Item>
        <Form.Item>
          <Checkbox
            checked={showSystemErrors}
            onChange={(e) => setShowSystemErrors(e.target.checked)}
          >
            Show System Errors
          </Checkbox>
        </Form.Item>
      </ButtonRow>
      <Spacer height={16} />
      <Modal
        title={title}
        open={openModal}
        onOk={handleUpdateNote}
        confirmLoading={loading}
        onCancel={handleOpenModal}
        okText="Update"
        destroyOnClose={true}
        width={800}
      >
        <Stack align="left" style={{ marginBottom: 25 }}>
          <TextArea
            style={{ width: "600px", height: "100px" }}
            value={note?.message}
            onChange={function (e) {
              handleEditNoteMessage(e.target.value);
            }}
            placeholder="Enter note"
          />
          <Spacer height={8} />
          <ButtonRow>
            <NoteCategoryDropdown
              value={note?.category ? note.category : NoteCategory.General}
              setValue={handleEditNoteCategory}
            />
          </ButtonRow>
        </Stack>
        <div style={{ position: "absolute", bottom: 20 }}>
          <ConfirmButton
            danger
            tooltip="Delete this note"
            onConfirm={handleDeleteNote}
            question="Are you sure you want to delete this note?"
            questionDescription="This action cannot be undone."
            okText="Delete note"
          >
            Delete
          </ConfirmButton>
        </div>
      </Modal>

      <NotesTable
        showSystemErrors={showSystemErrors}
        notes={props.shipmentData.notesTab.notes}
        noteTypeFilter={noteTypeFilter}
        noteAuthorFilter={noteAuthorFilter}
        noteCategoryFilter={noteCategoryFilter}
        freeFilter={freeFilter}
      />
    </Stack>
  );
}
