import { useState } from "react";
import { useQuoteTestApi } from "../../../../Apis/Apis";
import {
  PostQuoteTestScenarioBody,
  QuoteTestScenario,
  Shipment,
} from "@freightsimple/generated-apollo-openapi-client";

export function useQuoteTestScenarioApi() {
  const createApi = useQuoteTestApi();

  const [isLoading, setIsLoading] = useState(false);

  const createScenario = async (
    groupId: string,
    body: PostQuoteTestScenarioBody,
  ) => {
    setIsLoading(() => true);
    try {
      const api = await createApi();
      await api.createScenario({ groupId, postQuoteTestScenarioBody: body });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(() => false);
  };

  const updateScenario = async (
    scenarioId: string,
    groupId: string,
    body: QuoteTestScenario,
  ) => {
    setIsLoading(() => true);
    try {
      const api = await createApi();
      await api.updateScenario({
        quoteTestScenario: { ...body, scenarioId, groupId },
      });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(() => false);
  };

  const deleteScenario = async (scenarioId: string) => {
    setIsLoading(() => true);
    try {
      const api = await createApi();
      await api.deleteScenario({ scenarioId });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(() => false);
  };

  const createScenarioFromShipmentId = async (
    groupId: string,
    shipmentId: string,
  ) => {
    setIsLoading(() => true);
    try {
      const api = await createApi();
      await api.createScenarioFromShipmentId({ groupId, shipmentId });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(() => false);
  };

  const cloneScenario = async (
    groupId: string,
    scenarioId: string,
  ) => {
    setIsLoading(() => true);
    try {
      const api = await createApi();
      await api.cloneScenario({ groupId, scenarioId });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(() => false);
  };

  const createScenarioFromShipmentJson = async (
    shipment: Shipment,
    groupId: string,
  ) => {
    setIsLoading(() => true);
    try {
      const api = await createApi();
      await api.createScenarioFromShipmentJson({ shipment, groupId });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(() => false);
  };

  return {
    createScenario,
    isLoading,
    updateScenario,
    createScenarioFromShipmentId,
    createScenarioFromShipmentJson,
    deleteScenario,
    cloneScenario
  };
}
