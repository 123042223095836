import { useApiKeysApi } from "../Apis/Apis";
import { message, Modal } from "antd";

interface RevokeApiKeyMenuItemProps {
  apiKeyId: string;
  onRefresh: () => Promise<void>;
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
}

export function RevokeApiKeyMenuItem(props: RevokeApiKeyMenuItemProps) {
  const createApiKeysApi = useApiKeysApi();
  const handleCancel = () => props.setIsModalOpen(false);

  async function handleOk() {
    try {
      const apiKeysApi = await createApiKeysApi();
      if (props.apiKeyId) {
        await apiKeysApi.revokeApiKey({ apiKeyId: props.apiKeyId });
      } else {
        throw new Error("API key ID is undefined");
      }
      await props.onRefresh();
      message.success("API key revoked");
      props.setIsModalOpen(false);
    } catch (e) {
      message.error(`Oops. Something went wrong. ${e}`);
    }
  }

  return (
    <Modal
      title="Revoke API Key"
      open={props.isModalOpen}
      onCancel={handleCancel}
      onOk={() => {
        handleOk();
      }}
    >
      <p>
        Are you sure you want to revoke this API key? This key will be rendered
        inactive and will no longer be able to access the API.
      </p>
    </Modal>
  );
}
