import { QuoteTestExecutionDto } from "@freightsimple/generated-apollo-openapi-client";
import { Select, Tag } from "antd";
import dayjs from "dayjs";
import HorizontalStack from "../../../../Components/HorizontalStack";

interface QuoteTestHistorySelectorProps {
  executionId: string | null;
  setExecutionId: (_: string | null) => void;
  executions: Array<QuoteTestExecutionDto>;
}

export function QuoteTestHistorySelector(props: QuoteTestHistorySelectorProps) {
  const { executionId, setExecutionId, executions } = props;

  const historyOptions = executions.map((result) => ({
    value: result.executionId,
    label: result.createdAt,
    result,
  }));

  function renderHistoryEntry(execution: QuoteTestExecutionDto | undefined) {
    return (
      <HorizontalStack align="spread">
        <div>{execution?.tags?.map((t) => <Tag key={t}>{t}</Tag>)}</div>
        {dayjs(execution?.createdAt).format("YYYY/MM/DD HH:mm")}{" "}
        {execution?.createdBy}
      </HorizontalStack>
    );
  }

  return (
    <Select
      style={{ width: 500 }}
      showSearch
      value={executionId}
      onChange={(e) => setExecutionId(e)}
      labelRender={(o) => {
        const history = historyOptions.find((h) => h.value === o.value);

        return renderHistoryEntry(history?.result);
      }}
      optionRender={(o) => renderHistoryEntry(o.data.result)}
      options={historyOptions}
    />
  );
}
