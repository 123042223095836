import { useEffect, useState } from "react";
import { useQuoteTestApi } from "../../../../Apis/Apis";
import { QuoteTestGroupDto } from "@freightsimple/generated-apollo-openapi-client";

export function useFetchQuoteTestGroups(
  carrierIdentifier: string,
  serviceIdentifier: string,
) {
  const createApi = useQuoteTestApi();

  const [isLoading, setIsLoading] = useState(false);
  const [groups, setGroups] = useState<Array<QuoteTestGroupDto> | null>(null);

  async function fetch() {
    setIsLoading(() => true);
    try {
      const api = await createApi();
      const response = await api.getAllGroups({
        carrierIdentifier,
        serviceIdentifier,
      });
      setGroups(response);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(() => false);
  }

  useEffect(() => {
    fetch();
  }, [carrierIdentifier, serviceIdentifier]);

  return { groups, isLoading, refresh: fetch };
}
