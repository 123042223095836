import { Form, Input, Modal } from "antd";
import { UseDisplayReturn } from "../../../../Hooks/useDisplay";
import { QuoteTestDashboardProps } from "../QuoteTestDashboard";
import { RefreshFunction } from "../../../../Types/RefreshFunctionType";
import { useQuoteTestScenarioApi } from "../Hooks/useCreateQuoteScenario";
import { Shipment } from "@freightsimple/generated-apollo-openapi-client";

interface ShipmentJsonForm {
  shipmentJson: string;
}

export function QuoteTestScenarioFromJSONModal(
  props: QuoteTestDashboardProps & {
    groupId: string;
    refresh: RefreshFunction;
    display: UseDisplayReturn;
  },
) {
  const { groupId, refresh, display } = props;
  const { createScenarioFromShipmentJson, isLoading } =
    useQuoteTestScenarioApi();
  const [form] = Form.useForm<ShipmentJsonForm>();

  async function handleFinish(value: ShipmentJsonForm) {
    const shipment = JSON.parse(value.shipmentJson) as Shipment;
    await createScenarioFromShipmentJson(shipment, groupId);
    await refresh();
  }

  return (
    <Modal
      open={display.status}
      onCancel={display.hide}
      title="Create Scenario"
      confirmLoading={isLoading}
      okText="Create"
      onOk={form.submit}
      width={1600}
    >
      <Form form={form} onFinish={handleFinish}>
        <Form.Item label="Shipment JSON" name="shipmentJson">
          <Input.TextArea rows={20} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
