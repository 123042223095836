import { useEffect, useRef } from "react";

interface IsolatedHTMLProps {
  html: string;
}

export const IsolatedHTML: React.FC<IsolatedHTMLProps> = ({ html }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const shadowRootRef = useRef<ShadowRoot | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      // Attach shadow root only once
      if (!shadowRootRef.current) {
        shadowRootRef.current = containerRef.current.attachShadow({
          mode: "open",
        });
      }

      if (shadowRootRef.current) {
        // Create a container for the HTML content
        const contentContainer = document.createElement("div");
        contentContainer.style.width = "700px";
        contentContainer.innerHTML = html;

        // Clear previous content and append new content
        shadowRootRef.current.innerHTML = "";
        shadowRootRef.current.appendChild(contentContainer);
      }
    }
  }, [html]);

  return <div ref={containerRef} />;
};
