import { Modal, Select } from "antd";
import { UseDisplayReturn } from "../../../../Hooks/useDisplay";
import { RefreshFunction } from "../../../../Types/RefreshFunctionType";
import { QuoteTestDashboardProps } from "../QuoteTestDashboard";
import { useState } from "react";
import { useQuoteTestScenarioApi } from "../Hooks/useCreateQuoteScenario";

export function CloneQuoteTestScenarioModal(
  props: QuoteTestDashboardProps & {
    groups: Array<{ groupId: string; title: string }>;
    scenarioId: string;
    refresh: RefreshFunction;
    display: UseDisplayReturn;
  },
) {
  const { refresh, display, scenarioId, groups } = props;

  const { cloneScenario } = useQuoteTestScenarioApi();

  const [groupId, setGroupId] = useState<string | null>(null);

  async function handleFinish() {
    if (!groupId) return;
    await cloneScenario(groupId, scenarioId);
    await refresh();
  }

  return (
    <Modal
      title="Clone Scenario"
      onOk={handleFinish}
      onCancel={display.hide}
      okText="Clone"
      open={display.status}
      width={600}
      height={800}
    >
      <Select
        style={{minWidth: 300}}
        value={groupId}
        options={groups.map((g) => ({ value: g.groupId, label: g.title }))}
        onChange={(e) => setGroupId(e)}
      />
    </Modal>
  );
}
