import { message } from "antd";
import { useEffect } from "react";
import useClipboard from "react-use-clipboard";

interface CopyToClipboardOptions {
  doNotDisplayCopiedTextInMessage?: boolean;
}

export function useCopyToClipboard(
  textToCopy: string,
  // If the text is very long, the resulting message might be really ugly - so option to
  // not show it
  options?: CopyToClipboardOptions,
) {
  const [isCopied, setCopied] = useClipboard(textToCopy);

  useEffect(
    function () {
      if (isCopied) {
        if (options?.doNotDisplayCopiedTextInMessage) {
          message.success(`Copied to clipboard`);
        } else {
          message.success(`'${textToCopy}' copied to clipboard`);
        }
      }
    },

    [isCopied],
  );

  return setCopied;
}
