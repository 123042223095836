import {
  CarrierCreditNote,
  CarrierCreditNoteState,
} from "@freightsimple/generated-apollo-openapi-client";

import { Tabs } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCarrierInvoiceApi } from "../Apis/Apis";
import { ButtonRow } from "../Components/ButtonRow";
import CarrierLogo from "../Components/CarrierLogo";
import { DataTable, DataTableColumn } from "../Components/DataTable";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import { useOnce } from "../Hooks/useOnce";
import useQuery from "../Hooks/useQuery";
import { CarrierCreditNotesStateTag } from "../Components/CarrierCreditNotesStateTag";
import { Dollar } from "../Components/Dollar";
import { ViewShipmentButton } from "../Components/ViewShipmentButton";

const { TabPane } = Tabs;

interface CarrierCreditNotesTableProps {
  carrierCreditNotes: CarrierCreditNote[];
}

function CarrierCreditNotesTable(props: CarrierCreditNotesTableProps) {
  const columns: DataTableColumn<CarrierCreditNote>[] = [
    {
      title: "Carrier",
      render: (o) => (
        <CarrierLogo
          carrierIdentifier={o.carrierIdentifier!}
          brokeredCarrierIdentifier={undefined}
          width={40}
          height={30}
        />
      ),
    },
    {
      title: "State",
      render: (o) =>
        o.carrierCreditNoteState && (
          <div>
            <CarrierCreditNotesStateTag state={o.carrierCreditNoteState} />
          </div>
        ),
    },
    {
      title: "Refund Requested",
      render: (o) => <div>{o.refundRequested ? "Yes" : "No"}</div>,
    },

    {
      title: "Amount",
      render: (o) => <Dollar currency={o.currency}>{o.amount!}</Dollar>,
    },
    {
      title: "Actions",
      render: (o) => (
        <ButtonRow>
          <ViewShipmentButton shipmentId={o.shipmentId!} />
        </ButtonRow>
      ),
    },
  ];

  return (
    <DataTable
      pagination={false}
      columns={columns}
      data={props.carrierCreditNotes}
    />
  );
}

export function CarrierCreditNotesScreen() {
  const [carrierCreditNotes, setCarrierCreditNotes] = useState<
    CarrierCreditNote[] | undefined
  >();
  const createCarrierInvoiceApi = useCarrierInvoiceApi();

  async function refresh() {
    const carrierInvoiceApi = await createCarrierInvoiceApi();
    const response = await carrierInvoiceApi.viewCarrierCreditNotes();
    setCarrierCreditNotes(response);
  }

  const query = useQuery();
  const navigate = useNavigate();

  const tab = query.tab as string;

  useOnce(refresh);

  function callback(key: string) {
    navigate(`/carrier-credit-notes?tab=${key}`, {
      replace: true,
    });
  }

  const approvedCarrierCreditNotes = filterCarrierCreditNotesState(
    CarrierCreditNoteState.Approved,
  );
  const paidCarrierCreditNotes = filterCarrierCreditNotesState(
    CarrierCreditNoteState.Paid,
  );

  const voidedCarrierCreditNotes = filterCarrierCreditNotesState(
    CarrierCreditNoteState.Voided,
  );

  function filterCarrierCreditNotesState(state: CarrierCreditNoteState) {
    return carrierCreditNotes?.filter(
      (carrierCreditNote) => carrierCreditNote.carrierCreditNoteState == state,
    );
  }

  if (carrierCreditNotes === undefined) {
    return <Loading />;
  }

  return (
    <>
      <Page title={`Carrier Credit Notes`} tags={[]} extra={[]}>
        <PageTitle>{`Carrier Invoices`}</PageTitle>
        <Tabs
          defaultActiveKey={tab}
          onChange={callback}
          style={{ width: "100%" }}
        >
          <TabPane
            tab={`Approved (${approvedCarrierCreditNotes?.length}) `}
            key="approved"
          >
            <CarrierCreditNotesTable
              carrierCreditNotes={approvedCarrierCreditNotes!}
            />
          </TabPane>

          <TabPane tab={`Paid (${paidCarrierCreditNotes?.length})`} key="paid">
            <CarrierCreditNotesTable
              carrierCreditNotes={paidCarrierCreditNotes!}
            />
          </TabPane>
          <TabPane
            tab={`Voided (${voidedCarrierCreditNotes?.length})`}
            key="voided"
          >
            <CarrierCreditNotesTable
              carrierCreditNotes={voidedCarrierCreditNotes!}
            />
          </TabPane>
        </Tabs>
      </Page>
    </>
  );
}
