import { Form, Modal } from "antd";
import { UseDisplayReturn } from "../../../../Hooks/useDisplay";
import { QuoteTestDashboardProps } from "../QuoteTestDashboard";
import {
  PostQuoteTestScenarioBody,
  QuoteTestScenarioDto,
} from "@freightsimple/generated-apollo-openapi-client";
import { QuoteTestScenarioForm } from "../Forms/QuoteTestScenarioForm";
import { useQuoteTestScenarioApi } from "../Hooks/useCreateQuoteScenario";
import { useBaseScenario } from "../Hooks/useFetchBasicScenario";
import { RefreshFunction } from "../../../../Types/RefreshFunctionType";
import { Loading } from "../../../../Components/Loading";

export function QuoteTestScenarioFormModal(
  props: QuoteTestDashboardProps & {
    groupId: string;
    baseScenario: QuoteTestScenarioDto | null | undefined;
    isEdit?: boolean;
    refresh: RefreshFunction;
    display: UseDisplayReturn;
  },
) {
  const { groupId, baseScenario, refresh, display, isEdit } = props;
  const { data } = useBaseScenario(baseScenario);
  const { createScenario, isLoading, updateScenario } =
    useQuoteTestScenarioApi();
  const [form] = Form.useForm<PostQuoteTestScenarioBody>();

  async function handleFinish(value: PostQuoteTestScenarioBody) {
    if (isEdit && data) {
      await updateScenario(data.scenarioId!, data.groupId!, {
        ...data,
        ...value,
        metadata: JSON.stringify(value.metadata),
      });
    } else {
      await createScenario(groupId, value);
    }
    display.hide();
    await refresh();
  }

  if (!data) return <Loading />;

  const initialValues: PostQuoteTestScenarioBody = {
    title: data.title ?? "",
    description: data.description ?? "",
    lineItems: data.lineItems ?? [],
    pickupLocation: data.pickupLocation ?? {},
    deliveryLocation: data.deliveryLocation ?? {},
    metadata: baseScenario?.parsedMetadata ?? null,
  };

  return (
    <Modal
      destroyOnClose
      open={display.status}
      onCancel={display.hide}
      title={isEdit ? "Edit Scenario" : "Create New Scenario"}
      confirmLoading={isLoading}
      okText={isEdit ? "Edit" : "Create"}
      onOk={form.submit}
      width={1600}
    >
      <QuoteTestScenarioForm
        initialValues={initialValues}
        onFinish={handleFinish}
        form={form}
        isEdit={isEdit}
      />
    </Modal>
  );
}
