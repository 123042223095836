import { QuoteTestGroupDto } from "@freightsimple/generated-apollo-openapi-client";
import { QuoteTestDashboardProps } from "../QuoteTestDashboard";
import { Menu, MenuProps } from "antd";

interface QuoteTestGroupMenuProps extends QuoteTestDashboardProps {
  groupId: string | null;
  setGroupId: (_: string | null) => void;
  scenarioId: string | null;
  setScenarioId: (_: string | null) => void;
  groups: Array<QuoteTestGroupDto>;
}

type MenuItem = Required<MenuProps>["items"][number];

export function QuoteTestGroupMenu(props: QuoteTestGroupMenuProps) {
  const { groups, groupId, setGroupId, setScenarioId, scenarioId } = props;

  const items: MenuItem[] = groups.map((group) => ({
    key: group.groupId!,
    label: group.title,
    children:
      group.scenarios.length === 0
        ? null
        : group.scenarios.map((scenario) => ({
            key: scenario.scenarioId,
            label: scenario.title,
          })),
  }));

  return (
    <Menu
      style={{height: "100%"}}
      mode="inline"
      defaultOpenKeys={[groupId ?? ""]}
      defaultSelectedKeys={[scenarioId ?? ""]}
      items={items}
      onClick={(e) => {
        if (e.keyPath.length === 1) {
          setGroupId(e.keyPath[0]);
          setScenarioId(null);
        } else if (e.keyPath.length === 2) {
          setScenarioId(e.keyPath[0]);
          setGroupId(e.keyPath[1]);
        }
      }}
    />
  );
}
