import { useEffect, useState } from "react";
import { useQuoteTestApi } from "../../../../Apis/Apis";
import { QuoteTestScenarioDetailsDto } from "@freightsimple/generated-apollo-openapi-client";

export function useFetchQuoteTestScenario(
  scenarioId: string | undefined | null,
  callback?: (response: QuoteTestScenarioDetailsDto) => void,
) {
  const createApi = useQuoteTestApi();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<QuoteTestScenarioDetailsDto | null>(null);

  async function fetch() {
    if (!scenarioId) return;
    setIsLoading(() => true);
    try {
      const api = await createApi();
      const response = await api.getScenario({
        scenarioId,
      });
      setData(response);
      callback?.(response)
    } catch (error) {
      console.error(error);
    }
    setIsLoading(() => false);
  }

  useEffect(() => {
    fetch();
  }, [scenarioId]);

  return { data, isLoading, refresh: fetch };
}
