import { Form, Modal } from "antd";
import { UseDisplayReturn } from "../../../../Hooks/useDisplay";
import { QuoteTestDashboardProps } from "../QuoteTestDashboard";
import {
  PostQuoteTestExpectationBody,
  QuoteTestExpectationType,
} from "@freightsimple/generated-apollo-openapi-client";
import { useQuoteTestExpectationApi } from "../Hooks/useQuoteTestExpectationApi.tsx";
import { QuoteTestExpectationForm } from "../Forms/QuoteTestGroupExpectationForm";
import { RefreshFunction } from "../../../../Types/RefreshFunctionType";

export function QuoteTestGroupExpectationModal(
  props: QuoteTestDashboardProps & {
    groupId: string;
    refresh: RefreshFunction;
    display: UseDisplayReturn;
  },
) {
  const { groupId, refresh, display } = props;
  const { createGroupExpectation, isLoading } = useQuoteTestExpectationApi();
  const [form] = Form.useForm<PostQuoteTestExpectationBody>();

  async function handleFinish(values: PostQuoteTestExpectationBody) {
    await createGroupExpectation(groupId, values);
    await refresh();
    display.hide();
  }

  return (
    <Modal
      open={display.status}
      onCancel={display.hide}
      title="Create new quote test group"
      confirmLoading={isLoading}
      okText="Create"
      onOk={form.submit}
      width={700}
    >
      <QuoteTestExpectationForm
        initialValues={{
          type: QuoteTestExpectationType.QuotePriceByCarrer,
          description: "",
        }}
        onFinish={handleFinish}
        form={form}
      />
    </Modal>
  );
}
