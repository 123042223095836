import {
  CurrencyCode,
  EmailQuoteRequestAndResponses,
  EmailQuoteRequestState,
  Quote,
  QuoteGenerationMethod,
  ShipmentState,
  ViewShipmentDataForApollo,
} from "@freightsimple/generated-apollo-openapi-client";

import {
  EllipsisOutlined,
  EyeInvisibleTwoTone,
  EyeTwoTone,
  MessageOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Form,
  Menu,
  MenuItemProps,
  message,
  Modal,
  Switch,
  Tag,
  Tooltip,
  Typography,
} from "antd";

import { useState } from "react";
import reason from "react-syntax-highlighter/dist/esm/languages/prism/reason";
import { useQuotesApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import { CarrierFilter } from "../../Components/CarrierFilter";
import CarrierLogo from "../../Components/CarrierLogo";
import Colors from "../../Components/Colors";
import { ConfirmMenuItem } from "../../Components/ConfirmButton";
import { CreatedAt } from "../../Components/CreatedAt";
import { CurrencyInput } from "../../Components/CurrencyInput";
import { DataTable, DataTableColumn } from "../../Components/DataTable";
import { EmailQuoteRequestStateTag } from "../../Components/EmailQuoteRequestStateTag";
import HorizontalStack from "../../Components/HorizontalStack";
import { Percentage } from "../../Components/Percentage";
import { Price } from "../../Components/Price";
import Stack from "../../Components/Stack";
import { ConditionalWarningComponent } from "../../Components/WarningComponent";
import { assertNever } from "../../Helpers/assertNever";
import { calculateMargin } from "../../Helpers/calculateMargin";
import { getQuoteShipmentKeyDifferences } from "../../Helpers/getQuoteShipmentKeyDifferences";
import { useCopyToClipboard } from "../../Hooks/useCopyToClipboard";
import Spacer from "../../Spacer";
import { AddQuoteButton } from "./AddQuoteButton";
import { AddQuoteGroupButton } from "./AddQuoteGroupButton";
import { CloseManualQuotingButton } from "./CloseManualQuotingButton";
import { DeleteEntireQuoteGroupButton } from "./DeleteEntireQuoteGroupButton";
import { EmailForQuoteButton } from "./EmailForQuoteButton";
import { LogisticsAssistanceSupportEmailButton } from "./LogisticsAssistanceSupportEmailButton";
import { MakeEntireQuoteGroupHiddenButton } from "./MakeEntireQuoteGroupHiddenButton";
import { MakeEntireQuoteGroupVisibleButton } from "./MakeEntireQuoteGroupVisibleButton";
import { NoQuotesNotificationEmailButton } from "./NoQuotesNotificationEmailButton";
import { OpenForManualQuotingButton } from "./OpenForManualQuotingButton";
import { QuoteNotificationEmailMenuItem } from "./QuoteNotificationEmailMenuItem";
import { QuoteShipmentDifferencesButton } from "./QuoteShipmentDifferences";
import { ReopenManualQuotingButton } from "./ReopenManualQuotingButton";
import { SelectQuoteMenuItem } from "./SelectQuoteMenuItem";
import { TabProps } from "./TabProps";
import { ViewCarrierRequestAuditsForQuoteMenuItem } from "./ViewCarrierRequestAuditsForQuoteMenuItem";
import { ViewEmailQuoteResponsesButton } from "./ViewEmailQuoteResponsesButton";
import { ViewQuoteButton } from "./ViewQuoteButton";
import { ViewQuoteContactMenuItem } from "./ViewQuoteContactMenuItem";
import { ViewQuoteLogsButton } from "./ViewQuoteLogsButton";
import { NumberInput } from "../../Components/NumberInput";
import dayjs from "dayjs";
import { EditQuoteNotesButton } from "../EditQuoteNotesButton";
import { TransitTimeSourceTag } from "../../Components/TransitTimeSourceTag";

const { Title, Text } = Typography;

interface EmojiForQuoteGenerationMethodProps {
  quoteGenerationMethod: QuoteGenerationMethod | undefined;
}

function EmojiForQuoteGenerationMethod(
  props: EmojiForQuoteGenerationMethodProps,
) {
  switch (props.quoteGenerationMethod) {
    case QuoteGenerationMethod.Api:
      return <>🖥️</>;
    case QuoteGenerationMethod.Manual:
      return <>✉️</>;
    case QuoteGenerationMethod.Ratesheet:
      return <>🧮</>;
    case QuoteGenerationMethod.Scraping:
      return <>👋</>;
    case undefined:
      return <></>;
    default:
      assertNever(props.quoteGenerationMethod);
  }
}

function MarkCustomerVisibleMenuItem(props: ExtrasMenuProps) {
  const createQuotesApi = useQuotesApi();

  async function markCustomerVisible() {
    const quotesApi = await createQuotesApi();
    const quoteId = props.quote.quoteId!;
    try {
      await quotesApi.markQuoteCustomerVisible({
        quoteId,
      });
      await props.onRefresh();
      message.success("Marked visible");
    } catch (e) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
  }

  return (
    <Menu.Item onClick={markCustomerVisible} {...props}>
      Mark customer visible
    </Menu.Item>
  );
}

function MarkCustomerHiddenMenuItem(props: ExtrasMenuProps) {
  const createQuotesApi = useQuotesApi();

  async function markCustomerHidden() {
    const quotesApi = await createQuotesApi();
    const quoteId = props.quote.quoteId!;
    try {
      await quotesApi.markQuoteCustomerHidden({
        quoteId,
      });

      await props.onRefresh();
      message.success("Marked hidden");
    } catch (e) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
  }

  return (
    <Menu.Item onClick={markCustomerHidden} {...props}>
      Mark customer hidden
    </Menu.Item>
  );
}

function DeleteQuoteMenuItem(props: ExtrasMenuProps) {
  const createQuotesApi = useQuotesApi();

  async function deleteQuote() {
    const quotesApi = await createQuotesApi();
    const quoteId = props.quote.quoteId!;
    try {
      await quotesApi.deleteQuote({
        quoteId,
      });
      await props.onRefresh();
      message.success("🗑 Deleted");
    } catch (e) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
  }

  const selectedQuoteId = props.data.shipment.shipment.selectedQuoteId;

  function allowedToDelete(): boolean {
    return props.quote.quoteId !== selectedQuoteId;
  }

  if (!allowedToDelete()) {
    return (
      <Tooltip overlay="Cannot delete selected quote">
        <Menu.Item disabled {...props}>
          🗑 Delete quote
        </Menu.Item>
      </Tooltip>
    );
  }

  return (
    <ConfirmMenuItem
      onConfirm={deleteQuote}
      question="Are you sure you want to delete this quote? This cannot be undone."
      okText="Delete this quote"
      tooltip=""
      {...props}
    >
      🗑 Delete quote
    </ConfirmMenuItem>
  );
}

function SelectQuoteBeforeBookingMenuItem(props: ExtrasMenuProps) {
  const createQuotesApi = useQuotesApi();

  async function selectQuote() {
    const quotesApi = await createQuotesApi();
    const quoteId = props.quote.quoteId!;
    try {
      await quotesApi.selectQuoteBeforeBooking({
        quoteId,
      });
      await props.onRefresh();
      message.success("Selected. If applicable, bol/pickup pack regenerated");
    } catch (e) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
  }

  const state = props.data.shipment.shipment.state!;

  function allowedToSwitch(): boolean {
    // This is for before booking
    switch (state) {
      case ShipmentState.QuoteRequested:
      case ShipmentState.Quoted:
        return true;
      case ShipmentState.BookingRequested:
      case ShipmentState.BookingConfirmed:
      case ShipmentState.BookingFailed:
      case ShipmentState.OnHold:
      case ShipmentState.InTransit:
      case ShipmentState.Cancelled:
      case ShipmentState.Lost:
      case ShipmentState.Delivered:
        return false;
      default:
        assertNever(state);
    }
  }

  if (!allowedToSwitch()) {
    return <></>;
  }

  return (
    <ConfirmMenuItem
      onConfirm={selectQuote}
      question="Are you sure you want to select the quote for this shipment?"
      questionDescription="Shipment is not yet booked, so this simply switches the selected quote"
      okText="Select this quote"
      tooltip="Changing the selected quote will update the carrier that is selected for this shipment"
    >
      Select quote
    </ConfirmMenuItem>
  );
}

function SimpleSelectQuoteMenuItem(props: ExtrasMenuProps) {
  const createQuotesApi = useQuotesApi();

  async function selectQuote() {
    const quotesApi = await createQuotesApi();
    const quoteId = props.quote.quoteId!;
    try {
      await quotesApi.selectQuoteBeforeBooking({
        quoteId,
      });
      await props.onRefresh();
      message.success("Selected. If applicable, bol/pickup pack regenerated");
    } catch (e) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
  }

  const state = props.data.shipment.shipment.state!;

  function allowedToSwitch(): boolean {
    // This is for before booking
    switch (state) {
      case ShipmentState.QuoteRequested:
      case ShipmentState.Quoted:
        return true;
      case ShipmentState.BookingRequested:
      case ShipmentState.BookingConfirmed:
      case ShipmentState.BookingFailed:
      case ShipmentState.OnHold:
      case ShipmentState.InTransit:
      case ShipmentState.Cancelled:
      case ShipmentState.Lost:
      case ShipmentState.Delivered:
        return false;
      default:
        assertNever(state);
    }
  }

  if (!allowedToSwitch()) {
    return <></>;
  }

  return (
    <ConfirmMenuItem
      onConfirm={selectQuote}
      question="Are you sure you want to select the quote for this shipment?"
      questionDescription="This simply switches the selected quote. Only use this if you know what you're doing"
      okText="Select this quote"
      tooltip="Changing the selected quote will update the carrier that is selected for this shipment"
      {...props}
    >
      <Tag color="red">Advanced</Tag> Simple Switch quote
    </ConfirmMenuItem>
  );
}

function AdjustQuotePriceMenuItem(props: ExtrasMenuProps) {
  const createQuotesApi = useQuotesApi();
  const [amountCad, setAmountCad] = useState<number>(props.quote.priceCad!);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [saving, setSaving] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    const quotesApi = await createQuotesApi();
    const quoteId = props.quote.quoteId!;
    if (reason === "") {
      return;
    }

    if (amountCad < 10.0) {
      return;
    }

    try {
      setSaving(true);

      await quotesApi.adjustQuotePrice({
        quoteId,
        amountCad,
      });
      await props.onRefresh();
      message.success("Updated");
      setIsModalVisible(false);
    } catch (e) {
      message.error(`Oops something went wrong : ${e}`);
    }
    setSaving(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Adjust Quote Price"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        confirmLoading={saving}
        destroyOnClose
      >
        <Stack align="left">
          <Text>
            This allows you to alter the price of a quote. It's probably only a
            good idea to do before booking (TBB?). Maybe a coupon would solve
            the problem too?
          </Text>
          <Spacer height={32} />
          <Form
            style={{ width: "800px" }}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16, offset: 1 }}
            layout="horizontal"
            colon={false}
          >
            <Form.Item label="Amount CAD">
              <CurrencyInput
                amount={amountCad}
                setAmount={setAmountCad}
                currency={CurrencyCode.Cad}
              />
            </Form.Item>
          </Form>
        </Stack>
      </Modal>
      <Menu.Item onClick={showModal} {...props}>
        Adjust price
      </Menu.Item>
    </>
  );
}

function AdjustTransitDaysMenuItem(props: ExtrasMenuProps) {
  const createQuotesApi = useQuotesApi();
  const [earliestTransitDays, setEarliestTransitDays] = useState<
    number | undefined
  >(props.quote.transitBusinessDays);
  const [latestTransitDays, setLatestTransitDays] = useState<
    number | undefined
  >(props.quote.latestTransitBusinessDays);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [saving, setSaving] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    const quotesApi = await createQuotesApi();
    const quoteId = props.quote.quoteId!;
    if (reason === "") {
      return;
    }

    if (earliestTransitDays === undefined) {
      message.warning("Must set earliest");
      return;
    }

    if (latestTransitDays === undefined) {
      message.warning("Must set latest");
      return;
    }

    try {
      setSaving(true);

      await quotesApi.adjustTransitDays({
        quoteId,
        earliestTransitDays,
        latestTransitDays,
      });
      await props.onRefresh();
      message.success("Updated");
      setIsModalVisible(false);
    } catch (e) {
      message.error(`Oops something went wrong : ${e}`);
    }
    setSaving(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Adjust Transit Days"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        confirmLoading={saving}
        destroyOnClose
      >
        <Stack align="left">
          <Text>
            Set the transit window of the quote. This will update the dates too.
          </Text>
          <Spacer height={32} />
          <Form
            style={{ width: "800px" }}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16, offset: 1 }}
            layout="horizontal"
            colon={false}
          >
            <Form.Item label="Earliest Transit Days">
              <NumberInput
                value={earliestTransitDays}
                setValue={setEarliestTransitDays}
              />
            </Form.Item>
            <Form.Item label="Latest Transit Days">
              <NumberInput
                value={latestTransitDays}
                setValue={setLatestTransitDays}
              />
            </Form.Item>
          </Form>
        </Stack>
      </Modal>
      <Menu.Item onClick={showModal} {...props}>
        Adjust transit days
      </Menu.Item>
    </>
  );
}

export interface ExtrasMenuProps extends MenuItemProps {
  quote: Quote;
  data: ViewShipmentDataForApollo;
  onRefresh: () => Promise<void>;
}

function ExtrasMenu(props: ExtrasMenuProps) {
  const quoteId = props.quote.quoteId!;
  const copyShipmentId = useCopyToClipboard(quoteId);
  const hiddenFromCustomer = props.quote.hiddenFromCustomer;
  const isSelected = props.data.shipment.shipment.selectedQuoteId === quoteId;

  const menu = (
    <Menu>
      <Menu.Item onClick={copyShipmentId} icon={<PaperClipOutlined />}>
        Copy Quote Id to clipboard
      </Menu.Item>
      <Menu.Divider />
      {hiddenFromCustomer && <MarkCustomerVisibleMenuItem {...props} />}
      {!hiddenFromCustomer && <MarkCustomerHiddenMenuItem {...props} />}
      {!isSelected && <SelectQuoteBeforeBookingMenuItem {...props} />}
      {!isSelected && <SimpleSelectQuoteMenuItem {...props} />}
      {!isSelected && <SelectQuoteMenuItem {...props} />}
      <AdjustQuotePriceMenuItem {...props} />
      <AdjustTransitDaysMenuItem {...props} />
      <EditQuoteNotesButton {...props} />
      <Menu.Divider />
      <QuoteNotificationEmailMenuItem {...props} />
      <Menu.Divider />
      <ViewCarrierRequestAuditsForQuoteMenuItem {...props} />
      <Menu.Divider />
      <ViewQuoteContactMenuItem {...props} shipmentData={props.data} />
      <Menu.Divider />
      {<DeleteQuoteMenuItem {...props} />}
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button>
        <EllipsisOutlined />
      </Button>
    </Dropdown>
  );
}

interface QuotesSectionProps extends TabProps {
  filterCarrierIdentifier: string | undefined;
}

function QuotesSection(props: QuotesSectionProps) {
  const selectedQuoteId = props.shipmentData.shipment.shipment.selectedQuoteId;
  const quotes = props.shipmentData.manageQuotesTab.quotes;

  function ascendingQuoteSorter(a: Quote, b: Quote) {
    return dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf();
  }

  const entries = Object.entries(quotes)
    // Sort the quote groups so the most recent quote groups go first
    // Basically find the earliest quote from each group, and then reverse sort those
    .sort(function (a, b) {
      const earliestA = a[1].sort(ascendingQuoteSorter)[0];
      const earliestB = b[1].sort(ascendingQuoteSorter)[0];
      return (
        dayjs(earliestB.createdAt).valueOf() -
        dayjs(earliestA.createdAt).valueOf()
      );
    });

  if (entries.length === 0) {
    return (
      <div style={{ marginTop: "16px" }}>
        Wah, there are no quotes 🥲. Plz Fix. xoxo. The customer would probably
        like some. View the quote logs to figure out why.
      </div>
    );
  }

  return (
    <>
      <>
        {entries.map(function (o) {
          const quoteGroupName = o[0];
          const quotes = o[1];
          const earliestCreatedQuote = quotes.sort(ascendingQuoteSorter)[0];

          return (
            <>
              <QuotesTable
                quoteGroupName={quoteGroupName}
                groupGroupCreated={dayjs(earliestCreatedQuote.createdAt).format(
                  "dddd, MMMM Do YYYY, h:mm:ss a z",
                )}
                quotes={quotes.filter(function (q) {
                  if (props.filterCarrierIdentifier === undefined) {
                    return true;
                  } else {
                    return (
                      q.carrierIdentifier === props.filterCarrierIdentifier
                    );
                  }
                })}
                selectedQuoteId={selectedQuoteId}
                onRefresh={props.onRefresh}
                data={props.shipmentData}
              />
              <Spacer height={16} />
            </>
          );
        })}
      </>
    </>
  );
}
export interface QuotesTableProps {
  quoteGroupName: string;
  groupGroupCreated: string;
  quotes: Quote[];
  selectedQuoteId: string | undefined;
  onRefresh: () => Promise<void>;
  data: ViewShipmentDataForApollo;
}

function QuotesTable(props: QuotesTableProps) {
  const hasDifferences = props.quotes.some(
    (q) =>
      getQuoteShipmentKeyDifferences(q, props.data.shipment.shipment)
        .totalDifferencesCount > 0,
  );

  const columns: DataTableColumn<Quote>[] = [
    {
      title: "Visible",
      key: "hiddenFromCustomer",
      render: function (q) {
        if (q.hiddenFromCustomer) {
          return <EyeInvisibleTwoTone twoToneColor="#ccc" />;
        } else {
          return <EyeTwoTone />;
        }
      },
    },
    {
      title: "Selected?",
      key: "quoteId",
      render: function (q) {
        if (q.quoteId === props.selectedQuoteId) {
          return <>✅</>;
        } else {
          return <></>;
        }
      },
    },
    {
      title: "Differences",
      key: "differences",
      render: function (q: Quote) {
        return (
          <QuoteShipmentDifferencesButton
            shipment={props.data.shipment.shipment}
            quote={q}
          />
        );
      },
    },
    {
      title: "Price",
      key: "price",
      render: (o: Quote) =>
        props.data.shipment.company.currency === CurrencyCode.Cad ? (
          <Price currency={CurrencyCode.Cad}>{o.priceCad}</Price>
        ) : (
          <Price currency={CurrencyCode.Usd}>{o.priceUsd}</Price>
        ),
    },
    {
      title: "Carrier Price",
      key: "carrier-price",
      render: (o: Quote) => (
        <Price currency={CurrencyCode.Cad}>{o.quotedPriceByCarrierCad}</Price>
      ),
    },
    {
      title: "Margin",
      key: "margin",
      render: function (quote: Quote) {
        return (
          <Percentage colored>
            {quote.margin
              ? quote.margin / 100
              : calculateMargin(
                  quote.priceCad!,
                  quote.quotedPriceByCarrierCad!,
                )}
          </Percentage>
        );
      },
    },
    {
      title: "Carrier",
      key: "carrierIdentifier",
      render: (o) => (
        <CarrierLogo
          carrierIdentifier={o.carrierIdentifier}
          brokeredCarrierIdentifier={o.brokeredCarrierIdentifier}
          width={40}
          height={20}
        />
      ),
    },
    {
      title: "Service",
      key: "serviceIdentifier",
      render: (o) => <div>{o.serviceIdentifier}</div>,
    },
    {
      title: "Quote #",
      key: "carrierQuoteReference",
      render: (quote) => (
        <div>
          <Tooltip
            placement="left"
            title={`Method : ${quote.quoteGenerationMethod}`}
          >
            <span>
              <EmojiForQuoteGenerationMethod
                quoteGenerationMethod={quote.quoteGenerationMethod}
              />
            </span>
          </Tooltip>{" "}
          {quote.carrierQuoteReference}
        </div>
      ),
    },
    {
      title: "Transit",
      key: "transit",
      render: (quote: Quote) => (
        <div>
          {quote.transitBusinessDays}-{quote.latestTransitBusinessDays}
        </div>
      ),
    },
    {
      show: false,
      title: "Transit Source",
      key: "transitTimeSource",
      render: (quote: Quote) => (
        <TransitTimeSourceTag
          source={quote.transitTimeSource}
          notes={quote.transitTimeNotes}
        />
      ),
    },
    {
      title: "Earliest",
      key: "expectedDeliveryDate",
      render: function (q) {
        if (q.expectedDeliveryDate === undefined) {
          return "Unknown";
        }
        return <div>{q.expectedDeliveryDate}</div>;
      },
    },
    {
      title: "Latest",
      key: "latestEstimatedDeliveryDate",
      render: function (q) {
        if (q.latestEstimatedDeliveryDate === undefined) {
          return "Unknown";
        } else {
          return <div>{q.latestEstimatedDeliveryDate}</div>;
        }
      },
    },
    {
      title: "Notes",
      key: "notes",
      render: function (quote: Quote) {
        const hasNotes = quote.customerVisibleNote || quote.internalNote;
        return hasNotes ? (
          <Tooltip
            title={
              <Stack align="left">
                {quote.customerVisibleNote && (
                  <>
                    <Text style={{ color: "#fff" }} strong>
                      Customer Visible Note:
                    </Text>
                    <Text style={{ color: "#fff" }}>
                      {quote.customerVisibleNote}
                    </Text>
                  </>
                )}
                {quote.internalNote && (
                  <>
                    <Text style={{ color: "#fff" }} strong>
                      Internal Note:
                    </Text>
                    <Text style={{ color: "#fff" }}>{quote.internalNote}</Text>
                  </>
                )}
              </Stack>
            }
          >
            <MessageOutlined
              style={{
                fontSize: "16px",
              }}
            />
          </Tooltip>
        ) : (
          <MessageOutlined style={{ color: "transparent", fontSize: "16px" }} />
        );
      },
      width: 60,
    },
    {
      title: "Actions",
      key: "actions",
      render: function (quote: Quote) {
        return (
          <ButtonRow>
            {" "}
            <ViewQuoteButton quoteId={quote.quoteId!} />
            <ExtrasMenu
              quote={quote}
              onRefresh={props.onRefresh}
              data={props.data}
            />
          </ButtonRow>
        );
      },
    },
  ];

  return (
    <>
      <Spacer height={24} />
      <HorizontalStack align="spread" verticalAlign="top">
        <Stack align="left">
          <Title level={5}>
            <ConditionalWarningComponent condition={hasDifferences} />
            {props.quoteGroupName}
          </Title>
          <div
            style={{
              color: Colors.LightText,
              fontSize: "10px",
            }}
          >
            Created: {props.groupGroupCreated}
          </div>
        </Stack>
        <ButtonRow>
          <DeleteEntireQuoteGroupButton {...props} />
          <MakeEntireQuoteGroupHiddenButton {...props} />
          <MakeEntireQuoteGroupVisibleButton {...props} />
        </ButtonRow>
      </HorizontalStack>
      <Spacer height={24} />
      <DataTable
        pagination={false}
        columns={columns}
        data={props.quotes.sort(function (q1: Quote, q2: Quote) {
          if (q1.priceCad === undefined || q2.priceCad === undefined) {
            return 1;
          }
          return q1.priceCad - q2.priceCad;
        })}
      />
    </>
  );
}

export function EmailQuotesRequestsSection(props: TabProps) {
  const [hideRejected, setHideRejected] = useState(true);

  if (!props.shipmentData.manageQuotesTab.manualQuoting) {
    return <></>;
  }

  const columns: DataTableColumn<EmailQuoteRequestAndResponses>[] = [
    {
      title: "Carrier",
      render: (o) => (
        <CarrierLogo
          carrierIdentifier={o.request.carrierIdentifier!}
          brokeredCarrierIdentifier={undefined}
          width={40}
          height={30}
        />
      ),
    },
    {
      title: "State",
      render: function (o) {
        const unreadMessages = o.responses.filter((o) => o.read === false);
        return (
          <EmailQuoteRequestStateTag
            status={o.request.state!}
            unreadCount={unreadMessages.length}
          />
        );
      },
    },
    {
      title: "Created At",
      render: (o) => <CreatedAt timestamp={o.request.createdAt!} />,
    },
    {
      title: "To",
      render: (o) => <div>{o.request.toAddresses?.join(", ")}</div>,
    },
    {
      title: "Actions",
      render: (o) => (
        <ButtonRow>
          <ViewEmailQuoteResponsesButton
            emailQuoteRequestAndResponses={o}
            state={o.request.state!}
            {...props}
          />
        </ButtonRow>
      ),
    },
  ];

  let filtered = props.shipmentData.manageQuotesTab.emailQuoteRequests.sort(
    function (a, b) {
      return (
        b.responses.filter((o) => o.read === false).length -
        a.responses.filter((o) => o.read === false).length
      );
    },
  );

  if (hideRejected) {
    filtered = filtered.filter(function (eqr) {
      return (
        eqr.request.state !== EmailQuoteRequestState.PriceTooHigh &&
        eqr.request.state !== EmailQuoteRequestState.NoService
      );
    });
  }

  let quotingTag = <></>;
  if (props.shipmentData.shipment.shipment.manualQuotingOpen) {
    quotingTag = <Tag color="purple">🦸‍♀️ Manual Quoting Open</Tag>;
  } else if (props.shipmentData.shipment.shipment.manualQuoting) {
    quotingTag = <Tag color="processing">🦸‍♀️ Manual Quoting Closed</Tag>;
  }

  return (
    <>
      <HorizontalStack align="spread">
        <HorizontalStack align="left" verticalAlign="middle">
          <Title level={5}>Email Quote Requests</Title>
          <Spacer width={10} />
          <div style={{ marginBottom: -15 }}>{quotingTag}</div>
        </HorizontalStack>

        <ButtonRow>
          <EmailForQuoteButton {...props} />
          <CloseManualQuotingButton {...props} />
          <ReopenManualQuotingButton {...props} />
          <NoQuotesNotificationEmailButton {...props} />
          <Switch
            checked={hideRejected}
            checkedChildren="Hiding Rejected"
            unCheckedChildren="Showing Rejected"
            onChange={function () {
              setHideRejected(!hideRejected);
            }}
          />
        </ButtonRow>
      </HorizontalStack>
      <HorizontalStack
        spacing={16}
        verticalAlign="top"
        style={{ marginLeft: "-16px", paddingBottom: 20 }}
      >
        <div>
          <HorizontalStack verticalAlign="top">
            <strong>Notes: </strong>
            <Spacer width={5} />
            <div style={{ maxWidth: 700 }}>
              {props.shipmentData.shipment.shipment.manualQuotingNotes}
            </div>
          </HorizontalStack>
        </div>
        <div>
          <strong>Equipment Type: </strong>
          {props.shipmentData.shipment.shipment.equipmentType}
        </div>
        <div>
          <strong>Exclusive Use: </strong>
          {props.shipmentData.shipment.shipment.exclusiveUseNeeded}
        </div>
        <div>
          <strong>Linear Feet Override: </strong>
          {props.shipmentData.shipment.shipment.linearFeetOverride}
        </div>
      </HorizontalStack>

      <Spacer height={8} />
      <DataTable pagination={false} columns={columns} data={filtered} />
    </>
  );
}

export function ManageQuotesTab(props: TabProps) {
  const [filterCarrierIdentifier, setFilterCarrierIdentifier] = useState<
    string | undefined
  >();
  return (
    <>
      <HorizontalStack align="spread">
        <ButtonRow>
          <AddQuoteGroupButton {...props} />
          <AddQuoteButton {...props} />
          <OpenForManualQuotingButton {...props} />
          <ViewQuoteLogsButton {...props} />
          <LogisticsAssistanceSupportEmailButton data={props.shipmentData} />
        </ButtonRow>
        <ButtonRow>
          <CarrierFilter
            carrierIdentifier={filterCarrierIdentifier}
            setCarrierIdentifier={setFilterCarrierIdentifier}
          />
        </ButtonRow>
      </HorizontalStack>
      <Spacer height={32} />
      <EmailQuotesRequestsSection {...props} />
      <QuotesSection
        {...props}
        filterCarrierIdentifier={filterCarrierIdentifier}
      />
    </>
  );
}
