import { WarningFilled } from "@ant-design/icons";
import Colors from "./Colors";
import { Tooltip } from "antd";
import HorizontalStack from "./HorizontalStack";

interface CardExpiryProps {
  expiry: string | null | undefined;
}

export function CardExpiry({ expiry }: CardExpiryProps) {
  const [month, year] = expiry ? expiry.split("/").map(Number) : [];
  const expiryDate = new Date(year, month, 0);
  const threeMonthsFromNow = new Date();
  threeMonthsFromNow.setMonth(threeMonthsFromNow.getMonth() + 3);

  const isExpired = expiryDate < new Date();

  if (isExpired) {
    return (
      <HorizontalStack>
        <Tooltip title="Card Expired">
          <span style={{ color: Colors.Red, marginBottom: -5, marginRight: 3 }}>
            {expiry} <WarningFilled />
          </span>
        </Tooltip>
      </HorizontalStack>
    );
  }

  if (expiryDate <= threeMonthsFromNow) {
    return (
      <Tooltip title="Expiring Soon">
        {expiry} <WarningFilled style={{ color: Colors.Gold }} />
      </Tooltip>
    );
  }

  return <div>{expiry}</div>;
}
