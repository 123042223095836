import { useSearchParams } from "react-router-dom";
import { SearchParamsStateContext } from "./SearchParamsStateContext";

export const SearchParamsStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <SearchParamsStateContext.Provider value={[searchParams, setSearchParams]}>
      {children}
    </SearchParamsStateContext.Provider>
  );
};
