import { CSSProperties, ReactNode } from "react";
import Colors from "./Colors";
import { StackProps } from "./Stack";

interface StackedInfoProps {
  label: ReactNode;
  children: ReactNode;
  align?: StackProps["align"];
  style?: CSSProperties;
  labelStyle?: CSSProperties;
  fullWidth?: boolean;
  preWrapWhiteSpace?: boolean
}

export function StackedInfo({
  label,
  children,
  style,
  labelStyle,
  fullWidth = false,
  preWrapWhiteSpace = false
}: StackedInfoProps) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: fullWidth ? "100%" : "fit-content",
        ...style,
      }}
    >
      <div
        style={{
          fontSize: 14,
          fontWeight: 400,
          marginBottom: 4,
          color: Colors.LightText,
          width: fullWidth ? "100%" : "fit-content",
          ...labelStyle,
        }}
      >
        {label}
      </div>
      <div style={{whiteSpace: preWrapWhiteSpace ? "pre-wrap" : ""}}>{children}</div>
    </div>
  );
}
