import { useState } from "react";
import { useQuoteTestApi } from "../../../../Apis/Apis";
import {
  PostQuoteTestPermutationGroupBody,
} from "@freightsimple/generated-apollo-openapi-client";

export function useCreateQuoteTestPermutations() {
  const createApi = useQuoteTestApi();

  const [isLoading, setIsLoading] = useState(false);

  const create = async (body: PostQuoteTestPermutationGroupBody) => {
    setIsLoading(() => true);
    try {
      const api = await createApi();
      await api.createPermutationGroup({
        postQuoteTestPermutationGroupBody: body,
      });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(() => false);
  };

  return { create, isLoading };
}
