import { useState } from "react";
import { useQuoteTestApi } from "../../../../Apis/Apis";
import {
  PostQuoteTestGroupBody,
  QuoteTestExecutionType,
  QuoteTestGroup,
} from "@freightsimple/generated-apollo-openapi-client";

export function useQuoteTestGroupApi() {
  const createApi = useQuoteTestApi();

  const [isLoading, setIsLoading] = useState(false);

  const createGroup = async (body: PostQuoteTestGroupBody) => {
    setIsLoading(() => true);
    try {
      const api = await createApi();
      await api.createGroup({ postQuoteTestGroupBody: body });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(() => false);
  };

  const updateGroup = async (body: QuoteTestGroup) => {
    setIsLoading(() => true);
    try {
      const api = await createApi();
      await api.updateGroup({ quoteTestGroup: body });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(() => false);
  };

  const cloneGroup = async (
    groupId: string,
    carrierIdentifier: string,
    serviceIdentifier: string,
  ) => {
    setIsLoading(() => true);
    try {
      const api = await createApi();
      await api.cloneGroup({
        groupId,
        carrierIdentifier,
        serviceIdentifier,
      });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(() => false);
  };

  const deleteGroup = async (groupId: string) => {
    setIsLoading(() => true);
    try {
      const api = await createApi();
      await api.deleteGroup({ groupId });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(() => false);
  };

  const runGroup = async (
    groupId: string,
    tags: Array<string>,
    executionType: QuoteTestExecutionType = QuoteTestExecutionType.Test,
  ) => {
    setIsLoading(() => true);
    try {
      const api = await createApi();
      await api.runGroup({ groupId, tags, executionType });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(() => false);
  };

  return { createGroup, isLoading, updateGroup, deleteGroup, runGroup, cloneGroup };
}
