import {
  SignalAlarmRules,
  SignalRuleCount,
} from "@freightsimple/generated-apollo-openapi-client";

import { Button, Form, Input, message, Modal } from "antd";
import { useState } from "react";
import { useSignalAlarmRulesApi } from "../../../../Apis/Apis";
import Stack from "../../../../Components/Stack";
import { isBlank } from "@freightsimple/shared";
import { Select } from "antd/lib";

interface AddSignalAlarmRuleButtonButtonProps {
  onRefresh: () => Promise<void>;
  signalRuleCount: SignalRuleCount[] | undefined;

  // If we're editing then set this
  signalAlarm?: SignalAlarmRules;

  // When adding from the warning table
  originalSignalName?: string;
}

export function AddSignalAlarmRuleButtonButton(
  props: AddSignalAlarmRuleButtonButtonProps,
) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [signalName, setSignalName] = useState<string | undefined>(
    props.originalSignalName ?? props.signalAlarm?.signalName ?? undefined,
  );
  const [carrierIdentifier, setCarrierIdentifier] = useState<
    string | undefined
  >(props.signalAlarm?.carrierIdentifier ?? undefined);
  const [serviceType, setServiceType] = useState<string | undefined>(
    props.signalAlarm?.serviceType ?? undefined,
  );
  const [businessDayThreshold, setBusinessDayThreshold] = useState<number>(
    props.signalAlarm?.businessDayThreshold ?? 0,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const createSignalAlarmRulesApi = useSignalAlarmRulesApi();

  const signalAlarmRulesId = props.signalAlarm?.signalAlarmRulesId;

  const showModal = () => {
    setIsModalVisible(true);
  };

  function description() {
    if (props.signalAlarm) {
      return "Edit Rule";
    } else {
      return "Add Rule";
    }
  }

  async function handleOk() {
    if (isBlank(signalName)) {
      message.warning("Please select a signal to use");
      return;
    }
    if (businessDayThreshold <= 0) {
      message.warning("The business day threshold must be at least 1");
      return;
    }
    setLoading(true);
    try {
      const signalAlarmRulesApi = await createSignalAlarmRulesApi();
      if (signalAlarmRulesId) {
        await signalAlarmRulesApi.editSignalAlarmRule({
          signalAlarmRuleInput: {
            signalAlarmRuleId: signalAlarmRulesId,
            name: signalName!,
            carrierIdentifier,
            serviceType,
            businessDayThreshold,
          },
        });
        await props.onRefresh();
        message.success(`Successfully edited rule`);
      } else {
        await signalAlarmRulesApi.insertSignalAlarmRule({
          signalAlarmRuleInput: {
            name: signalName!,
            carrierIdentifier,
            serviceType,
            businessDayThreshold,
          },
        });
        await props.onRefresh();
        message.success(`Successfully created rule`);
      }
      setIsModalVisible(false);
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }

    setLoading(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    setCarrierIdentifier(props.signalAlarm?.carrierIdentifier ?? undefined);
    setServiceType(props.signalAlarm?.serviceType ?? undefined);
    setBusinessDayThreshold(props.signalAlarm?.businessDayThreshold ?? 0);
    setSignalName(
      props.originalSignalName ?? props.signalAlarm?.signalName ?? undefined,
    );
  };

  const options = props.signalRuleCount?.map((rule) => {
    return {
      value: rule.name,
      label: rule.name,
    };
  });

  return (
    <>
      <Modal
        title={
          <Stack align="left">
            <div>{description()}</div>
          </Stack>
        }
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={800}
        confirmLoading={loading}
        okText="Save"
      >
        <Form
          style={{ width: "700px" }}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 20, offset: 2 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Signal">
            <Select
              style={{ width: "400px" }}
              value={signalName}
              onChange={(e) => {
                setSignalName(e);
              }}
              placeholder="Select a signal"
              showSearch
              allowClear
              disabled={
                !!props.originalSignalName || !!props.signalAlarm?.signalName
              }
              options={options}
            />
          </Form.Item>
          <Form.Item label="Carrier Identifier">
            <Input
              value={carrierIdentifier}
              onChange={function (e) {
                setCarrierIdentifier(e.target.value);
              }}
              style={{ width: "400px" }}
            />
          </Form.Item>
          <Form.Item label="Service Type">
            <Input
              value={serviceType}
              onChange={function (e) {
                setServiceType(e.target.value);
              }}
              style={{ width: "400px" }}
            />
          </Form.Item>
          <Form.Item label="Business Days Threshold">
            <Input
              type="number"
              value={businessDayThreshold}
              onChange={function (e) {
                setBusinessDayThreshold(e.target.valueAsNumber);
              }}
              style={{ width: "400px" }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Button onClick={showModal}>{description()}</Button>
    </>
  );
}
