import { useEffect, useState } from "react";
import { useQuoteTestApi } from "../../../../Apis/Apis";
import { QuoteTestScenario } from "@freightsimple/generated-apollo-openapi-client";

export function useBaseScenario(baseScenario: QuoteTestScenario | null | undefined) {
  const createApi = useQuoteTestApi();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<QuoteTestScenario | null>(null);

  async function fetch() {
    if (baseScenario) return;

    setIsLoading(() => true);
    try {
      const api = await createApi();
      const response = await api.getBasicScenario();
      setData(response);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(() => false);
  }

  useEffect(() => {
    fetch();
  }, []);

  if (baseScenario) return { data: baseScenario, isLoading: false };

  return { data, isLoading };
}
