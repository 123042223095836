import {
  ApolloMapCompany,
  ApolloMapQuery,
  QuoteFilterType,
} from "@freightsimple/generated-apollo-openapi-client";

import {
  BankOutlined,
  BoxPlotOutlined,
  CalendarOutlined,
  CarOutlined,
  CloseOutlined,
  DashboardOutlined,
  EnvironmentOutlined,
  FieldTimeOutlined,
  FileSearchOutlined,
  FilterOutlined,
  ReloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Tag, Tooltip } from "antd";
import HorizontalStack from "../../Components/HorizontalStack";
import { describeState } from "../../Helpers/describeState";
import { emojiForShipmentVolume } from "../../Helpers/emojiForShipmentVolume";
import { isNotBlank } from "@freightsimple/shared";
import { isNotEmpty } from "../../Helpers/isEmpty";
import {
  describeInvoiceAuditProblemFault,
  describeInvoiceAuditProblemType,
  describeInvoiceAuditResolutionState,
  describeInvoiceAuditResolutionType,
} from "../../Helpers/describeInvoiceAudit";
import { useEffect, useState } from "react";
import Spacer from "../../Spacer";
import { nameLocationType } from "../../Helpers/locationTypes";

interface AllShipmentsScreenActiveFiltersProps {
  query: ApolloMapQuery;
  setQuery: (_: ApolloMapQuery) => void;
  companies: ApolloMapCompany[] | undefined;
}

export function AllShipmentsScreenActiveFilters(
  props: AllShipmentsScreenActiveFiltersProps,
) {
  const { companies, query } = props;

  const [localQuery, setLocalQuery] = useState({ ...query });

  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    setLocalQuery({ ...query });
  }, [query]);

  const filters = [
    ...generalFilters(localQuery),
    ...companyFilters(localQuery, companies),
    ...locationFilters(localQuery),
    ...shipmentDetailsFilters(localQuery),
    ...invoiceAuditsFilters(localQuery),
    ...carrierOptionsFilters(localQuery),
    ...accessorialsFilters(localQuery),
  ];

  function handleOnDelete(filter: FilterTagElementProps) {
    if (filter.filterKeys == null) {
      return;
    }
    setIsModified(true);
    setLocalQuery((prev) => {
      const copy = { ...prev };
      if (Array.isArray(filter.filterKeys)) {
        filter.filterKeys.forEach((key) => delete copy[key]);
      } else {
        delete copy[filter.filterKeys!];
      }
      return copy;
    });
  }

  function handleReload() {
    props.setQuery(localQuery);
    setIsModified(false);
  }

  return (
    <div
      style={{
        overflowX: "auto",
        width: "100%",
        marginLeft: "8px",
        marginRight: "16px",
        position: "relative",
      }}
    >
      <div style={{ overflowX: "auto", scrollbarWidth: "none" }}>
        <HorizontalStack style={{ marginLeft: "8px" }} spacing={8}>
          {isModified && (
            <>
              {" "}
              <Button
                type="primary"
                onClick={handleReload}
                size="small"
                icon={<ReloadOutlined />}
              >
                Apply
              </Button>
              <Spacer width={8} />
            </>
          )}
          {filters.map((filter, i) => (
            <FilterTag
              key={i}
              {...filter}
              onDelete={() => {
                handleOnDelete(filter);
              }}
            />
          ))}
        </HorizontalStack>
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "20px",
          height: "100%",
          background: "linear-gradient(to right, transparent, white)",
          pointerEvents: "none",
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "20px",
          height: "100%",
          background: "linear-gradient(to left, transparent, white)",
          pointerEvents: "none",
        }}
      />
    </div>
  );
}

interface FilterTagProps {
  icon?: React.ReactNode;
  label: string;
  color?: string;
  filterKeys: (keyof ApolloMapQuery)[] | keyof ApolloMapQuery | null;
  onDelete: () => void;
}

function FilterTag({
  icon,
  label,
  color = "#108ee9",
  filterKeys,
  onDelete,
}: FilterTagProps) {
  return (
    <Tag
      color={color}
      style={{ display: "flex", alignItems: "center", gap: "4px" }}
    >
      {icon}
      <span>{label}</span>
      {filterKeys != null && (
        <Tooltip title="Remove filter">
          <CloseOutlined color="red" onClick={onDelete} />
        </Tooltip>
      )}
    </Tag>
  );
}

function formatWeight(weight: number): string {
  return `${weight.toLocaleString()} lbs`;
}

function formatVolume(volume: number): string {
  return `${volume.toLocaleString()} ft³`;
}

type FilterTagElementProps = Omit<FilterTagProps, "onDelete">;

function generalFilters(query: ApolloMapQuery): FilterTagElementProps[] {
  const filters: FilterTagElementProps[] = [];
  if (isNotBlank(query.freeTextQuery)) {
    filters.push({
      icon: <SearchOutlined />,
      label: `Search: ${query.freeTextQuery}`,
      color: "default",
      filterKeys: "freeTextQuery",
    });
  }

  if (query.bookingStatus) {
    filters.push({
      icon: <FilterOutlined />,
      label: `Booking Status: ${query.bookingStatus}`,
      color: "default",
      filterKeys: null,
    });
  }

  if (query.shipmentState) {
    filters.push({
      icon: <DashboardOutlined />,
      label: `State: ${describeState(query.shipmentState)}`,
      color: "blue",
      filterKeys: "shipmentState",
    });
  }

  if (query.quoteFilter) {
    filters.push({
      icon: <DashboardOutlined />,
      label: `Quote Filter: ${QuoteFilterType[query.quoteFilter]}`,
      color: "green",
      filterKeys: "quoteFilter",
    });
  }

  if (query.startDate && query.endDate) {
    const startDate = new Date(query.startDate).toLocaleDateString();
    const endDate = new Date(query.endDate).toLocaleDateString();
    filters.push({
      icon: <CalendarOutlined />,
      label: `${startDate} - ${endDate}`,
      color: "cyan",
      filterKeys: null,
    });
  }

  return filters;
}

function companyFilters(
  query: ApolloMapQuery,
  companies: ApolloMapCompany[] | undefined,
): FilterTagElementProps[] {
  function lookupCompany() {
    if (companies === undefined) {
      return "Loading";
    }
    const foundCompany = companies.find(function (c) {
      return c.companyId === query.companyId;
    });

    if (foundCompany === undefined) {
      return "Unknown";
    }

    return foundCompany?.companyName;
  }
  const filters: FilterTagElementProps[] = [];

  if (query.companyId) {
    const queryCompanyName = query.companyId ? lookupCompany() : undefined;
    filters.push({
      icon: <BankOutlined />,
      label: `Company: ${queryCompanyName}`,
      color: "purple",
      filterKeys: "companyId",
    });
  }

  if (query.companyShipmentFrequency) {
    filters.push({
      icon: <FieldTimeOutlined />,
      label: `Frequency: ${emojiForShipmentVolume(
        query.companyShipmentFrequency,
      )} ${query.companyShipmentFrequency}`,
      color: "geekblue",
      filterKeys: "companyShipmentFrequency",
    });
  }

  if (
    query.companyFirstQuotedDateStartDate &&
    query.companyFirstQuotedDateEndDate
  ) {
    const startDate = new Date(
      query.companyFirstQuotedDateStartDate,
    ).toLocaleDateString();
    const endDate = new Date(
      query.companyFirstQuotedDateEndDate,
    ).toLocaleDateString();
    filters.push({
      icon: <FieldTimeOutlined />,
      label: `First Quoted: ${startDate} - ${endDate}`,
      color: "cyan",
      filterKeys: [
        "companyFirstQuotedDateStartDate",
        "companyFirstQuotedDateEndDate",
      ],
    });
  }
  return filters;
}

function locationFilters(query: ApolloMapQuery): FilterTagElementProps[] {
  const filters: FilterTagElementProps[] = [];

  if (query.geoFilter) {
    filters.push({
      icon: <FilterOutlined />,
      label: `GeoFilter: ${query.geoFilter}`,
      color: "cyan",
      filterKeys: "geoFilter",
    });
  }

  if (isNotEmpty(query.pickupLocationFilterIdentifiers)) {
    filters.push({
      icon: <FilterOutlined />,
      label: `Pickup Location: ${query.pickupLocationFilterIdentifiers?.join(", ")}`,
      color: "cyan",
      filterKeys: "pickupLocationFilterIdentifiers",
    });
  }

  if (isNotEmpty(query.deliveryLocationFilterIdentifiers)) {
    filters.push({
      icon: <FilterOutlined />,
      label: `Delivery Location: ${query.deliveryLocationFilterIdentifiers?.join(", ")}`,
      color: "blue",
      filterKeys: "deliveryLocationFilterIdentifiers",
    });
  }

  if (query.pickupCity || query.deliveryCity) {
    const locationText = [
      query.pickupCity && `From: ${query.pickupCity}`,
      query.deliveryCity && `To: ${query.deliveryCity}`,
    ]
      .filter(Boolean)
      .join(" → ");

    filters.push({
      icon: <EnvironmentOutlined />,
      label: locationText,
      color: "blue",
      filterKeys: ["pickupCity", "deliveryCity"],
    });
  }

  if (query.pickupLocationType || query.deliveryLocationType) {
    const locationTypeText = [
      query.pickupLocationType &&
        `Pickup: ${nameLocationType(query.pickupLocationType, query.pickupDistributionWarehouseBrand)}`,
      query.deliveryLocationType &&
        `Delivery: ${nameLocationType(query.deliveryLocationType, query.deliveryDistributionWarehouseBrand)}`,
    ]
      .filter(Boolean)
      .join(", ");

    filters.push({
      icon: <DashboardOutlined />,
      label: `Location Types: ${locationTypeText}`,
      color: "cyan",
      filterKeys: [
        "pickupLocationType",
        "pickupDistributionWarehouseBrand",
        "deliveryLocationType",
        "deliveryDistributionWarehouseBrand",
      ],
    });
  }

  return filters;
}

function shipmentDetailsFilters(
  query: ApolloMapQuery,
): FilterTagElementProps[] {
  const filters: FilterTagElementProps[] = [];
  if (query.minimumShipmentWeight || query.maximumShipmentWeight) {
    const weightText = [
      query.minimumShipmentWeight &&
        `Min: ${formatWeight(query.minimumShipmentWeight)}`,
      query.maximumShipmentWeight &&
        `Max: ${formatWeight(query.maximumShipmentWeight)}`,
    ]
      .filter(Boolean)
      .join(", ");

    filters.push({
      icon: <BoxPlotOutlined />,
      label: `Weight: ${weightText}`,
      color: "magenta",
      filterKeys: ["minimumShipmentWeight", "maximumShipmentWeight"],
    });
  }

  if (query.minimumNumberHandlingUnits || query.maximumNumberHandlingUnits) {
    const unitsText = [
      query.minimumNumberHandlingUnits &&
        `Min: ${query.minimumNumberHandlingUnits}`,
      query.maximumNumberHandlingUnits &&
        `Max: ${query.maximumNumberHandlingUnits}`,
    ]
      .filter(Boolean)
      .join(", ");

    filters.push({
      icon: <BoxPlotOutlined />,
      label: `Units: ${unitsText}`,
      color: "magenta",
      filterKeys: ["minimumNumberHandlingUnits", "maximumNumberHandlingUnits"],
    });
  }

  if (query.minimumVolume || query.maximumVolume) {
    const volumeText = [
      query.minimumVolume && `Min: ${formatVolume(query.minimumVolume)}`,
      query.maximumVolume && `Max: ${formatVolume(query.maximumVolume)}`,
    ]
      .filter(Boolean)
      .join(", ");

    filters.push({
      icon: <BoxPlotOutlined />,
      label: `Volume: ${volumeText}`,
      color: "magenta",
      filterKeys: ["minimumVolume", "maximumVolume"],
    });
  }

  if (
    query.minimumStandardDryVanLinearFeet ||
    query.maximumStandardDryVanLinearFeet
  ) {
    const standardDryVanLinearFeetText = [
      query.minimumStandardDryVanLinearFeet &&
        `Min: ${formatVolume(query.minimumStandardDryVanLinearFeet)}`,
      query.maximumStandardDryVanLinearFeet &&
        `Max: ${formatVolume(query.maximumStandardDryVanLinearFeet)}`,
    ]
      .filter(Boolean)
      .join(", ");

    filters.push({
      icon: <BoxPlotOutlined />,
      label: `Linear Feet: ${standardDryVanLinearFeetText}`,
      color: "magenta",
      filterKeys: [
        "minimumStandardDryVanLinearFeet",
        "maximumStandardDryVanLinearFeet",
      ],
    });
  }
  return filters;
}

function invoiceAuditsFilters(query: ApolloMapQuery): FilterTagElementProps[] {
  const filters: FilterTagElementProps[] = [];

  const base: Partial<FilterTagElementProps> = {
    icon: <FileSearchOutlined />,
    color: "magenta",
  };

  if (query.invoiceAuditEverOpened) {
    filters.push({
      ...base,
      label: `Invoice Audit Ever Opened: ${query.invoiceAuditEverOpened ? "Yes" : "No"}`,
      filterKeys: "invoiceAuditEverOpened",
    });
  }

  if (query.invoiceAuditCurrentlyOpen) {
    filters.push({
      ...base,
      label: `Invoice Audit Currently Opened: ${query.invoiceAuditCurrentlyOpen ? "Yes" : "No"}`,
      filterKeys: "invoiceAuditCurrentlyOpen",
    });
  }

  if (isNotEmpty(query.invoiceAuditType)) {
    filters.push({
      ...base,
      label: `Invoice Audit Type: ${query.invoiceAuditType?.map(describeInvoiceAuditProblemType).join(", ")}`,
      filterKeys: "invoiceAuditType",
    });
  }

  if (isNotEmpty(query.invoiceAuditProblemFault)) {
    filters.push({
      ...base,
      label: `Invoice Audit Fault: ${query.invoiceAuditProblemFault?.map(describeInvoiceAuditProblemFault).join(", ")}`,
      filterKeys: "invoiceAuditProblemFault",
    });
  }

  if (isNotEmpty(query.invoiceAuditResolutionType)) {
    filters.push({
      ...base,
      label: `Invoice Audit Resolution Type: ${query.invoiceAuditResolutionType?.map(describeInvoiceAuditResolutionType).join(", ")}`,
      filterKeys: "invoiceAuditResolutionType",
    });
  }

  if (isNotEmpty(query.invoiceAuditResolutionState)) {
    filters.push({
      ...base,
      label: `Invoice Audit Resolution State: ${query.invoiceAuditResolutionState?.map(describeInvoiceAuditResolutionState).join(", ")}`,
      filterKeys: "invoiceAuditResolutionState",
    });
  }

  return filters;
}

function carrierOptionsFilters(query: ApolloMapQuery): FilterTagElementProps[] {
  const filters: FilterTagElementProps[] = [];

  if (query.inCarrierCoverageAreaCarrierIdentifier) {
    filters.push({
      icon: <CarOutlined />,
      label: `Carrier Coverage: ${query.inCarrierCoverageAreaCarrierIdentifier}`,
      color: "orange",
      filterKeys: "inCarrierCoverageAreaCarrierIdentifier",
    });
  }

  if (query.bookedCarrierIdentifier) {
    filters.push({
      icon: <CarOutlined />,
      label: `Booked Carrier: ${query.bookedCarrierIdentifier}`,
      color: "orange",
      filterKeys: "bookedCarrierIdentifier",
    });
  }

  if (query.hadQuotesFromCarrierIdentifier) {
    filters.push({
      icon: <CarOutlined />,
      label: `Has Quotes: ${query.hadQuotesFromCarrierIdentifier}`,
      color: "orange",
      filterKeys: "hadQuotesFromCarrierIdentifier",
    });
  }

  if (query.lowestRateCarrierIdentifier) {
    filters.push({
      icon: <CarOutlined />,
      label: `Lowest Rate: ${query.lowestRateCarrierIdentifier}`,
      color: "orange",
      filterKeys: "lowestRateCarrierIdentifier",
    });
  }

  return filters;
}

function accessorialsFilters(query: ApolloMapQuery): FilterTagElementProps[] {
  const filters: FilterTagElementProps[] = [];

  if (query.pickupLiftGateRequired !== undefined) {
    filters.push({
      icon: <BoxPlotOutlined />,
      label: `Pickup Liftgate: ${query.pickupLiftGateRequired ? "Required" : "Not Required"}`,
      color: query.pickupLiftGateRequired ? "orange" : "red",
      filterKeys: "pickupLiftGateRequired",
    });
  }

  if (query.deliveryLiftGateRequired !== undefined) {
    filters.push({
      icon: <BoxPlotOutlined />,
      label: `Delivery Liftgate: ${query.deliveryLiftGateRequired ? "Required" : "Not Required"}`,
      color: query.deliveryLiftGateRequired ? "orange" : "red",
      filterKeys: "deliveryLiftGateRequired",
    });
  }

  if (query.pickupInsideRequired !== undefined) {
    filters.push({
      icon: <BoxPlotOutlined />,
      label: `Inside Pickup: ${query.pickupInsideRequired ? "Required" : "Not Required"}`,
      color: query.pickupInsideRequired ? "orange" : "red",
      filterKeys: "pickupInsideRequired",
    });
  }

  if (query.deliveryInsideRequired !== undefined) {
    filters.push({
      icon: <BoxPlotOutlined />,
      label: `Inside Delivery: ${query.deliveryInsideRequired ? "Required" : "Not Required"}`,
      color: query.deliveryInsideRequired ? "orange" : "red",
      filterKeys: "deliveryInsideRequired",
    });
  }

  if (query.dangerous !== undefined) {
    filters.push({
      icon: <BoxPlotOutlined />,
      label: `Dangerous Goods: ${query.dangerous ? "Yes" : "No"}`,
      color: query.dangerous ? "orange" : "red",
      filterKeys: "dangerous",
    });
  }

  if (query.protectFromFreezingRequired !== undefined) {
    filters.push({
      icon: <BoxPlotOutlined />,
      label: `Protect from Freezing: ${query.protectFromFreezingRequired ? "Required" : "Not Required"}`,
      color: query.protectFromFreezingRequired ? "orange" : "red",
      filterKeys: "protectFromFreezingRequired",
    });
  }
  return filters;
}
