import { QuoteTestGroupDto } from "@freightsimple/generated-apollo-openapi-client";
import { useFetchQuoteTestScenario } from "../Hooks/useFetchQuoteTestScenarios";
import { Badge, Button, Card, Divider, Empty, Popover } from "antd";
import { StackedInfo } from "../../../../Components/StackedInfo";
import Stack from "../../../../Components/Stack";
import { QuoteTestExecutionCommentList } from "./QuoteTestExecutionCommentList";
import { QuoteTestDashboardProps } from "../QuoteTestDashboard";
import { QuoteTestExpectationComponent } from "./QuoteTestExpectation";
import {
  CloseOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { QuoteTestScenarioInfo } from "./QuoteTestScenarioInfo";
import { useDisplay } from "../../../../Hooks/useDisplay";
import { QuoteTestScenarioDetails } from "./QuoteTestScenarioDetails";
import { RefreshFunction } from "../../../../Types/RefreshFunctionType";
import HorizontalStack from "../../../../Components/HorizontalStack";

interface QuoteTestScenarioViewProps extends QuoteTestDashboardProps {
  scenarioId?: string | null;
  executionId: string | null;
  setExecutionId: (_: string | null) => void;
  group: QuoteTestGroupDto;
  groups: Array<QuoteTestGroupDto>;
  groupRefresh: RefreshFunction;
}
export function QuoteTestScenarioView(props: QuoteTestScenarioViewProps) {
  const {
    scenarioId,
    group,
    groups,
    groupRefresh,
    executionId,
    setExecutionId,
  } = props;

  const groupExpectations = group.expectations;

  const displayPinnedInfo = useDisplay(false);

  const displayScenarioInfo = useDisplay();

  const { data: scenario, refresh } = useFetchQuoteTestScenario(
    scenarioId,
    (scenario) => {
      if (scenario.executions.length > 0) {
        const execution = scenario.executions[scenario.executions.length - 1];
        setExecutionId(execution.executionId!);
      } else {
        setExecutionId(null);
      }
    },
  );

  if (!scenario) {
    return <Empty />;
  }

  const pinnedComments = scenario.executions
    .flatMap((e) => e.comments)
    .filter((c) => c.isPinned);

  const importantInfoCount =
    pinnedComments.length +
    groupExpectations.length +
    scenario.expectations.length;

  const scenarioPopover = (
    <Popover
      open={displayScenarioInfo.status}
      content={
        <Stack
          style={{
            padding: 12,
            gap: 24,
            position: "relative",
            maxWidth: "60vw",
          }}
          align="left"
        >
          <Button
            style={{ position: "absolute", right: 0, top: 0 }}
            type="text"
            icon={<CloseOutlined />}
            onClick={displayScenarioInfo.hide}
          />
          <QuoteTestScenarioInfo scenario={scenario} />
        </Stack>
      }
      trigger="click"
      placement="bottomRight"
    >
      <Button
        icon={<InfoCircleOutlined />}
        onClick={displayScenarioInfo.toggle}
      />
    </Popover>
  );

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: displayPinnedInfo.status
          ? "calc(100vw - 1000px) 1fr"
          : "calc(100vw - 450px) 1fr",
        gap: 16,
      }}
    >
      <section>
        <QuoteTestScenarioDetails
          {...props}
          executionId={executionId}
          setExecutionId={setExecutionId}
          scenario={scenario}
          refresh={refresh}
          groupRefresh={groupRefresh}
          groups={groups}
        />
      </section>
      <aside style={{ position: "relative" }}>
        <div style={{ position: "sticky", top: 16 }}>
          {!displayPinnedInfo.status ? (
            <Stack style={{ gap: 12 }}>
              <Badge count={importantInfoCount}>
                <Button
                  icon={<LeftOutlined />}
                  onClick={displayPinnedInfo.show}
                />
              </Badge>
              {scenarioPopover}
            </Stack>
          ) : (
            <Card
              title={"Important Info"}
              extra={
                <HorizontalStack style={{ gap: 8 }}>
                  {scenarioPopover}
                  <Button
                    icon={<RightOutlined />}
                    onClick={displayPinnedInfo.hide}
                  />
                </HorizontalStack>
              }
            >
              <StackedInfo
                label="Pinned Comments"
                labelStyle={{ marginBottom: 16 }}
              >
                <QuoteTestExecutionCommentList
                  comments={pinnedComments}
                  refresh={refresh}
                  setExecutionId={setExecutionId}
                  showGoToExecution
                />
              </StackedInfo>
              <Divider />
              <StackedInfo
                label="Group Expectations"
                labelStyle={{ marginBottom: 16 }}
              >
                <div style={{ gap: 20 }}>
                  {groupExpectations.map((e) => (
                    <QuoteTestExpectationComponent
                      refresh={refresh}
                      key={e.expectationId!}
                      expectation={e}
                    />
                  ))}
                </div>
              </StackedInfo>
              <Divider />
              <StackedInfo
                label="Scenario Expectations"
                labelStyle={{ marginBottom: 16 }}
              >
                <Stack style={{ gap: 20 }} align="left">
                  {scenario.expectations.map((e) => (
                    <QuoteTestExpectationComponent
                      refresh={refresh}
                      key={e.expectationId!}
                      expectation={e}
                    />
                  ))}
                </Stack>
              </StackedInfo>
            </Card>
          )}
        </div>
      </aside>
    </div>
  );
}
