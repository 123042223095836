import { SalesContact } from "@freightsimple/generated-apollo-openapi-client";
import { Select, Tag } from "antd";
import styled from "styled-components";
import Colors from "../../Components/Colors";
import Stack from "../../Components/Stack";
import Admins from "../../GeneratedFixtures/Admins.json";

interface ManageRecipientsDropdownProps {
  contacts: SalesContact[];
  selectedSalesContactEmails?: string[];
  setSelectedSalesContactEmails: (_: string[]) => void;
  otherEmails: string[];
  setOtherEmails: (_: string[]) => void;
}

const ContactName = styled.div``;
const ContactEmail = styled.div`
  color: ${Colors.LightText};
  font-size: 11px;
  margin-top: -5px;
`;

const otherEmails = [
  {
    name: "Support",
    emailAddress: "support@freightsimple.com",
  },
  {
    name: "Claims",
    emailAddress: "claims@freightsimple.com",
  },

  {
    name: "Billing",
    emailAddress: "billing@freightsimple.com",
  },
];

// This function customizes the appearance of tags in the dropdown.
// It colors the tag blue if the value does not include "freightsimple".
const tagRender = (props: any) => {
  const { value, closable, onClose } = props;

  const isContact = () => {
    return !value?.includes("freightsimple");
  };

  return (
    <Tag
      color={isContact() ? "blue" : ""}
      closable={closable}
      onClose={onClose}
      style={{
        display: "flex",
        gap: 5,
      }}
    >
      {value}
    </Tag>
  );
};

export function ManageRecipientsDropdown(props: ManageRecipientsDropdownProps) {
  const { contacts, setSelectedSalesContactEmails, setOtherEmails } = props;

  const salesContactsOptions = contacts.flatMap((contact) => {
    if (contact.email!.trim() !== "") {
      return [
        {
          label: (
            <Stack align="left">
              <ContactName>{contact.name ?? "Unknown"}</ContactName>
              <ContactEmail>{contact.email}</ContactEmail>
            </Stack>
          ),
          value: contact.email,
        },
      ];
    } else {
      return [
        {
          label: (
            <Select.Option
              key={contact.salesContactId}
              value={contact.salesContactId}
            >
              {contact.name} <Tag color="red">Missing Email</Tag>
            </Select.Option>
          ),
          value: contact.email,
        },
      ];
    }
  });

  const freightSimpleTeamContactOptions = Admins.flatMap((contact) => {
    return [
      {
        label: <ContactName>{contact.fullName}</ContactName>,
        value: contact.emailAddress,
      },
    ];
  });
  const otherOptions = otherEmails.flatMap((contact) => {
    return [
      {
        label: <ContactName>{contact.name}</ContactName>,
        value: contact.emailAddress,
      },
    ];
  });

  const handleChange = (value: string[]) => {
    const salesContactEmail = value.filter((v) => !v.includes("freightsimple"));
    const freightSimpleTeamEmails = value.filter((v) =>
      v.includes("freightsimple"),
    );
    setSelectedSalesContactEmails(salesContactEmail);
    setOtherEmails(freightSimpleTeamEmails);
  };

  return (
    <Select
      allowClear
      tagRender={tagRender}
      mode="tags"
      style={{ width: "100%", minWidth: 320 }}
      dropdownStyle={{ maxWidth: 320 }}
      placeholder="Select Recipients"
      onChange={handleChange}
      options={[
        {
          label: <span>Sales Contacts</span>,
          title: "Sales Contacts",
          options: salesContactsOptions,
        },
        {
          label: <span>FreightSimple Team</span>,
          title: "FreightSimple Team",
          options: freightSimpleTeamContactOptions,
        },
        {
          label: <span>Other</span>,
          title: "Other",
          options: otherOptions,
        },
      ]}
    />
  );
}
