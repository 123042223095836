import {
  CarrierInvoice,
  DocumentType,
  InvoiceAuditProblem,
  InvoiceAuditProblemConfidence,
  InvoiceAuditProblemFault,
  InvoiceAuditProblemResolutionState,
  InvoiceAuditProblemResolutionType,
  InvoiceAuditProblemType,
  ShipmentDocument,
} from "@freightsimple/generated-apollo-openapi-client";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Select,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { useShipmentsApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import Colors from "../../Components/Colors";
import { ConfirmMenuItem } from "../../Components/ConfirmButton";
import { DataTable, DataTableColumn } from "../../Components/DataTable";
import HorizontalStack from "../../Components/HorizontalStack";
import Stack from "../../Components/Stack";
import { ViewCompanyButton } from "../../Components/ViewCompanyButton";
import { WarningComponent } from "../../Components/WarningComponent";
import { assertNever } from "../../Helpers/assertNever";
import { isBlank } from "@freightsimple/shared";
import Spacer from "../../Spacer";
import { shipmentInfoMessageForInvoiceAuditType } from "../shipmentInfoMessageForInvoiceAuditType";
import {
  ListDocuments,
  ManageAttachmentsButton,
} from "../ViewCompanyScreenComponents/ManageAttachmentsButton";
import { CommonCarrierProblemsSection } from "./CommonCarrierProblemsSection";
import { CommonCompanyProblemsSection } from "./CommonCompanyProblemsSection";
import { CommonProblemsForProblemTypeSection } from "./CommonProblemsForProblemTypeSection";
import { CompanyInvoiceAuditHistory } from "./CompanyInvoiceAuditHistory";
import { OptionalEnumDropdown } from "./EnumDropdown";
import { colorForInvoiceAuditProblemResolutionState } from "./colorForInvoiceAuditProblemResolutionState";
import { emojiForInvoiceAuditProblemFault } from "./emojiForInvoiceAuditProblemFault";
import { describeInvoiceAuditConfidence } from "./describeInvoiceAuditConfidence";
import { InvoiceAuditProblemTypeDropdown } from "./InvoiceAuditProblemTypeDropdown";
import { LocationTypeDropdown } from "./LocationTypeDropdown";
import { QuickViewCarrierInvoiceButton } from "./QuickViewCarrierInvoiceButton";
import { TabProps } from "./TabProps";
import { ViewEmailConversationsButton } from "./ViewEmailConversationsButton";
import { NumberInput } from "../../Components/NumberInput";
import { TextArea } from "../../Types/AntHelperTypes";
import { lookupShipmentDocumentsFromIds } from "../../Helpers/lookupShipmentDocumentsFromIds";
import { describeDocumentType } from "../../Helpers/describeDocumentType";
import { describeInvoiceAuditProblemType } from "./describeInvoiceAuditProblemType";
import { InvoiceAuditProblemFaultTag } from "./InvoiceAuditProblemFaultTag";
import { isInvoiceAuditProblemTypeAllowedForAdditionalCharge } from "./isInvoiceAuditProblemTypeAllowedForAdditionalCharge";
import { SelectProps } from "antd/lib";
const { Title } = Typography;

const CellHeader = styled.div`
  font-size: 11px;
  margin-top: 8px;
  color: ${Colors.LightText};
`;

interface EditInvoiceAuditProblemProps extends TabProps {
  carrierIdentifier: string;
  invoiceAuditProblem: InvoiceAuditProblem;
  setInvoiceAuditProblem: React.Dispatch<
    React.SetStateAction<InvoiceAuditProblem>
  >;
}

export const InvoiceAuditProblemFaultDropdown = OptionalEnumDropdown<
  InvoiceAuditProblemFault,
  typeof InvoiceAuditProblemFault
>(InvoiceAuditProblemFault, "Unknown Fault", function (et) {
  function text() {
    switch (et) {
      case InvoiceAuditProblemFault.Carrier:
        return "Carrier";
      case InvoiceAuditProblemFault.Customer:
        return "Customer";
      case InvoiceAuditProblemFault.Freightsimple:
        return "FreightSimple";
      case InvoiceAuditProblemFault.Unknown:
        return "Unknown";
      default:
        assertNever(et);
    }
  }

  return `${emojiForInvoiceAuditProblemFault(et)} ${text()}`;
});

export const InvoiceAuditProblemResolutionTypeDropdown = OptionalEnumDropdown<
  InvoiceAuditProblemResolutionType,
  typeof InvoiceAuditProblemResolutionType
>(
  InvoiceAuditProblemResolutionType,
  "Unknown Resolution Type",
  function (et) {
    switch (et) {
      case InvoiceAuditProblemResolutionType.AdditionalChargeIssued:
        return "Additional Charge Issued";
      case InvoiceAuditProblemResolutionType.CarrierCorrection:
        return "Carrier Correction";

      case InvoiceAuditProblemResolutionType.FreightsimpleAbsorbedCost:
        return "FreightSimple Absorbed Cost";

      case InvoiceAuditProblemResolutionType.Minor:
        return "Minor";

      default:
        assertNever(et);
    }
  },
  260,
);

export const InvoiceAuditProblemResolutionStateDropdown = OptionalEnumDropdown<
  InvoiceAuditProblemResolutionState,
  typeof InvoiceAuditProblemResolutionState
>(
  InvoiceAuditProblemResolutionState,
  "Unknown Resolution State",
  function (et) {
    switch (et) {
      case InvoiceAuditProblemResolutionState.Pending:
        return "Pending";
      case InvoiceAuditProblemResolutionState.Resolved:
        return "Resolved";
      case InvoiceAuditProblemResolutionState.Unresolved:
        return "Unresolved";

      default:
        assertNever(et);
    }
  },
);

interface InvoiceAuditProblemConfidenceDropdownProps {
  value: InvoiceAuditProblemConfidence | undefined;
  setValue: (_: InvoiceAuditProblemConfidence | undefined) => void;
  invoiceAuditFault: InvoiceAuditProblemFault | undefined;
}

function InvoiceAuditProblemConfidenceDropdown(
  props: InvoiceAuditProblemConfidenceDropdownProps,
) {
  if (props.invoiceAuditFault === undefined) {
    return <></>;
  }

  const fault = props.invoiceAuditFault;

  const options: SelectProps["options"] = Object.entries(
    InvoiceAuditProblemConfidence,
  ).map(([header, type]) => {
    const description = describeInvoiceAuditConfidence(type, fault);
    return {
      value: type,
      label: header,
      description,
    };
  });

  return (
    <Select
      value={props.value}
      onChange={props.setValue}
      style={{ width: "450px" }}
      allowClear={true}
      placeholder="No confidence set"
      options={options}
      optionRender={(o) => (
        <Stack align="left">
          <div>{o.data.label}</div>
          <div style={{ color: Colors.LightText, fontSize: "12px" }}>
            {o.data.description}
          </div>
        </Stack>
      )}
      labelRender={(o) => (
        <HorizontalStack>
          <div
            style={{
              fontSize: "14px",
              lineHeight: "1em",
            }}
          >
            {o.label}
          </div>
          <Spacer width={8} />
          <div
            style={{
              color: Colors.LightText,
              fontSize: "12px",
              lineHeight: "1em",
            }}
          >
            {options.find((o2) => o2.value === o.value)?.description}
          </div>
        </HorizontalStack>
      )}
    />
  );
}

function requiresCorrectedLocationTypeInfo(
  invoiceAuditProblem: InvoiceAuditProblem,
): boolean {
  return (
    invoiceAuditProblem.type ===
    InvoiceAuditProblemType.PickupLocationTypeChange ||
    invoiceAuditProblem.type ===
    InvoiceAuditProblemType.DeliveryLocationTypeChange
  );
}

function templateForProblemType(
  invoiceAuditProblem: InvoiceAuditProblem,
): string | undefined {
  const type = invoiceAuditProblem.type;

  switch (type) {
    case InvoiceAuditProblemType.PickupAddressChange:
      return "Put the new address";
    case InvoiceAuditProblemType.PickupLocationTypeChange:
      return undefined;
    case InvoiceAuditProblemType.LiftgateAtPickup:
      return undefined;
    case InvoiceAuditProblemType.InsidePickup:
      return undefined;
    case InvoiceAuditProblemType.PickupAppointment:
      return undefined;
    case InvoiceAuditProblemType.ResidentialPickup:
      return undefined;
    case InvoiceAuditProblemType.TradeshowPickup:
      return undefined;
    case InvoiceAuditProblemType.DeliveryAddressChange:
      return undefined;
    case InvoiceAuditProblemType.DeliveryLocationTypeChange:
      return undefined;
    case InvoiceAuditProblemType.LiftgateAtDelivery:
      return undefined;
    case InvoiceAuditProblemType.InsideDelivery:
      return undefined;
    case InvoiceAuditProblemType.DeliveryAppointment:
      return undefined;
    case InvoiceAuditProblemType.ResidentialDelivery:
      return undefined;
    case InvoiceAuditProblemType.TradeshowDelivery:
      return undefined;
    case InvoiceAuditProblemType.WeightChange:
      return `Replace with actual values: Quoted: <Weight>lb, Weighed: <Weight>lb`;
    case InvoiceAuditProblemType.DimensionChange:
      return `Replace with actual values: Quoted: <L>x<W>x<H>, Measured: <L>x<W><H>`;
    case InvoiceAuditProblemType.NumberHandlingUnitsChange:
      return undefined;
    case InvoiceAuditProblemType.ConsideredNonStackable:
      return undefined;
    case InvoiceAuditProblemType.Dangerous:
      return undefined;
    case InvoiceAuditProblemType.FreightClassChange:
      return undefined;
    case InvoiceAuditProblemType.CubingChange:
      return undefined;
    case InvoiceAuditProblemType.LinearFeetChange:
      return undefined;
    case InvoiceAuditProblemType.CubeToHeightRule:
      return undefined;
    case InvoiceAuditProblemType.ProtectFromFreezing:
      return undefined;
    case InvoiceAuditProblemType.TemperatureControlled:
      return undefined;
    case InvoiceAuditProblemType.Tarp:
      return undefined;
    case InvoiceAuditProblemType.PickupStraightTruck:
      return undefined;
    case InvoiceAuditProblemType.PickupFlatbed:
      return undefined;
    case InvoiceAuditProblemType.PickupOtherEquipment:
      return undefined;
    case InvoiceAuditProblemType.MissedPickup:
      return undefined;
    case InvoiceAuditProblemType.DetentionPickup:
      return `Replace with actual values: <X>pm to <Y>pm`;
    case InvoiceAuditProblemType.DetentionWithoutPowerPickup:
      return `Replace with actual values: <X>pm to <Y>pm`;
    case InvoiceAuditProblemType.AfterHoursPickup:
      return undefined;
    case InvoiceAuditProblemType.LimitedAccessPickup:
      return undefined;
    case InvoiceAuditProblemType.RuralRoutePickup:
      return undefined;
    case InvoiceAuditProblemType.PalletJackAtPickup:
      return undefined;
    case InvoiceAuditProblemType.DeliveryStraightTruck:
      return undefined;
    case InvoiceAuditProblemType.DeliveryFlatbed:
      return undefined;
    case InvoiceAuditProblemType.DeliveryOtherEquipment:
      return undefined;
    case InvoiceAuditProblemType.Redelivery:
      return undefined;
    case InvoiceAuditProblemType.DetentionDelivery:
      return `Replace with actual values: <X>pm to <Y>pm`;
    case InvoiceAuditProblemType.DetentionWithoutPowerDelivery:
      return `Replace with actual values: <X>pm to <Y>pm`;
    case InvoiceAuditProblemType.AfterHoursDelivery:
      return undefined;
    case InvoiceAuditProblemType.LimitedAccessDelivery:
      return undefined;
    case InvoiceAuditProblemType.DeliveryNotificationFee:
      return undefined;
    case InvoiceAuditProblemType.RuralRouteDelivery:
      return undefined;
    case InvoiceAuditProblemType.PalletJackAtDelivery:
      return undefined;
    case InvoiceAuditProblemType.Storage:
      return `Replace with actual values: Sept <X> to Sept <Y>`;
    case InvoiceAuditProblemType.CustomsCharge:
      return undefined;
    case InvoiceAuditProblemType.Reconsignment:
      return undefined;
    case InvoiceAuditProblemType.AdditionalCarrierConverageRequested:
      return undefined;
    case InvoiceAuditProblemType.BillOfLadingChange:
      return undefined;
    case InvoiceAuditProblemType.FreightsimpleEnteredIncorrectCurrency:
      return undefined;
    case InvoiceAuditProblemType.FreightsimpleEnteredIncorrectAmount:
      return undefined;
    case InvoiceAuditProblemType.FreightsimpleTaxIncorrectlyApplied:
      return undefined;
    case InvoiceAuditProblemType.SkidSpotsIncorrect:
      return undefined;
    case InvoiceAuditProblemType.FuelSurchargeIncorrect:
      return undefined;
    case InvoiceAuditProblemType.IncorrectRateUsed:
      return undefined;
    case InvoiceAuditProblemType.QuoteNotApplied:
      return undefined;
    case InvoiceAuditProblemType.CodFee:
      return undefined;
    case InvoiceAuditProblemType.LumperFee:
      return undefined;
    case InvoiceAuditProblemType.SwamperFee:
      return undefined;
    case InvoiceAuditProblemType.DocumentFee:
      return undefined;
    case InvoiceAuditProblemType.CrossDockFee:
      return undefined;
    case InvoiceAuditProblemType.DropTrailerFee:
      return undefined;
    case InvoiceAuditProblemType.ExclusiveUseFee:
      return undefined;
    case InvoiceAuditProblemType.ExtraLaborFee:
      return undefined;
    case InvoiceAuditProblemType.OverlengthFee:
      return undefined;
    case InvoiceAuditProblemType.SortationAndSegragationFee:
      return undefined;
    case InvoiceAuditProblemType.PackagingFee:
      return undefined;
    case InvoiceAuditProblemType.WeekendPickupFee:
      return undefined;
    case InvoiceAuditProblemType.WeekendDeliveryFee:
      return undefined;
    case InvoiceAuditProblemType.HolidayPickupFee:
      return undefined;
    case InvoiceAuditProblemType.HolidayDeliveryFee:
      return undefined;
    case InvoiceAuditProblemType.CrossBorderFee:
      return undefined;
    case InvoiceAuditProblemType.PermitFee:
      return undefined;
    case InvoiceAuditProblemType.InspectionFee:
      return undefined;
    case InvoiceAuditProblemType.BondFee:
      return undefined;
    case InvoiceAuditProblemType.Other:
      return undefined;
    case undefined:
      return undefined;
    default:
      assertNever(type);
  }
}

function expectedDocumentForProblemType(
  invoiceAuditProblem: InvoiceAuditProblem,
): DocumentType | undefined {
  const type = invoiceAuditProblem.type;

  switch (type) {
    case InvoiceAuditProblemType.PickupAddressChange:
      return undefined;
    case InvoiceAuditProblemType.PickupLocationTypeChange:
      return undefined;
    case InvoiceAuditProblemType.LiftgateAtPickup:
      return DocumentType.CarrierScannedBillOfLading;
    case InvoiceAuditProblemType.InsidePickup:
      return DocumentType.CarrierScannedBillOfLading;
    case InvoiceAuditProblemType.PickupAppointment:
      return undefined;
    case InvoiceAuditProblemType.ResidentialPickup:
      return DocumentType.GoogleMapsScreenshot;
    case InvoiceAuditProblemType.TradeshowPickup:
      return undefined;
    case InvoiceAuditProblemType.DeliveryAddressChange:
      return undefined;
    case InvoiceAuditProblemType.DeliveryLocationTypeChange:
      return DocumentType.GoogleMapsScreenshot;
    case InvoiceAuditProblemType.LiftgateAtDelivery:
      return DocumentType.ProofOfDelivery;
    case InvoiceAuditProblemType.InsideDelivery:
      return DocumentType.ProofOfDelivery;
    case InvoiceAuditProblemType.DeliveryAppointment:
      return undefined;
    case InvoiceAuditProblemType.ResidentialDelivery:
      return DocumentType.GoogleMapsScreenshot;
    case InvoiceAuditProblemType.TradeshowDelivery:
      return undefined;
    case InvoiceAuditProblemType.WeightChange:
      return DocumentType.CarrierWeightReport;
    case InvoiceAuditProblemType.DimensionChange:
      return DocumentType.CarrierWeightReport;
    case InvoiceAuditProblemType.NumberHandlingUnitsChange:
      return DocumentType.CarrierScannedBillOfLading;
    case InvoiceAuditProblemType.ConsideredNonStackable:
      return undefined;
    case InvoiceAuditProblemType.Dangerous:
      return undefined;
    case InvoiceAuditProblemType.FreightClassChange:
      return undefined;
    case InvoiceAuditProblemType.CubingChange:
      return undefined;
    case InvoiceAuditProblemType.LinearFeetChange:
      return undefined;
    case InvoiceAuditProblemType.CubeToHeightRule:
      return undefined;
    case InvoiceAuditProblemType.ProtectFromFreezing:
      return undefined;
    case InvoiceAuditProblemType.TemperatureControlled:
      return undefined;
    case InvoiceAuditProblemType.Tarp:
      return undefined;
    case InvoiceAuditProblemType.PickupStraightTruck:
      return undefined;
    case InvoiceAuditProblemType.PickupFlatbed:
      return undefined;
    case InvoiceAuditProblemType.PickupOtherEquipment:
      return undefined;
    case InvoiceAuditProblemType.MissedPickup:
      return undefined;
    case InvoiceAuditProblemType.DetentionPickup:
      return DocumentType.CarrierScannedBillOfLading;
    case InvoiceAuditProblemType.DetentionWithoutPowerPickup:
      return DocumentType.CarrierScannedBillOfLading;
    case InvoiceAuditProblemType.AfterHoursPickup:
      return DocumentType.CarrierScannedBillOfLading;
    case InvoiceAuditProblemType.LimitedAccessPickup:
      return DocumentType.GoogleMapsScreenshot;
    case InvoiceAuditProblemType.RuralRoutePickup:
      return undefined;
    case InvoiceAuditProblemType.PalletJackAtPickup:
      return undefined;
    case InvoiceAuditProblemType.DeliveryStraightTruck:
      return undefined;
    case InvoiceAuditProblemType.DeliveryFlatbed:
      return undefined;
    case InvoiceAuditProblemType.DeliveryOtherEquipment:
      return undefined;
    case InvoiceAuditProblemType.Redelivery:
      return undefined;
    case InvoiceAuditProblemType.DetentionDelivery:
      return DocumentType.ProofOfDelivery;
    case InvoiceAuditProblemType.DetentionWithoutPowerDelivery:
      return DocumentType.ProofOfDelivery;
    case InvoiceAuditProblemType.AfterHoursDelivery:
      return DocumentType.ProofOfDelivery;
    case InvoiceAuditProblemType.LimitedAccessDelivery:
      return DocumentType.GoogleMapsScreenshot;
    case InvoiceAuditProblemType.DeliveryNotificationFee:
      return undefined;
    case InvoiceAuditProblemType.RuralRouteDelivery:
      return undefined;
    case InvoiceAuditProblemType.PalletJackAtDelivery:
      return undefined;
    case InvoiceAuditProblemType.Storage:
      return undefined;
    case InvoiceAuditProblemType.CustomsCharge:
      return undefined;
    case InvoiceAuditProblemType.Reconsignment:
      return undefined;
    case InvoiceAuditProblemType.AdditionalCarrierConverageRequested:
      return undefined;
    case InvoiceAuditProblemType.BillOfLadingChange:
      return DocumentType.CarrierScannedBillOfLading;
    case InvoiceAuditProblemType.FreightsimpleEnteredIncorrectCurrency:
      return undefined;
    case InvoiceAuditProblemType.FreightsimpleEnteredIncorrectAmount:
      return undefined;
    case InvoiceAuditProblemType.FreightsimpleTaxIncorrectlyApplied:
      return undefined;
    case InvoiceAuditProblemType.SkidSpotsIncorrect:
      return undefined;
    case InvoiceAuditProblemType.FuelSurchargeIncorrect:
      return undefined;
    case InvoiceAuditProblemType.IncorrectRateUsed:
      return undefined;
    case InvoiceAuditProblemType.QuoteNotApplied:
      return undefined;
    case InvoiceAuditProblemType.CodFee:
      return undefined;
    case InvoiceAuditProblemType.LumperFee:
      return undefined;
    case InvoiceAuditProblemType.SwamperFee:
      return undefined;
    case InvoiceAuditProblemType.DocumentFee:
      return undefined;
    case InvoiceAuditProblemType.CrossDockFee:
      return undefined;
    case InvoiceAuditProblemType.DropTrailerFee:
      return undefined;
    case InvoiceAuditProblemType.ExclusiveUseFee:
      return undefined;
    case InvoiceAuditProblemType.ExtraLaborFee:
      return undefined;
    case InvoiceAuditProblemType.OverlengthFee:
      return undefined;
    case InvoiceAuditProblemType.SortationAndSegragationFee:
      return undefined;
    case InvoiceAuditProblemType.PackagingFee:
      return undefined;
    case InvoiceAuditProblemType.WeekendPickupFee:
      return undefined;
    case InvoiceAuditProblemType.WeekendDeliveryFee:
      return undefined;
    case InvoiceAuditProblemType.HolidayPickupFee:
      return undefined;
    case InvoiceAuditProblemType.HolidayDeliveryFee:
      return undefined;
    case InvoiceAuditProblemType.CrossBorderFee:
      return undefined;
    case InvoiceAuditProblemType.PermitFee:
      return undefined;
    case InvoiceAuditProblemType.InspectionFee:
      return undefined;
    case InvoiceAuditProblemType.BondFee:
      return undefined;
    case InvoiceAuditProblemType.Other:
      return undefined;
    case undefined:
      return undefined;
    default:
      assertNever(type);
  }
}

function EditInvoiceAuditProblem(props: EditInvoiceAuditProblemProps) {
  const [form] = Form.useForm();
  const { invoiceAuditProblem, setInvoiceAuditProblem } = props;
  const [customerVisibleDocuments, setCustomerVisibleDocuments] = useState<
    ShipmentDocument[]
  >(
    lookupShipmentDocumentsFromIds(
      props.shipmentData,
      invoiceAuditProblem.relatedCustomerVisibleDocumentIds,
    ) ?? [],
  );
  const [internalOnlyDocuments, setInternalOnlyDocuments] = useState<
    ShipmentDocument[]
  >(
    lookupShipmentDocumentsFromIds(
      props.shipmentData,
      invoiceAuditProblem.relatedInternalOnlyDocumentIds,
    ) ?? [],
  );

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  function onChangeCustomerVisibleDetail(value: string) {
    setInvoiceAuditProblem((iap) => ({ ...iap, customerVisibleDetail: value }));
  }

  function onChangeCorrectedLocationType(value: string | undefined) {
    setInvoiceAuditProblem((iap) => ({ ...iap, correctedLocationType: value }));
  }

  function onChangeCorrectedDistributionLocationBrand(
    value: string | undefined,
  ) {
    setInvoiceAuditProblem((iap) => ({
      ...iap,
      distributionWarehouseBrand: value,
    }));
  }

  function onChangeInternalVisibleDetail(value: string) {
    setInvoiceAuditProblem((iap) => ({ ...iap, internalVisibleDetail: value }));
  }

  function onChangeInternalEvidence(value: string) {
    setInvoiceAuditProblem((iap) => ({ ...iap, internalEvidence: value }));
  }

  function onChangeResolutionType(
    value: InvoiceAuditProblemResolutionType | undefined,
  ) {
    setInvoiceAuditProblem((iap) => ({ ...iap, resolutionType: value }));
  }

  function onChangeResolutionState(
    value: InvoiceAuditProblemResolutionState | undefined,
  ) {
    setInvoiceAuditProblem((iap) => ({ ...iap, resolutionState: value }));
  }

  function onChangeFault(value: InvoiceAuditProblemFault | undefined) {
    setInvoiceAuditProblem((iap) => ({ ...iap, fault: value }));
  }

  function onChangeConfidence(
    value: InvoiceAuditProblemConfidence | undefined,
  ) {
    setInvoiceAuditProblem((iap) => ({ ...iap, confidence: value }));
  }

  function onChangeEstimatedCostDifference(value: number) {
    setInvoiceAuditProblem((iap) => ({
      ...iap,
      estimatedCostDifference: value,
    }));
  }

  function onChangeType(value: InvoiceAuditProblemType | undefined) {
    setInvoiceAuditProblem((iap) => ({ ...iap, type: value }));
  }

  function onChangeCustomerVisibleDocuments(value: ShipmentDocument[]) {
    const documentIds = value.map((o) => o.shipmentDocumentId!);
    setInvoiceAuditProblem((iap) => ({
      ...iap,
      relatedCustomerVisibleDocumentIds: documentIds,
    }));
  }

  function onChangeInternalOnlyDocuments(value: ShipmentDocument[]) {
    const documentIds = value.map((o) => o.shipmentDocumentId!);
    setInvoiceAuditProblem((iap) => ({
      ...iap,
      relatedInternalOnlyDocumentIds: documentIds,
    }));
  }

  return (
    <>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <Form {...formItemLayout} layout="horizontal" form={form}>
          <Form.Item label="Problem Type">
            <Stack align="left">
              <InvoiceAuditProblemTypeDropdown
                value={invoiceAuditProblem.type}
                setValue={onChangeType}
              />
              <div style={{ marginTop: "2px", color: Colors.LightText }}>
                {shipmentInfoMessageForInvoiceAuditType(
                  invoiceAuditProblem.type,
                  props.shipmentData,
                )}
              </div>
            </Stack>
          </Form.Item>
          {requiresCorrectedLocationTypeInfo(invoiceAuditProblem) && (
            <Form.Item label="Corrected Location Type">
              <LocationTypeDropdown
                locationType={invoiceAuditProblem.correctedLocationType}
                distributionWarehouseBrand={
                  invoiceAuditProblem.distributionWarehouseBrand
                }
                setLocationType={function (e) {
                  onChangeCorrectedLocationType(e);
                }}
                setDistributionWarehouseBrand={function (e) {
                  onChangeCorrectedDistributionLocationBrand(e);
                }}
              />
            </Form.Item>
          )}
          <Form.Item label="Fault">
            <Stack align="left">
              <HorizontalStack>
                <InvoiceAuditProblemFaultDropdown
                  value={invoiceAuditProblem.fault}
                  setValue={onChangeFault}
                />
                <Spacer width={32} />
                <ViewCompanyButton
                  companyId={props.shipmentData.shipment.company.companyId!}
                  type="link"
                  openInNewTab
                />
              </HorizontalStack>
            </Stack>
          </Form.Item>
          {invoiceAuditProblem.fault !== InvoiceAuditProblemFault.Unknown && (
            <Form.Item label="Confidence">
              <InvoiceAuditProblemConfidenceDropdown
                value={invoiceAuditProblem.confidence}
                setValue={onChangeConfidence}
                invoiceAuditFault={invoiceAuditProblem.fault}
              />
            </Form.Item>
          )}
          <Form.Item label="Customer Visible Detail">
            <Stack align="left">
              <div
                style={{
                  marginTop: "5px",
                  marginBottom: "2px",
                  color: Colors.LightText,
                }}
              >
                This information will go on the invoice in case of additional
                charge
              </div>
              <Input
                value={invoiceAuditProblem.customerVisibleDetail}
                onChange={function (e) {
                  onChangeCustomerVisibleDetail(e.target.value);
                }}
              />
              <div
                style={{
                  marginTop: "5px",
                  marginBottom: "2px",
                  color: Colors.LightText,
                }}
              >
                {templateForProblemType(invoiceAuditProblem)}
              </div>
            </Stack>
          </Form.Item>
          <Form.Item label="Internal Visible Notes">
            <Stack align="left">
              <div
                style={{
                  marginTop: "5px",
                  marginBottom: "2px",
                  color: Colors.LightText,
                }}
              >
                Any other notes, thoughts, ideas about this problem. Like an
                internal scratch pad
              </div>
              <TextArea
                value={invoiceAuditProblem.internalVisibleDetail}
                onChange={function (e) {
                  onChangeInternalVisibleDetail(e.target.value);
                }}
                style={{ height: "100px" }}
              />
            </Stack>
          </Form.Item>
          <Form.Item label="Internal Evidence">
            <Stack align="left">
              <div
                style={{
                  marginTop: "5px",
                  marginBottom: "2px",
                  color: Colors.LightText,
                }}
              >
                Describe the evidence that we have supporting this
              </div>
              <TextArea
                value={invoiceAuditProblem.internalEvidence}
                onChange={function (e) {
                  onChangeInternalEvidence(e.target.value);
                }}
                style={{ height: "100px" }}
              />
            </Stack>
          </Form.Item>

          <Form.Item label="Estimated Cost Difference">
            <NumberInput
              prefix="$"
              value={invoiceAuditProblem.estimatedCostDifference}
              setValue={onChangeEstimatedCostDifference}
              width={300}
            />
          </Form.Item>
          <Form.Item label="Related Documents">
            <Stack align="left">
              <ManageAttachmentsButton
                buttonType="default"
                buttonText="Manage Customer Visible Documents"
                showThumbnails={true}
                shipmentData={props.shipmentData}
                attachments={customerVisibleDocuments}
                onUpdateAttachments={function (documents: ShipmentDocument[]) {
                  onChangeCustomerVisibleDocuments(documents);
                  setCustomerVisibleDocuments(documents);
                }}
              />
              <Spacer height={32} />
              <ManageAttachmentsButton
                buttonType="default"
                buttonText="Manage Internal Only Documents"
                showThumbnails={true}
                shipmentData={props.shipmentData}
                attachments={internalOnlyDocuments}
                onUpdateAttachments={function (documents: ShipmentDocument[]) {
                  onChangeInternalOnlyDocuments(documents);
                  setInternalOnlyDocuments(documents);
                }}
              />
            </Stack>
          </Form.Item>
          <Form.Item label="Resolution State">
            <InvoiceAuditProblemResolutionStateDropdown
              value={invoiceAuditProblem.resolutionState}
              setValue={onChangeResolutionState}
            />
          </Form.Item>
          {invoiceAuditProblem.resolutionState !==
            InvoiceAuditProblemResolutionState.Unresolved && (
              <Form.Item label="Resolution Type">
                <InvoiceAuditProblemResolutionTypeDropdown
                  value={invoiceAuditProblem.resolutionType}
                  setValue={onChangeResolutionType}
                />
              </Form.Item>
            )}
        </Form>
        <div>
          <CommonProblemsForProblemTypeSection
            invoiceAuditProblem={invoiceAuditProblem}
          />
          <CommonCompanyProblemsSection
            shipmentData={props.shipmentData}
            onPatchShipmentData={props.onPatchShipmentData}
          />
          <CompanyInvoiceAuditHistory shipmentData={props.shipmentData} />
          <CommonCarrierProblemsSection
            carrierIdentifier={props.carrierIdentifier}
          />
        </div>
      </div>
    </>
  );
}

interface EditInvoiceAuditProblemButtonProps extends TabProps {
  carrierIdentifier: string;
  index: number;
}

interface DeleteInvoiceAuditProblemButtonProps extends TabProps {
  index: number;
}

function DeleteInvoiceAuditProblemButton(
  props: DeleteInvoiceAuditProblemButtonProps,
) {
  const createShipmentApi = useShipmentsApi();
  const [loading, setLoading] = useState(false);

  async function onClick() {
    setLoading(true);
    try {
      const shipmentId = props.shipmentData.shipmentId;
      const shipmentApi = await createShipmentApi();
      const updated = [
        ...props.shipmentData.shipment.shipment.invoiceAuditProblems!,
      ];
      updated.splice(props.index, 1);
      await shipmentApi.updateInvoiceAuditProblems({
        shipmentId,
        invoiceAuditProblem: updated,
      });
      await props.onRefresh();
      message.success("Deleted");
    } catch (e) {
      message.error(`Oops. Something went wrong. ${e}`);
    }
    setLoading(false);
  }

  return (
    <ConfirmMenuItem
      loading={loading}
      onConfirm={onClick}
      question={"Are you sure you want to delete this problem?"}
      okText={"Yes, delete"}
      tooltip={"This will delete the problem"}
    >
      Delete
    </ConfirmMenuItem>
  );
}

export function EditInvoiceAuditProblemButton(
  props: EditInvoiceAuditProblemButtonProps,
) {
  const problems =
    props.shipmentData.shipment.shipment.invoiceAuditProblems ?? [];
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [invoiceAuditProblem, setInvoiceAuditProblem] = useState(
    problems[props.index],
  );
  const createShipmentApi = useShipmentsApi();
  const [loading, setLoading] = useState(false);

  useEffect(
    function () {
      if (isModalVisible) {
        console.log("!!!! isModalVisible", {
          index: props.index,
          problems: problems,
        });
        // Refresh when the modal is reopened
        setInvoiceAuditProblem(problems[props.index]);
      }
    },

    [isModalVisible, props.shipmentData, props.index],
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function checkDocuments(
    invoiceAuditProblem: InvoiceAuditProblem,
  ): Promise<boolean> {
    const { confirm } = Modal;
    const expectedDocumentType =
      expectedDocumentForProblemType(invoiceAuditProblem);
    function meetsDocumentRequirements(): boolean {
      if (
        invoiceAuditProblem.fault === InvoiceAuditProblemFault.Customer &&
        (invoiceAuditProblem.confidence ===
          InvoiceAuditProblemConfidence.Medium ||
          invoiceAuditProblem.confidence === InvoiceAuditProblemConfidence.High)
      ) {
        if (expectedDocumentType === undefined) {
          // No expectation - so does meet requirement
          return true;
        }
        const documents = lookupShipmentDocumentsFromIds(
          props.shipmentData,
          invoiceAuditProblem.relatedCustomerVisibleDocumentIds,
        );
        if (documents === undefined) {
          // No documents so doesn't meet requirement
          return false;
        }

        return documents.some((sd) => sd.documentType === expectedDocumentType);
      } else {
        // Not a customer medium/high problem - so no requirements - so we meet the requirements
        return true;
      }
    }

    if (meetsDocumentRequirements()) {
      return true;
    }

    return new Promise<boolean>((resolve) => {
      confirm({
        title: `Potentially Missing ${describeDocumentType(
          expectedDocumentType!,
        )} Document`,
        icon: <ExclamationCircleOutlined />,
        width: "600px",
        content:
          "Attributing this to the customer requires certain evidence that seems to be missing. Only proceed if you are confident we can back this up another way",
        okText: "I understand, proceed",
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  }

  async function handleOk() {
    if (!validateInvoiceAuditProblem(invoiceAuditProblem)) {
      return;
    }

    if ((await checkDocuments(invoiceAuditProblem)) === false) {
      return;
    }

    setLoading(true);
    try {
      const shipmentId = props.shipmentData.shipmentId;
      const shipmentApi = await createShipmentApi();

      const updatedProblems = [...problems];
      updatedProblems[props.index] = invoiceAuditProblem;

      console.log("!!!! updatedProblems", { updatedProblems });
      await shipmentApi.updateInvoiceAuditProblems({
        shipmentId,
        invoiceAuditProblem: updatedProblems,
      });

      await props.onRefresh();
      message.success("Saved!");
      setIsModalVisible(false);
    } catch {
      message.error("Oops something went wrong");
    }
    setLoading(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Modal
        title={
          <HorizontalStack align="spread">
            <div>Edit Invoice Audit Problem</div>
            <div style={{ marginRight: "24px" }}>
              <ViewEmailConversationsButton {...props} />
            </div>
          </HorizontalStack>
        }
        open={isModalVisible}
        onOk={handleOk}
        okText="Save Problem"
        onCancel={handleCancel}
        width={1600}
        confirmLoading={loading}
      >
        <EditInvoiceAuditProblem
          invoiceAuditProblem={invoiceAuditProblem}
          setInvoiceAuditProblem={setInvoiceAuditProblem}
          {...props}
        />
      </Modal>
      <Button onClick={showModal}>Edit</Button>
    </>
  );
}

function validateInvoiceAuditProblem(
  invoiceAuditProblem: InvoiceAuditProblem,
): boolean {
  if (invoiceAuditProblem.type === undefined) {
    message.error("Missing type");
    return false;
  }

  if (invoiceAuditProblem.fault === undefined) {
    message.error("Missing fault");
    return false;
  }

  if (
    invoiceAuditProblem.fault !== InvoiceAuditProblemFault.Unknown &&
    invoiceAuditProblem.confidence === undefined
  ) {
    message.error("Missing confidence");
    return false;
  }

  if (
    invoiceAuditProblem.resolutionState !==
    InvoiceAuditProblemResolutionState.Unresolved &&
    invoiceAuditProblem.resolutionType === undefined
  ) {
    message.error("Missing resolution type");
    return false;
  }

  if (
    invoiceAuditProblem.resolutionState !==
    InvoiceAuditProblemResolutionState.Unresolved &&
    invoiceAuditProblem.fault === InvoiceAuditProblemFault.Unknown
  ) {
    message.error("Cannot resolve when fault is unknown");
    return false;
  }

  if (
    requiresCorrectedLocationTypeInfo(invoiceAuditProblem) &&
    isBlank(invoiceAuditProblem.correctedLocationType)
  ) {
    message.error("Corrected location type is required");
    return false;
  }

  // We have a med/high customer issue - did we fill in the required Customer Visible Info?
  if (isBlank(invoiceAuditProblem.customerVisibleDetail)) {
    if (
      invoiceAuditProblem.fault === InvoiceAuditProblemFault.Customer &&
      (invoiceAuditProblem.confidence === InvoiceAuditProblemConfidence.High ||
        invoiceAuditProblem.confidence === InvoiceAuditProblemConfidence.Medium)
    ) {
      // If we have a template - then it is required for high/med customer issues
      if (templateForProblemType(invoiceAuditProblem) !== undefined) {
        message.error("Enter the customer visible issue");
        return false;
      }

      if (invoiceAuditProblem.type === InvoiceAuditProblemType.Other) {
        message.error("Enter the customer visible issue");
        return false;
      }
    }
  }

  // We have a med/high customer issue - did we fill in the internal evidence?
  if (isBlank(invoiceAuditProblem.internalEvidence)) {
    if (
      invoiceAuditProblem.fault === InvoiceAuditProblemFault.Customer &&
      (invoiceAuditProblem.confidence === InvoiceAuditProblemConfidence.High ||
        invoiceAuditProblem.confidence === InvoiceAuditProblemConfidence.Medium)
    ) {
      message.error("Enter some internal evidence");
      return false;
    }
  }

  return true;
}

function emptyInvoiceAuditProblem(): InvoiceAuditProblem {
  return {
    invoiceAuditProblemId: uuidv4(),
    type: undefined,
    confidence: undefined,
    fault: InvoiceAuditProblemFault.Unknown,
    customerVisibleDetail: "",
    internalVisibleDetail: "",
    estimatedCostDifference: 0,
    correctedLocationType: undefined,
    resolutionState: InvoiceAuditProblemResolutionState.Unresolved,
    resolutionType: undefined,
  };
}

interface AddInvoiceAuditProblemButtonProps extends TabProps {
  carrierIdentifier: string;
  carrierInvoice: CarrierInvoice | undefined;
}

export function AddInvoiceAuditProblemButton(
  props: AddInvoiceAuditProblemButtonProps,
) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [invoiceAuditProblem, setInvoiceAuditProblem] = useState(
    emptyInvoiceAuditProblem(),
  );
  const createShipmentApi = useShipmentsApi();
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (!validateInvoiceAuditProblem(invoiceAuditProblem)) {
      return;
    }

    setLoading(true);
    try {
      const shipmentId = props.shipmentData.shipmentId;
      const shipmentApi = await createShipmentApi();
      await shipmentApi.updateInvoiceAuditProblems({
        shipmentId,
        invoiceAuditProblem: [
          ...(props.shipmentData.shipment.shipment.invoiceAuditProblems ?? []),
          invoiceAuditProblem,
        ],
      });

      await props.onRefresh();
      message.success("Added");
      setInvoiceAuditProblem(emptyInvoiceAuditProblem());
      setIsModalVisible(false);
    } catch {
      message.error("Ooops. Something went wrong");
    }
    setLoading(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Modal
        title={
          <HorizontalStack align="spread">
            <div>Add Invoice Audit Problem</div>
            <div style={{ marginRight: "24px" }}>
              <ButtonRow>
                <QuickViewCarrierInvoiceButton {...props} />
                <ViewEmailConversationsButton {...props} />
              </ButtonRow>
            </div>
          </HorizontalStack>
        }
        open={isModalVisible}
        onOk={handleOk}
        okText="Save"
        onCancel={handleCancel}
        width={1600}
        confirmLoading={loading}
      >
        <EditInvoiceAuditProblem
          invoiceAuditProblem={invoiceAuditProblem}
          setInvoiceAuditProblem={setInvoiceAuditProblem}
          {...props}
        />
      </Modal>
      <Button onClick={showModal} icon={<PlusOutlined />}>
        Add Invoice Audit Problem
      </Button>
    </>
  );
}

interface InvoiceAuditResolutionStateTagProps {
  resolutionState: InvoiceAuditProblemResolutionState | undefined;
  resolutionType: InvoiceAuditProblemResolutionType | undefined;
}

function describeInvoiceAuditResolutionState(
  resolutionState: InvoiceAuditProblemResolutionState,
): string {
  switch (resolutionState) {
    case InvoiceAuditProblemResolutionState.Resolved:
      return "✅ Resolved";
    case InvoiceAuditProblemResolutionState.Pending:
      return "⌛ Pending";
    case InvoiceAuditProblemResolutionState.Unresolved:
      return "🤔 Unresolved";
    default:
      assertNever(resolutionState);
  }
}

function describeInvoiceAuditResolutionType(
  resolutionType: InvoiceAuditProblemResolutionType | undefined,
): string {
  switch (resolutionType) {
    case InvoiceAuditProblemResolutionType.AdditionalChargeIssued:
      return "(Additional Charge Issued)";
    case InvoiceAuditProblemResolutionType.CarrierCorrection:
      return "(Carrier Correction)";
    case InvoiceAuditProblemResolutionType.FreightsimpleAbsorbedCost:
      return "(We Absorbed Cost)";
    case InvoiceAuditProblemResolutionType.Minor:
      return "(Minor)";
    case undefined:
      return "";
    default:
      assertNever(resolutionType);
  }
}

function InvoiceAuditResolutionStateTag(
  props: InvoiceAuditResolutionStateTagProps,
) {
  if (props.resolutionState === undefined) {
    return <></>;
  }

  return (
    <Tag
      style={{
        textDecoration:
          props.resolutionState === InvoiceAuditProblemResolutionState.Resolved
            ? "line-through"
            : undefined,
      }}
      color={colorForInvoiceAuditProblemResolutionState(props.resolutionState)}
    >
      {describeInvoiceAuditResolutionState(props.resolutionState)}{" "}
      {describeInvoiceAuditResolutionType(props.resolutionType)}
    </Tag>
  );
}

interface InvoiceAuditProblemsTableProps extends TabProps {
  selected?: string[];
  onSelect?: (_: string[]) => void;
  isCheckboxDisabled?: (_: InvoiceAuditProblem) => boolean;
  isCheckboxDisabledDescription?: (
    _: InvoiceAuditProblem,
  ) => string | undefined;
  carrierIdentifier: string;
}

export function InvoiceAuditProblemsTable(
  props: InvoiceAuditProblemsTableProps,
) {
  const problems =
    props.shipmentData.shipment.shipment.invoiceAuditProblems ?? [];

  const columns: DataTableColumn<InvoiceAuditProblem>[] = [];

  columns.push({
    title: "Resolution",
    render: (o) => (
      <Stack>
        <InvoiceAuditResolutionStateTag
          resolutionState={o.resolutionState}
          resolutionType={o.resolutionType}
        />
      </Stack>
    ),
  });

  columns.push({
    title: "Fault",
    render: (o) => (
      <Stack align="center">
        <InvoiceAuditProblemFaultTag
          fault={o.fault!}
          confidence={o.confidence!}
        />
      </Stack>
    ),
  });

  columns.push({
    title: "Type",
    render: (o) => (
      <Stack align="center">
        <div>{describeInvoiceAuditProblemType(o)}</div>
        {!isInvoiceAuditProblemTypeAllowedForAdditionalCharge(o.type!) && (
          <div>
            <Tag style={{ marginTop: "4px" }} color="orange">
              <WarningComponent /> Type not allowed for additional charge
            </Tag>
          </div>
        )}
      </Stack>
    ),
  });

  columns.push({
    title: "Detail",
    render: function (o) {
      const relatedCustomerVisibleDocuments = lookupShipmentDocumentsFromIds(
        props.shipmentData,
        o.relatedCustomerVisibleDocumentIds,
      );

      const relatedInternalOnlyDocuments = lookupShipmentDocumentsFromIds(
        props.shipmentData,
        o.relatedInternalOnlyDocumentIds,
      );

      return (
        <>
          {o.customerVisibleDetail && (
            <>
              <CellHeader>Customer Visible Detail</CellHeader>
              {o.customerVisibleDetail}
            </>
          )}
          {o.internalEvidence && (
            <>
              <CellHeader>Internal Evidence</CellHeader>
              {o.internalEvidence}
            </>
          )}
          {o.internalVisibleDetail && (
            <>
              <CellHeader>Internal Visible Notes</CellHeader>
              {o.internalVisibleDetail}
            </>
          )}
          {o.estimatedCostDifference !== undefined &&
            o.estimatedCostDifference > 0 && (
              <>
                <CellHeader>Estimated Cost Difference</CellHeader>
                <div>${o.estimatedCostDifference}</div>
              </>
            )}
          {relatedCustomerVisibleDocuments &&
            relatedCustomerVisibleDocuments.length > 0 && (
              <>
                <CellHeader>
                  Customer Visible Documents (
                  {relatedCustomerVisibleDocuments.length})
                </CellHeader>
                <div
                  style={{
                    marginTop: "4px",
                    border: "1px solid #ddd",
                    padding: "8px",
                    backgroundColor: "#eee",
                  }}
                >
                  <ListDocuments
                    shipmentData={props.shipmentData}
                    documents={relatedCustomerVisibleDocuments}
                  />
                </div>
              </>
            )}
          {relatedInternalOnlyDocuments &&
            relatedInternalOnlyDocuments.length > 0 && (
              <>
                <CellHeader>
                  Internal Only Documents ({relatedInternalOnlyDocuments.length}
                  )
                </CellHeader>
                <div style={{ marginTop: "4px" }}>
                  <ListDocuments
                    shipmentData={props.shipmentData}
                    documents={relatedInternalOnlyDocuments}
                  />
                </div>
              </>
            )}
        </>
      );
    },
  });

  columns.push({
    title: "Actions",
    render: (_, index) => {
      return (
        <ButtonRow
          extrasMenu={
            <Menu>
              <DeleteInvoiceAuditProblemButton index={index} {...props} />
            </Menu>
          }
        >
          <EditInvoiceAuditProblemButton
            index={index}
            {...props}
            carrierIdentifier={props.carrierIdentifier}
          />
        </ButtonRow>
      );
    },
  });

  const getCheckboxProps = (record: InvoiceAuditProblem) => ({
    disabled:
      props.isCheckboxDisabled !== undefined
        ? props.isCheckboxDisabled(record)
        : false,
  });

  const renderCell = (
    _checked: boolean,
    record: InvoiceAuditProblem,
    _index: number,
    originNode: React.ReactNode,
  ) => (
    <Tooltip
      title={
        props.isCheckboxDisabledDescription !== undefined
          ? props.isCheckboxDisabledDescription(record)
          : undefined
      }
    >
      {" "}
      {/* Assuming you have a 'tooltipText' property */}
      {originNode}
    </Tooltip>
  );

  return (
    <Stack align="left">
      <DataTable
        pagination={false}
        columns={columns}
        data={problems}
        rowKey={(p) => p.invoiceAuditProblemId!}
        rowSelection={
          props.onSelect
            ? {
              type: "checkbox",
              selectedRowKeys: props.selected ?? [],
              onChange: (
                _selectedRowKeys: React.Key[],
                _selectedRows: InvoiceAuditProblem[],
              ) => {
                if (props.onSelect !== undefined)
                  props.onSelect(
                    _selectedRows.map(function (row) {
                      return row.invoiceAuditProblemId!;
                    }),
                  );
              },
              getCheckboxProps: getCheckboxProps,
              renderCell: renderCell,
            }
            : undefined
        }
      />
    </Stack>
  );
}

interface IdentifyInvoiceAuditProblemsSectionProps extends TabProps {
  carrierIdentifier: string;
  carrierInvoice: CarrierInvoice | undefined;
}

export function IdentifyInvoiceAuditProblemsSection(
  props: IdentifyInvoiceAuditProblemsSectionProps,
) {
  const noProblems =
    props.shipmentData.shipment.shipment.invoiceAuditProblems!.length === 0;

  return (
    <>
      <HorizontalStack align="spread">
        <Title level={4}>Invoice Audit Problems</Title>
        <ButtonRow>
          <AddInvoiceAuditProblemButton {...props} />
        </ButtonRow>
      </HorizontalStack>
      {noProblems && (
        <div
          style={{
            backgroundColor: "#fafafa",
            marginTop: "16px",
            border: "1px solid #ddd",
            width: "100%",
            padding: "24px",
          }}
        >
          <WarningComponent /> No problems identified
        </div>
      )}
      {!noProblems && <InvoiceAuditProblemsTable {...props} />}
    </>
  );
}
