import { Form, FormProps, Input } from "antd";

export interface QuoteTestScenarioFromShipmentBody {
  shipmentId: string;
}

export function QuoteTestScenarioFromShipment(
  props: FormProps<QuoteTestScenarioFromShipmentBody>,
) {
  type FormType = QuoteTestScenarioFromShipmentBody;

  return (
    <Form {...props} name="basic" autoComplete="off">
      <Form.Item<FormType>
        label="Shipment ID"
        name="shipmentId"
        rules={[{ required: true, message: "Please input the shipment ID" }]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
}
