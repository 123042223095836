import { Form, Modal } from "antd";
import { UseDisplayReturn } from "../../../Hooks/useDisplay";
import { useQuoteTestGroupApi } from "./Hooks/useCreateQuoteTestGroup";
import { QuoteTestGroupForm } from "./QuoteTestGroupForm";
import { QuoteTestDashboardProps } from "./QuoteTestDashboard";
import {
  PostQuoteTestGroupBody,
  QuoteTestGroupDto,
} from "@freightsimple/generated-apollo-openapi-client";

export function QuoteTestGroupFormModal(
  props: QuoteTestDashboardProps & {
    refresh: () => Promise<void>;
    group?: QuoteTestGroupDto;
    display: UseDisplayReturn;
  },
) {
  const { carrierIdentifier, serviceIdentifier, refresh, group, display } =
    props;
  const { createGroup, isLoading, updateGroup } = useQuoteTestGroupApi();
  const [form] = Form.useForm<PostQuoteTestGroupBody>();

  async function handleOnFinish(values: PostQuoteTestGroupBody) {
    if (group) {
      await updateGroup({
        ...values,
        groupId: group.groupId,
        carrierIdentifier,
        serviceIdentifier,
        metadata: JSON.stringify(values.metadata),
      });
    } else {
      await createGroup({ ...values, carrierIdentifier, serviceIdentifier });
    }
    display.hide();
    await refresh();
  }

  const initialValues: PostQuoteTestGroupBody = {
    carrierIdentifier,
    serviceIdentifier,
    title: group?.title ?? "",
    description: group?.description ?? "",
    metadata: group?.parsedMetadata ?? null,
  };

  return (
    <Modal
      open={display.status}
      onCancel={display.hide}
      title={group ? "Edit Group" : "Create New Group"}
      confirmLoading={isLoading}
      okText={group ? "Edit" : "Create"}
      onOk={form.submit}
      width={700}
    >
      <QuoteTestGroupForm
        initialValues={initialValues}
        onFinish={handleOnFinish}
        form={form}
      />
    </Modal>
  );
}
