import {
  CarrierRequestAudit,
  QuoteLog,
} from "@freightsimple/generated-apollo-openapi-client";
import { Button, Col, Form, Modal, Row, Switch, Typography } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { useQuotesApi, useShipmentsApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import { CarrierFilter } from "../../Components/CarrierFilter";
import CarrierLogo from "../../Components/CarrierLogo";
import { DataTable, DataTableColumn } from "../../Components/DataTable";
import HorizontalStack from "../../Components/HorizontalStack";
import { Loading } from "../../Components/Loading";
import Stack from "../../Components/Stack";
import Spacer from "../../Spacer";
import { ViewAuditButton } from "../CarrierRequestAuditsScreen";
import { LineItemsTable } from "./LineItemsTable";
import { LocationInfoForQuoting } from "./LocationInfo";
import { ShipmentLineItemsSummary } from "./ManagePickupTab";
import { TabProps } from "./TabProps";
const { Title } = Typography;

function ViewQuoteDetailsButton(props: TabProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  return (
    <>
      <Modal
        title={"Quote Details"}
        visible={isModalVisible}
        onOk={handleOk}
        okText="Done"
        onCancel={handleOk}
        width={1400}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div style={{ width: "100%", overflow: "scroll" }}>
          <Row>
            <Col span={12}>
              <Title level={4}>Pickup</Title>
              <LocationInfoForQuoting
                location={props.shipmentData.shipment.shipment.pickupLocation!}
                context="Pickup"
              />
            </Col>
            <Col span={12}>
              <Title level={4}>Delivery</Title>
              <LocationInfoForQuoting
                location={
                  props.shipmentData.shipment.shipment.deliveryLocation!
                }
                context="Delivery"
              />
            </Col>
          </Row>
          <Spacer height={64} />
          <Stack align="left">
            <Title level={4}>Line Items</Title>

            <ShipmentLineItemsSummary
              shipment={props.shipmentData.shipment.shipment}
            />
            <Spacer height={20} />
            <LineItemsTable
              lineItems={props.shipmentData.shipment.shipment.lineItems!}
            />
          </Stack>
        </div>
      </Modal>
      <Button onClick={showModal} type="primary">
        View Quote Details
      </Button>
    </>
  );
}

interface ViewQuoteLogsTableProps extends TabProps {
  audits: CarrierRequestAudit[];
}

function ViewQuoteLogsTable(props: ViewQuoteLogsTableProps) {
  const shipmentId = props.shipmentData.shipmentId;
  const createQuotesApi = useQuotesApi();
  const [quoteLogs, setQuoteLogs] = useState<QuoteLog[] | undefined>();
  const [carrierIdentifier, setCarrierIdentifier] = useState<
    string | undefined
  >();
  const [
    hideCarriersThatDoNotSupportInstantQuoting,
    setHideCarriersThatDoNotSupportInstantQuoting,
  ] = useState(true);
  const [hideOutOfServiceArea, setHideOutOfServiceArea] = useState(true);

  async function refresh() {
    const quotesApi = await createQuotesApi();

    const response = await quotesApi.getQuoteLogsForShipment({ shipmentId });
    setQuoteLogs(response);
  }

  useEffect(
    function () {
      refresh();
    },

    [shipmentId],
  );

  function getAuditForQuote(quoteId: string) {
    return props.audits.find(function (audit) {
      return audit.quoteId === quoteId;
    });
  }

  const columns: DataTableColumn<QuoteLog>[] = [
    {
      title: "Carrier",
      render: (o) => (
        <Stack align="center">
          <CarrierLogo
            carrierIdentifier={o.carrierIdentifier}
            brokeredCarrierIdentifier={undefined}
            width={60}
            height={40}
          />
        </Stack>
      ),
    },
    {
      title: "Service",
      render: (o) => <div>{o.serviceIdentifier}</div>,
    },
    {
      title: "Reason",
      render: (o) => (
        <div>
          {o.success ? "✅" : "❌"} {o.message}
        </div>
      ),
    },
    {
      title: "Audit",
      render: function (o) {
        const audit = getAuditForQuote(o.quoteId!);
        if (audit === undefined) {
          return <></>;
        }

        return (
          <ButtonRow>
            <ViewAuditButton audit={audit.requestString!}>
              View Request
            </ViewAuditButton>
            <Spacer width={8} />
            <ViewAuditButton audit={audit.responseString!}>
              View Response
            </ViewAuditButton>
          </ButtonRow>
        );
      },
    },
  ];

  if (quoteLogs === undefined) {
    return <Loading />;
  }

  const filteredData = quoteLogs
    .filter(function (ql) {
      if (carrierIdentifier) {
        return ql.carrierIdentifier === carrierIdentifier;
      } else {
        return true;
      }
    })
    .filter(function (ql) {
      if (hideCarriersThatDoNotSupportInstantQuoting) {
        return ql.message !== "Does not support instant quoting";
      } else {
        return true;
      }
    })
    .filter(function (ql) {
      if (hideOutOfServiceArea) {
        return !ql.message?.includes(
          "Cannot service shipment : Out of service area",
        );
      } else {
        return true;
      }
    });

  const grouped = new Map<string, QuoteLog[]>();

  filteredData.forEach(function (ql) {
    const group = ql.quoteGroup ?? "Unknown Group";
    const currentArray = grouped.get(group) ?? [];
    currentArray.push(ql);
    grouped.set(group, currentArray);
  });

  const tables: ReactNode[] = [];
  grouped.forEach(function (quoteLogs, group) {
    tables.push(
      <>
        <Title level={4}>{group}</Title>
        <DataTable pagination={false} columns={columns} data={quoteLogs} />
      </>,
    );
  });

  return (
    <Stack align="left">
      <HorizontalStack
        align="spread"
        verticalAlign="middle"
        style={{ width: "100%" }}
      >
        <HorizontalStack>
          <Form.Item label="Carrier">
            <CarrierFilter
              carrierIdentifier={carrierIdentifier}
              setCarrierIdentifier={setCarrierIdentifier}
            />
          </Form.Item>
          <Spacer width={16} />
          <Form.Item label="Hide email-only">
            <Switch
              checked={hideCarriersThatDoNotSupportInstantQuoting}
              onChange={setHideCarriersThatDoNotSupportInstantQuoting}
            />
          </Form.Item>
          <Spacer width={16} />
          <Form.Item label="Hide out-of-service-area">
            <Switch
              checked={hideOutOfServiceArea}
              onChange={setHideOutOfServiceArea}
            />
          </Form.Item>
        </HorizontalStack>
        <HorizontalStack style={{ position: "relative", top: "-8px" }}>
          <ButtonRow>
            <ViewQuoteDetailsButton {...props} />
          </ButtonRow>
        </HorizontalStack>
      </HorizontalStack>
      <Spacer height={16} />
      {tables}
    </Stack>
  );
}

export function ViewQuoteLogsButton(props: TabProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [audits, setAudits] = useState<CarrierRequestAudit[] | undefined>();
  const createShipmentsApi = useShipmentsApi();

  const shipmentId = props.shipmentData.shipmentId;

  async function loadAudits() {
    const shipmentsApi = await createShipmentsApi();
    const response = await shipmentsApi.carrierRequestAuditsForShipment({
      shipmentId,
    });
    setAudits(response);
  }

  useEffect(
    function () {
      if (isModalVisible) {
        loadAudits();
      }
    },

    [isModalVisible],
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Modal
        title="View Quote Logs"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1400}
      >
        {!audits && <Loading />}
        {audits && <ViewQuoteLogsTable {...props} audits={audits} />}
      </Modal>
      <Button onClick={showModal}>View Quote Logs</Button>
    </>
  );
}
