import { useState } from "react";
import { useQuoteTestApi } from "../../../../Apis/Apis";
import {
  PostQuoteTestExecutionCommentBody,
  QuoteTestExecutionComment,
} from "@freightsimple/generated-apollo-openapi-client";

export function useQuoteTestExecutionCommentApi() {
  const createApi = useQuoteTestApi();

  const [isLoading, setIsLoading] = useState(false);

  const create = async (body: PostQuoteTestExecutionCommentBody) => {
    setIsLoading(() => true);
    try {
      const api = await createApi();
      await api.createComment({
        postQuoteTestExecutionCommentBody: body,
      });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(() => false);
  };

  const update = async (body: QuoteTestExecutionComment) => {
    setIsLoading(() => true);
    try {
      const api = await createApi();
      await api.updateComment({
        quoteTestExecutionComment: body,
      });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(() => false);
  };

  const deleteComment = async (commentId: string) => {
    setIsLoading(() => true);
    try {
      const api = await createApi();
      await api.deleteComment({
        commentId,
      });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(() => false);
  };

  return { create, isLoading, update, deleteComment };
}
