import { Table } from "antd";
import { safeColumns } from "../Helpers/safeColumns";
import { CSSProperties, ReactNode } from "react";

interface KeyValuePair {
  key: ReactNode;
  value: ReactNode;
}

interface KeyValuesProps {
  data: Record<any, any> | KeyValuePair[];
  style?: CSSProperties;
}

export function KeyValues(props: KeyValuesProps) {
  if (Array.isArray(props.data)) {
    return (
      <Table
        showHeader={false}
        columns={safeColumns<KeyValuePair>([
          {
            title: "Key",
            render: (data: KeyValuePair) => (
              <div style={{ fontWeight: 600 }}>{data.key}</div>
            ),
          },
          { title: "Value", render: (data: KeyValuePair) => data.value },
        ])}
        dataSource={props.data}
        pagination={false}
      />
    );
  }

  const dataAsArray = Object.entries(props.data).map(function (entry) {
    return {
      key: entry[0],
      value: entry[1],
    };
  });

  return (
    <Table
      style={props.style}
      showHeader={false}
      columns={safeColumns<{ key: string; value: string }>([
        {
          title: "Key",
          dataIndex: "key",
          key: "key",
          render: (text: string) => (
            <strong>
              {text.split("\n").map((t) => (
                <>
                  {t}
                  <br />
                </>
              ))}
            </strong>
          ),
        },
        {
          title: "Value",
          dataIndex: "value",
          key: "value",
          render: (text: any) => <div>{text}</div>,
        },
      ])}
      dataSource={dataAsArray}
      pagination={false}
    />
  );
}
