import { Button, Table, Tooltip } from "antd";
import { ApiKey } from "@freightsimple/generated-apollo-openapi-client";
import { safeColumns } from "../Helpers/safeColumns";
import { sum } from "../Helpers/sum";
import { ColumnGroupType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";
import { Link } from "react-router-dom";

export interface AdditionalColumns<T> {
  index: number;
  column: ColumnGroupType<T> | ColumnType<T>;
}

export type ApiKeyWithCompanyName<T extends boolean> = T extends true
  ? ApiKey & { companyName: string }
  : ApiKey;

export interface ApiKeysTableProps<T extends boolean = false> {
  data: ApiKeyWithCompanyName<T>[];
  onRefresh: () => Promise<void>;
  additionalColumns?: AdditionalColumns<ApiKey>[];
}

export function ApiKeysTable<T extends boolean>(props: ApiKeysTableProps<T>) {
  function errorForApiKeyName(ak: ApiKey) {
    if (sum(props.data, (ak2) => (ak2.name === ak.name ? 1 : 0)) > 1) {
      return (
        <span style={{ marginLeft: "8px" }}>
          <Tooltip title="Duplicate name">🚨</Tooltip>
        </span>
      );
    } else {
      return "";
    }
  }

  const formatDate = (dateString: string) => {
    if (dateString) {
      const [date, time] = dateString.split("T");
      return (
        <div>
          <div>{date}</div>
          <div>{time.slice(0, -1)}</div>
        </div>
      );
    } else {
      return null;
    }
  };

  const columns = safeColumns<ApiKey>([
    {
      title: "API Key Name",
      dataIndex: "name",
      key: "keyname",
      render: (_, o) => (
        <div>
          {o.name}
          {errorForApiKeyName(o)}
        </div>
      ),
    },
    {
      title: "API Key Description",
      dataIndex: "description",
      key: "description",
      render: (_, o) => <div>{o.description}</div>,
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (_, o) => <div>{o.isActive ? "Active" : "Inactive"}</div>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 180,
      render: (_, o) => <>{formatDate(o.createdAt!)}</>,
    },
    {
      title: "Expires At",
      dataIndex: "expiresAt",
      key: "expiresAt",
      width: 180,
      render: (_, o) => <>{formatDate(o.expiresAt!)}</>,
    },
    {
      title: "Last Used At",
      dataIndex: "lastUsedAt",
      width: 180,
      render: (_, o) => <>{formatDate(o.lastUsedAt!)}</>,
    },
    {
      title: "Revoked At",
      dataIndex: "revokedAt",
      width: 180,
      render: (_, o) => <>{formatDate(o.revokedAt!)}</>,
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (_, o) => (
        <div>
          {o.createdByAdminEmail ? o.createdByAdminEmail : o.createdByEmail}
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, o) => (
        <Link to={`/view-api-key?apiKeyId=${o.apiKeyId}`} target="_blank">
          <Button>View API Key</Button>
        </Link>
      ),
    },
  ]);

  if (props.additionalColumns) {
    props.additionalColumns.forEach((additional) => {
      columns.splice(additional.index, 0, additional.column);
    });
  }

  const dataWithKeys = props.data.map((item) => ({
    ...item,
    key: item.apiKeyId,
  }));

  return (
    <div style={{ width: "100%", overflowX: "auto" }}>
      <Table
        columns={columns}
        dataSource={dataWithKeys}
        pagination={false}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
}
