import { useEffect, useState } from "react";
import { ShipmentApiActionMenuItem } from "./ShipmentApiActionButton";
import { useShipmentsApi } from "../../Apis/Apis";
import { ShipmentsApi } from "@freightsimple/generated-apollo-openapi-client";

interface ToggleAutomaticTrackingProps {
  shipmentId: string;
  onRefresh: () => Promise<void>;
}

export function ToggleAutomaticTracking({
  shipmentId,
  onRefresh,
}: ToggleAutomaticTrackingProps) {
  const [isEnable, setIsEnable] = useState<boolean | null>(null);
  const createShipmentApi = useShipmentsApi();

  useEffect(() => {
    const fetchTrackingStatus = async () => {
      try {
        const shipmentApi = await createShipmentApi();
        const response = await shipmentApi.getAutomaticallyFetchTrackingUpdates(
          {
            shipmentId,
          },
        );
        setIsEnable(response);
      } catch (error) {
        console.error("Failed to fetch tracking status:", error);
      }
    };

    fetchTrackingStatus();
  }, [shipmentId, createShipmentApi]);

  const handleClick = async (shipmentApi: ShipmentsApi) => {
    if (isEnable === null) return;

    const enable = !isEnable;
    setIsEnable(enable);

    try {
      await shipmentApi.setAutomaticallyFetchTrackingUpdates({
        shipmentId,
        enable,
      });
    } catch (error) {
      console.error("Failed to update tracking status:", error);
      setIsEnable(isEnable);
    }
  };

  return (
    <ShipmentApiActionMenuItem
      name={
        isEnable === null
          ? "Loading..."
          : isEnable
            ? "Disable Automatic Updates"
            : "Enable Automatic Updates"
      }
      confirmQuestion={
        isEnable ? "Are you sure you want to disable Automatic Updates?" : ""
      }
      onClick={handleClick}
      onRefresh={onRefresh}
      confirm={!!isEnable}
      hidden={false}
    />
  );
}
