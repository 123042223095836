import { ApiKey } from "@freightsimple/generated-apollo-openapi-client";
import { Button, Form, Input, message, Modal } from "antd";
import { useState } from "react";
import { useApiKeysApi } from "../Apis/Apis";
import { PaperClipOutlined } from "@ant-design/icons";
import { useCopyToClipboard } from "../Hooks/useCopyToClipboard";

interface AddApiKeyButtonProps {
  companyId?: string;
  onApiKeyAdded: (newApiKey: ApiKey) => Promise<void>;
}

export function AddApiKeyButton(props: AddApiKeyButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const title = "Add Api Key";
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [daysUntilExpiration, setDaysUntilExpiration] = useState<
    number | undefined
  >();
  const [saving, setSaving] = useState(false);
  const [apiKey, setApiKey] = useState<string | null>(null);
  const createApiKeysApi = useApiKeysApi();

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (name == "") {
      message.warning("Please enter a name for the api key");
      return;
    }

    setSaving(true);
    try {
      const apiKeysApi = await createApiKeysApi();
      const response = await apiKeysApi.createApiKey({
        name,
        description,
        daysUntilExpiration,
        companyId: props.companyId!,
      });

      setApiKey(response.keyValue);

      await props.onApiKeyAdded({
        name,
        description,
      });
      message.success(`New api key added`);
    } catch {
      message.error(`Eek. Something went wrong`);
    }

    setSaving(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    setName("");
    setDescription("");
    setDaysUntilExpiration(undefined);
    setApiKey(null);
  };

  const copyAPIKey = useCopyToClipboard(apiKey || "");

  return (
    <>
      <Modal
        title={title}
        open={isModalVisible}
        onOk={handleOk}
        confirmLoading={saving}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={900}
        footer={
          apiKey ? (
            <Button onClick={handleCancel}>Cancel</Button>
          ) : (
            <>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button type="primary" onClick={handleOk} loading={saving}>
                OK
              </Button>
            </>
          )
        }
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Form
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            layout="horizontal"
            colon={false}
          >
            <div style={{ width: "37rem" }}>
              <Form.Item label="Name">
                <Input
                  value={name}
                  placeholder="API key name"
                  onChange={function (e) {
                    setName(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item label="Description">
                <Input
                  value={description}
                  placeholder="API key description"
                  onChange={function (e) {
                    setDescription(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item label="Days until expiration">
                <Input
                  value={daysUntilExpiration}
                  type="number"
                  onChange={function (e) {
                    setDaysUntilExpiration(+e.target.value);
                  }}
                />
              </Form.Item>
            </div>
          </Form>

          {apiKey && (
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <strong>API Key:</strong>
              <Input.TextArea
                value={apiKey}
                readOnly
                autoSize
                style={{ marginTop: "10px" }}
              />
              <p style={{ color: "red" }}>
                Please copy this API key now. You won't be able to see it again!
              </p>
              <Button onClick={copyAPIKey} icon={<PaperClipOutlined />}>
                Copy API Key
              </Button>
            </div>
          )}
        </div>
      </Modal>
      <Button onClick={showModal}>{title}</Button>
    </>
  );
}
