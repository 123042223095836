import { Modal } from "antd";
import { UseDisplayReturn } from "../../../../Hooks/useDisplay";
import { RefreshFunction } from "../../../../Types/RefreshFunctionType";
import { useQuoteTestGroupApi } from "../Hooks/useCreateQuoteTestGroup";
import { CarrierAndServiceFilter } from "../../../../Components/CarrierAndServiceFilter";
import { QuoteTestDashboardProps } from "../QuoteTestDashboard";
import { useState } from "react";
import CarriersData from "../../../../GeneratedFixtures/CarriersData.json";
import { QuotingStrategyType } from "@freightsimple/generated-apollo-openapi-client";

export function CloneQuoteTestGroupModal(
  props: QuoteTestDashboardProps & {
    groupId: string;
    refresh: RefreshFunction;
    display: UseDisplayReturn;
  },
) {
  const { refresh, display, carrierIdentifier, serviceIdentifier, groupId } =
    props;
  const { cloneGroup } = useQuoteTestGroupApi();

  const [localCarrierIdentifier, setLocalCarrierIdentifier] =
    useState(carrierIdentifier);
  const [localServiceIdentifier, setLocalServiceIdentifier] =
    useState(serviceIdentifier);

  async function handleFinish() {
    await cloneGroup(groupId, localCarrierIdentifier, localServiceIdentifier);
    await refresh();
  }

  return (
    <Modal
      title="Clone Group"
      onOk={handleFinish}
      onCancel={display.hide}
      okText="Clone"
      open={display.status}
      width={600}
      height={800}
    >
      <CarrierAndServiceFilter
        carriers={CarriersData.filter(
          (c) =>
            c.serviceProperties.quotingStrategy.strategyType ==
            QuotingStrategyType.Instant,
        ).toSorted((a, b) =>
          a.carrierIdentifier.localeCompare(b.carrierIdentifier),
        )}
        carrierIdentifier={localCarrierIdentifier}
        serviceIdentifier={localServiceIdentifier}
        onChange={(carrierIdentifier, serviceIdentifier) => {
          setLocalCarrierIdentifier(carrierIdentifier);
          setLocalServiceIdentifier(serviceIdentifier);
        }}
      />
    </Modal>
  );
}
