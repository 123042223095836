import { useState } from "react";
import { useQuoteTestApi } from "../../../../Apis/Apis";
import { QuoteTestExecutionType } from "@freightsimple/generated-apollo-openapi-client";

export function useRunQuoteTestScenario() {
  const createApi = useQuoteTestApi();

  const [isLoading, setIsLoading] = useState(false);

  const run = async (
    scenarioId: string,
    tags: Array<string>,
    executionType: QuoteTestExecutionType,
  ) => {
    setIsLoading(() => true);
    try {
      const api = await createApi();
      await api.runScenario({
        scenarioId,
        tags,
        executionType,
      });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(() => false);
  };

  return { run, isLoading };

}
